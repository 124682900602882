/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect, useContext, useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import { getLeads, updateLead, getLeadsByFiltro, getLeadsByFiltroText, insertLead } from "./actions/contactosangariados";
import Pagination from "./Pagination";
const ContactosAngariadosDirectorClube = ({ auth: {user}, getLeads, contactosangariados: { data }, updateLead, getLeadsByFiltro, getLeadsByFiltroText, insertLead }) => {
    useLayoutEffect(() => {
        getLeads();
    }, []);
    useEffect(() => {
        setLeadsData({...leadsData,
            selected_colaborador: data ? data.selected_colaborador ? data.selected_colaborador : 0 : 0,
            tipo_conctacto_filtro: data ? data.tipo_conctacto_filtro ? data.tipo_conctacto_filtro : 0 : 0,
            nome_socio_filtro: data ? data.nome_socio_filtro ? data.nome_socio_filtro : "" : "",
            telemovel_socio_filtro: data ? data.telemovel_socio_filtro ? data.telemovel_socio_filtro : "" : "",
            alert: data ? data.alert ? data.alert : "" : "",
            loading: false,
            openModal: data ? data.alert ? true : false : false,
        });

    }, [data]);
    const [leadsData, setLeadsData] = useState({
        openModal: false,
        editNome: "",
        editContacto: "",
        leadID: "",
        leadClube: 0,
        leadStatus: 0,
        leadNotes:"",
        leadConsultor: "",
        leadMarcacao: "",
        tipo_conctacto: "",
        leadContacto: "",
        leadNome: "",
        alert: "",
        selected_colaborador: "",
        tipo_conctacto_filtro: "",
        nome_socio_filtro: "",
        telemovel_socio_filtro: "",
        loading: false,
        modalType: ""
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let resultados = data ? data.results ? data.results : [] : [];
    const currentPosts = resultados.slice(indexOfFirstPost,indexOfLastPost);
    const paginate = (e,pageNumber) => {
        e.preventDefault()
        setCurrentPage(pageNumber)
    }
    const { openModal,editNome,editContacto, leadID, leadClube, leadStatus, leadNotes, leadConsultor, leadMarcacao,tipo_conctacto,leadContacto, leadNome, alert, modalType, selected_colaborador, tipo_conctacto_filtro, nome_socio_filtro, telemovel_socio_filtro, loading } = leadsData;
    const onChange = e => setLeadsData({...leadsData, [e.target.name]: e.target.value});
    const onOpenLead = (e,id, nome, contacto, consultor, status, outras_notas, id_clube, tipo_conctacto) => {
        setLeadsData({...leadsData, ["leadID"]: id,
            ["openModal"]: true,
            ["editNome"]: nome,
            ["editContacto"]: contacto,
            ["leadConsultor"]: consultor ? consultor : "",
            ["leadStatus"]: status ? status : 0,
            ["leadNotes"]: outras_notas ? outras_notas : "",
            ["leadClube"]: id_clube,
            ["tipo_conctacto"]: tipo_conctacto,
            ["modalType"]: "update",
            ["leadMarcacao"]: "",
            ["leadNome"]: "",
            ["leadContacto"]: "",
        });
    }
    const onChangeCol =  async (e) => {
        setCurrentPage(1)
        setLeadsData({...leadsData,
           [e.target.name]: e.target.value,
            ["loading"]: true
       });


        let selectedData = selected_colaborador,
            tipoContactoDate = tipo_conctacto_filtro;

        if(selected_colaborador != e.target.value && "selected_colaborador" === e.target.name)
            selectedData =  e.target.value

        if(tipo_conctacto_filtro != e.target.value && "tipo_conctacto_filtro" === e.target.name)
            tipoContactoDate =  e.target.value

         try  {
            await getLeadsByFiltro({ selected_colaborador:   parseInt(selectedData), tipo_conctacto_filtro:  parseInt(tipoContactoDate) }).then((err,res)=> {
                setLeadsData({...leadsData, ["loading"]: false});
            })
        } catch (e){
             setLeadsData({...leadsData, ["loading"]: false});
        }

    };
    const onChangeText = async (e) => {
        setCurrentPage(1)
         let nomeData = nome_socio_filtro,
            telemovelData = telemovel_socio_filtro;

        if(nome_socio_filtro != e.target.value && "nome_socio_filtro" === e.target.name) {
            nomeData = e.target.value;
            telemovelData = "";
            setLeadsData({...leadsData,
                [e.target.name]: e.target.value,
                ["loading"]: true,
                ["telemovel_socio_filtro"]: ""
            });
        }

        if(telemovel_socio_filtro != e.target.value && "telemovel_socio_filtro" === e.target.name) {
            telemovelData = e.target.value;
            nomeData = "";
            setLeadsData({...leadsData,
                [e.target.name]: e.target.value,
                ["loading"]: true,
                ["nome_socio_filtro"]: ""
            });
        }


        try {
            await getLeadsByFiltroText({ nome_socio_filtro: nomeData, telemovel_socio_filtro: telemovelData })
        } catch (e){

        }

    };
    const onOpenModal = (e) => {
        setLeadsData({...leadsData,
            ["openModal"]:true,
            ["editNome"]: "",
            ["editContacto"]: "",
            ["leadConsultor"]:"",
            ["leadStatus"]: "",
            ["leadNotes"]: "",
            ["leadClube"]: "",
            ["tipo_conctacto"]: "",
            ["modalType"]: "",
        });
    }
    const onCloseModal = (e) => {
        setLeadsData({...leadsData,
            ["openModal"]:false,
            ["editNome"]: "",
            ["editContacto"]: "",
            ["leadConsultor"]:"",
            ["leadStatus"]: "",
            ["leadNotes"]: "",
            ["leadClube"]: "",
            ["tipo_conctacto"]: "",
            ["modalType"]: "",

        });
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        setLeadsData({...leadsData, ["loading"]: true});

        try{
            await updateLead({ leadID, leadClube, leadStatus, leadNotes, leadConsultor, leadMarcacao, tipo_conctacto })
                setLeadsData({...leadsData, ["loading"]: false});
        }catch(e){
            setLeadsData({...leadsData, ["loading"]: false});
        }
    }
    const onNewLead = async (e) => {
        e.preventDefault();
        setLeadsData({...leadsData, ["loading"]: true});

        try{
            await insertLead({ leadID, leadClube, leadStatus, leadNotes, leadConsultor, leadMarcacao, tipo_conctacto, leadContacto, leadNome })
            setLeadsData({...leadsData, ["loading"]: false});
        }catch(e){
            setLeadsData({...leadsData, ["loading"]: false});
        }
    }

    return user === null ? <Fragment>
            <div><img src="./img/loading.gif" alt=""/></div>
        </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container">
                <div className="container">
                    <div className="breadcrumbs">
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Gestão Comercial >
                        <Link to={"/contactosangariados"}>Contactos Angariados</Link>
                    </div>
                    {loading ? (<div className={"loading-spinner"}></div>) : ("")}
                    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="" style={{marginRight: "20px"}}>
                            Colaborador:
                            <select name="selected_colaborador"   value={selected_colaborador} onChange={ e => onChangeCol(e)}>
                                <option value="0">TODOS</option>
                                {user && user.colaboradores.map((e) => (
                                    <option value={e.id}>{e.nome}</option>
                                ))}
                            </select>
                        </label>
                        <label htmlFor=""  style={{marginRight: "20px"}}>
                            Tipo de Contacto:
                            <select name="tipo_conctacto_filtro"  value={tipo_conctacto_filtro} id="" onChange={ e => onChangeCol(e)}>
                                <option value="0">TODOS</option>
                                <option value="1">LEADS</option>
                                <option value="2">POS</option>
                                <option value="3">MI</option>
                                <option value="4">PROSPECT</option>
                                <option value="5">PARCERIAS</option>
                            </select>
                        </label>
                        <label htmlFor="">
                            Nome:
                            <input type="text" name="nome_socio_filtro"  value={nome_socio_filtro} id="" onChange={ e => onChangeText(e)} />
                        </label>
                        <label htmlFor="">
                            Telemovel:
                            <input type="number" name="telemovel_socio_filtro"  value={telemovel_socio_filtro} id="" onChange={ e => onChangeText(e)} />
                        </label>
                    </div>
                    <button  onClick={e => onOpenModal(e)} className={"new-btn"} style={{maxWidth: "200px", margin: "20px 0 0 0"}}>Novo</button>


                        <table className="table-format-one">
                            <thead>
                            <tr>
                                <th className="hide">Nome</th>
                                <th>Contacto</th>
                                <th className="hide">Data de Entrada</th>
                                <th className="hide">Tipo de Contacto</th>
                                <th className="hide">Data de Contacto</th>
                                <th className="hide">Consultor</th>
                            </tr>
                            </thead>
                            <tbody>

                                {currentPosts.map(({id,nome, contacto, repeated_phone, consultor_id, status, outras_notas, id_clube, data_entrada, tipo_contacto, data_contacto}) => (
                                    <tr className={parseInt(status) === 0 ? "to-call" :  parseInt(status) === 1 ? "green-call" : parseInt(status) === 2 ? "yellow-call" : parseInt(status) === 3 ? "red-call" : parseInt(status) === 4 ? "call-again" : parseInt(status) === 5 ? "to-call" : parseInt(status) === 6 ? "outros-call" : "outros-call"} onClick={ e => onOpenLead(e,id, nome, contacto, consultor_id, status, outras_notas, id_clube, tipo_contacto)}>
                                        <td> {nome}      </td>
                                        <td>
                                            {((repeated_phone, contacto) => {
                                                if(parseInt(repeated_phone) === 1)
                                                    return <strike> Repetido </strike>
                                                else
                                                    return contacto
                                            })(repeated_phone, contacto)}
                                        </td>
                                        <td> {
                                            data_entrada ? (
                                                (parseInt(new Date(data_entrada).getDate())  < 10 ? ("0" + new Date(data_entrada).getDate()) : new Date(data_entrada).getDate())
                                                + "/" +
                                                (parseInt(new Date(data_entrada).getMonth() +1)  < 10 ? ("0" + (new Date(data_entrada).getMonth() +1)) : new Date(data_entrada).getMonth() +1)
                                                + "/" +
                                                (parseInt(new Date(data_entrada).getFullYear())) + " " +

                                            (parseInt(new Date(data_entrada).getHours())  < 10 ? ("0" + new Date(data_entrada).getHours()) : new Date(data_entrada).getHours())
                                        + ":" +
                                        (parseInt(new Date(data_entrada).getMinutes())  < 10 ? ("0" + new Date(data_entrada).getMinutes()) : new Date(data_entrada).getMinutes())
                                            ): ("")
                                        }  </td>
                                        <td>
                                            {((tipo_contacto) => {
                                                if(parseInt(tipo_contacto) === 2)
                                                    return "POS"
                                                else  if(parseInt(tipo_contacto) === 3)
                                                    return "MI"
                                                else  if(parseInt(tipo_contacto) === 4)
                                                    return "PROSPECT"
                                                else  if(parseInt(tipo_contacto) === 5)
                                                    return "PARCERIAS"
                                                else
                                                    return "LEADS"
                                            })(tipo_contacto)}
                                        </td>
                                        <td> {
                                            data_contacto ? (
                                                (parseInt(new Date(data_contacto).getDate())  < 10 ? ("0" + new Date(data_contacto).getDate()) : new Date(data_contacto).getDate())
                                                + "/" +
                                                (parseInt(new Date(data_contacto).getMonth() +1)  < 10 ? ("0" + (new Date(data_contacto).getMonth() +1)) : new Date(data_contacto).getMonth() +1)
                                                + "/" +
                                                (parseInt(new Date(data_contacto).getFullYear())) + " " +

                                                (parseInt(new Date(data_contacto).getHours())  < 10 ? ("0" + new Date(data_contacto).getHours()) : new Date(data_contacto).getHours())
                                                + ":" +
                                                (parseInt(new Date(data_contacto).getMinutes())  < 10 ? ("0" + new Date(data_contacto).getMinutes()) : new Date(data_contacto).getMinutes())
                                            ): ("")
                                        }  </td>
                                        <td>

                                            {user && user.colaboradores.map((element) => { if(parseInt(element.id) === parseInt(consultor_id)) return element.nome  })} </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    <Pagination postsPerPAge={postsPerPage} totalPosts={resultados.length} paginate={paginate} currentPage={currentPage}/>
                </div>
                <div className={openModal ? "new-lead-modal active": "new-lead-modal"}>
                     <span id="close-new-lead-modal" onClick={ e => onCloseModal(e)}>
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus"
                                 className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 384 512"><path fill="currentColor"
                                                             d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                    </span>
                    <form onSubmit={e => {modalType === "update" ? (
                            onSubmit(e)
                        ) : (
                            onNewLead(e)
                        )} }>

                        {modalType === "update" ? (
                            <h2>{editNome}</h2>
                        ) : (
                            <h2><input type="text" placeholder={"Nome"} name="leadNome" value={leadNome} onChange={e => onChange(e)} required/></h2>
                        )}

                        <label htmlFor="">Status:
                            <select name="leadStatus" value={leadStatus} onChange={e => onChange(e)} required>
                                <option value="">Selecione um Status</option>
                                {leadStatus === 1 ? (<option value="1" selected>Atendeu e Marcou</option>) : (<option value="1" >Atendeu e Marcou</option>)}
                                {leadStatus === 2 ? (<option value="2" selected>Atendeu e Não Marcou</option>) : (<option value="2" >Atendeu e Não Marcou</option>)}
                                {leadStatus === 3 ? (<option value="3" selected>Não Tem Interesse</option>) : (<option value="3" >Não Tem Interesse</option>)}
                                {leadStatus === 4 ? (<option value="4" selected>Não Atendeu</option>) : (<option value="4" >Não Atendeu</option>)}
                                {leadStatus === 5 ? (<option value="5" selected>Não Foi Contactado</option>) : (<option value="5" >Não Foi Contactado</option>)}
                                {leadStatus === 6 ? (<option value="6" selected>Outros/Obersvações</option>) : (<option value="6" >Outros/Obersvações</option>)}
                            </select>
                        </label>
                        <label htmlFor="">Clube:
                            <select name="leadClube" value={leadClube} onChange={e => onChange(e)} required>
                                <option value="">Selecione um Clube</option>
                                {leadClube === 1 ? (<option value="1" selected>Faro</option>) : (<option value="1" >Faro</option>)}
                                {leadClube === 2 ? (<option value="2" selected>Olhão</option>) : (<option value="2" >Olhão</option>)}
                                {leadClube === 3 ? (<option value="3" selected>Tavira</option>) : (<option value="3" >Tavira</option>)}
                                {leadClube === 4 ? (<option value="4" selected>Portimão</option>) : (<option value="4" >Portimão</option>)}
                            </select>
                        </label>
                        <label htmlFor="">Tipo Contacto:
                            <select name="tipo_conctacto"  value={tipo_conctacto} id="" onChange={ e => onChange(e)} required>
                                <option value="">TODOS</option>
                                <option value="1">LEADS</option>
                                <option value="2">POS</option>
                                <option value="3">MI</option>
                                <option value="4">PROSPECT</option>
                                <option value="5">PARCERIAS</option>
                            </select>
                        </label>
                        <label htmlFor="" className="form-column">
                            Data da Marcação:
                            <input type="datetime-local" name="leadMarcacao" value={leadMarcacao} onChange={e => onChange(e)} />
                        </label>
                        <label htmlFor="">
                            <textarea cols="5" rows="2" name="leadNotes" value={leadNotes} onChange={e => onChange(e)}></textarea>
                        </label>
                        <label htmlFor="">
                            {modalType === "update" ? (
                                <>
                                    Contacto: {editContacto}
                                </>
                            ) : (
                                <>
                                    Contacto: <input type="number"  name="leadContacto" value={leadContacto} onChange={e => onChange(e)} required/>
                                </>
                            )}

                        </label>
                        <label htmlFor="">Consultor:
                            <select name="leadConsultor" value={leadConsultor} onChange={e => onChange(e)} required>
                                <option value="">Selecione uma Opção</option>

                                {user && user.colaboradores.map(({id,nome}) => {
                                    if(id === leadConsultor)
                                       return <option value={id} selected>{nome}</option>
                                    else
                                        return <option value={id} >{nome}</option>
                                })}
                            </select>
                        </label>
                        <button>GUARDAR</button>
                        <div className={alert ? "alert alert-danger active": "alert alert-danger"}>{alert}</div>

                    </form>
                </div>
            </div>
        </Fragment>
};
ContactosAngariadosDirectorClube.propTypes = {
    getLeads: PropTypes.func.isRequired,
    updateLead:  PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    contactosangariados: PropTypes.object.isRequired,
    getLeadsByFiltro: PropTypes.func.isRequired,
    getLeadsByFiltroText: PropTypes.func.isRequired,
    insertLead: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    contactosangariados: state.contactosangariados
});
export default connect(mapStateToProps, { getLeads, updateLead, getLeadsByFiltro, getLeadsByFiltroText, insertLead })( ContactosAngariadosDirectorClube );
