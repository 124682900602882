/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_NAOCONVERTIDOS,
    GET_NAOCONVERTIDOS_ERROR,
} from "./types";

export const getLeads = () => async dispatch => {

    try {
        const res = await axios.get("/api/contactosnaoconvertidos");


        dispatch({
            type: GET_NAOCONVERTIDOS,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_NAOCONVERTIDOS_ERROR,
        })
    }
}
export const getLeadsByFiltro = ({selected_colaborador, tipo_conctacto_filtro}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { selected_colaborador, tipo_conctacto_filtro };
    try {
        const res = await axios.post("/api/contactosnaoconvertidos", body, config);


        dispatch({
            type: GET_NAOCONVERTIDOS,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_NAOCONVERTIDOS_ERROR,
        })
    }
}
export const getLeadsByFiltroText = ({nome_socio_filtro, telemovel_socio_filtro}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { nome_socio_filtro, telemovel_socio_filtro };
    try {
        const res = await axios.post("/api/leads/filtro", body, config);


        dispatch({
            type: GET_NAOCONVERTIDOS,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_NAOCONVERTIDOS_ERROR,
        })
    }
}
export const updateLead = ({ leadID, leadClube, leadStatus, leadNotes, leadConsultor, leadMarcacao, tipo_conctacto  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { leadID, leadClube, leadStatus, leadNotes, leadConsultor, leadMarcacao, tipo_conctacto  };
    try {
        const res = await axios.post("/api/leads/update", body, config);

        dispatch({
            type: GET_NAOCONVERTIDOS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_NAOCONVERTIDOS_ERROR,
        })
    }
}
export const insertLead = ({ leadID, leadClube, leadStatus, leadNotes, leadConsultor, leadMarcacao, tipo_conctacto, leadContacto, leadNome  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { leadID, leadClube, leadStatus, leadNotes, leadConsultor, leadMarcacao, tipo_conctacto ,  leadContacto, leadNome  };
    try {
        const res = await axios.post("/api/leads/new", body, config);

        dispatch({
            type: GET_NAOCONVERTIDOS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_NAOCONVERTIDOS_ERROR,
        })
    }
}


