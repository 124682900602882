/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getInstrutorSalaAvaliacoes, updateInstrutorSala, deleteInstrutorSala } from "./actions/instrutorsala";
import Pagination from "../gestaocomercial/Pagination";

const InstrutorSalaDirectorClube = ({ auth: {user}, getInstrutorSalaAvaliacoes, updateInstrutorSala, deleteInstrutorSala, instrutorsala: {data }  }) => {

    useEffect(() => {
        getInstrutorSalaAvaliacoes({id_clube : 1});
    },[]);

    useLayoutEffect(() => {
        setInstrutorSalaData({...instrutorSalaData,
            modal: data ? !!data.err : false,
            err: data ? data.err ?  data.err : false : false,
            clube_selected: data ? data.clube_selected ?  data.clube_selected : 1 : 1,
            loading:false,
        });
    },[data]);

    const [instrutorSalaData, setInstrutorSalaData] = useState({
        modal: false,
        id: "",
        instrutor_select: "",
        av_observador: "",
        av_instru_chega: "",
        av_instru_verifica_sala: "",
        av_instru_col_posicao: "",
        av_instru_obs: "",
        av_instru_desf: "",
        av_instru_corrige_verb: "",
        av_instru_corrige_demos: "",
        av_instru_conhece: "",
        av_instru_passa_turno: "",
        av_instru_app: "",
        av_instru_valida_app: "",
        av_instru_controla_app: "",
        av_instru_observacao: "",
        error: "",
        clube_selected: 1,
    });

    const { clube_selected, id, modal, instrutor_select, av_observador, av_instru_chega, av_instru_cumpr,  av_instru_verifica_sala,  av_instru_col_posicao,
        av_instru_obs, av_instru_desf, av_instru_corrige_verb, av_instru_corrige_demos, av_instru_conhece, av_instru_passa_turno, av_instru_app,
        av_instru_valida_app, av_instru_controla_app, av_instru_observacao
    } = instrutorSalaData;
    const onChange = e => setInstrutorSalaData({...instrutorSalaData, [e.target.name]: e.target.value});

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let resultados = data ? data.instrutorsala ? data.instrutorsala : [] : [];
    const currentPosts = resultados.slice(indexOfFirstPost,indexOfLastPost);


    const paginate = (e,pageNumber) => {
        e.preventDefault()
        setCurrentPage(pageNumber)
    }

    const onChangeDate = async e => {
        setInstrutorSalaData({...instrutorSalaData, [e.target.name]: e.target.value});


    };

    const onSubmit = async e => {
        e.preventDefault();

        try {
            await updateInstrutorSala({instrutorSalaData, id_clube: clube_selected})
        } catch (e) {

        }
    }

    const onDelete = async e => {
        e.preventDefault();
        
        await deleteInstrutorSala({id_clube: clube_selected, id});
       
    }

    const onOpenModal = async e => {
        e.preventDefault();
        setInstrutorSalaData({...instrutorSalaData, ["modal"]: modal ? false : true,
            ["av_observador"]: "",
            ["instrutor_select"]: "",
            ["av_instru_chega"]: "",
            ["av_instru_verifica_sala"]: "",
            ["av_instru_col_posicao"]: "",
            ["av_instru_obs"]: "",
            ["av_instru_desf"]: "",
            ["av_instru_corrige_verb"]: "",
            ["av_instru_corrige_demos"]: "",
            ["av_instru_conhece"]: "",
            ["av_instru_passa_turno"]: "",
            ["av_instru_app"]: "",
            ["av_instru_valida_app"]: "",
            ["av_instru_controla_app"]: "",
            ["av_instru_observacao"]: "",
            ["id"]: "",
        });

    }
    const onOpenModalValues  = async (e, id, instrutor, observador, avaliacao) => {
        e.preventDefault();
        let personalTrainerContent = JSON.parse(avaliacao);

        setInstrutorSalaData({...instrutorSalaData,
            ["modal"]: modal ? false : true,
            ["av_observador"]: observador,
            ["instrutor_select"]: instrutor,
            ["av_instru_chega"]: personalTrainerContent ? personalTrainerContent.av_instru_chega  ? personalTrainerContent.av_instru_chega : 0 : 0,
            ["av_instru_verifica_sala"]: personalTrainerContent ? personalTrainerContent.av_instru_verifica_sala  ? personalTrainerContent.av_instru_verifica_sala : 0 : 0,
            ["av_instru_col_posicao"]: personalTrainerContent ? personalTrainerContent.av_instru_col_posicao  ? personalTrainerContent.av_instru_col_posicao : 0 : 0,
            ["av_instru_obs"]: personalTrainerContent ? personalTrainerContent.av_instru_obs  ? personalTrainerContent.av_instru_obs : 0 : 0,
            ["av_instru_desf"]: personalTrainerContent ? personalTrainerContent.av_instru_desf  ? personalTrainerContent.av_instru_desf : 0 : 0,
            ["av_instru_corrige_verb"]: personalTrainerContent ? personalTrainerContent.av_instru_corrige_verb  ? personalTrainerContent.av_instru_corrige_verb : 0 : 0,
            ["av_instru_corrige_demos"]: personalTrainerContent ? personalTrainerContent.av_instru_corrige_demos  ? personalTrainerContent.av_instru_corrige_demos : 0 : 0,
            ["av_instru_conhece"]: personalTrainerContent ? personalTrainerContent.av_instru_conhece  ? personalTrainerContent.av_instru_conhece : 0 : 0,
            ["av_instru_passa_turno"]: personalTrainerContent ? personalTrainerContent.av_instru_passa_turno  ? personalTrainerContent.av_instru_passa_turno : 0 : 0,
            ["av_instru_app"]: personalTrainerContent ? personalTrainerContent.av_instru_app  ? personalTrainerContent.av_instru_app : 0 : 0,
            ["av_instru_valida_app"]: personalTrainerContent ? personalTrainerContent.av_instru_valida_app  ? personalTrainerContent.av_instru_valida_app : 0 : 0,
            ["av_instru_controla_app"]: personalTrainerContent ? personalTrainerContent.av_instru_controla_app  ? personalTrainerContent.av_instru_controla_app : 0 : 0,
            ["av_instru_observacao"]: personalTrainerContent ? personalTrainerContent.av_instru_observacao  ? personalTrainerContent.av_instru_observacao : 0 : 0,
            ["id"]: id,
        });

    }
    const onChangeConsultor = async e => {
        setInstrutorSalaData({...instrutorSalaData, [e.target.name]: e.target.value});
        await getInstrutorSalaAvaliacoes({id_clube: e.target.value });
    };
    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Serviço ao Cliente >
                        <Link to={"/instrutorsala"}>Instrutor Sala</Link>
                    </div>
                    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="">
                            Clube:
                            <select name="clube_selected"   value={clube_selected} onChange={ e => onChangeConsultor(e)} style={{textTransform: "capitalize"}}>
                                {user && user.clubes.map((e) => (
                                    <option value={e.id} >{e.localidade}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <button onClick={e => onOpenModal(e)} className={"new-btn"}>Novo</button>

                    <table className="table-format-one">
                        <thead>
                            <tr>
                                <th>Instrutor</th>
                                <th>Observador</th>
                                <th>Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts && currentPosts.map(({id, instrutor, observador, date, nome_pt, avaliacao }) => (
                                <tr className={"to-call"} onClick={e => onOpenModalValues(e, id, instrutor, observador, avaliacao )}>
                                    <td>{nome_pt}</td>
                                    <td>{observador}</td>
                                    <td>  { date ? (
                                        (parseInt(new Date(date).getDate())  < 10 ? ("0" + new Date(date).getDate()) : new Date(date).getDate())
                                        + "/" +
                                        (parseInt(new Date(date).getMonth() + 1)  < 10 ? ("0" + (new Date(date).getMonth() +1)) : new Date(date).getMonth() +1)
                                        + "/" +
                                        (parseInt(new Date(date).getFullYear())) + " " + (
                                            (parseInt(new Date(date).getHours())  < 10 ? ("0" + new Date(date).getHours()) : new Date(date).getHours())
                                            + ":" +
                                            (parseInt(new Date(date).getMinutes())  < 10 ? ("0" + new Date(date).getMinutes()) : new Date(date).getMinutes())
                                        )
                                    ): ("") }</td>

                                </tr>
                            ))}

                        </tbody>
                    </table>
                    <Pagination postsPerPAge={postsPerPage} totalPosts={resultados.length} paginate={paginate} currentPage={currentPage}/>

                    <div className={modal ? "new-lead-modal new-personaltable active": "new-lead-modal new-personaltable"}>
                         <span id="close-new-lead-modal" onClick={ e => onOpenModal(e)}>
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => onSubmit(e)}>
                            <div className="top-form">
                                <label htmlFor="">
                                    Instrutor
                                    <select name={"instrutor_select"} value={instrutor_select} onChange={e => onChange(e)} required>
                                       <option value="">Seleciona um Instrutor</option>
                                        {user && user.personaltrainers.map((e) => (
                                            <option value={e.id}>{e.nome}</option>
                                        ))}
                                    </select>
                                </label>
                                <label htmlFor="">
                                    Observador
                                    <input type="text" name={"av_observador"} value={av_observador} onChange={e => onChange(e)} required/>
                                </label>

                            </div>
                            <label htmlFor=""><h3>Critério de observação</h3></label>
                            <label htmlFor="">
                                Pontualidade/ Chega 5' antes do turno iniciar
                                <span>
                                      Faz
                                    <input type="radio" name={"av_instru_chega"} value={"1"} onChange={e => onChange(e)} checked={av_instru_chega === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_instru_chega"} value={"2"} onChange={e => onChange(e)} checked={av_instru_chega === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_instru_chega"} value={"3"} onChange={e => onChange(e)} checked={av_instru_chega === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_instru_chega"} value={"4"} onChange={e => onChange(e)} checked={av_instru_chega === "4"} required/>
                                </span>
                            </label>

                            <label htmlFor="">
                                Apresentação (farda, badge)
                                <span>
                                      Faz
                                    <input type="radio" name={"av_instru_cumpr"} value={"1"} onChange={e => onChange(e)} checked={av_instru_cumpr === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_instru_cumpr"} value={"2"} onChange={e => onChange(e)} checked={av_instru_cumpr === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_instru_cumpr"} value={"3"} onChange={e => onChange(e)} checked={av_instru_cumpr === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_instru_cumpr"} value={"4"} onChange={e => onChange(e)} checked={av_instru_cumpr === "4"} required/>
                                </span>
                            </label>

                            <label htmlFor="">
                                Cumprimenta/Despede-se dos clientes
                                <span>
                                      Faz
                                    <input type="radio" name={"av_instru_verifica_sala"} value={"1"} onChange={e => onChange(e)} checked={av_instru_verifica_sala === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_instru_verifica_sala"} value={"2"} onChange={e => onChange(e)} checked={av_instru_verifica_sala === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_instru_verifica_sala"} value={"3"} onChange={e => onChange(e)} checked={av_instru_verifica_sala === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_instru_verifica_sala"} value={"4"} onChange={e => onChange(e)} checked={av_instru_verifica_sala === "4"} required/>
                                </span>
                            </label>

                            <label htmlFor="">
                                Verifica/arruma sala e equipamentos
                                <span>
                                      Faz
                                    <input type="radio" name={"av_instru_col_posicao"} value={"1"} onChange={e => onChange(e)} checked={av_instru_col_posicao === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_instru_col_posicao"} value={"2"} onChange={e => onChange(e)} checked={av_instru_col_posicao === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_instru_col_posicao"} value={"3"} onChange={e => onChange(e)} checked={av_instru_col_posicao === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_instru_col_posicao"} value={"4"} onChange={e => onChange(e)} checked={av_instru_col_posicao === "4"} required/>
                                </span>
                            </label>

                            <label htmlFor="">
                                Coloca-se em posição e postura privilegiada na sala
                                <span>
                                      Faz
                                    <input type="radio" name={"av_instru_obs"} value={"1"} onChange={e => onChange(e)} checked={av_instru_obs === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_instru_obs"} value={"2"} onChange={e => onChange(e)} checked={av_instru_obs === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_instru_obs"} value={"3"} onChange={e => onChange(e)} checked={av_instru_obs === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_instru_obs"} value={"4"} onChange={e => onChange(e)} checked={av_instru_obs === "4"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Observa clientes e intervem em ajudas garantindo a sua execução
                                <span>
                                      Faz
                                    <input type="radio" name={"av_instru_desf"} value={"1"} onChange={e => onChange(e)} checked={av_instru_desf === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_instru_desf"} value={"2"} onChange={e => onChange(e)} checked={av_instru_desf === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_instru_desf"} value={"3"} onChange={e => onChange(e)} checked={av_instru_desf === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_instru_desf"} value={"4"} onChange={e => onChange(e)} checked={av_instru_desf === "4"} required/>
                                </span>

                            </label>

                            <label htmlFor="">
                                Corrige exercicios verbalmente
                                <span>
                                      Faz
                                    <input type="radio" name={"av_instru_corrige_verb"} value={"1"} onChange={e => onChange(e)} checked={av_instru_corrige_verb === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_instru_corrige_verb"} value={"2"} onChange={e => onChange(e)} checked={av_instru_corrige_verb === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_instru_corrige_verb"} value={"3"} onChange={e => onChange(e)} checked={av_instru_corrige_verb === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_instru_corrige_verb"} value={"4"} onChange={e => onChange(e)} checked={av_instru_corrige_verb === "4"} required/>
                                </span>

                            </label>

                            <label htmlFor="">
                                Corrige exercícios com demonstração
                                <span>
                                      Faz
                                    <input type="radio" name={"av_instru_corrige_demos"} value={"1"} onChange={e => onChange(e)} checked={av_instru_corrige_demos === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_instru_corrige_demos"} value={"2"} onChange={e => onChange(e)} checked={av_instru_corrige_demos === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_instru_corrige_demos"} value={"3"} onChange={e => onChange(e)} checked={av_instru_corrige_demos === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_instru_corrige_demos"} value={"4"} onChange={e => onChange(e)} checked={av_instru_corrige_demos === "4"} required/>
                                </span>

                            </label>
                            <label htmlFor="">
                                Demonstra os exercícios corretamente
                                <span>
                                      Faz
                                    <input type="radio" name={"av_instru_conhece"} value={"1"} onChange={e => onChange(e)} checked={av_instru_conhece === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_instru_conhece"} value={"2"} onChange={e => onChange(e)} checked={av_instru_conhece === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_instru_conhece"} value={"3"} onChange={e => onChange(e)} checked={av_instru_conhece === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_instru_conhece"} value={"4"} onChange={e => onChange(e)} checked={av_instru_conhece === "4"} required/>
                                </span>

                            </label>
                            <label htmlFor="">
                                Adapta os exercicios do plano de treino
                                <span>
                                      Faz
                                    <input type="radio" name={"av_instru_passa_turno"} value={"1"} onChange={e => onChange(e)} checked={av_instru_passa_turno === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_instru_passa_turno"} value={"2"} onChange={e => onChange(e)} checked={av_instru_passa_turno === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_instru_passa_turno"} value={"3"} onChange={e => onChange(e)} checked={av_instru_passa_turno === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_instru_passa_turno"} value={"4"} onChange={e => onChange(e)} checked={av_instru_passa_turno === "4"} required/>
                                </span>

                            </label>
                            <label htmlFor="">
                                Passa turno ao colega com infos necessárias
                                <span>
                                      Faz
                                    <input type="radio" name={"av_instru_app"} value={"1"} onChange={e => onChange(e)} checked={av_instru_app === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_instru_app"} value={"2"} onChange={e => onChange(e)} checked={av_instru_app === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_instru_app"} value={"3"} onChange={e => onChange(e)} checked={av_instru_app === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_instru_app"} value={"4"} onChange={e => onChange(e)} checked={av_instru_app === "4"} required/>
                                </span>

                            </label>
                            <label htmlFor="">
                                Usa App com clientes
                                <span>
                                      Faz
                                    <input type="radio" name={"av_instru_valida_app"} value={"1"} onChange={e => onChange(e)} checked={av_instru_valida_app === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_instru_valida_app"} value={"2"} onChange={e => onChange(e)} checked={av_instru_valida_app === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_instru_valida_app"} value={"3"} onChange={e => onChange(e)} checked={av_instru_valida_app === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_instru_valida_app"} value={"4"} onChange={e => onChange(e)} checked={av_instru_valida_app === "4"} required/>
                                </span>

                            </label>
                            <label htmlFor="">
                                Controla lotação da sala na APP
                                <span>
                                      Faz
                                    <input type="radio" name={"av_instru_controla_app"} value={"1"} onChange={e => onChange(e)} checked={av_instru_controla_app === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_instru_controla_app"} value={"2"} onChange={e => onChange(e)} checked={av_instru_controla_app === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_instru_controla_app"} value={"3"} onChange={e => onChange(e)} checked={av_instru_controla_app === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_instru_controla_app"} value={"4"} onChange={e => onChange(e)} checked={av_instru_controla_app === "4"} required/>
                                </span>

                            </label>
                            <label htmlFor="" className={"area-column"}>
                                Situações Observadas
                                <textarea name={"av_instru_observacao"} value={av_instru_observacao} onChange={e => onChange(e)}  cols="4" rows="4"></textarea>
                            </label>

                            <button>GUARDAR</button>
			    <button id="delete" onClick={e => onDelete(e)}>APAGAR</button>
                        </form>
                    </div>

                </div>
            </div>

        </Fragment>
};
InstrutorSalaDirectorClube.propTypes = {
    auth: PropTypes.object.isRequired,
    getInstrutorSalaAvaliacoes: PropTypes.func.isRequired,
    updateInstrutorSala: PropTypes.func.isRequired,
    deleteInstrutorSala: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    auth: state.auth,
    instrutorsala: state.instrutorsala
});

export default connect(mapStateToProps, {  getInstrutorSalaAvaliacoes, updateInstrutorSala, deleteInstrutorSala })( InstrutorSalaDirectorClube );
