/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getReceita, insertReceitas, updateBloquear } from "./actions/receitas";
import NumberFormat from 'react-number-format';

const ReceitasDirectorClube = ({ auth: {user}, getReceita, insertReceitas, updateBloquear, receitas: {data}}) => {


    useEffect(() => {

        if(!data)
            getReceita({ date: moment().format("YYYY-MM-DD"), id_clube: 1 });


        setReceitasData(
            {
                ...receitasData,
                inscricoes: data && data.receitas ? data.receitas.inscricoes ?  data.receitas.inscricoes  : 0  : 0,
                mensalidades: data && data.receitas ? data.receitas.mensalidades ?   data.receitas.mensalidades: 0  : 0,
                entradas_diarias: data && data.receitas ? data.receitas.entradas_diarias  ?   data.receitas.entradas_diarias: 0  : 0,
                agua_vit: data && data.receitas ? data.receitas.agua_vit   ?  data.receitas.agua_vit : 0  : 0,
                cartoes_socios:data && data.receitas ? data.receitas.cartoes_socios   ?  data.receitas.cartoes_socios: 0  : 0,
                nutricao:  data && data.receitas ? data.receitas.nutricao   ?  data.receitas.nutricao : 0  : 0,
                seguros: data && data.receitas ? data.receitas.seguros   ?   data.receitas.seguros: 0  : 0,
                rendas: data && data.receitas ? data.receitas.rendas   ?   data.receitas.rendas : 0  : 0,
                pack_senhas: data && data.receitas ? data.receitas.pack_senhas   ?   data.receitas.pack_senhas : 0  : 0,
                maq_vending: data && data.receitas ? data.receitas.maq_vending   ?  data.receitas.maq_vending : 0  : 0,
                merch: data && data.receitas ? data.receitas.merchandising   ?  data.receitas.merchandising : 0  : 0,
                totalDia: data && data.totalDiaReceitas   ? data.totalDiaReceitas : 0,
                totalMes: data && data.totalMes   ? data.totalMes : 0,

                inscricoesTotal: data && data.inscricoesTotal   ? data.inscricoesTotal : 0,
                segurosTotal: data && data.segurosTotal   ? data.segurosTotal : 0,
                mensalidadesTotal: data && data.mensalidadesTotal   ? data.mensalidadesTotal : 0,
                rendasTotal: data && data.rendasTotal   ? data.rendasTotal : 0,
                cartoesTotal: data && data.cartoesTotal   ? data.cartoesTotal : 0,
                entradasdiariasTotal: data && data.entradasdiariasTotal   ? data.entradasdiariasTotal : 0,
                packsenhasTotal: data && data.packsenhasTotal   ? data.packsenhasTotal : 0,
                nutricaoTotal: data && data.nutricaoTotal   ? data.nutricaoTotal : 0,
                aguaTotal: data && data.aguaTotal   ? data.aguaTotal : 0,
                merchTotal: data && data.merchTotal   ? data.merchTotal : 0,
                maqvendTotal: data && data.maqvendTotal   ? data.maqvendTotal : 0,



                inscricoesCaixa: data && data.receitas ? data.receitas.inscricoes_caixa ?  data.receitas.inscricoes_caixa  : 0  : 0,
                mensalidadesCaixa:data &&  data.receitas ? data.receitas.mensalidades_caixa ?  data.receitas.mensalidades_caixa  : 0  : 0,
                entradas_diariasCaixa: data &&  data.receitas ? data.receitas.entradas_diarias_caixa ?  data.receitas.entradas_diarias_caixa : 0  : 0,
                agua_vitCaixa:data &&  data.receitas ? data.receitas.agua_vit_caixa ?  data.receitas.agua_vit_caixa  : 0  : 0,
                cartoes_sociosCaixa:data && data.receitas ?  data.receitas.cartoes_socios_caixa ?  data.receitas.cartoes_socios_caixa  : 0  : 0,
                nutricaoCaixa: data && data.receitas ? data.receitas.nutricao_caixa ?  data.receitas.nutricao_caixa : 0  : 0,
                segurosCaixa: data && data.receitas ? data.receitas.seguros_caixa ?  data.receitas.seguros_caixa  : 0  : 0,
                rendasCaixa: data && data.receitas ? data.receitas.rendas_caixa ?  data.receitas.rendas_caixa  : 0  : 0,
                pack_senhasCaixa:data && data.receitas ? data.receitas.pack_senhas_caixa ?  data.receitas.pack_senhas_caixa : 0  : 0,
                maq_vendingCaixa: data && data.receitas ? data.receitas.maq_vending_caixa ?  data.receitas.maq_vending_caixa  : 0  : 0,
                merchCaixa: data && data.receitas ? data.receitas.merchandising_caixa ?  data.receitas.merchandising_caixa : 0  : 0,
                totalDiaCaixa: data && data.totalDiaCaixa   ? data.totalDiaCaixa : 0,
                totalMesCaixa:data && data.totalMesCaixa   ? data.totalMesCaixa : 0,
                totalMesGeral:data && data.totalMesGeral   ? data.totalMesGeral : 0,
                clube_selected:  data && data.clube_selected,

                inscricoes_objetivo: data && data.objectivos ? data.objectivos.inscricoes ?  data.objectivos.inscricoes  : 0  : 0,
                mensalidades_objetivo: data && data.objectivos ?  data.objectivos.mensalidades  ?  data.objectivos.mensalidades   : 0  : 0,
                entradas_diarias_objetivo: data && data.objectivos ?  data.objectivos.entradas_diarias  ?  data.objectivos.entradas_diarias   : 0  : 0,
                agua_vit_objetivo: data && data.objectivos ?  data.objectivos.agua_vit ?  data.objectivos.agua_vit   : 0  : 0,
                cartoes_socios_objetivo: data && data.objectivos ?  data.objectivos.cartoes_socios  ?  data.objectivos.cartoes_socios   : 0  : 0,
                nutricao_objetivo: data && data.objectivos ?  data.objectivos.nutricao ?  data.objectivos.nutricao   : 0  : 0,
                seguros_objetivo: data && data.objectivos ?  data.objectivos.seguros  ?  data.objectivos.seguros   : 0  : 0,
                rendas_objetivo: data && data.objectivos ?  data.objectivos.rendas  ?  data.objectivos.rendas   : 0  : 0,
                pack_senhas_objetivo: data && data.objectivos ?  data.objectivos.pack_senhas  ?  data.objectivos.pack_senhas  : 0  : 0,
                maq_vending_objetivo: data && data.objectivos ?  data.objectivos.maq_vending  ?  data.objectivos.maq_vending  : 0  : 0,
                merch_objetivo: data && data.objectivos ?  data.objectivos.merchandising ?  data.objectivos.merchandising  : 0  : 0,
                checked: !!(data &&  data.receitas && data.receitas.checked === 1),
            }
        );
    },[data]);

    const [receitasData, setReceitasData] = useState({
        date:  moment().format("YYYY-MM-DD"),
        inscricoes: "0",
        mensalidades: "0",
        entradas_diarias: 0,
        agua_vit: 0,
        cartoes_socios: 0,
        nutricao: 0,
        seguros: "0",
        rendas: 0,
        pack_senhas: 0,
        maq_vending: 0,
        merch: 0,
        totalDia: 0,
        totalMes: 0,
        inscricoesCaixa: "0",
        mensalidadesCaixa: "0",
        entradas_diariasCaixa: 0,
        agua_vitCaixa: 0,
        cartoes_sociosCaixa: 0,
        nutricaoCaixa: 0,
        segurosCaixa: "0",
        rendasCaixa: 0,
        pack_senhasCaixa: 0,
        maq_vendingCaixa: 0,
        merchCaixa: 0,
        totalDiaCaixa: 0,
        totalMesCaixa: 0,
        totalMesGeral: 0,
        clube_selected: 1,

        inscricoesTotal:0,
        segurosTotal:0,
        mensalidadesTotal:0,
        rendasTotal:0,
        cartoesTotal:0,
        entradasdiariasTotal:0,
        packsenhasTotal:0,
        nutricaoTotal:0,
        aguaTotal:0,
        merchTotal:0,
        maqvendTotal:0,

        inscricoes_objetivo:0,
        mensalidades_objetivo: "0",
        entradas_diarias_objetivo: 0,
        agua_vit_objetivo: 0,
        cartoes_socios_objetivo: 0,
        nutricao_objetivo: 0,
        seguros_objetivo: "0",
        rendas_objetivo: 0,
        pack_senhas_objetivo: 0,
        maq_vending_objetivo: 0,
        merch_objetivo: 0,
        checked: false
    });

    const {  inscricoesTotal,segurosTotal, mensalidadesTotal, rendasTotal, cartoesTotal, entradasdiariasTotal, packsenhasTotal, nutricaoTotal, aguaTotal, merchTotal, maqvendTotal, checked,clube_selected, date, inscricoes, mensalidades, entradas_diarias, agua_vit, cartoes_socios, nutricao, seguros, rendas, pack_senhas, maq_vending, merch, totalDia, totalMes,
        inscricoesCaixa, mensalidadesCaixa, entradas_diariasCaixa, agua_vitCaixa, cartoes_sociosCaixa, nutricaoCaixa, segurosCaixa, rendasCaixa, pack_senhasCaixa, maq_vendingCaixa, merchCaixa, totalDiaCaixa,
        totalMesCaixa, totalMesGeral, inscricoes_objetivo, mensalidades_objetivo, entradas_diarias_objetivo, agua_vit_objetivo, cartoes_socios_objetivo, nutricao_objetivo,
        seguros_objetivo, rendas_objetivo, pack_senhas_objetivo, maq_vending_objetivo, merch_objetivo} = receitasData;
    const onChange = e => setReceitasData({...receitasData, [e.target.name]: e.target.value});
    const onChangeNumber = e => setReceitasData({...receitasData, [e.target.name]: e.target.value.replace("€","")});
    const onChangeCol = async e => {
        setReceitasData({...receitasData, [e.target.name]: e.target.value});
        await getReceita({ date, id_clube:  e.target.value});

    };
    const onChangeDate = async e => {
        setReceitasData({...receitasData, [e.target.name]: e.target.value});
        await getReceita({ date:e.target.value, id_clube: clube_selected });
    };

    const onSubmit = async e => {
        e.preventDefault();

        await insertReceitas({ date, inscricoes, mensalidades, entradas_diarias, agua_vit, cartoes_socios, nutricao, seguros, rendas, pack_senhas, maq_vending, merch, id_clube: clube_selected,
            inscricoesCaixa, mensalidadesCaixa, entradas_diariasCaixa, agua_vitCaixa, cartoes_sociosCaixa, nutricaoCaixa, segurosCaixa, rendasCaixa, pack_senhasCaixa, maq_vendingCaixa, merchCaixa,
            inscricoes_objetivo, mensalidades_objetivo, entradas_diarias_objetivo, agua_vit_objetivo, cartoes_socios_objetivo, nutricao_objetivo, seguros_objetivo, rendas_objetivo, pack_senhas_objetivo, maq_vending_objetivo, merch_objetivo
        });


    }
    const  onChangeBloquear  = async e => {
        e.preventDefault();

        await updateBloquear({date, checked:  e.target.checked, id_clube: clube_selected});
    }
    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs">
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Gestão Comercial >
                        <Link to={"/receitas"}>Receitas</Link>
                    </div>

                    <form onSubmit={e => onSubmit(e) } className={"table-inputs"}>
                        <label htmlFor="">Filtro:
                            <input type="date" name={"date"} value={date} onChange={e => onChangeDate(e)}/>
                        </label>
                      {/*  <label htmlFor="" style={{margin: "20px 0", fontStyle: "italic"}}>
                            Bloquear Edição ao Clube: <input type="checkbox" name={"checked"} value={checked} style={{height: "20px"}} onChange={ e => onChangeBloquear(e)} checked={checked}  />
                        </label>*/}
                        <div className="table-grid-two-columns">
                            <label>
                                <div>
                                    Re-/Inscrições
                                    <span>Objectivo: <NumberFormat value={inscricoes_objetivo} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'} name={"inscricoes_objetivo"} onChange={e => onChangeNumber(e)} /></span>
                                    <span>Total Mês: <NumberFormat displayType={"text"}  value={inscricoesTotal} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'}name={"inscricoesTotal"} onChange={e => onChangeNumber(e)} /></span>
                                </div>
                                <NumberFormat value={inscricoes}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'} name={"inscricoes"} onChange={e => onChangeNumber(e)} disabled={checked}/>
                            </label>
                            <label>
                                <div>
                                    Seguros
                                    <span>Objectivo: <NumberFormat  value={seguros_objetivo} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'} name={"seguros_objetivo"} onChange={e => onChangeNumber(e)} /></span>
                                    <span>Total Mês: <NumberFormat displayType={"text"}  value={segurosTotal} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'}name={"inscricoesTotal"} onChange={e => onChangeNumber(e)} /></span>

                                </div>
                                <NumberFormat value={seguros} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={false} suffix={'€'} name={"seguros"} onChange={e => onChangeNumber(e)} disabled={checked}/>
                            </label>
                            <label>
                                <div>
                                    Mensalidades
                                    <span>Objectivo: <NumberFormat  value={mensalidades_objetivo} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'} name={"mensalidades_objetivo"} onChange={e => onChangeNumber(e)} /></span>
                                    <span>Total Mês: <NumberFormat displayType={"text"}  value={mensalidadesTotal} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'}name={"inscricoesTotal"} onChange={e => onChangeNumber(e)} /></span>

                                </div>
                                <NumberFormat value={mensalidades}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} name={"mensalidades"} onChange={e => onChangeNumber(e)} disabled={checked}/>

                            </label>
                            <label>
                                <div>
                                    Rendas
                                    <span>Objectivo: <NumberFormat  value={rendas_objetivo} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'} name={"rendas_objetivo"} onChange={e => onChangeNumber(e)} /></span>
                                    <span>Total Mês: <NumberFormat displayType={"text"}  value={rendasTotal} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'}name={"inscricoesTotal"} onChange={e => onChangeNumber(e)} /></span>

                                </div>
                                <NumberFormat value={rendas} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true}  suffix={'€'} name={"rendas"} onChange={e => onChangeNumber(e)} disabled={checked}/>
                            </label>
                            <label>
                                <div>
                                    Cartões Socios
                                    <span>Objectivo: <NumberFormat  value={cartoes_socios_objetivo} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'} name={"cartoes_socios_objetivo"} onChange={e => onChangeNumber(e)} /></span>
                                    <span>Total Mês: <NumberFormat displayType={"text"}  value={cartoesTotal} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'}name={"inscricoesTotal"} onChange={e => onChangeNumber(e)} /></span>

                                </div>
                                <NumberFormat value={cartoes_socios} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} name={"cartoes_socios"} onChange={e => onChangeNumber(e)} disabled={checked}/>

                            </label>
                            <label>
                                <div>
                                    Entradas Diarias
                                    <span>Objectivo: <NumberFormat  value={entradas_diarias_objetivo} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'} name={"entradas_diarias_objetivo"} onChange={e => onChangeNumber(e)} /></span>
                                    <span>Total Mês: <NumberFormat displayType={"text"}  value={entradasdiariasTotal} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'}name={"inscricoesTotal"} onChange={e => onChangeNumber(e)} /></span>

                                </div>
                                <NumberFormat value={entradas_diarias}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true}  suffix={'€'} name={"entradas_diarias"} onChange={e => onChangeNumber(e)} disabled={checked}/>
                            </label>
                            <label>
                                <div>
                                    Pack Senhas
                                    <span>Objectivo: <NumberFormat value={pack_senhas_objetivo} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'} name={"pack_senhas_objetivo"} onChange={e => onChangeNumber(e)} /></span>
                                    <span>Total Mês: <NumberFormat displayType={"text"}  value={packsenhasTotal} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'}name={"inscricoesTotal"} onChange={e => onChangeNumber(e)} /></span>

                                </div>
                                <NumberFormat value={pack_senhas}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} name={"pack_senhas"} onChange={e => onChangeNumber(e)} disabled={checked}/>
                            </label>
                            <label>
                                <div>
                                    Nutrição
                                    <span>Objectivo: <NumberFormat  value={nutricao_objetivo} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'} name={"nutricao_objetivo"} onChange={e => onChangeNumber(e)} /></span>
                                    <span>Total Mês: <NumberFormat displayType={"text"}  value={nutricaoTotal} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'}name={"inscricoesTotal"} onChange={e => onChangeNumber(e)} /></span>

                                </div>
                                <NumberFormat value={nutricao}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} name={"nutricao"} onChange={e => onChangeNumber(e)} disabled={checked}/>

                            </label>
                            <label>
                                <div>
                                    Agua Vit.
                                    <span>Objectivo: <NumberFormat  value={agua_vit_objetivo} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'} name={"agua_vit_objetivo"} onChange={e => onChangeNumber(e)} /></span>
                                    <span>Total Mês: <NumberFormat displayType={"text"}  value={aguaTotal} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'}name={"inscricoesTotal"} onChange={e => onChangeNumber(e)} /></span>

                                </div>
                                <NumberFormat value={agua_vit}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} name={"agua_vit"} onChange={e => onChangeNumber(e)} disabled={checked}/>

                            </label>
                            <label>
                                <div>
                                    Merchandising
                                    <span>Objectivo: <NumberFormat value={merch_objetivo} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'} name={"merch_objetivo"} onChange={e => onChangeNumber(e)} /></span>
                                    <span>Total Mês: <NumberFormat displayType={"text"}  value={merchTotal} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'}name={"inscricoesTotal"} onChange={e => onChangeNumber(e)} /></span>

                                </div>
                                <NumberFormat value={merch}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} name={"merch"} onChange={e => onChangeNumber(e)} disabled={checked}/>
                            </label>
                            <label>
                                <div>
                                    Maq. Vending
                                    <span>Objectivo: <NumberFormat  value={maq_vending_objetivo} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'} name={"maq_vending_objetivo"} onChange={e => onChangeNumber(e)} /></span>
                                    <span>Total Mês: <NumberFormat displayType={"text"}  value={maqvendTotal} style={{marginTop: "0px"}} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'}name={"inscricoesTotal"} onChange={e => onChangeNumber(e)} /></span>

                                </div>
                                <NumberFormat value={maq_vending}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} name={"maq_vending"} onChange={e => onChangeNumber(e)} disabled={checked}/>
                            </label>
                            <label htmlFor=""></label>
                            <label htmlFor="" className={"label-total-receitas"}>
                                <div>
                                    Total HC Dia
                                </div>
                                <NumberFormat value={totalDia}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} displayType={'text'}  />
                            </label>

                            <label htmlFor="" className={"label-total-receitas"}>
                                <div>
                                    Total HC Mês
                                </div>
                                <NumberFormat value={totalMes}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} displayType={'text'}  />
                            </label>
                        </div>
                        <div className="table-grid-two-columns" style={{marginTop: "40px"}}>
                            <label>
                                <div>
                                    Re-/Inscrições Caixa
                                </div>
                                <NumberFormat value={inscricoesCaixa}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  suffix={'€'} name={"inscricoesCaixa"} onChange={e => onChangeNumber(e)} />
                            </label>
                            <label>
                                <div>
                                    Seguros Caixa
                                </div>
                                <NumberFormat value={segurosCaixa} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={false} suffix={'€'} name={"segurosCaixa"} onChange={e => onChangeNumber(e)} />
                            </label>
                            <label>
                                <div>
                                    Mensalidades Caixa
                                </div>
                                <NumberFormat value={mensalidadesCaixa}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} name={"mensalidadesCaixa"} onChange={e => onChangeNumber(e)} />

                            </label>
                            <label>
                                <div>
                                    Rendas Caixa
                                </div>
                                <NumberFormat value={rendasCaixa} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true}  suffix={'€'} name={"rendasCaixa"} onChange={e => onChangeNumber(e)} />
                            </label>
                            <label>
                                <div>
                                    Cartões Socios Caixa
                                </div>
                                <NumberFormat value={cartoes_sociosCaixa} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} name={"cartoes_sociosCaixa"} onChange={e => onChangeNumber(e)} />

                            </label>
                            <label>
                                <div>
                                    Entradas Diarias Caixa
                                </div>
                                <NumberFormat value={entradas_diariasCaixa}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true}  suffix={'€'} name={"entradas_diariasCaixa"} onChange={e => onChangeNumber(e)} />
                            </label>
                            <label>
                                <div>
                                    Pack Senhas Caixa
                                </div>
                                <NumberFormat value={pack_senhasCaixa}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} name={"pack_senhasCaixa"} onChange={e => onChangeNumber(e)} />
                            </label>
                            <label>
                                <div>
                                    Nutrição Caixa
                                </div>
                                <NumberFormat value={nutricaoCaixa}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} name={"nutricaoCaixa"} onChange={e => onChangeNumber(e)} />

                            </label>
                            <label>
                                <div>
                                    Agua Vit. Caixa
                                </div>
                                <NumberFormat value={agua_vitCaixa}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} name={"agua_vitCaixa"} onChange={e => onChangeNumber(e)} />

                            </label>
                            <label>
                                <div>
                                    Merchandising Caixa
                                </div>
                                <NumberFormat value={merchCaixa}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} name={"merchCaixa"} onChange={e => onChangeNumber(e)} />
                            </label>
                            <label>
                                <div>
                                    Maq. Vending Caixa
                                </div>
                                <NumberFormat value={maq_vendingCaixa}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} name={"maq_vendingCaixa"} onChange={e => onChangeNumber(e)} />
                            </label>
                            <label htmlFor=""></label>
                            <label htmlFor="" className={"label-total-receitas"}>
                                <div>
                                    Total Dia Caixa
                                </div>
                                <NumberFormat value={totalDiaCaixa}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} displayType={'text'}  />
                            </label>

                            <label htmlFor="" className={"label-total-receitas"}>
                                <div>
                                    Total Mês Caixa
                                </div>
                                <NumberFormat value={totalMesCaixa}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} displayType={'text'}  />
                            </label>
                            <label htmlFor="" className={"label-total-receitas"}>
                                <div>
                                    Total Mês Geral
                                </div>
                                <NumberFormat value={totalMesGeral}  fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","}  allowLeadingZeros={true} suffix={'€'} displayType={'text'}  />
                            </label>
                        </div>


                            <button>Guardar</button>
                    </form>
                </div>
            </div>
        </Fragment>
};
ReceitasDirectorClube.propTypes = {
    auth: PropTypes.object.isRequired,
    getReceita: PropTypes.func.isRequired,
    insertReceitas: PropTypes.func.isRequired,
    updateBloquear: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    receitas: state.receitas
});
export default connect(mapStateToProps, { getReceita, insertReceitas, updateBloquear })( ReceitasDirectorClube );
