/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_MANUTENCAO,
    GET_MANUTENCAO_ERROR,
} from "./types";

export const getManutencao = ({status}) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = { status };
        const res = await axios.post("/api/manutencao", body, config);


        dispatch({
            type: GET_MANUTENCAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_MANUTENCAO_ERROR,
        })
    }
}


export const updateManutencao = ({  data_reportado,que, falha, quem, quemfoi, acao, data_resolucao,  status, notes, idmanutencao }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { data_reportado,que, falha, quem, quemfoi, acao, data_resolucao,  status, notes, idmanutencao };
    try {
        const res = await axios.post("/api/manutencao/update",body, config);


        dispatch({
            type: GET_MANUTENCAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_MANUTENCAO_ERROR,
        })
    }
}