/**
 * Created by danielsilva on 10/04/2020.
 */
import React, { Fragment, useState, useEffect, useContext, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../../actions/auth";
import '../../../styles/dashboard.css';
import HeaderAdmin from "./Header";
import Chart from 'react-apexcharts'
import NumberFormat from 'react-number-format';
import { getDashboard } from "./actions/agenda";

const DashboardAdmin = ({ auth: { user }, getDashboard, agenda: { data } }) => {
    const getMonth = function (idx) {

        var objDate = new Date();
        objDate.setDate(1);
        objDate.setMonth(idx - 1);

        var locale = "pt-PT",
            month = objDate.toLocaleString(locale, { month: "short" });

        return month;
    }
    useEffect(() => {
        getDashboard({ id_clube: 0, currentDate: new Date() });
    }, []);
    const ApexCharts = window.ApexCharts;


    useLayoutEffect(() => {
        setDashboardData(
            {
                ...dashboardData,
                sociosDD: data && data.receitas ? data.receitas : 0,
                nSociosDD: data && data.nSociosDD ? data.nSociosDD : 0,
                receitasDD: data && data.receitasDD ? data.receitasDD : 0,
                segurosDD: data && data.segurosDD ? data.segurosDD : 0,
                mensalidadesDD: data && data.mensalidadesDD ? data.mensalidadesDD : 0,
                aguaDD: data && data.aguaDD ? data.aguaDD : 0,
                inscricoesFormatedValue: data && data.inscricoes ? data.inscricoes : 0,
                mensalidadesFormatedValue: data && data.mensalidades ? data.mensalidades : 0,
                rendasFormatedValue: data && data.rendas ? data.rendas : 0,
                aguasFormatedValue: data && data.aguas ? data.aguas : 0,
                segurosFormatedValue: data && data.seguros ? data.seguros : 0,
                maqvendingFormatedValue: data && data.maqvending ? data.maqvending : 0,
                merchandisingFormatedValue: data && data.merchandising ? data.merchandising : 0,
                nutricaoFormatedValue: data && data.nutricao ? data.nutricao : 0,
                totalReceitas: data && data.totalReceitas ? data.totalReceitas : 0,

                retornoFirst: data && data._totalFirstRetornos,
                retornoSecond: data && data._totalSecondRetornos,
                retornoThird: data && data._totalThirdRetornos,


                objInscricoes: data && data.objInscricoes ? data.objInscricoes : 0,
                objMensalidades: data && data.objMensalidades ? data.objMensalidades : 0,
                objRendas: data && data.objRendas ? data.objRendas : 0,
                objAgua: data && data.objAguas ? data.objAguas : 0,
                objSeguros: data && data.objSeguros ? data.objSeguros : 0,
                objMaqVending: data && data.objMaqVending ? data.objMaqVending : 0,
                objMerchandising: data && data.objMerchandising ? data.objMerchandising : 0,
                objNutricao: data && data.objNutricao ? data.objNutricao : 0,
                totalObjReceitas: data && data.totalObjReceitas ? data.totalObjReceitas : 0,

                seriesSpark: [{
                    data: data && data.receitaDiasMes ? data.receitaDiasMes : 0,
                }],
                seriesInscricoes: [{
                    data: data && data.inscricoesDiasMes ? data.inscricoesDiasMes : 0,
                }],
                seriesMesalidades: [{
                    data: data && data.mensalidadesDiasMes ? data.mensalidadesDiasMes : 0,
                }],
                seriesRendas: [{
                    data: data && data.rendasDiasMes ? data.rendasDiasMes : 0,
                }],
                seriesAgua: [{
                    data: data && data.aguasDiasMes ? data.aguasDiasMes : 0,
                }],
                seriesSeguros: [{
                    data: data && data.segurosDiasMes ? data.segurosDiasMes : 0,
                }],
                seriesMaqVending: [{
                    data: data && data.maqvendingDiasMes ? data.maqvendingDiasMes : 0,
                }],
                seriesMerchandising: [{
                    data: data && data.merchandisingDiasMes ? data.merchandisingDiasMes : 0,
                }],
                seriesNutricao: [{
                    data: data && data.nutricaoDiasMes ? data.nutricaoDiasMes : 0,
                }],
                seriesReceitaAno: [{
                    name: 'Ano Passado',
                    data: data && data.totalAnoAnteriorReceitasMes ? data.totalAnoAnteriorReceitasMes : 0
                }, {
                    name: 'Este Ano',
                    data: data && data.totalAnoReceitasMes ? data.totalAnoReceitasMes : 0
                }],
                seriesDD: [
                    (data ? data.refused_adc ? data.refused_adc : 0 : 0),
                    (data ? data.refused_aut ? data.refused_aut : 0 : 0),
                    (data ? data.refused_nores ? data.refused_nores : 0 : 0),
                    data ? data.refused_closed ? data.refused_closed : 0 : 0,
                    data ? data.refused_nofunds ? data.refused_nofunds : 0 : 0,
                    data ? data.refused_contest ? data.refused_contest : 0 : 0,
                    data ? data.refused_refused ? data.refused_refused : 0 : 0,
                    data ? data.refused_serv ? data.refused_serv : 0 : 0],
                    refused_adc: data ? data.refused_adc ? data.refused_adc : 0 : 0,
                    refused_aut: data ? data.refused_aut ? data.refused_aut : 0 : 0,
                    refused_nores: data ? data.refused_nores ? data.refused_nores : 0 : 0,
                    refused_closed: data ? data.refused_closed ? data.refused_closed : 0 : 0,
                    refused_nofunds: data ? data.refused_nofunds ? data.refused_nofunds : 0 : 0,
                    refused_contest: data ? data.refused_contest ? data.refused_contest : 0 : 0,
                    refused_refused: data ? data.refused_refused ? data.refused_refused : 0 : 0,
                    refused_serv: data ? data.refused_serv ? data.refused_serv : 0 : 0,


                two_seriesDD: [
                    (data ? data.two_refused_adc ? data.two_refused_adc : 0 : 0),
                    (data ? data.two_refused_aut ? data.two_refused_aut : 0 : 0),
                    (data ? data.two_refused_nores ? data.two_refused_nores : 0 : 0),
                    data ? data.two_refused_closed ? data.two_refused_closed : 0 : 0,
                    data ? data.two_refused_nofunds ? data.two_refused_nofunds : 0 : 0,
                    data ? data.two_refused_contest ? data.two_refused_contest : 0 : 0,
                    data ? data.two_refused_refused ? data.two_refused_refused : 0 : 0,
                    data ? data.two_refused_serv ? data.two_refused_serv : 0 : 0],
                    two_refused_adc: data ? data.two_refused_adc ? data.two_refused_adc : 0 : 0,
                    two_refused_aut: data ? data.two_refused_aut ? data.two_refused_aut : 0 : 0,
                    two_refused_nores: data ? data.two_refused_nores ? data.two_refused_nores : 0 : 0,
                    two_refused_closed: data ? data.two_refused_closed ? data.two_refused_closed : 0 : 0,
                    two_refused_nofunds: data ? data.two_refused_nofunds ? data.two_refused_nofunds : 0 : 0,
                    two_refused_contest: data ? data.two_refused_contest ? data.two_refused_contest : 0 : 0,
                    two_refused_refused: data ? data.two_refused_refused ? data.two_refused_refused : 0 : 0,
                    two_refused_serv: data ? data.two_refused_serv ? data.two_refused_serv : 0 : 0,

                third_seriesDD: [
                    (data ? data.third_refused_adc ? data.third_refused_adc : 0 : 0),
                    (data ? data.third_refused_aut ? data.third_refused_aut : 0 : 0),
                    (data ? data.third_refused_nores ? data.third_refused_nores : 0 : 0),
                    data ? data.third_refused_closed ? data.third_refused_closed : 0 : 0,
                    data ? data.third_refused_nofunds ? data.third_refused_nofunds : 0 : 0,
                    data ? data.third_refused_contest ? data.third_refused_contest : 0 : 0,
                    data ? data.third_refused_refused ? data.third_refused_refused : 0 : 0,
                    data ? data.third_refused_serv ? data.third_refused_serv : 0 : 0],
                    third_refused_adc: data ? data.third_refused_adc ? data.third_refused_adc : 0 : 0,
                    third_refused_aut: data ? data.third_refused_aut ? data.third_refused_aut : 0 : 0,
                    third_refused_nores: data ? data.third_refused_nores ? data.third_refused_nores : 0 : 0,
                    third_refused_closed: data ? data.third_refused_closed ? data.third_refused_closed : 0 : 0,
                    third_refused_nofunds: data ? data.third_refused_nofunds ? data.third_refused_nofunds : 0 : 0,
                    third_refused_contest: data ? data.third_refused_contest ? data.third_refused_contest : 0 : 0,
                    third_refused_refused: data ? data.third_refused_refused ? data.third_refused_refused : 0 : 0,
                    third_refused_serv: data ? data.third_refused_serv ? data.third_refused_serv : 0 : 0,

                seriesfechoObjectivo: [
                    data ? data.fechoFaro ? data.fechoFaro : 0 : 0,
                    data ? data.fechoOlhao ? data.fechoOlhao : 0 : 0,
                    data ? data.fechoTavira ? data.fechoTavira : 0 : 0,
                    data ? data.fechoPortimao ? data.fechoPortimao : 0 : 0,
                    data ? data.fechoLoule ? data.fechoLoule : 0 : 0
                ],

                objectivosClubes: data ? data.objectivosClubes ? data.objectivosClubes : 0 : 0,

                objectivosClubesFecho: (data ? data.objectivoFechoFaro ? data.objectivoFechoFaro : 0 : 0) + (data ? data.objectivoFechoOlhao ? data.objectivoFechoOlhao : 0 : 0)
                    + (data ? data.objectivoFechoTavira ? data.objectivoFechoTavira : 0 : 0) + (data ? data.objectivoFechoPortimao ? data.objectivoFechoPortimao : 0 : 0) + (data ? data.objectivoFechoLoule ? data.objectivoFechoLoule : 0 : 0),

                objectivosClubesAttrition: (data ? data.objectivoAttritionFaro ? data.objectivoAttritionFaro : 0 : 0) + (data ? data.objectivoAttritionOlhao ? data.objectivoAttritionOlhao : 0 : 0)
                    + (data ? data.objectivoAttritionTavira ? data.objectivoAttritionTavira : 0 : 0) + (data ? data.objectivoAttritionPortimao ? data.objectivoAttritionPortimao : 0 : 0) + (data ? data.objectivoAttritionLoule ? data.objectivoAttritionLoule : 0 : 0),

                objectivosClubesSuspensao: (data ? data.objectivoSuspenFaro ? data.objectivoSuspenFaro : 0 : 0) + (data ? data.objectivoSuspenOlhao ? data.objectivoSuspenOlhao : 0 : 0)
                    + (data ? data.objectivoSuspenTavira ? data.objectivoSuspenTavira : 0 : 0) + (data ? data.objectivoSuspenPortimao ? data.objectivoSuspenPortimao : 0 : 0) + (data ? data.objectivoSuspenLoule ? data.objectivoSuspenLoule : 0 : 0),


                seriesObjectivosAdesoes: [(data ? data.objectivoFaro ? data.objectivoFaro : 0 : 0), (data ? data.objectivoOlhao ? data.objectivoOlhao : 0 : 0)
                    , (data ? data.objectivoTavira ? data.objectivoTavira : 0 : 0), (data ? data.objectivoPortimao ? data.objectivoPortimao : 0 : 0) , (data ? data.objectivoLoule ? data.objectivoLoule : 0 : 0)],

                seriesObjectivosFecho: [(data ? data.objectivoFechoFaro ? data.objectivoFechoFaro : 0 : 0), (data ? data.objectivoFechoOlhao ? data.objectivoFechoOlhao : 0 : 0)
                    , (data ? data.objectivoFechoTavira ? data.objectivoFechoTavira : 0 : 0), (data ? data.objectivoFechoPortimao ? data.objectivoFechoPortimao : 0 : 0) , (data ? data.objectivoFechoLoule ? data.objectivoFechoLoule : 0 : 0)],

                seriesObjectivosAttrition: [(data ? data.objectivoAttritionFaro ? data.objectivoAttritionFaro : 0 : 0), (data ? data.objectivoAttritionOlhao ? data.objectivoAttritionOlhao : 0 : 0)
                    , (data ? data.objectivoAttritionTavira ? data.objectivoAttritionTavira : 0 : 0), (data ? data.objectivoAttritionPortimao ? data.objectivoAttritionPortimao : 0 : 0) , (data ? data.objectivoAttritionLoule ? data.objectivoAttritionLoule : 0 : 0)],

                seriesObjectivosSuspensao: [(data ? data.objectivoSuspenFaro ? data.objectivoSuspenFaro : 0 : 0), (data ? data.objectivoSuspenOlhao ? data.objectivoSuspenOlhao : 0 : 0)
                    , (data ? data.objectivoSuspenTavira ? data.objectivoSuspenTavira : 0 : 0), (data ? data.objectivoSuspenPortimao ? data.objectivoSuspenPortimao : 0 : 0) , (data ? data.objectivoSuspenLoule ? data.objectivoSuspenLoule : 0 : 0)],


                seriesAdesoes: [(data ? data.realFaro ? data.realFaro : 0 : 0), (data ? data.realOlhao ? data.realOlhao : 0 : 0)
                    , (data ? data.realTavira ? data.realTavira : 0 : 0), (data ? data.realPortimao ? data.realPortimao : 0 : 0) , (data ? data.realLoule ? data.realLoule : 0 : 0)],
                adesoesClubes: data ? data.realClubes ? data.realClubes : 0 : 0,


                seriesFechoClubes: data ? data.realFechoClubes ? data.realFechoClubes : 0 : 0,
                seriesSuspensaoClubes: data ? data.realSuspensaoClubes ? data.realSuspensaoClubes : 0 : 0,
                seriesAttritionClubes: data ? data.realAttritionClubes ? data.realAttritionClubes : 0 : 0,

                seriesFecho: [(data ? data.realFechoFaro ? data.realFechoFaro : 0 : 0), (data ? data.realFechoOlhao ? data.realFechoOlhao : 0 : 0)
                    , (data ? data.realFechoTavira ? data.realFechoTavira : 0 : 0), (data ? data.realFechoPortimao ? data.realFechoPortimao : 0 : 0) , (data ? data.realFechoLoule ? data.realFechoLoule : 0 : 0)],
                seriesSuspensao: [(data ? data.realSuspensaoFaro ? data.realSuspensaoFaro : 0 : 0), (data ? data.realSuspensaoOlhao ? data.realSuspensaoOlhao : 0 : 0)
                    , (data ? data.realSuspensaoTavira ? data.realSuspensaoTavira : 0 : 0), (data ? data.realSuspensaoPortimao ? data.realSuspensaoPortimao : 0 : 0) , (data ? data.realSuspensaoLoule ? data.realSuspensaoLoule : 0 : 0)],
                seriesAttrition: [(data ? data.realAttritionFaro ? data.realAttritionFaro : 0 : 0), (data ? data.realAttritionOlhao ? data.realAttritionOlhao : 0 : 0)
                    , (data ? data.realAttritionTavira ? data.realAttritionTavira : 0 : 0), (data ? data.realAttritionPortimao ? data.realAttritionPortimao : 0 : 0) , (data ? data.realAttritionLoule ? data.realAttritionLoule : 0 : 0)],
            });

        let monthName = new Date();

    }, [data]);

    console.log({data})
    Number.prototype.format = function (n, x, s, c) {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
            num = this.toFixed(Math.max(0, ~~n));

        return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    };
    let monthcheck = new Date();
    let daysInMonth = new Date(monthcheck.getFullYear(), (monthcheck.getMonth() + 1), 0).getDate();
    let receitasMes = Array.from(Array(daysInMonth), (_, i) => 0)

    let sparklineData = ["280", "120", 160, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const [dashboardData, setDashboardData] = useState({
        retornoFirst: 0,
        retornoSecond: 0,
        retornoThird: 0,
        current_date: new Date(),
        initState: "",
        totalReceitas: 0,
        refused_adc: 0,
        refused_aut: 0,
        refused_nores: 0,
        refused_closed: 0,
        refused_nofunds: 0,
        refused_contest: 0,
        refused_refused: 0,
        refused_serv: 0,

        two_refused_adc: 0,
        two_refused_aut: 0,
        two_refused_nores: 0,
        two_refused_closed: 0,
        two_refused_nofunds: 0,
        two_refused_contest: 0,
        two_refused_refused: 0,
        two_refused_serv: 0,


        third_refused_adc: 0,
        third_refused_aut: 0,
        third_refused_nores: 0,
        third_refused_closed: 0,
        third_refused_nofunds: 0,
        third_refused_contest: 0,
        third_refused_refused: 0,
        third_refused_serv: 0,

        clube_selected: 0,
        objectivosClubes: 0,
        objectivosClubesFecho: 0,
        objectivosClubesAttrition: 0,
        objectivosClubesSuspensao: 0,
        seriesAdesoes: [0, 0, 0, 0],

        adesoesClubes: 0,
        optionsAdesoes: {
            chart: {
                height: 350,
                type: 'radialBar',
                group: 'adesoesradal',
                id: "adesoesreal",
            },
            yaxis: {
                labels: {
                    minWidth: 40
                }
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                            formatter: function (val, opts) {
                                return val
                            },
                        },
                        total: {
                            show: true,
                            label: 'Real',
                            formatter: function (w) {
                                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                return 0
                            }
                        }
                    }
                }
            },
            //colors: ['#ff4560', '#775dd1', '#c33fff', '#fbb01a'],
            colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
            labels: ['Faro', 'Olhão', 'Tavira', 'Portimão', 'Loule'],
            legend: {
                show: false,
                floating: true,
                fontSize: '16px',
                position: 'left',
                offsetX: 0,
                offsetY: 0,
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    size: 0
                },
                formatter: function (seriesName, opts) {
                    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                },
                itemMargin: {
                    vertical: 3
                }
            },

        },

        seriesFecho: [0, 0, 0, 0, 0],
        optionsFecho: {
            chart: {
                height: 350,
                type: 'radialBar',
                group: 'adesoesradal',
                id: "fechoreal",
            },
            yaxis: {
                labels: {
                    minWidth: 40
                }
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                            formatter: function (val, opts) {
                                return val
                            },
                        },
                        total: {
                            show: true,
                            label: 'Real',
                            formatter: function (w) {
                                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                return 0
                            }
                        }
                    }
                }
            },
            //colors: ['#ff4560', '#775dd1', '#c33fff', '#fbb01a'],
            colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
            labels: ['Faro', 'Olhão', 'Tavira', 'Portimão', 'Loule'],
            legend: {
                show: false,
                floating: true,
                fontSize: '16px',
                position: 'left',
                offsetX: 0,
                offsetY: 0,
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    size: 0
                },
                formatter: function (seriesName, opts) {
                    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                },
                itemMargin: {
                    vertical: 3
                }
            },

        },

        seriesSuspensao: [0, 0, 0, 0, 0],
        optionsSuspensao: {
            chart: {
                height: 350,
                type: 'radialBar',
                group: 'adesoesradal',
                id: "suspencaoreal",
            },
            yaxis: {
                labels: {
                    minWidth: 40
                }
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                            formatter: function (val, opts) {
                                return val
                            },
                        },
                        total: {
                            show: true,
                            label: 'Real',
                            formatter: function (w) {
                                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                return 0
                            }
                        }
                    }
                }
            },
            //colors: ['#ff4560', '#775dd1', '#c33fff', '#fbb01a'],
            colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
            labels: ['Faro', 'Olhão', 'Tavira', 'Portimão', 'Loule'],
            legend: {
                show: false,
                floating: true,
                fontSize: '16px',
                position: 'left',
                offsetX: 0,
                offsetY: 0,
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    size: 0
                },
                formatter: function (seriesName, opts) {
                    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                },
                itemMargin: {
                    vertical: 3
                }
            },

        },

        seriesAttrition: [0, 0, 0, 0, 0],
        optionsAttrition: {
            chart: {
                height: 350,
                type: 'radialBar',
                group: 'adesoesradal',
                id: "attritionreal",
            },
            yaxis: {
                labels: {
                    minWidth: 40
                }
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                            formatter: function (val, opts) {
                                return val
                            },
                        },
                        total: {
                            show: true,
                            label: 'Real',
                            formatter: function (w) {
                                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                return 0
                            }
                        }
                    }
                }
            },
            //colors: ['#ff4560', '#775dd1', '#c33fff', '#fbb01a'],
            colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
            labels: ['Faro', 'Olhão', 'Tavira', 'Portimão', 'Loule'],
            legend: {
                show: false,
                floating: true,
                fontSize: '16px',
                position: 'left',
                offsetX: 0,
                offsetY: 0,
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    size: 0
                },
                formatter: function (seriesName, opts) {
                    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                },
                itemMargin: {
                    vertical: 3
                }
            },

        },



        seriesObjectivosAdesoes: [0, 0, 0, 0, 0],
        optionsObjectivosAdesoes: {
            chart: {
                height: 350,
                type: 'radialBar',
                group: 'adesoesradal',
                id: "adesoesobjectivos",
            },
            yaxis: {
                labels: {
                    minWidth: 40
                }
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                            formatter: function (val, opts) {
                                return val
                            },
                        },
                        total: {
                            show: true,
                            label: 'Objectivo',
                            formatter: function (w) {
                                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                return 0
                            }
                        }
                    }
                }
            },
            colors: ['#ff4560', '#775dd1', '#c33fff', '#fbb01a'],
            labels: ['Faro', 'Olhão', 'Tavira', 'Portimão', 'Loule'],
            legend: {
                show: false,
                floating: true,
                fontSize: '16px',
                position: 'left',
                offsetX: 0,
                offsetY: 0,
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    size: 0
                },
                formatter: function (seriesName, opts) {
                    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                },
                itemMargin: {
                    vertical: 3
                }
            },

        },

        seriesObjectivosFecho: [0, 0, 0, 0, 0],
        optionsObjectivosFecho: {
            chart: {
                height: 350,
                type: 'radialBar',
                group: 'fechoesradal',
                id: "fechoobjectivos",
            },
            yaxis: {
                labels: {
                    minWidth: 40
                }
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                            formatter: function (val, opts) {
                                return val
                            },
                        },
                        total: {
                            show: true,
                            label: 'Objectivo',
                            formatter: function (w) {
                                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                return 0
                            }
                        }
                    }
                }
            },
            colors: ['#ff4560', '#775dd1', '#c33fff', '#fbb01a'],
            labels: ['Faro', 'Olhão', 'Tavira', 'Portimão', 'Loule'],
            legend: {
                show: false,
                floating: true,
                fontSize: '16px',
                position: 'left',
                offsetX: 0,
                offsetY: 0,
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    size: 0
                },
                formatter: function (seriesName, opts) {
                    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                },
                itemMargin: {
                    vertical: 3
                }
            },

        },

        seriesObjectivosAttrition: [0, 0, 0, 0, 0],
        optionsObjectivosAttrition: {
            chart: {
                height: 350,
                type: 'radialBar',
                group: 'fechoesradal',
                id: "attritionobjectivos",
            },
            yaxis: {
                labels: {
                    minWidth: 40
                }
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                            formatter: function (val, opts) {
                                return val
                            },
                        },
                        total: {
                            show: true,
                            label: 'Objectivo',
                            formatter: function (w) {
                                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                return 0
                            }
                        }
                    }
                }
            },
            colors: ['#ff4560', '#775dd1', '#c33fff', '#fbb01a'],
            labels: ['Faro', 'Olhão', 'Tavira', 'Portimão', 'Loule'],
            legend: {
                show: false,
                floating: true,
                fontSize: '16px',
                position: 'left',
                offsetX: 0,
                offsetY: 0,
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    size: 0
                },
                formatter: function (seriesName, opts) {
                    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                },
                itemMargin: {
                    vertical: 3
                }
            },

        },

        seriesObjectivosSuspensao: [0, 0, 0, 0, 0],
        optionsObjectivosSuspensao: {
            chart: {
                height: 350,
                type: 'radialBar',
                group: 'fechoesradal',
                id: "suspensaoobjectivos",
            },
            yaxis: {
                labels: {
                    minWidth: 40
                }
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                            formatter: function (val, opts) {
                                return val
                            },
                        },
                        total: {
                            show: true,
                            label: 'Objectivo',
                            formatter: function (w) {
                                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                return 0
                            }
                        }
                    }
                }
            },
            colors: ['#ff4560', '#775dd1', '#c33fff', '#fbb01a'],
            labels: ['Faro', 'Olhão', 'Tavira', 'Portimão', 'Loule'],
            legend: {
                show: false,
                floating: true,
                fontSize: '16px',
                position: 'left',
                offsetX: 0,
                offsetY: 0,
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    size: 0
                },
                formatter: function (seriesName, opts) {
                    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                },
                itemMargin: {
                    vertical: 3
                }
            },

        },

        optionsSpark: {
            chart: {
                type: 'area',
                group: 'receita',
                id: "receitatotal",
                height: 160,
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'straight'
            },
            fill: {
                opacity: 1,
            },
            yaxis: {
                min: 0,
                labels: {
                    show: false,
                    formatter: function (val) {

                        return val.format(2, 3, '.', ',') + "€";
                    }
                }

            },
            colors: ['#7d00ff'],
            title: {
                text: '0',
                offsetX: 0,
                style: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: '#7d00ff'
                },
                fill: "#7d00ff"
            },
            subtitle: {
                text: 'Receita total',
                offsetX: 0,
                style: {
                    fontSize: '14px',
                },
                fill: "#7d00ff"
            },
            tooltip: {
                shared: true,
                intersect: false,
                colors: ["#7d00ff", "#7d00ff"],
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y + "€";
                        }
                        return y;

                    }
                }
            }
        },
        seriesSpark: [{
            name: 'Dia',
            data: sparklineData,
            colors: ["#7d00ff"],
        }],
        inscricoesFormatedValue: 0,
        mensalidadesFormatedValue: 0,
        rendasFormatedValue: 0,
        aguasFormatedValue: 0,
        segurosFormatedValue: 0,
        maqvendingFormatedValue: 0,
        merchandisingFormatedValue: 0,
        nutricaoFormatedValue: 0,
        objInscricoes: 0,
        objMensalidades: 0,
        objRendas: 0,
        objAgua: 0,
        objSeguros: 0,
        objMaqVending: 0,
        objMerchandising: 0,
        objNutricao: 0,
        totalObjReceitas: 0,

        sociosDD: 0,

        optionsSparkNOFUND: {
            chart: {
                type: 'area',
                height: 160,
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'straight'
            },
            fill: {
                opacity: 1,
            },
            yaxis: {
                min: 0
            },
            colors: ['#7d00ff'],
            title: {
                text: '194.652,90€',
                offsetX: 0,
                style: {
                    fontSize: '24px',
                },
                fill: "#7d00ff"
            },
            subtitle: {
                text: 'Receita total',
                offsetX: 0,
                style: {
                    fontSize: '14px',
                },
                fill: "#7d00ff"
            }
        },
        seriesSparkNOFUND: [{
            name: 'Dia',
            data: sparklineData,
            colors: ["#7d00ff"],
        }],

        optionsInscricoes: {
            chart: {
                type: 'area',
                height: 160,
                group: 'receita',
                id: "inscricoes",
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'straight'
            },
            fill: {
                opacity: 1,
                colors: ["#7d00ff"],
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: undefined,
                    color: '#7d00ff'
                },
            },
            yaxis: {
                min: 0
            },
            colors: ['#7d00ff'],
            title: {
                text: 0,
                offsetX: 0,
                style: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: '#7d00ff'
                },
            },
            subtitle: {
                text: 'Inscrições',
                offsetX: 0,
                style: {
                    fontSize: '14px',
                },
                fill: "#7d00ff"
            },
            tooltip: {
                shared: true,
                intersect: false,
                colors: ["#7d00ff", "#7d00ff"],
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y + "€";
                        }
                        return y;

                    }
                }
            }
        },
        seriesInscricoes: [{
            name: 'Dia',
            data: sparklineData,
            colors: ["#7d00ff"],
        }],

        optionsMesalidades: {
            chart: {
                type: 'area',
                height: 160,
                group: 'receita',
                id: "mensalidades",
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'straight'
            },
            fill: {
                opacity: 1,
            },
            yaxis: {
                min: 0
            },
            colors: ['#7d00ff'],
            title: {
                text: '0',
                offsetX: 0,
                style: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: '#7d00ff'
                },
                fill: "#7d00ff"
            },
            subtitle: {
                text: 'Mensalidades',
                offsetX: 0,
                style: {
                    fontSize: '14px',
                },
                fill: "#7d00ff"
            },
            tooltip: {
                shared: true,
                intersect: false,
                colors: ["#7d00ff", "#7d00ff"],
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y + "€";
                        }
                        return y;

                    }
                }
            }
        },
        seriesMesalidades: [{
            name: 'Dia',
            data: sparklineData,
            colors: ["#7d00ff"],
        }],

        optionsRendas: {
            chart: {
                type: 'area',
                height: 160,
                group: 'receita',
                id: "rendas",
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'straight'
            },
            fill: {
                opacity: 1,
            },
            yaxis: {
                min: 0
            },
            colors: ['#7d00ff'],
            title: {
                text: '0',
                offsetX: 0,
                style: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: '#7d00ff'
                },
                fill: "#7d00ff"
            },
            subtitle: {
                text: 'Rendas',
                offsetX: 0,
                style: {
                    fontSize: '14px',
                },
                fill: "#7d00ff"
            },
            tooltip: {
                shared: true,
                intersect: false,
                colors: ["#7d00ff", "#7d00ff"],
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y + "€";
                        }
                        return y;

                    }
                }
            }
        },
        seriesRendas: [{
            name: 'Dia',
            data: sparklineData,
            colors: ["#7d00ff"],
        }],

        optionsAgua: {
            chart: {
                type: 'area',
                height: 160,
                group: 'receita',
                id: "aguas",
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'straight'
            },
            fill: {
                opacity: 1,
            },
            yaxis: {
                min: 0
            },
            colors: ['#7d00ff'],
            title: {
                text: '0',
                offsetX: 0,
                style: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: '#7d00ff'
                },
                fill: "#7d00ff"
            },
            subtitle: {
                text: 'Agua Vit.',
                offsetX: 0,
                style: {
                    fontSize: '14px',
                },
                fill: "#7d00ff"
            },
            tooltip: {
                shared: true,
                intersect: false,
                colors: ["#7d00ff", "#7d00ff"],
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y + "€";
                        }
                        return y;

                    }
                }
            }
        },
        seriesAgua: [{
            name: 'Dia',
            data: sparklineData,
            colors: ["#7d00ff"],
        }],

        optionsSeguros: {
            chart: {
                type: 'area',
                height: 160,
                group: 'receita',
                id: "seguros",
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'straight'
            },
            fill: {
                opacity: 1,
            },
            yaxis: {
                min: 0
            },
            colors: ['#7d00ff'],
            title: {
                text: '0',
                offsetX: 0,
                style: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: '#7d00ff'
                },
                fill: "#7d00ff"
            },
            subtitle: {
                text: 'Seguros',
                offsetX: 0,
                style: {
                    fontSize: '14px',
                },
                fill: "#7d00ff"
            },
            tooltip: {
                shared: true,
                intersect: false,
                colors: ["#7d00ff", "#7d00ff"],
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y + "€";
                        }
                        return y;

                    }
                }
            }
        },
        seriesSeguros: [{
            name: 'Dia',
            data: sparklineData,
            colors: ["#7d00ff"],
        }],

        optionsMaqVending: {
            chart: {
                type: 'area',
                height: 160,
                group: 'receita',
                id: "maqvending",
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'straight'
            },
            fill: {
                opacity: 1,
            },
            yaxis: {
                min: 0
            },
            colors: ['#7d00ff'],
            title: {
                text: '0',
                offsetX: 0,
                style: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: '#7d00ff'
                },
                fill: "#7d00ff"
            },
            subtitle: {
                text: 'Maq. Vending',
                offsetX: 0,
                style: {
                    fontSize: '14px',
                },
                fill: "#7d00ff"
            },
            tooltip: {
                shared: true,
                intersect: false,
                colors: ["#7d00ff", "#7d00ff"],
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y + "€";
                        }
                        return y;

                    }
                }
            }
        },
        seriesMaqVending: [{
            name: 'Dia',
            data: sparklineData,
            colors: ["#7d00ff"],
        }],

        optionsMerchandising: {
            chart: {
                type: 'area',
                height: 160,
                group: 'receita',
                id: "merchandising",
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'straight'
            },
            fill: {
                opacity: 1,
            },
            yaxis: {
                min: 0
            },
            colors: ['#7d00ff'],
            title: {
                text: '0',
                offsetX: 0,
                style: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: '#7d00ff'
                },
                fill: "#7d00ff"
            },
            subtitle: {
                text: 'Merchandising',
                offsetX: 0,
                style: {
                    fontSize: '14px',
                },
                fill: "#7d00ff"
            },
            tooltip: {
                shared: true,
                intersect: false,
                colors: ["#7d00ff", "#7d00ff"],
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y + "€";
                        }
                        return y;

                    }
                }
            }
        },
        seriesMerchandising: [{
            name: 'Dia',
            data: sparklineData,
            colors: ["#7d00ff"],
        }],

        optionsNutricao: {
            chart: {
                type: 'area',
                height: 160,
                group: 'receita',
                id: "nutricao",
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'straight'
            },
            fill: {
                opacity: 1,
            },
            yaxis: {
                min: 0
            },
            colors: ['#7d00ff'],
            title: {
                text: '0',
                offsetX: 0,
                style: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: '#7d00ff'
                },
                fill: "#7d00ff"
            },
            subtitle: {
                text: 'Nutrição',
                offsetX: 0,
                style: {
                    fontSize: '14px',
                },
                fill: "#7d00ff"
            },
            tooltip: {
                shared: true,
                intersect: false,
                colors: ["#7d00ff", "#7d00ff"],
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y + "€";
                        }
                        return y;

                    }
                }
            }
        },
        seriesNutricao: [{
            name: 'Dia',
            data: sparklineData,
            colors: ["#7d00ff"],
        }],

        seriesArea: [{
            name: 'Adesões Diarias',
            type: 'column',
            data: [0, 0, 0, 0, 0],
            colors: ["#7d00ff", "#7d00ff"],

        }, {
            name: 'Objetivo',
            type: 'line',
            data: [0, 0, 0, 0, 0]
        }],
        optionsArea: {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
            },
            stroke: {
                colors: ["#c33fff"],
                width: [0, 5],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%'
                },
                colors: ["#7d00ff", "#7d00ff"],
            },

            fill: {
                colors: ["#7d00ff", "#7d00ff"],
                opacity: [1, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100, 100]
                }
            },
            labels: ['Olhão', 'Tavira', 'Faro', 'Portimão', 'Loule'],
            markers: {
                size: 0,
                colors: ['#F44336']
            },
            xaxis: {
                type: 'string'
            },
            yaxis: {
                min: 0
            },
            title: {
                text: 'Adesões Diarias',
                align: 'left',
                colors: ['#F44336']
            },
            tooltip: {
                shared: true,
                intersect: false,
                colors: ["#7d00ff", "#7d00ff"],
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0) + " Adesões";
                        }
                        return y;

                    }
                }
            }
        },

        seriesMensal: [{
            name: 'Adesões Mensais',
            type: 'column',
            data: [0, 0, 0, 0, 0]
        }, {
            name: 'Objetivo',
            type: 'line',
            data: [0, 0, 0, 0, 0]
        }],
        optionsMensal: {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
            },
            stroke: {
                colors: ["#c33fff"],
                width: [0, 5],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%'
                }
            },

            fill: {
                colors: ["#7d00ff"],
                opacity: [1, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100, 100]
                }
            },
            labels: ['Olhão', 'Tavira', 'Faro', 'Portimão', 'Loule'],
            markers: {
                size: 0
            },
            xaxis: {
                type: 'string',
            },
            yaxis: {
                min: 0
            },
            title: {
                text: 'Adesões Mensais',
                align: 'left'
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0) + " Adesões";
                        }
                        return y;

                    }
                }
            },
            responsive: [{
                breakpoint: 1280,
                options: {
                    tooltip: {
                        enabled: false,
                        shared: false,
                        x: {
                            show: false,
                            format: 'dd MMM',
                            formatter: undefined,
                        },
                        fixed: {
                            enabled: false,
                            position: 'bottomRight',
                            offsetX: 0,
                            offsetY: 0,
                        },
                    }
                },
            }]
        },

        seriesAtrition: [{
            name: 'Atrittion Mensais',
            type: 'column',
            data: [0, 0, 0, 0, 0]
        }, {
            name: 'Objetivo',
            type: 'line',
            data: [0, 0, 0, 0, 0]
        }],
        optionsAtrition: {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
            },
            stroke: {
                colors: ["#c33fff"],
                width: [0, 5],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%'
                }
            },

            fill: {
                colors: ["#7d00ff"],
                opacity: [1, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100, 100]
                }
            },
            labels: ['Olhão', 'Tavira', 'Faro', 'Portimão', 'Loule'],
            markers: {
                size: 0
            },
            xaxis: {
                type: 'string',
            },
            yaxis: {
                min: 0
            },
            title: {
                text: 'Atrittion Mensais',
                align: 'left'
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0) + " Atrittion";
                        }
                        return y;

                    }
                }
            },
            responsive: [{
                breakpoint: 1280,
                options: {
                    tooltip: {
                        enabled: false,
                        shared: false,
                        x: {
                            show: false,
                            format: 'dd MMM',
                            formatter: undefined,
                        },
                        fixed: {
                            enabled: false,
                            position: 'bottomRight',
                            offsetX: 0,
                            offsetY: 0,
                        },
                    }
                },
            }]
        },

        seriesSuspencoes: [{
            name: 'Suspenções Mensais',
            type: 'column',
            data: [0, 0, 0, 0, 0]
        }, {
            name: 'Objetivo',
            type: 'line',
            data: [0, 0, 0, 0, 0]
        }],
        optionsSuspencoes: {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
            },
            stroke: {
                colors: ["#c33fff"],
                width: [0, 5],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%'
                }
            },

            fill: {
                colors: ["#7d00ff"],
                opacity: [1, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100, 100]
                }
            },
            labels: ['Olhão', 'Tavira', 'Faro', 'Portimão', 'Loule'],
            markers: {
                size: 0
            },
            xaxis: {
                type: 'string',
            },
            yaxis: {
                min: 0
            },
            title: {
                text: 'Suspenções Mensais',
                align: 'left'
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0) + " Suspenções";
                        }
                        return y;

                    }
                }
            },
            responsive: [{
                breakpoint: 1280,
                options: {
                    tooltip: {
                        enabled: false,
                        shared: false,
                        x: {
                            show: false,
                            format: 'dd MMM',
                            formatter: undefined,
                        },
                        fixed: {
                            enabled: false,
                            position: 'bottomRight',
                            offsetX: 0,
                            offsetY: 0,
                        },
                    }
                },
            }]
        },
        seriesFechoClubes: 0, seriesSuspensaoClubes: 0, seriesAttritionClubes: 0,
        seriesfechoObjectivo: [0, 0, 0, 0, 0],
        OptionsdechoObjectivo: {
            chart: {
                width: 380,
                type: 'donut',
            },
            labels: ['Faro', 'Olhão', 'Tavira', 'Portimão', 'Loule'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },


        seriesDD: [0, 0, 0, 0, 0, 0, 0, 0],
        optionsDD: {
            chart: {
                width: 380,
                type: 'donut',
            },
            labels: ['Data Limite ADC', 'Autorização Inativa', 'Sem Respota', 'Conta Encerrada', 'Insuficiência de Fundos', 'Operação Contestada', 'Recusado pelo Devedor', 'Serviço Especifico'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },

        two_seriesDD: [0, 0, 0, 0, 0, 0, 0, 0],
        two_optionsDD: {
            chart: {
                width: 380,
                type: 'donut',
            },
            labels: ['Data Limite ADC', 'Autorização Inativa', 'Sem Respota', 'Conta Encerrada', 'Insuficiência de Fundos', 'Operação Contestada', 'Recusado pelo Devedor', 'Serviço Especifico'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },

        third_seriesDD: [0, 0, 0, 0, 0, 0, 0, 0],
        third_optionsDD: {
            chart: {
                width: 380,
                type: 'donut',
            },
            labels: ['Data Limite ADC', 'Autorização Inativa', 'Sem Respota', 'Conta Encerrada', 'Insuficiência de Fundos', 'Operação Contestada', 'Recusado pelo Devedor', 'Serviço Especifico'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },

        seriesReceitaAno: [{
            name: 'Ano Passado',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }, {
            name: 'Este Ano',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }],


        optionsReceitaAno: {
            chart: {
                height: 350,
                type: 'bar',
                id: "receitasAno",

            },

            dataLabels: {
                enabled: false,
                formatter: function (val) {
                    return val + "€";
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#7d00ff"]
                }
            },

            xaxis: {
                categories: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dec"],
                position: 'top',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#7d00ff',
                            colorTo: '#7d00ff',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: false,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                    formatter: function (val) {
                        console.log({ val })
                        return val.format(2, 3, '.', ',') + "€";
                    }
                }

            },
            title: {
                text: 'Receitas Ano',
                floating: true,
                offsetY: 330,
                align: 'center',
                style: {
                    color: '#000000'
                }
            }
        },

        seriesContactos: [{
            name: 'POS',
            data: [0]
        }, {
            name: 'Leads',
            data: [0]
        }, {
            name: 'MI',
            data: [0]
        }, {
            name: 'Prospects',
            data: [0]
        }, {
            name: 'Parcerias',
            data: [0]
        }, {
            name: 'Walk In',
            data: [0]
        }],
        optionsContactos: {
            chart: {
                type: 'bar',
                height: 350,
                group: 'contactos',
                id: "contactos",
                stacked: true,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                categories: ["Contactos Angariados"],
                labels: {
                    formatter: function (val) {
                        return val + ""
                    }
                }
            },
            yaxis: {
                title: {
                    text: undefined
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + ""
                    }
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            }
        },

        seriesContactosReal: [{
            name: 'POS',
            data: [0]
        }, {
            name: 'Leads',
            data: [0]
        }, {
            name: 'MI',
            data: [0]
        }, {
            name: 'Prospects',
            data: [0]
        }, {
            name: 'Parcerias',
            data: [0]
        }, {
            name: 'Walk In',
            data: [0]
        }],
        optionsContactosReal: {
            chart: {
                type: 'bar',
                height: 350,
                group: 'contactos',
                id: "real",
                stacked: true,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                categories: ["Contactos Convertidos"],
                labels: {
                    formatter: function (val) {
                        return val + ""
                    }
                }
            },
            yaxis: {
                title: {
                    text: undefined
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + ""
                    }
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            }
        },

        seriesRadar: [{
            name: 'Series 1',
            data: [0, 0, 0, 0, 0, 0],
        }],
        optionsRadar: {
            chart: {
                height: 350,
                type: 'radar',
            },
            title: {
                text: 'Basic Radar Chart'
            },
            xaxis: {
                categories: ['January', 'February', 'March', 'April', 'May', 'June']
            }
        },
        nSociosDD: 0,
        receitasDD: 0,
        segurosDD: 0,
        mensalidadesDD: 0,
        aguaDD: 0,
    });

    const {
        retornoFirst, retornoSecond, retornoThird,
        totalObjReceitas,
        objInscricoes,
        objMensalidades,
        objRendas,
        objSeguros,
        objMaqVending,
        objMerchandising,
        objNutricao,
        current_date,
        seriesfechoObjectivo, OptionsdechoObjectivo,
        two_optionsDD, two_seriesDD,
        third_optionsDD, third_seriesDD,
        third_refused_adc, third_refused_aut, third_refused_nores, third_refused_closed, third_refused_nofunds, third_refused_contest, third_refused_refused, third_refused_serv,
        two_refused_adc, two_refused_aut, two_refused_nores, two_refused_closed, two_refused_nofunds, two_refused_contest, two_refused_refused, two_refused_serv,
        objAgua, clube_selected, adesoesClubes, objectivosClubes, refused_adc, refused_aut, refused_nores, refused_closed, refused_nofunds, refused_contest, refused_refused, refused_serv,
        seriesFechoClubes, seriesSuspensaoClubes, seriesAttritionClubes, optionsFecho, seriesFecho, optionsSuspensao, seriesSuspensao, optionsAttrition, seriesAttrition, objectivosClubesAttrition, objectivosClubesSuspensao, objectivosClubesFecho, seriesObjectivosAttrition, optionsObjectivosAttrition, seriesObjectivosSuspensao, optionsObjectivosSuspensao, optionsObjectivosFecho, seriesObjectivosFecho, optionsObjectivosAdesoes, seriesObjectivosAdesoes, seriesAdesoes, optionsAdesoes, totalReceitas, segurosFormatedValue, maqvendingFormatedValue, merchandisingFormatedValue, nutricaoFormatedValue, mensalidadesFormatedValue, rendasFormatedValue, aguasFormatedValue, inscricoesFormatedValue, sociosDD, nSociosDD, receitasDD, segurosDD, mensalidadesDD, aguaDD,
        seriesContactos, optionsContactos, seriesContactosReal, optionsContactosReal, seriesRadar, optionsRadar, optionsSpark, seriesSpark, optionsReceitaAno,
        seriesReceitaAno, optionsDD, seriesDD, optionsSparkNOFUND, seriesSparkNOFUND, optionsRendas, seriesRendas, optionsAgua, seriesAgua, optionsInscricoes,
        seriesInscricoes, optionsMesalidades, seriesMesalidades, optionsSeguros, seriesSeguros, optionsMaqVending, seriesMaqVending, optionsMerchandising, seriesMerchandising, optionsNutricao, seriesNutricao, options, series, seriesMensal, optionsMensal, seriesArea, optionsArea, seriesAtrition,
        optionsAtrition, optionsSuspencoes, seriesSuspencoes } = dashboardData;

    const onChange = e => setDashboardData({ ...dashboardData, [e.target.name]: e.target.value });
    const onChangeCol = async e => {
        setDashboardData({ ...dashboardData, [e.target.name]: e.target.value });
        await getDashboard({ id_clube: parseInt(e.target.value), currentDate: current_date });

    };
    const onChangeDate = async e => {
        setDashboardData({ ...dashboardData, [e.target.name]: e.target.value });
        await getDashboard({ id_clube: parseInt(clube_selected), currentDate: e.target.value });
    };
    return user === null ? <Fragment><div> <img src="/img/loading.gif" alt="" /></div></Fragment> : <Fragment>
        <HeaderAdmin />
        <div className="hide" style={{ "display": "none" }}>
            <NumberFormat value={(inscricoesFormatedValue)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('inscricoes', 'updateOptions', {
                    title: {
                        text: formattedValue,
                        offsetX: 0,
                        style: {
                            fontSize: '24px',
                        },
                        fill: "#7d00ff"
                    },
                }, false, true);
            }} />
            <NumberFormat value={(mensalidadesFormatedValue)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('mensalidades', 'updateOptions', {
                    title: {
                        text: formattedValue,
                        offsetX: 0,
                        style: {
                            fontSize: '24px',
                        },
                        fill: "#7d00ff"
                    },
                }, false, true);
            }} />
            <NumberFormat value={(rendasFormatedValue)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('rendas', 'updateOptions', {
                    title: {
                        text: formattedValue,
                        offsetX: 0,
                        style: {
                            fontSize: '24px',
                        },
                        fill: "#7d00ff"
                    },
                }, false, true);
            }} />
            <NumberFormat value={(aguasFormatedValue)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('aguas', 'updateOptions', {
                    title: {
                        text: formattedValue,
                        offsetX: 0,
                        style: {
                            fontSize: '24px',
                        },
                        fill: "#7d00ff"
                    },
                }, false, true);
            }} />
            <NumberFormat value={(segurosFormatedValue)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('seguros', 'updateOptions', {
                    title: {
                        text: formattedValue,
                        offsetX: 0,
                        style: {
                            fontSize: '24px',
                        },
                        fill: "#7d00ff"
                    },
                }, false, true);
            }} />
            <NumberFormat value={(maqvendingFormatedValue)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('maqvending', 'updateOptions', {
                    title: {
                        text: formattedValue,
                        offsetX: 0,
                        style: {
                            fontSize: '24px',
                        },
                        fill: "#7d00ff"
                    },
                }, false, true);
            }} />
            <NumberFormat value={(merchandisingFormatedValue)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('merchandising', 'updateOptions', {
                    title: {
                        text: formattedValue,
                        offsetX: 0,
                        style: {
                            fontSize: '24px',
                        },
                        fill: "#7d00ff"
                    },
                }, false, true);
            }} />
            <NumberFormat value={(nutricaoFormatedValue)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('nutricao', 'updateOptions', {
                    title: {
                        text: formattedValue,
                        offsetX: 0,
                        style: {
                            fontSize: '24px',
                        },
                        fill: "#7d00ff"
                    },
                }, false, true);
            }} />
            <NumberFormat value={(totalReceitas)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('receitatotal', 'updateOptions', {
                    title: {
                        text: formattedValue,

                    },
                }, false, true);
            }} />
            <NumberFormat value={(objectivosClubes)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('adesoesobjectivos', 'updateOptions', {
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: '22px',
                                },
                                value: {
                                    fontSize: '16px',
                                    formatter: function (val, opts) {
                                        return val
                                    },
                                },
                                total: {
                                    show: true,
                                    label: 'Objectivo',
                                    formatter: function (w) {
                                        // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                        return objectivosClubes
                                    }
                                }
                            }
                        }
                    },
                }, false, true);
            }} />


            <NumberFormat value={(objectivosClubesFecho)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('fechoobjectivos', 'updateOptions', {
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: '22px',
                                },
                                value: {
                                    fontSize: '16px',
                                    formatter: function (val, opts) {
                                        return val
                                    },
                                },
                                total: {
                                    show: true,
                                    label: 'Objectivo',
                                    formatter: function (w) {
                                        // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                        return objectivosClubesFecho
                                    }
                                }
                            }
                        }
                    },
                }, false, true);
            }} />

            <NumberFormat value={(objectivosClubesAttrition)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('attritionobjectivos', 'updateOptions', {
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: '22px',
                                },
                                value: {
                                    fontSize: '16px',
                                    formatter: function (val, opts) {
                                        return val
                                    },
                                },
                                total: {
                                    show: true,
                                    label: 'Objectivo',
                                    formatter: function (w) {
                                        // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                        return objectivosClubesAttrition
                                    }
                                }
                            }
                        }
                    },
                }, false, true);
            }} />

            <NumberFormat value={(objectivosClubesSuspensao)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('suspensaoobjectivos', 'updateOptions', {
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: '22px',
                                },
                                value: {
                                    fontSize: '16px',
                                    formatter: function (val, opts) {
                                        return val
                                    },
                                },
                                total: {
                                    show: true,
                                    label: 'Objectivo',
                                    formatter: function (w) {
                                        // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                        return objectivosClubesSuspensao
                                    }
                                }
                            }
                        }
                    },
                }, false, true);
            }} />
            <NumberFormat value={(adesoesClubes)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('adesoesreal', 'updateOptions', {
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: '22px',
                                },
                                value: {
                                    fontSize: '16px',
                                    formatter: function (val, opts) {
                                        return val
                                    },
                                },
                                total: {
                                    show: true,
                                    label: 'Real',
                                    formatter: function (w) {
                                        // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                        return adesoesClubes
                                    }
                                }
                            }
                        }
                    },
                }, false, true);
            }} />

            <NumberFormat value={(seriesFechoClubes)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('fechoreal', 'updateOptions', {
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: '22px',
                                },
                                value: {
                                    fontSize: '16px',
                                    formatter: function (val, opts) {
                                        return val
                                    },
                                },
                                total: {
                                    show: true,
                                    label: 'Real',
                                    formatter: function (w) {
                                        // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                        return seriesFechoClubes
                                    }
                                }
                            }
                        }
                    },
                }, false, true);
            }} />
            <NumberFormat value={(seriesAttritionClubes)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('attritionreal', 'updateOptions', {
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: '22px',
                                },
                                value: {
                                    fontSize: '16px',
                                    formatter: function (val, opts) {
                                        return val
                                    },
                                },
                                total: {
                                    show: true,
                                    label: 'Real',
                                    formatter: function (w) {
                                        // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                        return seriesAttritionClubes
                                    }
                                }
                            }
                        }
                    },
                }, false, true);
            }} />
            <NumberFormat value={(seriesSuspensaoClubes)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                const { formattedValue, value } = values;
                ApexCharts.exec('suspencaoreal', 'updateOptions', {
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    fontSize: '22px',
                                },
                                value: {
                                    fontSize: '16px',
                                    formatter: function (val, opts) {
                                        return val
                                    },
                                },
                                total: {
                                    show: true,
                                    label: 'Real',
                                    formatter: function (w) {
                                        // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                        return seriesSuspensaoClubes
                                    }
                                }
                            }
                        }
                    },
                }, false, true);
            }} />

        </div>
        <div id="main-container">
            <div className="table-inputs-filtros table-inputs-filtros-contactos">
                <label htmlFor="">
                    Clube:
                    <select name="clube_selected" value={clube_selected} onChange={e => onChangeCol(e)} style={{ textTransform: "capitalize" }}>
                        <option value="0">Todos</option>
                        {user && user.clubes.map((e) => (
                            <option value={e.id} >{e.localidade}</option>
                        ))}
                    </select>
                </label>
                <label htmlFor="">Mês:
                    <input type="date" name={"current_date"} value={current_date} onChange={e => onChangeDate(e)} />
                </label>
            </div>


            <div className="container">
                <div className="graph-container" >
                    <div className="graph-item">
                        <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                            "color": " #000000",
                            fontStyle: "normal"
                        }}><NumberFormat value={totalObjReceitas} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} />  </strong></div>
                        <Chart options={optionsSpark} series={seriesSpark} type="area" width={"100%"} height={"140px"} />
                    </div>
                    <div className="graph-item">
                        <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                            "color": " #000000",
                            fontStyle: "normal"
                        }}><NumberFormat value={objInscricoes} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} />  </strong></div>

                        <Chart options={optionsInscricoes} series={seriesInscricoes} type="area" width={"100%"} height={"140px"} />
                    </div>
                    <div className="graph-item">
                        <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                            "color": " #000000",
                            fontStyle: "normal"
                        }}><NumberFormat value={objMensalidades} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} />  </strong></div>

                        <Chart options={optionsMesalidades} series={seriesMesalidades} type="area" width={"100%"} height={"140px"} />
                    </div>
                    <div className="graph-item">
                        <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                            "color": " #000000",
                            fontStyle: "normal"
                        }}><NumberFormat value={objRendas} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} />  </strong></div>

                        <Chart options={optionsRendas} series={seriesRendas} type="area" width={"100%"} height={"140px"} />
                    </div>
                    <div className="graph-item">
                        <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                            "color": " #000000",
                            fontStyle: "normal"
                        }}>

                            <NumberFormat value={objAgua} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} />  </strong></div>

                        <Chart options={optionsAgua} series={seriesAgua} type="area" width={"100%"} height={"140px"} />
                    </div>

                </div>
                <div className="graph-container" >

                    <div className="graph-item">
                        <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }}> </div>

                    </div>
                    <div className="graph-item">
                        <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                            "color": " #000000",
                            fontStyle: "normal"
                        }}>

                            <NumberFormat value={objSeguros} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} />  </strong></div>

                        <Chart options={optionsSeguros} series={seriesSeguros} type="area" width={"100%"} height={"140px"} />
                    </div>
                    <div className="graph-item">
                        <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                            "color": " #000000",
                            fontStyle: "normal"
                        }}>

                            <NumberFormat value={objMaqVending} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} />  </strong></div>

                        <Chart options={optionsMaqVending} series={seriesMaqVending} type="area" width={"100%"} height={"140px"} />
                    </div>
                    <div className="graph-item">
                        <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                            "color": " #000000",
                            fontStyle: "normal"
                        }}>

                            <NumberFormat value={objMerchandising} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} />  </strong></div>

                        <Chart options={optionsMerchandising} series={seriesMerchandising} type="area" width={"100%"} height={"140px"} />
                    </div>
                    <div className="graph-item">
                        <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                            "color": " #000000",
                            fontStyle: "normal"
                        }}>

                            <NumberFormat value={objNutricao} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} />  </strong></div>

                        <Chart options={optionsNutricao} series={seriesNutricao} type="area" width={"100%"} height={"140px"} />
                    </div>

                </div>

                <div className="alerts-container" >
                    <div className="" style={{ fontStyle: "italic" }} > DD Recebidos Gymnasium:  <strong style={{
                        "color": " #7d00ff",
                        fontStyle: "normal"
                    }}><NumberFormat value={receitasDD} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} />  </strong></div>
                    <div className="" style={{ fontStyle: "italic" }} >
                        DD Recebidos Água:  <strong style={{
                            "color": " #7d00ff",
                            fontStyle: "normal"
                        }}><NumberFormat value={aguaDD} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} />  </strong><br />
                        DD Recebidos Seguros:  <strong style={{
                            "color": " #7d00ff",
                            fontStyle: "normal"
                        }}><NumberFormat value={segurosDD} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} />  </strong><br />
                        DD Recebidos Mensalidades:  <strong style={{
                            "color": " #7d00ff",
                            fontStyle: "normal"
                        }}><NumberFormat value={mensalidadesDD} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} />  </strong>
                    </div>

                    <div className="" style={{ fontStyle: "italic" }} > Total Sócios Pagos a DD:  <strong style={{
                        "color": " #7d00ff",
                        fontStyle: "normal"
                    }}><NumberFormat value={nSociosDD} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} displayType={'text'} /> </strong> </div>
                </div>

                <div className="content-container">
                    <div className="graph-container-2-columns" >
                        <div className="graph-item">
                            <Chart options={optionsReceitaAno} series={seriesReceitaAno} type="bar" width={"100%"} height={"250px"} />
                        </div>
                        <div className="graph-item">
                            <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                                "color": " #000000",
                                fontSize: "14px", marginBottom: " 10px", display: "block", textAlign: "center"
                            }}> Fecho </strong></div>
                            <Chart options={OptionsdechoObjectivo} series={seriesfechoObjectivo} type="donut" width={"100%"} height={"250px"} />
                        </div>
                    </div>
                    <div className="graph-container-3-columns" >
                        <div className="graph-item">
                            <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                                "color": " #000000",
                                fontSize: "14px", marginBottom: " 10px", display: "block", textAlign: "center"
                            }}> 1ª Retorno - <NumberFormat value={(retornoFirst)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                                const { formattedValue, value } = values;
                                ApexCharts.exec('suspencaoreal', 'updateOptions', {
                                    plotOptions: {
                                        radialBar: {
                                            dataLabels: {
                                                name: {
                                                    fontSize: '22px',
                                                },
                                                value: {
                                                    fontSize: '16px',
                                                    formatter: function (val, opts) {
                                                        return val
                                                    },
                                                },
                                                total: {
                                                    show: true,
                                                    label: 'Real',
                                                    formatter: function (w) {
                                                        // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                                        return seriesSuspensaoClubes
                                                    }
                                                }
                                            }
                                        }
                                    },
                                }, false, true);
                            }} /></strong></div>
                            <Chart options={optionsDD} series={seriesDD} type="donut" width={"100%"} height={"230px"} />

                        </div>
                        <div className="graph-item">
                            <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                                "color": " #000000",
                                fontSize: "14px", marginBottom: " 10px", display: "block", textAlign: "center"
                            }}> 2ª Retorno - <NumberFormat value={(retornoSecond)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                                const { formattedValue, value } = values;
                                ApexCharts.exec('suspencaoreal', 'updateOptions', {
                                    plotOptions: {
                                        radialBar: {
                                            dataLabels: {
                                                name: {
                                                    fontSize: '22px',
                                                },
                                                value: {
                                                    fontSize: '16px',
                                                    formatter: function (val, opts) {
                                                        return val
                                                    },
                                                },
                                                total: {
                                                    show: true,
                                                    label: 'Real',
                                                    formatter: function (w) {
                                                        // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                                        return seriesSuspensaoClubes
                                                    }
                                                }
                                            }
                                        }
                                    },
                                }, false, true);
                            }} /></strong></div>
                            <Chart options={two_optionsDD} series={two_seriesDD} type="donut" width={"100%"} height={"230px"} />

                        </div>
                        <div className="graph-item">
                            <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                                "color": " #000000",
                                fontSize: "14px", marginBottom: " 10px", display: "block", textAlign: "center"
                            }}> 3ª Retorno - <NumberFormat value={(retornoThird)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} allowLeadingZeros={true} suffix={'€'} displayType={'text'} onValueChange={(values) => {
                                const { formattedValue, value } = values;
                                ApexCharts.exec('suspencaoreal', 'updateOptions', {
                                    plotOptions: {
                                        radialBar: {
                                            dataLabels: {
                                                name: {
                                                    fontSize: '22px',
                                                },
                                                value: {
                                                    fontSize: '16px',
                                                    formatter: function (val, opts) {
                                                        return val
                                                    },
                                                },
                                                total: {
                                                    show: true,
                                                    label: 'Real',
                                                    formatter: function (w) {
                                                        // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                                        return seriesSuspensaoClubes
                                                    }
                                                }
                                            }
                                        }
                                    },
                                }, false, true);
                            }} /></strong></div>
                            <Chart options={third_optionsDD} series={third_seriesDD} type="donut" width={"100%"} height={"230px"} />

                        </div>
                    </div>
                    <div className="alerts-container" >
                        <div className="sms-total-value">Manutenção: <strong>0</strong></div>
                        <div className="sms-total-value">Pontos Criticos: <strong>0</strong> </div>
                        <div className="sms-total-value">Saldo SMS: <strong>18.45 €</strong></div>
                    </div>
                    {/*  <div className="graph-container-2-columns" >
                        <div className="graph-item">
                            <Chart options={optionsObjectivosAdesoes} series={seriesObjectivosAdesoes} type="radialBar" width={"100%"} height={"400px"} />
                        </div>
                        <div className="graph-item">
                            <Chart options={optionsAdesoes} series={seriesAdesoes} type="radialBar" width={"100%"} height={"400px"} />
                        </div>
                    </div>*/}
                    <div className="graph-container-4-columns" >
                        <div className="graph-item">
                            <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                                "color": " #000000",
                                fontSize: "14px", marginBottom: " 10px", display: "block", textAlign: "center"
                            }}> Vendas </strong></div>
                            <Chart options={optionsObjectivosAdesoes} series={seriesObjectivosAdesoes} type="radialBar" width={"100%"} height={"300px"} />
                        </div>
                        <div className="graph-item">
                            <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                                "color": " #000000",
                                fontSize: "14px", marginBottom: " 10px", display: "block", textAlign: "center"
                            }}> Fecho </strong></div>
                            <Chart options={optionsObjectivosFecho} series={seriesObjectivosFecho} type="radialBar" width={"100%"} height={"300px"} />
                        </div>
                        <div className="graph-item">
                            <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                                "color": " #000000",
                                fontSize: "14px", marginBottom: " 10px", display: "block", textAlign: "center"
                            }}> Suspensão </strong></div>
                            <Chart options={optionsObjectivosSuspensao} series={seriesObjectivosSuspensao} type="radialBar" width={"100%"} height={"300px"} />
                        </div>
                        <div className="graph-item">
                            <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                                "color": " #000000",
                                fontSize: "14px", marginBottom: " 10px", display: "block", textAlign: "center"
                            }}> Atrittion </strong></div>
                            <Chart options={optionsObjectivosAttrition} series={seriesObjectivosAttrition} type="radialBar" width={"100%"} height={"300px"} />
                        </div>
                    </div>

                    <div className="graph-container-4-columns" >
                        <div className="graph-item">
                            <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                                "color": " #000000",
                                fontSize: "14px", marginBottom: " 10px", display: "block", textAlign: "center"
                            }}> Vendas </strong></div>
                            <Chart options={optionsAdesoes} series={seriesAdesoes} type="radialBar" width={"100%"} height={"300px"} />
                        </div>
                        <div className="gr aph-item">
                            <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                                "color": " #000000",
                                fontSize: "14px", marginBottom: " 10px", display: "block", textAlign: "center"
                            }}> Fecho </strong></div>
                            <Chart options={optionsFecho} series={seriesFecho} type="radialBar" width={"100%"} height={"300px"} />
                        </div>
                        <div className="graph-item">
                            <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                                "color": " #000000",
                                fontSize: "14px", marginBottom: " 10px", display: "block", textAlign: "center"
                            }}> Suspensão </strong></div>
                            <Chart options={optionsSuspensao} series={seriesSuspensao} type="radialBar" width={"100%"} height={"300px"} />
                        </div>
                        <div className="graph-item">
                            <div className="" style={{ fontStyle: "italic", "marginLeft": "10px" }} >  <strong style={{
                                "color": " #000000",
                                fontSize: "14px", marginBottom: " 10px", display: "block", textAlign: "center"
                            }}> Atrittion </strong></div>
                            <Chart options={optionsAttrition} series={seriesAttrition} type="radialBar" width={"100%"} height={"300px"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
};

DashboardAdmin.propTypes = {
    auth: PropTypes.object.isRequired,
    getDashboard: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    agenda: state.agenda
});

export default connect(mapStateToProps, { getDashboard })(DashboardAdmin);
