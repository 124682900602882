/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getChamadas, insertChamadas, getChamadasByConsultor, insertChamadasByConsultor } from "./actions/chamadas";

const ChamadasDirectorClube = ({ auth: {user}, getChamadas, insertChamadas, chamadas: {data}, getChamadasByConsultor, insertChamadasByConsultor}) => {
    useLayoutEffect(() => {
        getChamadas({ date: moment().format("YYYY-MM-DD")});

    },[]);

    useEffect(() => {
        setChamadasData(
            {
                ...chamadasData,
                pos_realizadas: data && data.chamadas.pos_realizadas  ? data.chamadas.pos_realizadas : 0,
                pos_atendidas: data && data.chamadas.pos_atendidas  ? data.chamadas.pos_atendidas : 0,
                pos_marcadas: data && data.chamadas.pos_marcadas  ? data.chamadas.pos_marcadas : 0,

                leads_realizadas: data && data.chamadas.leads_realizadas  ? data.chamadas.leads_realizadas : 0,
                leads_atendidas: data && data.chamadas.leads_atendidas  ? data.chamadas.leads_atendidas : 0,
                leads_marcadas: data && data.chamadas.leads_marcadas  ? data.chamadas.leads_marcadas : 0,

                mi_realizadas: data && data.chamadas.mi_realizadas  ? data.chamadas.mi_realizadas : 0,
                mi_atendidas: data && data.chamadas.mi_atendidas  ? data.chamadas.mi_atendidas : 0,
                mi_marcadas: data && data.chamadas.mi_marcadas  ? data.chamadas.mi_marcadas : 0,

                prospect_realizadas: data && data.chamadas.prospect_realizadas  ? data.chamadas.prospect_realizadas : 0,
                prospect_atendidas: data && data.chamadas.prospect_atendidas  ? data.chamadas.prospect_atendidas : 0,
                prospect_marcadas: data && data.chamadas.prospect_marcadas  ? data.chamadas.prospect_marcadas : 0,

                desistencia_realizadas: data && data.chamadas.desistencia_realizadas  ? data.chamadas.desistencia_realizadas : 0,
                desistencia_atendidas: data && data.chamadas.desistencia_atendidas  ? data.chamadas.desistencia_atendidas : 0,
                desistencia_marcadas: data && data.chamadas.desistencia_marcadas  ? data.chamadas.desistencia_marcadas : 0,

                parcerias_realizadas: data && data.chamadas.parcerias_realizadas  ? data.chamadas.parcerias_realizadas : 0,
                parcerias_atendidas: data && data.chamadas.parcerias_atendidas  ? data.chamadas.parcerias_atendidas : 0,
                parcerias_marcadas: data && data.chamadas.parcerias_marcadas  ? data.chamadas.parcerias_marcadas : 0,

                followup_realizadas: data && data.chamadas.followup_realizadas  ? data.chamadas.followup_realizadas : 0,
                followup_atendidas: data && data.chamadas.followup_atendidas  ? data.chamadas.followup_atendidas : 0,
                followup_marcadas: data && data.chamadas.followup_marcadas  ? data.chamadas.followup_marcadas : 0,

                total_realizadas:  (data && data.chamadas.total_realizadas  ? data.chamadas.total_realizadas : 0),
                total_atendidas:  (data && data.chamadas.total_atendidas  ? data.chamadas.total_atendidas : 0),
                total_marcadas:  (data && data.chamadas.total_marcadas  ? data.chamadas.total_marcadas : 0),

                mes_total_realizadas: (data && data.chamadas.mes_total_realizadas  ? data.chamadas.mes_total_realizadas : 0),
                mes_total_atendidas: (data && data.chamadas.mes_total_atendidas  ? data.chamadas.mes_total_atendidas : 0),
                mes_total_marcadas: (data && data.chamadas.mes_total_marcadas  ? data.chamadas.mes_total_marcadas : 0),

                totalMesPos: (data && data.totalMesPos  ? data.totalMesPos : 0),
                totalMesLeads: (data && data.totalMesLeads  ? data.totalMesLeads : 0),
                totalMesMi : (data && data.totalMesMi  ? data.totalMesMi : 0),
                totalMesProspect : (data && data.totalMesProspect  ? data.totalMesProspect : 0),
                totalMesDesis : (data && data.totalMesDesis  ? data.totalMesDesis : 0),
                totalMesParcerias : (data && data.totalMesParcerias  ? data.totalMesParcerias : 0),
                totalMesFollowup : (data && data.totalMesFollowup  ? data.totalMesFollowup : 0),


                totalMesPosR : (data && data.totalMesPosR  ? data.totalMesPosR : 0),
                totalMesLeadsR : (data && data.totalMesLeadsR  ? data.totalMesLeadsR : 0),
                totalMesMiR : (data && data.totalMesMiR  ? data.totalMesMiR : 0),
                totalMesProspectR : (data && data.totalMesProspectR  ? data.totalMesProspectR : 0),
                totalMesDesisR : (data && data.totalMesDesisR  ? data.totalMesDesisR : 0),
                totalMesParceriasR : (data && data.totalMesParceriasR  ? data.totalMesParceriasR : 0),
                totalMesFollowupR : (data && data.totalMesFollowupR  ? data.totalMesFollowupR : 0),


                totalMesPosA: (data && data.totalMesPosA  ? data.totalMesPosA : 0),
                totalMesLeadsA : (data && data.totalMesLeadsA  ? data.totalMesLeadsA : 0),
                totalMesMiA : (data && data.totalMesMiA  ? data.totalMesMiA : 0),
                totalMesProspectA : (data && data.totalMesProspectA  ? data.totalMesProspectA : 0),
                totalMesDesisA : (data && data.totalMesDesisA  ? data.totalMesDesisA : 0),
                totalMesParceriasA : (data && data.totalMesParceriasA  ? data.totalMesParceriasA : 0),
                totalMesFollowupA : (data && data.totalMesFollowupA  ? data.totalMesFollowupA : 0),

                selected_colaborador:  (data && data.selected_colaborador ? data.selected_colaborador : ""),
                error:  data && data.error  ? data.error : false,
                clube_selected:  data && data.clube_selected,
            }
        );
    },[data]);


    const [chamadasData, setChamadasData] = useState({
        date:  moment().format("YYYY-MM-DD"),
        pos_realizadas:  0,
        pos_atendidas:0,
        pos_marcadas:0,
        leads_realizadas: 0,
        leads_atendidas: 0,
        leads_marcadas: 0,
        mi_realizadas: 0,
        mi_atendidas: 0,
        mi_marcadas: 0,
        prospect_realizadas: 0,
        prospect_atendidas:  0,
        prospect_marcadas:  0,
        desistencia_realizadas:0,
        desistencia_atendidas: 0,
        desistencia_marcadas: 0,
        parcerias_realizadas: 0,
        parcerias_atendidas: 0,
        parcerias_marcadas: 0,
        followup_realizadas: 0,
        followup_atendidas: 0,
        followup_marcadas: 0,
        total_realizadas: 0,
        total_atendidas: 0,
        total_marcadas: 0,
        mes_total_realizadas: 0,
        mes_total_atendidas: 0,
        mes_total_marcadas: 0,
        selected_colaborador: "",

        totalMesPos: 0,
        totalMesLeads:  0,
        totalMesMi :  0,
        totalMesProspect :  0,
        totalMesDesis :  0,
        totalMesParcerias :  0,
        totalMesFollowup :  0,

        totalMesPosA: 0,
        totalMesLeadsA:  0,
        totalMesMiA :  0,
        totalMesProspectA :  0,
        totalMesDesisA :  0,
        totalMesParceriasA :  0,
        totalMesFollowupA :  0,

        totalMesPosR: 0,
        totalMesLeadsR:  0,
        totalMesMiR :  0,
        totalMesProspectR :  0,
        totalMesDesisR :  0,
        totalMesParceriasR :  0,
        totalMesFollowupR :  0,
        error: false,
        clube_selected: 1,
    });

    const {
        totalMesPosA, totalMesLeadsA, totalMesMiA, totalMesProspectA, totalMesDesisA, totalMesParceriasA, totalMesFollowupA,
        totalMesPosR, totalMesLeadsR, totalMesMiR, totalMesProspectR, totalMesDesisR, totalMesParceriasR, totalMesFollowupR,
        totalMesPos, totalMesLeads,totalMesMi, totalMesProspect, totalMesDesis,totalMesParcerias,totalMesFollowup, clube_selected, error, date, pos_realizadas, pos_atendidas, pos_marcadas, leads_realizadas, leads_atendidas, leads_marcadas, mi_realizadas, mi_atendidas, mi_marcadas, prospect_realizadas, prospect_atendidas, prospect_marcadas,
        desistencia_realizadas, desistencia_atendidas, desistencia_marcadas, parcerias_realizadas, parcerias_atendidas, parcerias_marcadas, followup_realizadas, followup_atendidas, followup_marcadas, total_realizadas,  total_atendidas,  total_marcadas,  selected_colaborador, mes_total_realizadas, mes_total_atendidas, mes_total_marcadas } = chamadasData;
    const onChange = e => setChamadasData({...chamadasData, [e.target.name]: e.target.value});

    const onChangeDate = async e => {
        setChamadasData({...chamadasData, [e.target.name]: e.target.value});


        if(!selected_colaborador)
            await getChamadas({ date:e.target.value });
        else
            await getChamadasByConsultor({ date: e.target.value, selected_colaborador: selected_colaborador});

    };

    const onSubmit = async e => {
        e.preventDefault();
    }
    const onSubmitNewData = async e => {
        e.preventDefault();

        console.log({
            date, pos_realizadas, pos_atendidas, pos_marcadas, leads_realizadas, leads_atendidas, leads_marcadas, mi_realizadas, mi_atendidas, mi_marcadas, prospect_realizadas, prospect_atendidas, prospect_marcadas,
            desistencia_realizadas, desistencia_atendidas, desistencia_marcadas, parcerias_realizadas, parcerias_atendidas, parcerias_marcadas, followup_realizadas, followup_atendidas, followup_marcadas, selected_colaborador
        })
        if(!selected_colaborador)
            setChamadasData({...chamadasData,
                ["error"]: "Seleciona um Colaborador para editar"
            });
        else
            try  {
                await insertChamadasByConsultor({ date, pos_realizadas, pos_atendidas, pos_marcadas, leads_realizadas, leads_atendidas, leads_marcadas, mi_realizadas, mi_atendidas, mi_marcadas, prospect_realizadas, prospect_atendidas, prospect_marcadas,
                    desistencia_realizadas, desistencia_atendidas, desistencia_marcadas, parcerias_realizadas, parcerias_atendidas, parcerias_marcadas, followup_realizadas, followup_atendidas, followup_marcadas, selected_colaborador }).then((err,res)=> {
                    setChamadasData({...chamadasData, ["loading"]: false});
                })
            } catch (e){
                setChamadasData({...chamadasData, ["loading"]: false});
            }

    }
    const onChangeCol =  async (e) => {
        setChamadasData({...chamadasData,
            ["selected_colaborador"]: e.target.value,
            ["loading"]: true
        });

        let selectedData = selected_colaborador

        try  {
            await getChamadasByConsultor({ date: date, selected_colaborador: e.target.value  }).then((err,res)=> {
                setChamadasData({...chamadasData, ["loading"]: false});
            })
        } catch (e){
            setChamadasData({...chamadasData, ["loading"]: false});
        }

    };
    const onChangeConsultor = async e => {
        setChamadasData({...chamadasData, [e.target.name]: e.target.value});
        getChamadas({ date});
    };
    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs">
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Gestão Comercial >
                        <Link to={"/chamadas"}>Chamadas</Link>
                    </div>

                    <form onSubmit={e => onSubmit(e) } className={"table-inputs-filtros table-inputs-filtros-contactos "}>
                        <label htmlFor="">Filtro:
                            <input type="date" name={"date"} value={date} onChange={e => onChangeDate(e)}/>
                        </label>

                        <label htmlFor="" style={{marginRight: "20px",marginTop: "0"}}>
                            Colaborador:
                            <select name="selected_colaborador"   value={selected_colaborador} onChange={ e => onChangeCol(e)}>
                                <option value="">TODOS</option>
                                {user && user.colaboradores.map((e) => (
                                    <option value={e.id}>{e.nome}</option>
                                ))}
                            </select>
                        </label>

                        {/*
                        <div className="table-grid-two-columns">
                                <label htmlFor="">
                                    <div>
                                        POS
                                        <span>Total: 24</span>
                                    </div>
                                    <input type="number" name={"pos"} value={pos} onChange={e => onChange(e)} />
                                </label>
                                <label htmlFor="">
                                    <div>
                                        Leads
                                        <span>Total: 134</span>
                                    </div>
                                    <input type="number" name={"leads"} value={leads} onChange={e => onChange(e)} />
                                </label>
                                <label htmlFor="">
                                    <div>
                                        MI
                                        <span>Total: 10</span>
                                    </div>
                                    <input type="number" name={"mi"} value={mi} onChange={e => onChange(e)} />
                                </label>
                                <label htmlFor="">
                                    <div>
                                        Prospect
                                        <span>Total: 34</span>
                                    </div>

                                    <input type="number" name={"prospect"} value={prospect} onChange={e => onChange(e)} />
                                </label>
                                <label htmlFor="">
                                    <div>
                                        Desistência
                                        <span>Total: 40</span>
                                    </div>

                                    <input type="number" name={"desistencia"} value={desistencia} onChange={e => onChange(e)} />
                                </label>
                                <label htmlFor="">
                                    <div>
                                        Parcerias
                                        <span>Total: 13</span>
                                    </div>

                                    <input type="number"  name={"parcerias"} value={parcerias} onChange={e => onChange(e)} />
                                </label>
                                <label htmlFor="">
                                    <div>
                                        Follow up
                                        <span>Total: 1</span>
                                    </div>

                                    <input type="number" name={"followup"} value={followup} onChange={e => onChange(e)}  />
                                </label>

                            </div>
*/}



                    </form>

                    <form onSubmit={e => onSubmitNewData(e) } >
                        <div id="chamadas-projecao-container" className="agenda-comercial-container">
                            <div className="agenda-column">
                                <h3>-</h3>
                                <div className="agenda-item-container" data-zone="">
                                    <span>Realizadas</span>
                                    <span>Atendidas</span>
                                    <span>Marcadas</span>
                                    <span>Total Mês R.</span>
                                    <span>Total Mês A.</span>
                                    <span>Total Mês M.</span>
                                </div>
                            </div>

                            <div className="agenda-column">
                                <h3>POS</h3>
                                <div className="agenda-item-container" data-zone="segunda">
                                    <span> <input type="number" name={"pos_realizadas"} value={pos_realizadas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" name={"pos_atendidas"} value={pos_atendidas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" name={"pos_marcadas"} value={pos_marcadas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" value={totalMesPosR} disabled /></span>
                                    <span> <input type="number" value={totalMesPosA} disabled /></span>
                                    <span> <input type="number" value={totalMesPos} disabled /></span>
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Leads</h3>
                                <div className="agenda-item-container" data-zone="terca">
                                    <span> <input type="number" name={"leads_realizadas"} value={leads_realizadas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" name={"leads_atendidas"} value={leads_atendidas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" name={"leads_marcadas"} value={leads_marcadas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" value={totalMesLeadsR} disabled /></span>
                                    <span> <input type="number" value={totalMesLeadsA} disabled /></span>
                                    <span> <input type="number" value={totalMesLeads} disabled /></span>
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>MI</h3>
                                <div className="agenda-item-container" data-zone="quarta">
                                    <span> <input type="number" name={"mi_realizadas"} value={mi_realizadas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" name={"mi_atendidas"} value={mi_atendidas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" name={"mi_marcadas"} value={mi_marcadas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" value={totalMesMiR} disabled /></span>
                                    <span> <input type="number" value={totalMesMiA} disabled /></span>
                                    <span> <input type="number" value={totalMesMi} disabled /></span>

                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Prosp.</h3>
                                <div className="agenda-item-container" data-zone="quinta">
                                    <span> <input type="number" name={"prospect_realizadas"} value={prospect_realizadas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" name={"prospect_atendidas"} value={prospect_atendidas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" name={"prospect_marcadas"} value={prospect_marcadas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" value={totalMesProspectR} disabled /></span>
                                    <span> <input type="number" value={totalMesProspectA} disabled /></span>
                                    <span> <input type="number" value={totalMesProspect} disabled /></span>
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Desis.</h3>
                                <div className="agenda-item-container" data-zone="sexta">
                                    <span> <input type="number" name={"desistencia_realizadas"} value={desistencia_realizadas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" name={"desistencia_atendidas"} value={desistencia_atendidas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" name={"desistencia_marcadas"} value={desistencia_marcadas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" value={totalMesDesisR} disabled /></span>
                                    <span> <input type="number" value={totalMesDesisA} disabled /></span>
                                    <span> <input type="number" value={totalMesDesis} disabled /></span>
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Parcer.</h3>
                                <div className="agenda-item-container" data-zone="sabado">
                                    <span> <input type="number" name={"parcerias_realizadas"} value={parcerias_realizadas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" name={"parcerias_atendidas"} value={parcerias_atendidas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" name={"parcerias_marcadas"} value={parcerias_marcadas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" value={totalMesParceriasR} disabled /></span>
                                    <span> <input type="number" value={totalMesParceriasA} disabled /></span>
                                    <span> <input type="number" value={totalMesParcerias} disabled /></span>
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>FollowUp</h3>
                                <div className="agenda-item-container" data-zone="domingo">
                                    <span> <input type="number" name={"followup_realizadas"} value={followup_realizadas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" name={"followup_atendidas"} value={followup_atendidas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" name={"followup_marcadas"} value={followup_marcadas} onChange={e => onChange(e)} /></span>
                                    <span> <input type="number" value={totalMesFollowupR} disabled /></span>
                                    <span> <input type="number" value={totalMesFollowupA} disabled /></span>
                                    <span> <input type="number" value={totalMesFollowup} disabled /></span>

                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Total</h3>
                                <div className="agenda-item-container" data-zone="domingo">
                                    <span><input type="text" value={total_realizadas} disabled /></span>
                                    <span><input type="text" value={total_atendidas} disabled /></span>
                                    <span><input type="text" value={total_marcadas} disabled /></span>
                                    <span> <input type="number" value={mes_total_realizadas} disabled /></span>
                                    <span> <input type="number" value={mes_total_atendidas} disabled /></span>
                                    <span><input type="text" value={mes_total_marcadas} disabled /></span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="total-projeccoes">
                            <div><strong>Total Chamadas Realizadas Mês:</strong> {mes_total_realizadas} </div>
                            <div><strong>Total Chamadas Atendidas Mês:</strong> {mes_total_atendidas} </div>
                            <div><strong>Total Chamadas Marcadas Mês:</strong> {mes_total_marcadas} </div>

                        </div>*/}
                        <button className={"new-btn"}>Guardar</button>
                        <div className={error ? "alert alert-danger active": "alert alert-danger"}>{error}</div>

                    </form>
                </div>
            </div>
        </Fragment>
};
ChamadasDirectorClube.propTypes = {
    auth: PropTypes.object.isRequired,
    getChamadas: PropTypes.func.isRequired,
    insertChamadas: PropTypes.func.isRequired,
    getChamadasByConsultor: PropTypes.func.isRequired,
    insertChamadasByConsultor: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    chamadas: state.chamadas
});
export default connect(mapStateToProps, { getChamadas, insertChamadas, getChamadasByConsultor, insertChamadasByConsultor })( ChamadasDirectorClube );
