/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_BANCO_DD,
    GET_BANCO_DD_ERROR,
} from "./types";

export const getBancoDD = ({ id_clube, banco_dd }) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = {  id_clube, banco_dd };
        const res = await axios.post("/api/bancodd" , body, config);


        dispatch({
            type: GET_BANCO_DD,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_BANCO_DD_ERROR,
        })
    }
}
export const updateBancoDD = ({ id_clube, banco_dd, data }) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = {  id_clube, banco_dd, data };
        const res = await axios.post("/api/bancodd/update" , body, config);


        dispatch({
            type: GET_BANCO_DD,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_BANCO_DD_ERROR,
        })
    }
}
export const newBancoDD = ({ nome_dd, date_dd  }) => async dispatch => {
    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = {  nome_dd, date_dd };
        const res = await axios.post("/api/bancodd/insertnew" , body, config);


        dispatch({
            type: GET_BANCO_DD,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_BANCO_DD_ERROR,
        })
    }
}

