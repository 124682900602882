/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_ACUPUNTURA,
    GET_ACUPUNTURA_ERROR,
} from "./types";

export const getRotaAcupuntura = ({prevDate, id_clube, id_acupunt }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { prevDate, id_clube, id_acupunt };
    try {
        const res = await axios.post("/api/acupuntura", body, config);

        dispatch({
            type: GET_ACUPUNTURA,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ACUPUNTURA_ERROR,
        })
    }
}
export const currentDataPrev = ({ prevDate, id_clube, id_acupunt }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { prevDate, id_clube, id_acupunt  };
    try {
        const res = await axios.post("/api/acupuntura", body, config);

        dispatch({
            type: GET_ACUPUNTURA,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ACUPUNTURA_ERROR,
        })
    }
}
export const insertAppointment = ({marcacaoID, n_socio, time_appoint, prevDate, id_clube, id_acupunt  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { marcacaoID, n_socio, time_appoint, prevDate, id_clube, id_acupunt};
    try {
        
        const res = await axios.post("/api/acupuntura/insert", body, config);
        
        dispatch({
            type: GET_ACUPUNTURA,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ACUPUNTURA_ERROR,
        })
    }
}
export const deleteAppointment = ({ marcacaoID, prevDate, id_clube, id_acupunt }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { marcacaoID, prevDate, id_clube, id_acupunt };

    try {
        const res = await axios.post("/api/acupuntura/delete", body, config);

        dispatch({
            type: GET_ACUPUNTURA,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ACUPUNTURA_ERROR,
        })
    }
}