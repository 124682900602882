import React, {Fragment, useEffect} from 'react';
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import './styles/global.css';
import Login from "./components/layout/Login";

import Dashboard from "./components/layout/Dashboard";
/* GESTÃO COMERCIAL */
import ContactosAngariados from "./components/layout/gestaocomercial/ContactosAngariados";
import ContactosNaoConvertidos from "./components/layout/gestaocomercial/ContactosNaoConvertidos";
import Inscricoes from "./components/layout/gestaocomercial/Inscricoes";
import Receitas from "./components/layout/gestaocomercial/Receitas";
import Cancelamentos from "./components/layout/gestaocomercial/Cancelamentos";
import Chamadas from "./components/layout/gestaocomercial/Chamadas";
import Agenda from "./components/layout/gestaocomercial/Agenda";


/* SERVIÇO CLIENTE */
import RotaPT from "./components/layout/servicocliente/RotaPT";
import RotaNutricao from "./components/layout/servicocliente/RotaNutricao";
import RotaAcupuntura from "./components/layout/servicocliente/RotaAcupuntura";


import Socios from "./components/layout/servicocliente/Socios";
import Manutencao from "./components/layout/servicocliente/Manutencao";
import Limpeza from "./components/layout/servicocliente/Limpeza";
import Aulas from "./components/layout/servicocliente/Aulas";
import PersonalTrainers from "./components/layout/servicocliente/PersonalTrainers";
import InstrutorSala from "./components/layout/servicocliente/InstrutorSala";



/* Gestão CLube */

import Utilizadores from "./components/layout/gestaoclube/Utilizadores";
import Colaboradores from "./components/layout/gestaoclube/Colaboradores";
import Reunioes from "./components/layout/gestaoclube/Reunioes";
import PontosCriticos from "./components/layout/gestaoclube/PontosCriticos";
import PlanoFormacao from "./components/layout/gestaoclube/PlanoFormacao";
import MapaFerias from "./components/layout/gestaoclube/MapaFerias";
import ProjeccaoComercial from "./components/layout/gestaoclube/ProjeccaoComercial";
import BancoDD from "./components/layout/gestaoclube/BancoDD";
import RelatorioRotaPT from "./components/layout/gestaoclube/RelatorioRotaPT";






import PrivateRoute from "./components/routing/PrivateRoute";
// @Title REDUX
import { Provider } from "react-redux";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import { loadUser } from "./actions/auth";

if(localStorage.token)
    setAuthToken(localStorage.token);

const App= () => {
    useEffect(() => {
        store.dispatch(loadUser());
    }, []);
    return(
        <Provider store={store}>
            <Router>
                <Fragment>
                    <Route exact path="/" component={Login} />
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <PrivateRoute exact path="/dashboard" component={Dashboard} />

                        {/* GESTÃO COMERCIAL */}
                        <PrivateRoute exact path="/inscricoes" component={Inscricoes} />
                        <PrivateRoute exact path="/contactosangariados" component={ContactosAngariados} />
                        <PrivateRoute exact path="/contactosnaoconvertidos" component={ContactosNaoConvertidos} />
                        <PrivateRoute exact path="/agenda" component={Agenda} />



                        <PrivateRoute exact path="/cancelamentos" component={Cancelamentos} />
                        <PrivateRoute exact path="/receitas" component={Receitas} />
                        <PrivateRoute exact path="/cobrancas" component={Dashboard} />
                        <PrivateRoute exact path="/chamadas" component={Chamadas} />

                        {/* SERVIÇO CLIENTE */}
                        <PrivateRoute exact path="/rota_pt" component={RotaPT} />
                        <PrivateRoute exact path="/rota_nutricao" component={RotaNutricao} />
                        <PrivateRoute exact path="/rota_acupuntura" component={RotaAcupuntura} />

                        <PrivateRoute exact path="/socios" component={Socios} />
                        <PrivateRoute exact path="/manutencao" component={Manutencao} />
                        <PrivateRoute exact path="/limpeza" component={Limpeza} />
                        <PrivateRoute exact path="/aulas" component={Aulas} />
                        <PrivateRoute exact path="/personaltrainers" component={PersonalTrainers} />
                        <PrivateRoute exact path="/instrutorsala" component={InstrutorSala} />

                        {/* GESTÃO CLUBE */}
                        <PrivateRoute exact path="/colaboradores" component={Colaboradores} />
                        <PrivateRoute exact path="/utilizadores" component={Utilizadores} />
                        <PrivateRoute exact path="/relatorios_rotapt" component={RelatorioRotaPT} />

                        <PrivateRoute exact path="/reunioes" component={Reunioes} />
                        <PrivateRoute exact path="/pontoscriticos" component={PontosCriticos} />
                        <PrivateRoute exact path="/planoformacao" component={PlanoFormacao} />
                        <PrivateRoute exact path="/mapaferias" component={MapaFerias} />
                        <PrivateRoute exact path="/projeccaocomercial" component={ProjeccaoComercial} />
                        <PrivateRoute exact path="/bancodd" component={BancoDD} />


                    </Switch>

                </Fragment>
            </Router>
        </Provider>
    )};

export default App;
