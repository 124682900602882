/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getUtilizadores, insertUtilizadores, deleteUtilizadores } from "./actions/utilizadores";

const UtilizadoresAdministracao = ({ auth: {user}, getUtilizadores, insertUtilizadores, deleteUtilizadores, utilizadores: { data} }) => {
    useEffect(() => {
        getUtilizadores({ nome_colaborador_filtro: false });
    },[]);

    useLayoutEffect(()=> {
        setUtilizadoresData({...utilizadoresData,
            modal: data && data.err ? true : false,
            err:  data && data.err
        });

    },[data]);

    const [utilizadoresData, setUtilizadoresData] = useState({
        modal: false,
        nome_colaborador_filtro: "",
        nome_ut:"",
        email_ut:"",
        tel_ut:"",
        pass_ut:"",
        id_level_ut: "",
        id: "",
        id_clube_ut: "",
	err: "",
    });

    const { modal, nome_colaborador_filtro, nome_ut, email_ut, tel_ut, pass_ut, id_level_ut, id, id_clube_ut, err } = utilizadoresData;
    const onChange = e => setUtilizadoresData({...utilizadoresData, [e.target.name]: e.target.value});

    const onChangeDate = async e => {
        setUtilizadoresData({...utilizadoresData, [e.target.name]: e.target.value});

    };
    const onChangeText = async (e) => {
        setUtilizadoresData({...utilizadoresData,
            [e.target.name]: e.target.value,
        });

        try {
            await getUtilizadores({ nome_colaborador_filtro: e.target.value})
        } catch (e){

        }

    };
    const onSubmit = async e => {
        e.preventDefault();
        await insertUtilizadores({ nome_ut, email_ut, tel_ut, pass_ut, id_level_ut, id, id_clube_ut });
    }

    const onDelete = async e => {
        e.preventDefault();
        
        await deleteUtilizadores({id_clube_ut, id});
       
    }

    const onOpenModal = async e => {
        e.preventDefault();
        setUtilizadoresData({...utilizadoresData, 
            ["modal"]: modal ? false : true,
            ["nome_ut"]: "",
	    ["tel_ut"]: "",
	    ["email_ut"]: "",
	    ["pass_ut"]: "",
            ["id_level_ut"]: "",
            ["id_clube_ut"]: "",         
         });
    }

    const onOpenModalValues = async (e, nome, telemovel, email, id_level, id_clube, id) => {
        e.preventDefault();
        setUtilizadoresData({...utilizadoresData,
            ["modal"]: modal ? false : true,
            ["nome_ut"]: nome,
	    ["tel_ut"]: telemovel,
	    ["email_ut"]: email,
	    ["pass_ut"]: "",
            ["id_level_ut"]: id_level,
	    ["id_clube_ut"]: id_clube,
	    ["id"]: id,
        });
    }

    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Gestão Clube >
                        <Link to={"/utilizadores"}>Utilizadores</Link>
                    </div>

                    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="">
                            Nome:
                            <input type="text" name="nome_colaborador_filtro"  value={nome_colaborador_filtro} id="" onChange={ e => onChangeText(e)} />
                        </label>
                    </div>

                    <button  onClick={e => onOpenModal(e)} className={"new-btn"} style={{maxWidth: "200px", margin: "20px 0 0 0"}}>Novo</button>

                    <table className="table-format-one">
                        <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Telemovel</th>
                        </tr>
                        </thead>
                        <tbody>

                        {data && data.utilizadores.map(({ nome, telemovel, email, id_level,id_clube, id }) => (
                            <tr className={"to-call"} onClick={ e => onOpenModalValues(e, nome, telemovel, email, id_level, id_clube, id )}>
                                <td>{nome}</td>
                                <td>{telemovel}</td>
                            </tr>
                        ))}


                        </tbody>
                    </table>

                    <div className={modal ? "new-lead-modal new-colaborador active": "new-colaborador new-lead-modal"}>
                         <span id="close-new-lead-modal" onClick={ e => onOpenModal(e)}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => onSubmit(e)}>
                            <label htmlFor="">
                                Nome
                                <input type="text" name={"nome_ut"} value={nome_ut} onChange={e => onChange(e)} required/>
                            </label>

                            <label htmlFor="">
                                E-mail
                                <input type="email" name={"email_ut"} value={email_ut} onChange={e => onChange(e)} required/>
                            </label>

                            <label htmlFor="">
                                Telemovel
                                <input type="tel" name={"tel_ut"} value={tel_ut} onChange={e => onChange(e)} required/>
                            </label>

                            <label htmlFor="">
                                Password (min 8)
                                <input type="password" name={"pass_ut"} minLength={8} value={pass_ut} onChange={e => onChange(e)} required/>
                            </label>

                            <label htmlFor="">
                                Accesso
                                <select name="id_level_ut" value={id_level_ut}  onChange={e => onChange(e)} required>
                                    <option value="">Seleciona um Accesso</option>
                                    <option value="0">Direção Geral</option>
                                    <option value="1">Direção Clube</option>
                                    <option value="2">Recepção</option>
                                    <option value="4">Personal Trainer</option>
                                    <option value="5">Nutricionista</option>
                                    <option value="6">Manutenção</option>
                                    <option value="7">Acupuntura</option>

                                </select>
                            </label>

                            <label htmlFor="">
                                Clube
                                <select name="id_clube_ut" value={id_clube_ut} onChange={ e => onChange(e)} required style={{textTransform: "capitalize"}}>
                                     <option value="">Seleciona um Clube</option>
                                     {user && user.clubes.map((e) => (
                                        <option value={e.id} >{e.localidade}</option>
                                    ))}
                                </select>
                            </label>


                            <button>GUARDAR</button>
			    <button id="delete" onClick={e => onDelete(e)}>APAGAR</button>

                            {err ? err : ""}
                        </form>
                    </div>

                </div>
            </div>

        </Fragment>
};
UtilizadoresAdministracao.propTypes = {
    auth: PropTypes.object.isRequired,
    getUtilizadores: PropTypes.func.isRequired,
    insertUtilizadores: PropTypes.func.isRequired,
    deleteUtilizadores: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    utilizadores: state.utilizadores
});
export default connect(mapStateToProps, { getUtilizadores, insertUtilizadores, deleteUtilizadores })( UtilizadoresAdministracao );
