/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import DashboardAdmin from "../../administracao/layout/Dashboard";
 import RelatorioRotaPTDiretorClube from "../../diretorclube/gestaoclube/RelatorioRotaPT";
import RelatorioRotaPTSenior from "../../ptsenior/gestaoclube/RelatorioRotaPT";
const RelatorioRotaPT = ({auth: {user}}) => {
    const [leadsData, setLeadsData] = useState({
        initState: ""
    });
    const { initState } = leadsData;
    const onChange = e => setLeadsData({...leadsData, [e.target.name]: e.target.value});

    return user === null ? <Fragment>
            <div><img src="./img/loading.gif" alt=""/></div>
        </Fragment> :
        <Fragment>
            {(function() {
                switch (user.id_level) {
                    case 0:
                        return <DashboardAdmin />;
                    case 1:
                        return <RelatorioRotaPTDiretorClube />;
                    case 2:
                        return <DashboardAdmin />;
                    case 3:
                        return <DashboardAdmin />;
                    case 8:
                        return <RelatorioRotaPTSenior />;
                    default:
                        return null;
                }
            })()}
        </Fragment>

};

RelatorioRotaPT.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(mapStateToProps, {})(RelatorioRotaPT);
