/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import {
    getReunioesCordenacao,
    updateOrInsertReunioesCordenacao,
    deleteReunioesCordenacao,
 } from "./actions/reunioescordenacao";
import Pagination from "../gestaocomercial/Pagination";


const ReunioesDiretorClube = ({ auth: {user} , getReunioesCordenacao, updateOrInsertReunioesCordenacao, deleteReunioesCordenacao, reunioescordenacao: { data }}) => {
    useEffect(() => {
        getReunioesCordenacao();
    },[]);

    useLayoutEffect(() => {
        setReunioesData({...reunioesData,
            modal: data ? !!data.err : false,
            err: data ? data.err ?  data.err : false : false
        });

    },[data]);

    const [reunioesData, setReunioesData] = useState({
        modal: false,
        date: moment().format("YYYY-MM-DD"),
        topico: "" ,
        assistente: "" ,
        pessoas_frequentes: "",
        ata: "",
        idreuniao: "",
        err: false,
    });
    const { modal, err, date, topico, assistente, pessoas_frequentes, ata, idreuniao } = reunioesData;
    const onChange = e => setReunioesData({...reunioesData, [e.target.name]: e.target.value});
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let resultados = data ? data.reunioescordenacao ? data.reunioescordenacao : [] : [];
    const currentPosts = resultados.slice(indexOfFirstPost,indexOfLastPost);
    const paginate = (e,pageNumber) => {
        e.preventDefault()
        setCurrentPage(pageNumber)
    }

    const onSubmit = async e => {
        e.preventDefault();

        try{
            await updateOrInsertReunioesCordenacao({  date, topico, assistente, pessoas_frequentes, ata, idreuniao });
        }catch (e){
            console.log(e)
        }
    }
    const onOpenModal = async e => {
        e.preventDefault();
        setReunioesData({...reunioesData,
            ["modal"]: modal ? false : true,
            ["date"]: moment().format("YYYY-MM-DD"),
            ["topico"]: "",
            ["assistente"]: "",
            ["pessoas_frequentes"]:  "",
            ["ata"]:  "",
            ["idreuniao"]:  "",
        });
    }
     const onOpenModalValues = async (e, vdate, vtopico, vassistente, vpessoas_frequentes, vata, vdata_entrada, vid, vid_clube) => {
        e.preventDefault();

        if(!e.target.classList.contains("row-delete"))
            setReunioesData({...reunioesData,
                ["modal"]: modal ? false : true,
                ["date"]: moment(vdate).format("YYYY-MM-DD"),
                ["topico"]: vtopico,
                ["assistente"]: vassistente,
                ["pessoas_frequentes"]: vpessoas_frequentes,
                ["ata"]: vata,
                ["idreuniao"]: vid,
            });
    }

    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Gestão Clube >
                        <Link to={"/reunioes"}>Reunioes</Link>
                    </div>
                    <button  onClick={e => onOpenModal(e)} className={"new-btn"}>Novo</button>

                    <table className="table-format-one">
                        <thead>
                        <tr>
                            <th>Data</th>
                            <th>Topico</th>
                            <th>Assistente</th>
                        </tr>
                        </thead>
                        <tbody>

                            {currentPosts && currentPosts.map(({date, topico, assistente, pessoas_frequentes, ata,data_entrada, id, id_clube}) => (
                                <tr key={id} className={"to-call"} onClick={e => onOpenModalValues(e, date, topico, assistente, pessoas_frequentes, ata, data_entrada, id, id_clube)}>
                                    <td>
                                        { date ? (
                                            (parseInt(new Date(date).getDate())  < 10 ? ("0" + new Date(date).getDate()) : new Date(date).getDate())
                                            + "/" +
                                            (parseInt(new Date(date).getMonth() + 1)  < 10 ? ("0" + (new Date(date).getMonth() +1)) : new Date(date).getMonth() +1)
                                            + "/" +
                                            (parseInt(new Date(date).getFullYear()))
                                        ) : ("") }
                                    </td>
                                    <td>{topico}</td>
                                    <td>
                                        {assistente}
                                    </td>
                                </tr>
                            ))}


                        </tbody>
                    </table>
                    <Pagination postsPerPAge={postsPerPage} totalPosts={resultados.length} paginate={paginate} currentPage={currentPage}/>

                    <div className={modal ? "new-lead-modal new-colaborador  new-colaborador-reunioes active": "new-colaborador new-colaborador-reunioes new-lead-modal"}>
                         <span id="close-new-lead-modal" onClick={ e => onOpenModal(e)}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => onSubmit(e)}>
                            <label htmlFor="">
                                Data
                                <input type="date" name={"date"} value={date} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                Topico
                                <input type="text" name={"topico"} value={topico} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                Assistente
                                <input type="text" name={"assistente"} value={assistente} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                Pessoas Presentes
                                <textarea name={"pessoas_frequentes"} value={pessoas_frequentes} onChange={e => onChange(e)} required cols="5" rows="5"></textarea>
                            </label>
                            <label htmlFor="">
                                ATA
                                <textarea name={"ata"} value={ata} onChange={e => onChange(e)} required cols="10" rows="10"></textarea>
                            </label>
                            <button>GUARDAR</button>
                        </form>
                    </div>

                </div>
            </div>

        </Fragment>
};
ReunioesDiretorClube.propTypes = {
    auth: PropTypes.object.isRequired,
    getReunioesCordenacao: PropTypes.func.isRequired,
    updateOrInsertReunioesCordenacao: PropTypes.func.isRequired,
    deleteReunioesCordenacao: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    reunioescordenacao: state.reunioescordenacao
});
export default connect(mapStateToProps, { getReunioesCordenacao, updateOrInsertReunioesCordenacao, deleteReunioesCordenacao  })( ReunioesDiretorClube );
