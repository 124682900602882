/**
 * Created by danielsilva on 11/04/2020.
 */
import {
    GET_AULAS,
    GET_AULAS_ERROR,
} from "../actions/types";

const initialState = {
    loading: true,
    data: null,
    error: {}
}

export default  function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_AULAS:
            return {
                ...state,
                data: payload,
                loading: false
            }
        case GET_AULAS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}