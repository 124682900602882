/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGOUT,
    RECOVERY_PASS_SUCCESS,
    RECOVERY_PASS_FAIL,
    RESET_PASS_SUCCESS,
    RESET_PASS_FAIL,
    TYPE_MARCACAO_SUCCESS,
    TYPE_MARCACAO_FAIL,
    TYPELIST_MARCACAO_SUCCESS,
    TYPELIST_MARCACAO_FAIL
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { Router } from "react-router-dom";


export const loadUser = () => async dispatch => {
    if(localStorage.token)
        setAuthToken(localStorage.token);

    try {
        const res = await axios.get("/api/auth");


        dispatch({
            type:USER_LOADED,
            payload: res.data
        })


    } catch (err) {
        dispatch({
            type: AUTH_ERROR,
        })
    }
}

// Register User
export const login = ({ email, password}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }

    // const body = JSON.stringify({name, email, password});
    const body = {email, password};

    try {
        const res = await axios.post("/api/auth/login", body, config);

        console.log(res.data)
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        dispatch(loadUser());
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach(error => dispatch("eroor"));
        }


        dispatch({
            type: LOGIN_FAIL
        })
    }
}
// LOGOUT
export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    });
}