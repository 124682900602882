/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getColaboradores, insertColaboradores, deleteColaboradores  } from "./actions/colaboradores";

const ColaboradoresAdministracao = ({ auth: {user}, getColaboradores, insertColaboradores , deleteColaboradores, colaboradores: { data} }) => {
    useEffect(() => {
        getColaboradores({ nome_colaborador_filtro: false});
    },[]);

    useLayoutEffect(()=> {
        setColaboradoresData({...colaboradoresData,
            modal: data && data.err ? true : false,
            err:  data && data.err
        });

    },[data]);

    const [colaboradoresData, setColaboradoresData] = useState({
        modal: false,
        nome_colaborador_filtro: "",
        nome_co:"",
        tel_co:"",
        funcao_co:"",
        morada_co:"",
        iban_co:"",
        email_gym_co:"",
        email_pessoal_co:"",
        tipo_contrato_co: 1,
        inicio_contrato_co:"",
        fim_contrato_co:"",
        id_clube_co: 1,
        efetivo_co: "",
        id: "",
        err: "",
        
    });

    const { err, modal, nome_colaborador_filtro,nome_co,tel_co,funcao_co,morada_co,iban_co,email_gym_co,email_pessoal_co,tipo_contrato_co,inicio_contrato_co,fim_contrato_co,id_clube_co,efetivo_co,id } = colaboradoresData;
    const onChange = e => setColaboradoresData({...colaboradoresData, [e.target.name]: e.target.value});
    const onCheckboxChange = (e) => {
        setColaboradoresData({...colaboradoresData, [e.target.name]: e.target.checked});

    }

    const onChangeDate = async e => {
        setColaboradoresData({...colaboradoresData, [e.target.name]: e.target.value});
    };
    const onChangeText = async (e) => {
        setColaboradoresData({...colaboradoresData,
            [e.target.name]: e.target.value,
        });

        try {
            await getColaboradores({ nome_colaborador_filtro: e.target.value })
        } catch (e){

        }

    };
    const onSubmit = async e => {
                
        await insertColaboradores({ nome_co,tel_co,funcao_co,morada_co,iban_co,email_gym_co,email_pessoal_co,tipo_contrato_co,inicio_contrato_co,fim_contrato_co,id_clube_co,efetivo_co,id  });
        
    }

     const onDelete = async e => {
        e.preventDefault();
        
        await deleteColaboradores({id_clube_co, id});
       
   }

    const onOpenModal = async e => {
        e.preventDefault();
        setColaboradoresData({...colaboradoresData,
           ["modal"]: modal ? false : true,
           ["nome_co"]:"",
           ["tel_co"]:"",
           ["funcao_co"]:"",
           ["morada_co"]:"",
           ["iban_co"]:"",
           ["email_gym_co"]:"",
           ["email_pessoal_co"]:"",
           ["tipo_contrato_co"]: 1,
           ["inicio_contrato_co"]:"",
           ["fim_contrato_co"]:"",
           ["id_clube_co"]: 1,
           ["efetivo_co"]: "",
        });
    }

    const onOpenModalValues = async (e, nome, telemovel, funcao, morada, iban, email_gym, email_pessoal, tipo_contrato, inicio_contrato, fim_contrato, id_clube, efetivo, id) => {
        e.preventDefault();
        setColaboradoresData({...colaboradoresData,
           ["modal"]: modal ? false : true,
           ["nome_co"]:nome,
           ["tel_co"]:telemovel,
           ["funcao_co"]:funcao,
           ["morada_co"]:morada,
           ["iban_co"]:iban,
           ["email_gym_co"]:email_gym,
           ["email_pessoal_co"]:email_pessoal,
           ["tipo_contrato_co"]:tipo_contrato,
           ["inicio_contrato_co"]:inicio_contrato ? moment(inicio_contrato).format("YYYY-MM-DD")  : "",
           ["fim_contrato_co"]:fim_contrato ? moment(fim_contrato).format("YYYY-MM-DD")  : "",
           ["id_clube_co"]: id_clube,
           ["efetivo_co"]: efetivo === 1 ? 1 : 0,
           ["id"]:id,
        });
    }

    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Gestão Clube >
                        <Link to={"/colaboradores"}>Colaboradores</Link>
                    </div>

                    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="">
                            Nome:
                            <input type="text" name="nome_colaborador_filtro"  value={nome_colaborador_filtro} id="" onChange={ e => onChangeText(e)} />
                        </label>
                    </div>

	      <button  onClick={e => onOpenModal(e)} className={"new-btn"} style={{maxWidth: "200px", margin: "20px 0 0 0"}}>Novo</button>

                    <table className="table-format-one">
                        <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Telemovel</th>
                            <th>Email Pessoal</th>
                            <th>Função</th>
                            <th>Inicio Contr.</th>
                            <th>Fim Contr.</th>
                        </tr>
                        </thead>
                        <tbody>

                        {data && data.colaboradores.map(({nome,  telemovel, funcao, morada, iban,email_gym,email_pessoal,tipo_contrato, inicio_contrato,fim_contrato,id_clube,efetivo,id}) => (
                            <tr className={"to-call"} onClick={e => onOpenModalValues(e, nome,  telemovel, funcao, morada, iban,email_gym,email_pessoal,tipo_contrato, inicio_contrato,fim_contrato, id_clube, efetivo, id )}>
                                <td>{nome}</td>
                                <td>{telemovel}</td>
                                <td>{email_pessoal}</td>
                                <td>{funcao}</td>

                                <td>
                                    {
                                        (parseInt(new Date(inicio_contrato).getDate())  < 10 ? ("0" + new Date(inicio_contrato).getDate()) : new Date(inicio_contrato).getDate())
                                        + "/" +
                                        (parseInt(new Date(inicio_contrato).getMonth() +1)  < 10 ? ("0" + (new Date(inicio_contrato).getMonth() +1)) : new Date(inicio_contrato).getMonth() +1)
                                        + "/" +
                                        (parseInt(new Date(inicio_contrato).getFullYear()))
                                    }
                                </td>
                                <td>
                                    {
                                        (parseInt(new Date(fim_contrato).getDate())  < 10 ? ("0" + new Date(fim_contrato).getDate()) : new Date(fim_contrato).getDate())
                                        + "/" +
                                        (parseInt(new Date(fim_contrato).getMonth() +1)  < 10 ? ("0" + (new Date(fim_contrato).getMonth() +1)) : new Date(fim_contrato).getMonth() +1)
                                        + "/" +
                                        (parseInt(new Date(fim_contrato).getFullYear()))
                                    }
                                </td>
                            </tr>
                        ))}


                        </tbody>
                    </table>

                    <div className={modal ? "new-lead-modal new-colaborador active": "new-colaborador new-lead-modal"}>
                         <span id="close-new-lead-modal" onClick={ e => onOpenModal(e)}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => onSubmit(e)}>
                            <label htmlFor="">
                                Nome
                                <input type="text" name={"nome_co"} value={nome_co} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                Telemovel
                                <input type="text" name={"tel_co"} value={tel_co} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                Função
                                <select name="funcao_co" value={funcao_co}  onChange={e => onChange(e)} required>
                                    
                                    <option value="Ass. Administrativa">Ass. Administrativa</option>
                                    <option value="Assessora de Direção">Assessora de Direção</option>
                                    <option value="Aulas">Aulas</option>
                                    <option value="Consultor">Consultor</option>
                                    <option value="Consultor Sênior">Consultor Sênior</option>
		         <option value="Dep. Comunicação">Dep. Comunicação</option>
                                    <option value="Direção Clube">Direção Clube</option>
		         <option value="Direção Geral">Direção Geral</option>
                                    <option value="Diretor">Diretor Fitness</option>
                                    <option value="Gabinetes">Gabinetes</option>
                                    <option value="Gym kids">Gym kids</option> 
                                    <option value="Nutrição">Nutrição</option>
                                    <option value="PT">PT</option>
                                  

                                </select>
                            </label>

                             <label htmlFor="">
                                Clube
                                <select name="id_clube_co"  value={id_clube_co} onChange={ e => onChange(e)} style={{textTransform: "capitalize"}} required>
                                    {user && user.clubes.map((e) => (
                                        <option value={e.id} >{e.localidade}</option>
                                    ))}
                                    <option value="5" >Geral</option>
                                    <option value="6" >Freelancer</option>
                                </select>
                            </label>

                            <label htmlFor="">
                                Morada
                                <input type="text" name={"morada_co"} value={morada_co} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                IBAN
                                <input type="text" name={"iban_co"} value={iban_co} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                E-mail Gymnasium
                                <input type="text" name={"email_gym_co"} value={email_gym_co} onChange={e => onChange(e)} />
                            </label>
                            <label htmlFor="">
                                E-mail Pessoal
                                <input type="text" name={"email_pessoal_co"} value={email_pessoal_co} onChange={e => onChange(e)} />
                            </label>

	                        <label htmlFor="">
                                Tipo de Contrato
                                <select name={"tipo_contrato_co"} value={tipo_contrato_co}  onChange={e => onChange(e)} required>
                                        <option value="1">Prestação de Serviços</option>
                                        <option value="2">Contrato de Trabalho</option>
                                </select>
                            </label>

                            <label htmlFor="">
                                    <span>
                                        Efetivo
                                        <input type="checkbox" name={"efetivo_co"} value={efetivo_co}  onChange={e => onCheckboxChange(e)} checked={efetivo_co} />
                                    </span>
                            </label>

                            

                             <label htmlFor="">
                                 início contrato
                                  <input type="date" name={"inicio_contrato_co"} value={inicio_contrato_co}  onChange={e => onChange(e)} required />
                            </label>
                            <label htmlFor="">
                                 Fim contrato
                                  <input type="date" name={"fim_contrato_co"} value={fim_contrato_co}  onChange={e => onChange(e)} />
                            </label>


                            <button>GUARDAR</button>
                            <button id="delete" onClick={e => onDelete(e)}>APAGAR</button>

                            {err ? err : ""}
                        </form>
                    </div>

                </div>
            </div>

        </Fragment>
};
ColaboradoresAdministracao.propTypes = {
    auth: PropTypes.object.isRequired,
    getColaboradores: PropTypes.func.isRequired,
    insertColaboradores: PropTypes.func.isRequired,
    deleteColaboradores: PropTypes.func.isRequired,
    
};
const mapStateToProps = state => ({
    auth: state.auth,
    colaboradores: state.colaboradores
});
export default connect(mapStateToProps, { getColaboradores, insertColaboradores, deleteColaboradores })( ColaboradoresAdministracao );
