/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_FERIAS,
    GET_FERIAS_ERROR,
} from "./types";

export const insertFerias = ({selected_colaborador,  datede, dateate}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {selected_colaborador,  datede, dateate };

    try {
        const res = await axios.post("/api/ferias/new", body, config);


        dispatch({
            type: GET_FERIAS,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_FERIAS_ERROR,
        })
    }
}
export const getFerias = ({selected_colaborador}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { selected_colaborador };

    try {
        const res = await axios.post("/api/ferias", body, config);


        dispatch({
            type: GET_FERIAS,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_FERIAS_ERROR,
        })
    }
}

