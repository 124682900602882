/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getManutencao, updateManutencao } from "./actions/manutencao";
import Pagination from "./Pagination";

const ManutencaoManutencao = ({ auth: {user}, getManutencao, updateManutencao, manutencao: { data }}) => {
    useEffect(() => {
        getManutencao({ id_clube: 0, status: 0})
    },[]);
    useLayoutEffect(() => {
        setManutencaoData({...manutencaoData,
            modal: data ? !!data.err : false,
            err: data ? data.err ?  data.err : false : false,
	    
	    
        });

    },[data]);
    const [manutencaoData, setManutencaoData] = useState({
        modal: false,
        data_reportado: "",
        que: "",
        falha: "",
        quem: "",
        quemfoi: "",
        acao: "",
        data_resolucao: "",
        status: 0,
        notes: "",
        idmanutencao: false,
        err: false,
        clube_selected: 0
    });

    const { clube_selected, err, modal, data_reportado, que, falha, quem, quemfoi, acao, data_resolucao, status, notes, idmanutencao } = manutencaoData;
    const onChange = e => setManutencaoData({...manutencaoData, [e.target.name]: e.target.value});

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let resultados = data ? data.manutencao ? data.manutencao : [] : [];
    const currentPosts = resultados.slice(indexOfFirstPost,indexOfLastPost);

    const paginate = (e,pageNumber) => {
        e.preventDefault()
        setCurrentPage(pageNumber)
    }

    const onChangeDate = async e => {
        setManutencaoData({...manutencaoData, [e.target.name]: e.target.value});
        console.log(e.target.value)

    };

    const onSubmit = async e => {
        e.preventDefault();

        try {
            await updateManutencao({ data_reportado, que, falha, quem, quemfoi, acao, data_resolucao, status, notes, idmanutencao, id_clube: clube_selected });
        } catch (e) {

        }
    }

    const onChangeConsultor = async e => {
        setManutencaoData({...manutencaoData, [e.target.name]: e.target.value});
        await getManutencao({ id_clube:  e.target.value, status: status});

    };
    const onChangeStatus = async e => {
        setManutencaoData({...manutencaoData, [e.target.name]: e.target.value});
        await getManutencao({ id_clube: clube_selected, status: e.target.value });

    };

    const onOpenModal = async e => {
        e.preventDefault();
        setManutencaoData({...manutencaoData, ["modal"]: modal ? false : true,
            ["data_reportado"]: moment().format("YYYY-MM-DD"),
            ["que"]: "",
            ["falha"]: "",
            ["quem"]: "",
            ["quemfoi"]: "",
            ["acao"]: "",
            ["data_resolucao"]: "",
            ["status"]: 0,
            ["notes"]:"",
            ["idmanutencao"]: false,
	    
        });

    }
    const onOpenModalValues = async (e, dbdata_reportado, dbque, dbfalha, dbquem, dbquemfoi, dbacao, dbdata_resolucao, dbstatus, dbnotes, dbidmanutencao, id_clube) => {
        e.preventDefault();

            setManutencaoData({...manutencaoData,
                ["modal"]: modal ? false : true,
                ["data_reportado"]: moment(dbdata_reportado).format("YYYY-MM-DD"),
                ["que"]: dbque,
                ["falha"]: dbfalha,
                ["quem"]: dbquem,
                ["quemfoi"]: dbquemfoi,
                ["acao"]: dbacao,
                ["data_resolucao"]: dbdata_resolucao ? moment(dbdata_resolucao).format("YYYY-MM-DD") : null,
                ["status"]: dbstatus,
                ["notes"]: dbnotes,
                ["idmanutencao"]: dbidmanutencao,
	        
                
            });
    }
    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Serviço ao Cliente >
                        <Link to={"/manutencao"}>Manutenção</Link>
                    </div>
		    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="">
                            Clube:
                            <select name="clube_selected"   value={clube_selected} onChange={ e => onChangeConsultor(e)} style={{textTransform: "capitalize"}}>
                                <option value="0">Todos</option>
				{user && user.clubes.map((e) => (
                                    <option value={e.id} >{e.localidade}</option>
                                ))}
                            </select>
                        </label>
			<label htmlFor="">
                           Status:
                           <select name="status"   value={status} onChange={ e => onChangeStatus(e)} style={{textTransform: "capitalize"}}>
                               <option value="0">Todos</option>
                               <option value="1">Pendente</option>
			       <option value="2">Resolvido</option>
                            </select>
                        </label>
                    </div>
                    <table className="table-format-one">
                        <thead>
                        <tr>
                            <th>Data Reportado</th>
                            <th>Falha Detectada</th>
                            <th>Data de Resolução</th>
                            <th>Status</th>
                            <th>Clube</th>
                        </tr>
                        </thead>
                        <tbody>
                            {currentPosts && currentPosts.map(({ data_reportado,que, falha, quem, quemfoi, acao, data_resolucao,  status, notes, id, id_clube }) => (
                                <tr className={parseInt(status) === 1 ? "yellow-call" :  parseInt(status) === 2 ? "green-call" : "to-call"} onClick={e => onOpenModalValues(e, data_reportado, que, falha, quem, quemfoi, acao, data_resolucao, status, notes, id, id_clube)}>
                                    <td>
                                    { data_reportado ? (
                                        (parseInt(new Date(data_reportado).getDate())  < 10 ? ("0" + new Date(data_reportado).getDate()) : new Date(data_reportado).getDate())
                                        + "/" +
                                        (parseInt(new Date(data_reportado).getMonth() + 1)  < 10 ? ("0" + (new Date(data_reportado).getMonth() +1)) : new Date(data_reportado).getMonth() +1)
                                        + "/" +
                                        (parseInt(new Date(data_reportado).getFullYear()))
                                    ): ("") }
                                    </td>
                                    <td>{falha}</td>
                                    <td> { data_resolucao ? (
                                        (parseInt(new Date(data_resolucao).getDate())  < 10 ? ("0" + new Date(data_resolucao).getDate()) : new Date(data_resolucao).getDate())
                                        + "/" +
                                        (parseInt(new Date(data_resolucao).getMonth() + 1)  < 10 ? ("0" + (new Date(data_resolucao).getMonth() +1)) : new Date(data_resolucao).getMonth() +1)
                                        + "/" +
                                        (parseInt(new Date(data_resolucao).getFullYear()))
                                    ): ("") }</td>
                                    <td>  {((status) => {
                                        if(parseInt(status) === 1)
                                            return "Pendente"
                                        else
                                            return "Resolvido"
                                    })(status)}</td>
                                    <td>
                                        {((id_clube) => {
                                            if(parseInt(id_clube) === 1)
                                                return "Faro"
                                            if(parseInt(id_clube) === 2)
                                                return "Olhão"
                                            if(parseInt(id_clube) === 3)
                                                return "Tavira"
                                            if(parseInt(id_clube) === 4)
                                                return "Portimão"
                                            if(parseInt(id_clube) === 5)
                                                return "Loule"
                                        })(id_clube)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination postsPerPAge={postsPerPage} totalPosts={resultados.length} paginate={paginate} currentPage={currentPage}/>

                    <div className={modal ? "new-lead-modal new-manutencao active": "new-manutencao new-lead-modal"}>
                        <span id="close-new-lead-modal" onClick={ e => onOpenModal(e)}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => onSubmit(e)}>
                            <label htmlFor="">
                                Data Reportado
                                <input type="date" name={"data_reportado"} value={data_reportado} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                O Quê
                                <input type="text" name={"que"} value={que} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                Falha Detectada
                                <input type="text" name={"falha"} value={falha} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                Quem Reporta
                                <input type="text" name={"quem"} value={quem} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                A Quem foi Reportado
                                <input type="text" name={"quemfoi"} value={quemfoi} onChange={e => onChange(e)} />
                            </label>
                            <label htmlFor="">
                                Ação Tomada
                                <input type="text" name={"acao"} value={acao} onChange={e => onChange(e)} />
                            </label>
                            <label htmlFor="">
                                Data de Resolução
                                <input type="date" name={"data_resolucao"} value={data_resolucao} onChange={e => onChange(e)} />
                            </label>
                            <label htmlFor="">
                                Status
                                <select  name={"status"} value={status} onChange={e => onChange(e)} required>
                                    <option value="">Selecionar</option>
                                    <option value="1">Pendente</option>
                                    <option value="2">Resolvido</option>
                                </select>

                            </label>
                            <label htmlFor="">
                                Notas
                                <textarea name={"notes"} value={notes} onChange={e => onChange(e)}  cols="10" rows="5"></textarea>

                            </label>

                            <button>GUARDAR</button>
                        </form>
                    </div>

                </div>
            </div>

        </Fragment>
};
ManutencaoManutencao.propTypes = {
    auth: PropTypes.object.isRequired,
    getManutencao: PropTypes.func.isRequired,
    updateManutencao: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    manutencao: state.manutencao
});
export default connect(mapStateToProps, { getManutencao, updateManutencao })( ManutencaoManutencao );
