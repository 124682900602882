/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useContext } from "react";
import { Link, Redirect } from "react-router-dom"
import {connect} from "react-redux";
import PropTypes from "prop-types";
import '../../styles/dashboard.css';
import '../../styles/login.css';
import { login } from "../../actions/auth";

const Login = ({auth: {loading},login, isAuthenticated }) => {
    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
        loading: false,
        alert: ""
    });
    const { email, password, alert } = loginData;
    const onChange = e => setLoginData({ ...loginData, [e.target.name]: e.target.value});
    const onSubmit = async e => {
        e.preventDefault();
        try{
            await login({ email, password }).then((res) =>{
                if(!res)
                    setLoginData(
                        {
                            ...loginData,
                            loading:false,
                            alert: "Dados Incorrectos tente novamente"
                        }
                    );

            }, (err) => {
                setLoginData(
                    {
                        ...loginData,
                        loading:false,
                        alert: "Dados Incorrectos tente novamente"
                    }
                );
            });
            console.log("teste");
        }catch(e){
            setLoginData(
                {
                    ...loginData,
                    loading:false,
                    alert: "Dados Incorrectos tente novamente"
                }
            );
        }
    }
    if(isAuthenticated)
        return <Redirect to="/dashboard" />

    return loading ? <Fragment><div> <h1>Loading</h1> </div></Fragment> :
        <Fragment>
            <div className="login-container">
                    <img src="./img/logo.png" alt="Logo-dashboard" className="login-img" />

                <div className={alert ? "alert alert-danger active": "alert alert-danger"}>{alert}</div>

                <form onSubmit={ e => onSubmit(e)}>
                        <div className="form-group">
                            <i className="img user"></i>
                            <input type="text" className="form-control" name="email" value={email} placeholder="E-mail"
                                   autoComplete="off" required  onChange={e=> onChange(e)} />
                        </div>
                        <div className="form-group">
                            <i className="img lock"></i>
                            <input type="password" className="form-control" name="password" value={password} required  onChange={e=> onChange(e)} placeholder="Password" />
                        </div>

                        <button type="submit" className="btn btn-warning btn-lg">ENTRAR</button>
                    </form>


            </div>
        </Fragment>
};

Login.propTypes = {
    auth: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
    auth: state.auth,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {login})(Login);
