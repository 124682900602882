/**
 * Created by danielsilva on 10/04/2020.
 */
export const GET_LEADS =        "GET_LEADS";
export const GET_LEADS_ERROR =     "GET_LEADS_ERROR";
export const GET_INSCRICOES =        "GET_INSCRICOES";
export const GET_INSCRICOES_ERROR =     "GET_INSCRICOES_ERROR";
export const GET_RECEITAS =        "GET_RECEITAS";
export const GET_RECEITAS_ERROR =     "GET_RECEITAS_ERROR";
export const GET_SOCIOS =        "GET_SOCIOS";
export const GET_SOCIOS_ERROR =     "GET_SOCIOS_ERROR";
export const GET_CHAMADAS =        "GET_CHAMADAS";
export const GET_CHAMADAS_ERROR =     "GET_CHAMADAS_ERROR";
export const GET_CANCELAMENTOS =        "GET_CANCELAMENTOS";
export const GET_CANCELAMENTOS_ERROR =     "GET_CANCELAMENTOS_ERROR";
export const GET_NAOCONVERTIDOS =        "GET_NAOCONVERTIDOS";
export const GET_NAOCONVERTIDOS_ERROR =     "GET_NAOCONVERTIDOS_ERROR";

