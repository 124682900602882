/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_NUTRICAO,
    GET_NUTRICAO_ERROR,
} from "./types";

export const getRotaNutricao = ({prevDate, id_clube }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {prevDate, id_clube };
    try {
        const res = await axios.post("/api/rotanutricao", body , config);

        dispatch({
            type: GET_NUTRICAO,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_NUTRICAO_ERROR,
        })
    }
}
export const currentDataPrev = ({ prevDate, id_clube }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { prevDate, id_clube };
    try {
        const res = await axios.post("/api/rotanutricao", body, config);

        dispatch({
            type: GET_NUTRICAO,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_NUTRICAO_ERROR,
        })
    }
}
export const insertAppointment = ({ marcacaoID, n_socio, time_appoint, prevDate, id_clube  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { marcacaoID, n_socio, time_appoint, prevDate, id_clube  };
    try {
        const res = await axios.post("/api/rotanutricao/insert", body, config);

        dispatch({
            type: GET_NUTRICAO,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_NUTRICAO_ERROR,
        })
    }
}
export const deleteAppointment = ({ marcacaoID, prevDate, id_clube }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { marcacaoID, prevDate, id_clube };

    try {
        const res = await axios.post("/api/rotanutricao/delete", body, config);

        dispatch({
            type: GET_NUTRICAO,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_NUTRICAO_ERROR,
        })
    }
}

export const insertDisponibilidade = ({  marcacao_de, marcacao_ate, currentData, id_clube  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { marcacao_de, marcacao_ate, currentData, id_clube  };
    try {
        const res = await axios.post("/api/rotanutricao/disponibilidade", body, config);

        dispatch({
            type: GET_NUTRICAO,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_NUTRICAO_ERROR,
        })
    }
}

