/**
 * Created by danielsilva on 10/04/2020.
 */
export const GET_FERIAS =                    "GET_FERIAS";
export const GET_FERIAS_ERROR =              "GET_FERIAS_ERROR";
export const GET_COLABORADORES =             "GET_COLABORADORES";
export const GET_COLABORADORES_ERROR =       "GET_COLABORADORES_ERROR";
export const GET_PROJECCAO =                 "GET_PROJECCAO";
export const GET_PROJECCAO_ERROR =           "GET_PROJECCAO_ERROR";
export const GET_PONTOS_CRITICOS =           "GET_PONTOS_CRITICOS";
export const GET_PONTOS_CRITICOS_ERROR =     "GET_PONTOS_CRITICOS_ERROR";
export const GET_PLANO_FORMACAO =           "GET_PLANO_FORMACAO";
export const GET_PLANO_FORMACAO_ERROR =     "GET_PLANO_FORMACAO_ERROR";
export const GET_REUNIOES_CORDENACAO =           "GET_REUNIOES_CORDENACAO";
export const GET_REUNIOES_CORDENACAO_ERROR =     "GET_REUNIOES_CORDENACAO_ERROR";
export const GET_BANCO_DD =           "GET_BANCO_DD";
export const GET_BANCO_DD_ERROR =     "GET_BANCO_DD_ERROR";
export const GET_UTILIZADORES =           "GET_UTILIZADORES";
export const GET_UTILIZADORES_ERROR =     "GET_UTILIZADORES_ERROR";
