/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_REUNIOES_CORDENACAO,
    GET_REUNIOES_CORDENACAO_ERROR,
} from "./types";

export const getReunioesCordenacao = () => async dispatch => {

    try {
        const res = await axios.post("/api/reunioescordenacao");


        dispatch({
            type: GET_REUNIOES_CORDENACAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_REUNIOES_CORDENACAO_ERROR,
        })
    }
}
export const updateOrInsertReunioesCordenacao = ({  date, topico, assistente, pessoas_frequentes, ata, idreuniao  }) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = { date, topico, assistente, pessoas_frequentes, ata, idreuniao  };

        const res = await axios.post("/api/reunioescordenacao/update", body, config);


        dispatch({
            type: GET_REUNIOES_CORDENACAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_REUNIOES_CORDENACAO_ERROR,
        })
    }
}
export const deleteReunioesCordenacao = ({ idreunioes  }) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = { idreunioes  };

        const res = await axios.post("/api/reunioescordenacao/delete", body, config);


        dispatch({
            type: GET_REUNIOES_CORDENACAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_REUNIOES_CORDENACAO_ERROR,
        })
    }
}

