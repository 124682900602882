/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getAulasAvaliacoes, updateAulas, deleteAulas } from "./actions/aulas";
import Pagination from "../gestaocomercial/Pagination";

const AulasAdministracao = ({ auth: {user}, getAulasAvaliacoes, updateAulas, deleteAulas, aulas: { data }}) => {
    useEffect(() => {
        getAulasAvaliacoes({id_clube: 1});
    },[]);
    useLayoutEffect(() => {
        setAvAulasData({...avAulasData,
            modal: data ? !!data.err : false,
            err: data ? data.err ?  data.err : false : false,
            clube_selected: data ? data.clube_selected ?  data.clube_selected : 1 : 1,
            loading:false,
        });
    },[data]);

    const [avAulasData, setAvAulasData] = useState({
        modal: false,
        id: "",
        instrutor_select: "",
        av_observador: "",
        av_aula: "",
	av_data_avaliacao: "",
	av_obsgerais: "",
        av_horac: "",
        av_prepaula: "",
        av_idalunos: "",
        av_rec_alunos: "",
        av_in_fim_aul: "",
        av_planoacacao: "",

        av_tec_plano: "",
        av_tec_demo: "",
        av_tec_demo_seg: "",
        av_tec_move: "",
        av_tec_id: "",
        av_tec_demo_energy: "",
        av_tec_planoacao: "",

        av_coach_inf: "",
        av_coach_facil: "",
        av_coach_lang: "",
        av_coach_org: "",
        av_coach_planoacao: "",

        av_empatia_env: "",
        av_empatia_apra: "",
        av_empatia_atende: "",
        av_empatia_cria: "",
        av_empatia_planoacao: "",
        clube_selected: 1
    });

    const { clube_selected, modal, id, instrutor_select, av_observador, av_aula, av_data_avaliacao, av_obsgerais, av_horac, av_prepaula, av_idalunos, av_rec_alunos, av_in_fim_aul, av_planoacacao,
        av_tec_plano, av_tec_demo, av_tec_demo_seg, av_tec_move, av_tec_id, av_tec_demo_energy, av_tec_planoacao,
        av_coach_inf, av_coach_facil, av_coach_lang, av_coach_org, av_coach_planoacao,
        av_empatia_env, av_empatia_apra, av_empatia_atende, av_empatia_cria, av_empatia_planoacao
    } = avAulasData;
    const onChange = e => setAvAulasData({...avAulasData, [e.target.name]: e.target.value});
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let resultados = data ? data.aulas ? data.aulas : [] : [];
    const currentPosts = resultados.slice(indexOfFirstPost,indexOfLastPost);

    const paginate = (e,pageNumber) => {
        e.preventDefault()
        setCurrentPage(pageNumber)
    }
    const onChangeDate = async e => {
        setAvAulasData({...avAulasData, [e.target.name]: e.target.value});
        console.log(e.target.value)

    };
    const onChangeConsultor = async e => {
        setAvAulasData({...avAulasData, [e.target.name]: e.target.value});
        await getAulasAvaliacoes({id_clube: e.target.value });
    };
    const onSubmit = async e => {
        e.preventDefault();
        console.log(avAulasData)
        try {
            await updateAulas({avAulasData, id_clube: clube_selected});
        } catch(e) {

        }
    }

    const onDelete = async e => {
        e.preventDefault();
        
        await deleteAulas({id_clube: clube_selected, id});
       
    }

    const onOpenModal = async e => {
        e.preventDefault();
        setAvAulasData({...avAulasData,
            ["modal"]: modal ? false : true,
            ["id"]: "",
            ["instrutor_select"]: "",
            ["av_observador"]: "",
            ["av_aula"]: "",
	    ["av_data_avaliacao"]: "",
	    ["av_obsgerais"]: "",
            ["av_chega"]:       "",
            ["av_horac"]:       "",
            ["av_prepaula"]:    "",
            ["av_idalunos"]:    "",
            ["av_rec_alunos"]:  "",
            ["av_in_fim_aul"]:  "",
            ["av_planoacacao"]: "",

            ["av_tec_plano"]:       "",
            ["av_tec_demo"]:       "",
            ["av_tec_demo_seg"]:    "",
            ["av_tec_move"]:    "",
            ["av_tec_id"]:  "",
            ["av_tec_demo_energy"]:  "",
            ["av_tec_planoacao"]: "",


            ["av_coach_inf"]:       "",
            ["av_coach_facil"]:       "",
            ["av_coach_lang"]:    "",
            ["av_coach_org"]:    "",
            ["av_coach_planoacao"]:  "",



            ["av_empatia_env"]:       "",
            ["av_empatia_apra"]:       "",
            ["av_empatia_atende"]:    "",
            ["av_empatia_cria"]:    "",
            ["av_empatia_planoacao"]:  "",

        });

    }
    const onOpenModalValues = async (e, id, instrutor, observador, aula, data_avaliacao, obsgerais, avaliacao) => {
        e.preventDefault();

        let avAulaContent = JSON.parse(avaliacao);

        setAvAulasData({...avAulasData,
            ["modal"]: modal ? false : true,
            ["id"]: id,
            ["instrutor_select"]: instrutor,
            ["av_observador"]: observador,
            ["av_aula"]: aula,
	    ["av_data_avaliacao"]: data_avaliacao  ? moment(data_avaliacao).format("YYYY-MM-DDTHH:mm:ss")  : "",
	    ["av_obsgerais"]:   obsgerais,
            ["av_chega"]:       avAulaContent ? avAulaContent.av_chega  ? avAulaContent.av_chega : 0 : 0,
            ["av_horac"]:       avAulaContent ? avAulaContent.av_horac  ? avAulaContent.av_horac : 0 : 0,
            ["av_prepaula"]:    avAulaContent ? avAulaContent.av_prepaula  ? avAulaContent.av_prepaula : 0 : 0,
            ["av_idalunos"]:    avAulaContent ? avAulaContent.av_idalunos  ? avAulaContent.av_idalunos : 0 : 0,
            ["av_rec_alunos"]:  avAulaContent ? avAulaContent.av_rec_alunos  ? avAulaContent.av_rec_alunos : 0 : 0,
            ["av_in_fim_aul"]:  avAulaContent ? avAulaContent.av_in_fim_aul  ? avAulaContent.av_in_fim_aul : 0 : 0,
            ["av_planoacacao"]: avAulaContent ? avAulaContent.av_planoacacao  ? avAulaContent.av_planoacacao : "" : "",


            ["av_tec_plano"]:        avAulaContent ? avAulaContent.av_tec_plano  ? avAulaContent.av_tec_plano : 0 : 0,
            ["av_tec_demo"]:         avAulaContent ? avAulaContent.av_tec_demo  ? avAulaContent.av_tec_demo : 0 : 0,
            ["av_tec_demo_seg"]:     avAulaContent ? avAulaContent.av_tec_demo_seg  ? avAulaContent.av_tec_demo_seg : 0 : 0,
            ["av_tec_move"]:         avAulaContent ? avAulaContent.av_tec_move  ? avAulaContent.av_tec_move : 0 : 0,
            ["av_tec_id"]:           avAulaContent ? avAulaContent.av_tec_id  ? avAulaContent.av_tec_id : 0 : 0,
            ["av_tec_demo_energy"]:  avAulaContent ? avAulaContent.av_tec_demo_energy  ? avAulaContent.av_tec_demo_energy : 0 : 0,
            ["av_tec_planoacao"]:    avAulaContent ? avAulaContent.av_tec_planoacao  ? avAulaContent.av_tec_planoacao : "" : "",

            ["av_coach_inf"]:        avAulaContent ? avAulaContent.av_coach_inf  ? avAulaContent.av_coach_inf : 0 : 0,
            ["av_coach_facil"]:      avAulaContent ? avAulaContent.av_coach_facil  ? avAulaContent.av_coach_facil : 0 : 0,
            ["av_coach_lang"]:       avAulaContent ? avAulaContent.av_coach_lang  ? avAulaContent.av_coach_lang : 0 : 0,
            ["av_coach_org"]:        avAulaContent ? avAulaContent.av_coach_org  ? avAulaContent.av_coach_org : 0 : 0,
            ["av_coach_planoacao"]:  avAulaContent ? avAulaContent.av_coach_planoacao  ? avAulaContent.av_coach_planoacao : "" : "",

            ["av_empatia_env"]:     avAulaContent ? avAulaContent.av_empatia_env  ? avAulaContent.av_empatia_env : 0 : 0,
            ["av_empatia_apra"]:    avAulaContent ? avAulaContent.av_empatia_apra  ? avAulaContent.av_empatia_apra : 0 : 0,
            ["av_empatia_atende"]:  avAulaContent ? avAulaContent.av_empatia_atende  ? avAulaContent.av_empatia_atende : 0 : 0,
            ["av_empatia_cria"]:    avAulaContent ? avAulaContent.av_empatia_cria  ? avAulaContent.av_empatia_cria : 0 : 0,
            ["av_empatia_planoacao"]:   avAulaContent ? avAulaContent.av_empatia_planoacao  ? avAulaContent.av_empatia_planoacao : "" : "",

        });
    }

    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Serviço ao Cliente >
                        <Link to={"/aulas"}>Aulas</Link>
                    </div>
                    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="">
                            Clube:
                            <select name="clube_selected"   value={clube_selected} onChange={ e => onChangeConsultor(e)} style={{textTransform: "capitalize"}}>
                                {user && user.clubes.map((e) => (
                                    <option value={e.id} >{e.localidade}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <button  onClick={e => onOpenModal(e)} className={"new-btn"}>Novo</button>

                    <table className="table-format-one">
                        <thead>
                        <tr>
                            <th>Instrutor</th>
                            <th>Observador</th>
                            <th>Aula</th>
                            <th>Data avaliação</th>
                        </tr>
                        </thead>
                        <tbody>
                            {currentPosts && currentPosts.map(({id, instrutor, observador, aula, data_avaliacao, obsgerais, nome_pt, avaliacao }) => (
                                <tr className={"to-call"} onClick={e => onOpenModalValues(e, id, instrutor, observador, aula, data_avaliacao, obsgerais, avaliacao )}>
                                    <td>{nome_pt}</td>
                                    <td>{observador}</td>
                                    <td>{aula}</td>
                                    <td>  { data_avaliacao ? (
                                        (parseInt(new Date(data_avaliacao).getDate())  < 10 ? ("0" + new Date(data_avaliacao).getDate()) : new Date(data_avaliacao).getDate())
                                        + "/" +
                                        (parseInt(new Date(data_avaliacao).getMonth() + 1)  < 10 ? ("0" + (new Date(data_avaliacao).getMonth() +1)) : new Date(data_avaliacao).getMonth() +1)
                                        + "/" +
                                        (parseInt(new Date(data_avaliacao).getFullYear())) + " " + (
                                            (parseInt(new Date(data_avaliacao).getHours())  < 10 ? ("0" + new Date(data_avaliacao).getHours()) : new Date(data_avaliacao).getHours())
                                            + ":" +
                                            (parseInt(new Date(data_avaliacao).getMinutes())  < 10 ? ("0" + new Date(data_avaliacao).getMinutes()) : new Date(data_avaliacao).getMinutes())
                                        )
                                    ): ("") }</td>

                                </tr>
                            ))}

                        </tbody>
                    </table>
                    <Pagination postsPerPAge={postsPerPage} totalPosts={resultados.length} paginate={paginate} currentPage={currentPage}/>

                    <div className={modal ? "new-lead-modal new-personaltable active": "new-lead-modal new-personaltable"}>
                         <span id="close-new-lead-modal" onClick={ e => onOpenModal(e)}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => onSubmit(e)}>
                            <div className="top-form">
                                <label htmlFor="">
                                    Instrutor
                                    <select name={"instrutor_select"} value={instrutor_select} onChange={e => onChange(e)} required>
                                       <option value="">Seleciona um Instrutor</option>
                                        {user && user.personaltrainers.map((e) => (
                                            <option value={e.id}>{e.nome}</option>
                                        ))}
                                    </select>
                                </label>
                                <label htmlFor="">
                                    Observador
                                    <input type="text" name={"av_observador"} value={av_observador} onChange={e => onChange(e)} required/>
                                </label>

                                <label htmlFor="">
                                    Aula
                                    <input type="text" name={"av_aula"} value={av_aula} onChange={e => onChange(e)} required/>
                                </label>
				<label htmlFor="">
                                    Data avaliação
                                    <input type="datetime-local" name="av_data_avaliacao" value={av_data_avaliacao} onChange={e => onChange(e)} />
                                </label>
                                <label htmlFor="" className={"area-column"}>
                                   Observações Gerais
                                  <textarea name={"av_obsgerais"} value={av_obsgerais} onChange={e => onChange(e)}  cols="4" rows="4"></textarea>
                                </label>
                            </div>
                            <label htmlFor=""><h3>PREPARAÇÃO DA AULA</h3></label>
                            <label htmlFor="">
                                Hora de Chegada
                                 <span>
                                      Faz
                                    <input type="radio" name={"av_horac"} value={"1"} onChange={e => onChange(e)} checked={av_horac === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_horac"} value={"2"} onChange={e => onChange(e)} checked={av_horac === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_horac"} value={"3"} onChange={e => onChange(e)} checked={av_horac === "3"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Preparação da aula
                                <span>
                                      Faz
                                    <input type="radio" name={"av_prepaula"} value={"1"} onChange={e => onChange(e)} checked={av_prepaula === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_prepaula"} value={"2"} onChange={e => onChange(e)} checked={av_prepaula === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_prepaula"} value={"3"} onChange={e => onChange(e)} checked={av_prepaula === "3"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Identificação de novos alunos
                                <span>
                                      Faz
                                    <input type="radio" name={"av_idalunos"} value={"1"} onChange={e => onChange(e)} checked={av_idalunos === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_idalunos"} value={"2"} onChange={e => onChange(e)} checked={av_idalunos === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_idalunos"} value={"3"} onChange={e => onChange(e)} checked={av_idalunos === "3"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Receção de alunos
                                <span>
                                      Faz
                                    <input type="radio" name={"av_rec_alunos"} value={"1"} onChange={e => onChange(e)} checked={av_rec_alunos === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_rec_alunos"} value={"2"} onChange={e => onChange(e)} checked={av_rec_alunos === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_rec_alunos"} value={"3"} onChange={e => onChange(e)} checked={av_rec_alunos === "3"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Inicio e fim da aula (hora
                                <span>
                                      Faz
                                    <input type="radio" name={"av_in_fim_aul"} value={"1"} onChange={e => onChange(e)} checked={av_in_fim_aul === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_in_fim_aul"} value={"2"} onChange={e => onChange(e)} checked={av_in_fim_aul === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_in_fim_aul"} value={"3"} onChange={e => onChange(e)} checked={av_in_fim_aul === "3"} required/>
                                </span>
                            </label>
                            <label htmlFor="" className={"area-column"}>
                                Plano de ação
                                <textarea name={"av_planoacacao"} value={av_planoacacao} onChange={e => onChange(e)}  cols="4" rows="4"></textarea>
                            </label>

                            <label htmlFor=""><h3>TÉCNICA</h3></label>
                            <label htmlFor="">
                                Passa o plano de aula corretamente – formato e progressõe
                                <span>
                                      Faz
                                    <input type="radio" name={"av_tec_plano"} value={"1"} onChange={e => onChange(e)} checked={av_tec_plano === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_tec_plano"} value={"2"} onChange={e => onChange(e)} checked={av_tec_plano === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_tec_plano"} value={"3"} onChange={e => onChange(e)} checked={av_tec_plano === "3"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Demonstra alinhamento e postura corretos
                                <span>
                                      Faz
                                    <input type="radio" name={"av_tec_demo"} value={"1"} onChange={e => onChange(e)} checked={av_tec_demo === "1"} required/>
                                </span>
                                <span>
                                      Não Faz
                                    <input type="radio" name={"av_tec_demo"} value={"2"} onChange={e => onChange(e)} checked={av_tec_demo === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_tec_demo"} value={"3"} onChange={e => onChange(e)} checked={av_tec_demo === "3"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Demonstra segurança e efetividade nos movimentos e amplitudes
                                <span>
                                      Faz
                                    <input type="radio" name={"av_tec_demo_seg"} value={"1"} onChange={e => onChange(e)} checked={av_tec_demo_seg === "1"} required />
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_tec_demo_seg"} value={"2"} onChange={e => onChange(e)} checked={av_tec_demo_seg === "2"} required />
                                </span>
                                <span>
                                   Com Dificuldade
                                    <input type="radio" name={"av_tec_demo_seg"} value={"3"} onChange={e => onChange(e)} checked={av_tec_demo_seg === "3"} required />
                                </span>
                            </label>
                            <label htmlFor="">
                                Move-se no tempo e batida da música
                                <span>
                                      Faz
                                    <input type="radio" name={"av_tec_move"} value={"1"} onChange={e => onChange(e)} checked={av_tec_move === "1"} required />
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_tec_move"} value={"2"} onChange={e => onChange(e)} checked={av_tec_move === "2"} required />
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_tec_move"} value={"3"} onChange={e => onChange(e)} checked={av_tec_move === "3"} required />
                                </span>
                            </label>
                            <label htmlFor="">
                                Identificação de novos alunos
                                <span>
                                      Faz
                                    <input type="radio"  name={"av_tec_id"} value={"1"} onChange={e => onChange(e)} checked={av_tec_id === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio"  name={"av_tec_id"} value={"2"} onChange={e => onChange(e)} checked={av_tec_id === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio"  name={"av_tec_id"} value={"3"} onChange={e => onChange(e)} checked={av_tec_id === "3"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Demonstra energia, aspeto e atitude apropriados
                                <span>
                                      Faz
                                    <input type="radio" name={"av_tec_demo_energy"} value={"1"} onChange={e => onChange(e)} checked={av_tec_demo_energy === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_tec_demo_energy"} value={"2"} onChange={e => onChange(e)} checked={av_tec_demo_energy === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_tec_demo_energy"} value={"3"} onChange={e => onChange(e)} checked={av_tec_demo_energy === "3"} required/>
                                </span>
                            </label>
                            <label htmlFor="" className={"area-column"}>
                                Plano de ação
                                <textarea name={"av_tec_planoacao"} value={av_tec_planoacao} onChange={e => onChange(e)}    cols="4" rows="4"></textarea>
                            </label>

                            <label htmlFor=""><h3>Coaching</h3></label>
                            <label htmlFor="">
                                Dá informação no momento certo
                                <span>
                                      Faz
                                    <input type="radio" name={"av_coach_inf"} value={"1"} onChange={e => onChange(e)} checked={av_coach_inf === "1"} required />
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_coach_inf"} value={"2"} onChange={e => onChange(e)} checked={av_coach_inf === "2"} required />
                                </span>
                                <span>
                                   Com Dificuldade
                                    <input type="radio" name={"av_coach_inf"} value={"3"} onChange={e => onChange(e)} checked={av_coach_inf === "3"} required />
                                </span>
                            </label>
                            <label htmlFor="">
                                É facilmente entendido e seguido
                                <span>
                                      Faz
                                    <input type="radio" name={"av_coach_facil"} value={"1"} onChange={e => onChange(e)} checked={av_coach_facil === "1"} required />
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_coach_facil"} value={"2"} onChange={e => onChange(e)} checked={av_coach_facil === "2"} required />
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_coach_facil"} value={"3"} onChange={e => onChange(e)} checked={av_coach_facil === "3"} required />
                                </span>
                            </label>
                            <label htmlFor="">
                                Usa linguagem e coaching eficaz
                                <span>
                                      Faz
                                    <input type="radio" name={"av_coach_lang"} value={"1"} onChange={e => onChange(e)} checked={ av_coach_lang === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_coach_lang"} value={"2"} onChange={e => onChange(e)} checked={av_coach_lang === "2"} required />
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio"  name={"av_coach_lang"} value={"3"} onChange={e => onChange(e)} checked={av_coach_lang === "3"} required />
                                </span>
                            </label>
                            <label htmlFor="">
                                É organizado
                                <span>
                                      Faz
                                    <input type="radio" name={"av_coach_org"} value={"1"} onChange={e => onChange(e)} checked={av_coach_org === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_coach_org"} value={"2"} onChange={e => onChange(e)} checked={av_coach_org === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_coach_org"} value={"3"} onChange={e => onChange(e)} checked={av_coach_org === "3"} required/>
                                </span>
                            </label>
                            <label htmlFor="" className={"area-column"}>
                                Plano de ação
                                <textarea name={"av_coach_planoacao"} value={av_coach_planoacao} onChange={e => onChange(e)} cols="4" rows="4"></textarea>
                            </label>

                            <label htmlFor=""><h3>Empatia com clientes</h3></label>
                            <label htmlFor="">
                                Envolve os participantes
                                <span>
                                      Faz
                                    <input type="radio" name={"av_empatia_env"} value={"1"} onChange={e => onChange(e)} checked={av_empatia_env === "1"} required />
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_empatia_env"} value={"2"} onChange={e => onChange(e)} checked={av_empatia_env === "2"} required />
                                </span>
                                <span>
                                   Com Dificuldade
                                    <input type="radio" name={"av_empatia_env"} value={"3"} onChange={e => onChange(e)} checked={av_empatia_env === "3"} required />
                                </span>
                            </label>
                            <label htmlFor="">
                                Aparenta sensibilidade e abertura
                                <span>
                                      Faz
                                    <input type="radio" name={"av_empatia_apra"} value={"1"} onChange={e => onChange(e)} checked={av_empatia_apra === "1"} required />
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_empatia_apra"} value={"2"} onChange={e => onChange(e)} checked={av_empatia_apra === "2"} required />
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_empatia_apra"} value={"3"} onChange={e => onChange(e)} checked={av_empatia_apra === "3"} required />
                                </span>
                            </label>
                            <label htmlFor="">
                                Atende às necessidades da turma
                                <span>
                                      Faz
                                    <input type="radio"  name={"av_empatia_atende"} value={"1"} onChange={e => onChange(e)} checked={av_empatia_atende === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_empatia_atende"} value={"2"} onChange={e => onChange(e)} checked={av_empatia_atende === "2"} required />
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_empatia_atende"} value={"3"} onChange={e => onChange(e)} checked={av_empatia_atende === "3"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Cria progressões para diferentes tipologias de participantes
                                <span>
                                      Faz
                                    <input type="radio" name={"av_empatia_cria"} value={"1"} onChange={e => onChange(e)} checked={av_empatia_cria === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_empatia_cria"} value={"2"} onChange={e => onChange(e)} checked={av_empatia_cria === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_empatia_cria"} value={"3"} onChange={e => onChange(e)} checked={av_empatia_cria === "3"} required/>
                                </span>
                            </label>
                            <label htmlFor="" className={"area-column"}>
                                Plano de ação
                                <textarea name={"av_empatia_planoacao"} value={av_empatia_planoacao} onChange={e => onChange(e)}    cols="4" rows="4"></textarea>
                            </label>


                            <button>GUARDAR</button>
			    <button id="delete" onClick={e => onDelete(e)}>APAGAR</button>
                        </form>
                    </div>

                </div>
            </div>

        </Fragment>
};
AulasAdministracao.propTypes = {
    auth: PropTypes.object.isRequired,
    getAulasAvaliacoes: PropTypes.func.isRequired,
    updateAulas: PropTypes.func.isRequired,
    deleteAulas: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    aulas: state.aulas
});
export default connect(mapStateToProps, { getAulasAvaliacoes, updateAulas, deleteAulas })( AulasAdministracao );
