/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/rotapt.css";
import moment from "moment";
import { getRotaAcupuntura, currentDataPrev, insertAppointment, deleteAppointment } from "./actions/rotaacupuntura";

const RotaAcupunturaAdministracao = ({ auth: {user}, rotaacupuntura: { data }, getRotaAcupuntura, currentDataPrev, insertAppointment, deleteAppointment }) => {
    useLayoutEffect(() => {
        getRotaAcupuntura({id_acupunt: 0});
    },[]);

    useEffect(() => {
        setSRotaACData(
            {
                ...rotaACData,
                modal: data ? data.payload ? data.payload.error ? true : false : false: false,
                alert: data ? data.payload ? data.payload.error : 0  : 0,
                loading: false,
                consultores: user ? user.acupunt ? user.acupunt : []  : []

            }
        );
    },[data]);

    const [rotaACData, setSRotaACData] = useState({
        marcacaoID: "",
        currentData: new Date(),
        modal: false,
        modalType: "",
        time_appoint: "",
        nome_ac: "",
        n_socio: "",
        date: "",
        date_fim: "",
        nome_client: "",
        alert: false,
	acupunt_selected: 0,
	consultores: []
    });

    const { clube_selected, acupunt_selected, consultores, marcacaoID, date, currentData, modal, modalType, time_appoint, nome_ac, n_socio, date_fim, nome_client, alert } = rotaACData;


    let diaSemanaAgendaText = new Date(currentData)
    let diaSemanaAgendaTextDomingo = new Date(currentData),
        diaSemanaAgendaTextSegunda= new Date(currentData),
        diaSemanaAgendaTextTerca= new Date(currentData),
        diaSemanaAgendaTextQuarta= new Date(currentData),
        diaSemanaAgendaTextQuinta= new Date(currentData),
        diaSemanaAgendaTextSexta= new Date(currentData),
        diaSemanaAgendaTextSabado= new Date(currentData);

    if(diaSemanaAgendaText.getDay() === 0) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 6)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 5)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() - 4)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() - 3)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() - 2)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() - 1)
    }
    if(diaSemanaAgendaText.getDay() === 1) {
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() + 1)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() + 2)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() + 3)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() + 4)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 5)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 6)
    }
    if(diaSemanaAgendaText.getDay() === 2) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 1)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() + 1)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() + 2)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() + 3)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 4)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 5)
    }
    if(diaSemanaAgendaText.getDay() === 3) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 2)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 1)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() + 1)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() + 2)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 3)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 4)
    }
    if(diaSemanaAgendaText.getDay() === 4) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 3)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 2)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() - 1)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() + 1)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 2)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 3)
    }
    if(diaSemanaAgendaText.getDay() === 5) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 4)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 3)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() - 2)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() - 1)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 1)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 2)
    }
    if(diaSemanaAgendaText.getDay() === 6) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 5)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 4)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() - 3)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() - 2)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() - 1)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() +1 )
    }

    const onChange = e => setSRotaACData({...rotaACData, [e.target.name]: e.target.value});
    const onChangeDate = async e => {
        setSRotaACData({...rotaACData, [e.target.name]: e.target.value});


    };
    const onSubmit = async e => {
        e.preventDefault();

        try {
            await insertAppointment({marcacaoID, n_socio, time_appoint, prevDate: currentData, id_clube: clube_selected, id_acupunt: acupunt_selected });
            setSRotaACData({...rotaACData,
                ["modal"]: false,
                ["modalType"]: "",
                ["nome_ac"]: "",
                ["n_socio"]:  "",
                ["date"]:  "",
                ["date_fim"]: "",
                ["nome_client"]: "",
                ["marcacaoID"]: "",
            });
        } catch (e) {
            setSRotaACData(
                {
                    ...rotaACData,
                    alert: "Erro ao Inserir appointment"
                }
            );
        }
    }
    const onDelete = async e => {
        e.preventDefault();

        try {

            await deleteAppointment({marcacaoID, prevDate: currentData, id_clube: clube_selected, id_acupunt: acupunt_selected});
            setSRotaACData({...rotaACData,
                ["modal"]: false,
                ["modalType"]: "",
                ["nome_ac"]: "",
                ["n_socio"]:  "",
                ["date"]:  "",
                ["date_fim"]: "",
                ["nome_client"]: "",
                ["marcacaoID"]: "",
            });

        } catch (e) {
            setSRotaACData(
                {
                    ...rotaACData,
                    alert: "Erro ao apagar appointment"
                }
            );
        }
    }
    const onChangeCurrentDataPrev = async e => {
        e.preventDefault();
        setSRotaACData(
            {
                ...rotaACData,
                currentData: new Date(currentData.setDate(currentData.getDate() - 7))
            }
        );

        try {
            await currentDataPrev({ prevDate: currentData, id_clube: clube_selected, id_acupunt: acupunt_selected }).then((res) =>{
                console.log(res)
                // window.location.reload();
            });
        }catch(e){

        }
    };
    const onChangeCurrentDataNext = async e => {
        e.preventDefault();
        setSRotaACData(
            {
                ...rotaACData,
                currentData: new Date(currentData.setDate(currentData.getDate() + 7))
            }
        );

        try {
         await currentDataPrev({prevDate: currentData , id_clube: clube_selected, id_acupunt: acupunt_selected }).then((res) =>{
                console.log(res)
                // window.location.reload();
            });
        }catch(e){

        }
    };
    const onScroll = e => {
        let agendaId = document.getElementById("agenda-comercial-container");
        let agendaScrollId = document.getElementById("agenda-comercial-container-scroll");
        agendaScrollId.width = agendaId.width;
        agendaId.scrollLeft = agendaScrollId.scrollLeft;
    }
    const onMainScroll = e => {
        let agendaId = document.getElementById("agenda-comercial-container");
        let agendaScrollId = document.getElementById("agenda-comercial-container-scroll");
        agendaScrollId.width = agendaId.width;

        agendaScrollId.scrollLeft = agendaId.scrollLeft;
    }
    const onOpenModal = async (e, type, nome_ac, n_socio, date, date_fim, nome_client,marcacaoID) => {
        e.preventDefault();

        setSRotaACData({...rotaACData,
            ["modal"]: true,
            ["modalType"]: type,
            ["nome_ac"]: nome_ac ? nome_ac : "",
            ["n_socio"]: n_socio ? n_socio: "",
            ["date"]: date  ? date: "",
            ["date_fim"]: date_fim  ? date_fim: "",
            ["nome_client"]: nome_client ? nome_client : "",
            ["marcacaoID"]: marcacaoID ? marcacaoID : "",

        });

    }
    const onCloseModal = async (e, type) => {
        e.preventDefault();
        setSRotaACData({...rotaACData, ["modal"]: modal ? false : true });

    }
    
    const onChangeCol = async e => {
        setSRotaACData({...rotaACData, [e.target.name]: e.target.value});
        await getRotaAcupuntura({prevDate: currentData, id_clube: clube_selected, id_acupunt: e.target.value });
    };

    function changeTimezone(date, ianatz) {
        let invdate = new Date(date.toLocaleString('en-US', { timeZone: ianatz }));
        // let diff = date.getTime() - invdate.getTime();
        // return new Date(date.getTime() + diff);
	return invdate;
    }
    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Serviço ao Cliente >
                        <Link to={"/rota_acupuntura"}>Rota Acupuntura</Link>
                    </div>
                    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        
			<label htmlFor="">
                                Acupunturista:
                                <select name="acupunt_selected"   value={acupunt_selected} onChange={ e => onChangeCol(e)}>
                                  <option value="0">Todos</option>
                                  {consultores.map((e) => (    
                                      <option value={e.id}>{e.nome}</option>
                                 ))}
                            </select>
                        </label>
                    </div>
		    <div className="agenda-comercial-container-tot acupuntura"  onScroll={ e => onMainScroll(e)} >
                       <div className="agenda-column">
                                <h4>Tot Seg</h4>
			        <h3> {data ? (
                                data.payload.segundaMarcacoesTot.length > 0 ? (
                                        data.payload.segundaMarcacoesTot.map((e) => (
                                            e.total
                                        ))
                                    ):
                                    (
                                         <>0</>
                                    )
                            ) : ("") }</h3>
                                
                       </div> 
                       <div className="agenda-column">
                                <h4>Tot Ter</h4>
				<h3> {data ? (
                                data.payload.tercaMarcacoesTot.length > 0 ? (
                                        data.payload.tercaMarcacoesTot.map((e) => (
                                            e.total
                                        ))
                                    ):
                                    (
                                         <>0</>
                                    )
                            ) : ("") }</h3>
		       </div>
		       <div className="agenda-column">
                                <h4>Tot Qua</h4>
				<h3> {data ? (
                                data.payload.quartaMarcacoesTot.length > 0 ? (
                                        data.payload.quartaMarcacoesTot.map((e) => (
                                            e.total
                                        ))
                                    ):
                                    (
                                         <>0</>
                                    )
                            ) : ("") }</h3>
                       </div> 
                       <div className="agenda-column">
                                <h4>Tot Qui</h4>
				<h3> {data ? (
                                data.payload.quintaMarcacoesTot.length > 0 ? (
                                        data.payload.quintaMarcacoesTot.map((e) => (
                                            e.total
                                        ))
                                    ):
                                    (
                                         <>0</>
                                    )
                            ) : ("") }</h3>
		       </div>
		       <div className="agenda-column">
                                <h4>Tot Sex</h4>
				<h3> {data ? (
                                data.payload.sextaMarcacoesTot.length > 0 ? (
                                        data.payload.sextaMarcacoesTot.map((e) => (
                                            e.total
                                        ))
                                    ):
                                    (
                                         <>0</>
                                    )
                            ) : ("") }</h3>
                       </div> 
                       <div className="agenda-column">
                                <h4>Tot Sab</h4>
				<h3> {data ? (
                                data.payload.sabadoMarcacoesTot.length > 0 ? (
                                        data.payload.sabadoMarcacoesTot.map((e) => (
                                            e.total
                                        ))
                                    ):
                                    (
                                         <>0</>
                                    )
                            ) : ("") }</h3>
		       </div>
		       <div className="agenda-column">
                                <h4>Tot Dom</h4>
				<h3> {data ? (
                                data.payload.domingoMarcacoesTot.length > 0 ? (
                                        data.payload.domingoMarcacoesTot.map((e) => (
                                            e.total
                                        ))
                                    ):
                                    (
                                        <>0</>
                                    )
                            ) : ("") }</h3>
		       </div>
		    </div>
                    <div className="agenda-comercial">
                        <div className="buttons-days buttons-days-acupuntura">
                            <a href="" onClick={ e => onChangeCurrentDataPrev(e) }>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-left"
                                     className="svg-inline--fa fa-chevron-left fa-w-8" role="img"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path fill="currentColor"
                                          d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"></path>
                                </svg>
                                Semana Anterior
                            </a>
                            <a href="" onClick={ e => onChangeCurrentDataNext(e) }>
                                Semana Seguinte
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-right"
                                     className="svg-inline--fa fa-chevron-right fa-w-8" role="img"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path fill="currentColor"
                                          d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path>
                                </svg>

                            </a>
                        </div>
                        <div className="current-mes-week-acupuntura">
                            {((diaSemanaAgendaTextSegunda) => {
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 1)
                                    return "Janeiro";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 2)
                                    return "Fevereiro";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 3)
                                    return "Março";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 4)
                                    return "Abril";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 5)
                                    return "Maio";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 6)
                                    return "Junho";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 7)
                                    return "Julho";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 8)
                                    return "Agosto";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 9)
                                    return "Setembro";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 10)
                                    return "Outrubro";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 11)
                                    return "Novembro";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 12)
                                    return "Dezembro";

                            })(diaSemanaAgendaTextSegunda)} | Semana de dia  {diaSemanaAgendaTextSegunda.getDate()} - {diaSemanaAgendaTextDomingo.getDate()}
                        </div>
                        <div id="agenda-comercial-container-scroll" className="agenda-comercial-container-scrol agenda-comercial-container-scrol-acupuntura" onScroll={ e => onScroll(e)}>
                            <div className="agenda-comercial-container-div"></div>
                        </div>
                        <div id="agenda-comercial-container" className="agenda-comercial-container agenda-comercial-container-acupuntura" onScroll={ e => onMainScroll(e)}>

                            <div className="agenda-column">
                                <h3>Segunda / {diaSemanaAgendaTextSegunda.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="segunda">
                                    {data && data.payload.segundaValues.map((element) => (
                                        <div className="agenda-item" onClick={e => onOpenModal(e, "livre", element.nome_ac,  element.n_socio, element.date, element.date_fim, null, element.id)}>
                                            <div className="name-type">
                                                <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                    (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                    + ":" +
                                                    (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                                }
                                                </span>
                                                <span className="type-title"><p>{element.nome_ac}</p></span>
                                            </div>
                                        </div>
                                    ))}

                                    {data && data.payload.segundaMarcacoesValues.map((element) => (
                                        <div className="agenda-marcacao" onClick={e => onOpenModal(e, "marcacao", element.nome_ac,  element.n_socio, element.date, element.date_fim, element.client_name, element.id)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.client_name} / {element.nome_ac}</p></span>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Terça / {diaSemanaAgendaTextTerca.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="terca">
                                    {data && data.payload.tercaValues.map((element) => (
                                        <div className="agenda-item" onClick={e => onOpenModal(e, "livre", element.nome_ac,  element.n_socio, element.date, element.date_fim, null, element.id)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.nome_ac} </p></span>
                                            </div>
                                        </div>
                                    ))}

                                    {data && data.payload.tercaMarcacoesValues.map((element) => (
                                        <div className="agenda-marcacao" onClick={e => onOpenModal(e, "marcacao", element.nome_ac,  element.n_socio, element.date, element.date_fim,  element.client_name, element.id)}>
                                            <div className="name-type">
                                           <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                               + ":" +
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                           }
                                                </span>
                                                <span className="type-title"><p>{element.client_name} / {element.nome_ac}</p></span>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Quarta / {diaSemanaAgendaTextQuarta.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="quarta">
                                    {data && data.payload.quartaValues.map((element) => (
                                        <div className="agenda-item" onClick={e => onOpenModal(e, "livre", element.nome_ac,  element.n_socio, element.date, element.date_fim, null, element.id)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.nome_ac} </p></span>
                                            </div>
                                        </div>
                                    ))}

                                    {data && data.payload.quartaMarcacoesValues.map((element) => (
                                        <div className="agenda-marcacao" onClick={e => onOpenModal(e, "marcacao", element.nome_ac,  element.n_socio, element.date, element.date_fim, element.client_name, element.id)}>
                                            <div className="name-type">
                                                <span className="name-title">
                                                        {
                                                            (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                            + ":" +
                                                            (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                        } - {
                                                    (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                    + ":" +
                                                    (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                                }
                                                    </span>
                                                <span className="type-title"><p>{element.client_name} / {element.nome_ac}</p></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Quinta / {diaSemanaAgendaTextQuinta.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="quinta">
                                    {data && data.payload.quintaValues.map((element) => (
                                        <div className="agenda-item" onClick={e => onOpenModal(e, "livre", element.nome_ac,  element.n_socio, element.date, element.date_fim, null, element.id)}>
                                            <div className="name-type">
                                                <span className="name-title">
                                                        {
                                                            (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                            + ":" +
                                                            (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                        } - {
                                                    (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                    + ":" +
                                                    (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                                }
                                                    </span>
                                                <span className="type-title"><p>{element.nome_ac} </p></span>
                                            </div>
                                        </div>
                                    ))}

                                    {data && data.payload.quintaMarcacoesValues.map((element) => (
                                        <div className="agenda-marcacao" onClick={e => onOpenModal(e, "marcacao", element.nome_ac,  element.n_socio, element.date, element.date_fim, element.client_name, element.id)}>
                                            <div className="name-type">
                                               <span className="name-title">
                                                        {
                                                            (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                            + ":" +
                                                            (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                        } - {
                                                   (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                   + ":" +
                                                   (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                               }
                                                    </span>
                                                <span className="type-title"><p>{element.client_name} / {element.nome_ac}</p></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Sexta / {diaSemanaAgendaTextSexta.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="sexta">
                                    {data && data.payload.sextaValues.map((element) => (
                                        <div className="agenda-item" onClick={e => onOpenModal(e, "livre", element.nome_ac,  element.n_socio, element.date, element.date_fim, null, element.id)}>
                                            <div className="name-type">
                                           <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                               + ":" +
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                           }
                                                </span>
                                                <span className="type-title"><p>{element.nome_ac} </p></span>
                                            </div>
                                        </div>
                                    ))}

                                    {data && data.payload.sextaMarcacoesValues.map((element) => (
                                        <div className="agenda-marcacao" onClick={e => onOpenModal(e, "marcacao", element.nome_ac,  element.n_socio, element.date, element.date_fim, element.client_name, element.id)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.client_name} / {element.nome_ac}</p></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Sabado / {diaSemanaAgendaTextSabado.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="sabado">
                                    {data && data.payload.sabadoValues.map((element) => (
                                        <div className="agenda-item" onClick={e => onOpenModal(e, "livre", element.nome_ac,  element.n_socio, element.date, element.date_fim, null, element.id)}>
                                            <div className="name-type">
                                           <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                               + ":" +
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                           }
                                                </span>
                                                <span className="type-title"><p>{element.nome_ac}</p></span>
                                            </div>
                                        </div>
                                    ))}

                                    {data && data.payload.sabadoMarcacoesValues.map((element) => (
                                        <div className="agenda-marcacao" onClick={e => onOpenModal(e, "marcacao", element.nome_ac,  element.n_socio, element.date, element.date_fim, element.client_name, element.id)}>
                                            <div className="name-type">
                                           <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                               + ":" +
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                           }
                                                </span>
                                                <span className="type-title"><p>{element.client_name} / {element.nome_ac}</p></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="agenda-column">
                                <h3>Domingo / {diaSemanaAgendaTextDomingo.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="domingo">
                                    {data && data.payload.domingoValues.map((element) => (
                                        <div className="agenda-item" onClick={e => onOpenModal(e, "livre", element.nome_ac,  element.n_socio, element.date, element.date_fim, null, element.id)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.nome_ac} </p></span>
                                            </div>
                                        </div>
                                    ))}

                                    {data && data.payload.domingoMarcacoesValues.map((element) => (
                                        <div className="agenda-marcacao" onClick={e => onOpenModal(e, "marcacao", element.nome_ac,  element.n_socio, element.date, element.date_fim, element.client_name, element.id )}>
                                            <div className="name-type">
                                           <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                               + ":" +
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                           }
                                                </span>
                                                <span className="type-title"><p>{element.client_name} / {element.nome_ac}</p></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={modal ? "new-lead-modal new-appointment new-appoint-Acupuntura active " + modalType: "new-appointment   new-appoint-Acupuntura  new-lead-modal " + modalType}>
                         <span id="close-new-lead-modal" onClick={ e => onCloseModal(e)}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => {modalType === "livre" ? (
                            onSubmit(e)
                        ) : (
                           // onSubmit(e)
                            onDelete(e)
                        )} } >
                            <label htmlFor=""> acupuntcionista: {nome_ac} </label>
                            {modalType === "livre" ? (
                                <label htmlFor="">
                                    Horas Disponiveis: {
                                    (parseInt(changeTimezone(new Date(date), "Europe/Lisbon").getHours()) < 10 ? ("0" + changeTimezone(new Date(date), "Europe/Lisbon").getHours()) : changeTimezone(new Date(date), "Europe/Lisbon").getHours())
                                    + ":" +
                                    (parseInt(changeTimezone(new Date(date), "Europe/Lisbon").getMinutes()) < 10 ? ("0" + changeTimezone(new Date(date), "Europe/Lisbon").getMinutes()) : changeTimezone(new Date(date), "Europe/Lisbon").getMinutes())
                                } - {
                                    (parseInt(changeTimezone(new Date(date_fim), "Europe/Lisbon").getHours()) < 10 ? ("0" + changeTimezone(new Date(date_fim), "Europe/Lisbon").getHours()) : changeTimezone(new Date(date_fim), "Europe/Lisbon").getHours())
                                    + ":" +
                                    (parseInt(changeTimezone(new Date(date_fim), "Europe/Lisbon").getMinutes()) < 10 ? ("0" + changeTimezone(new Date(date_fim), "Europe/Lisbon").getMinutes()) : changeTimezone(new Date(date_fim), "Europe/Lisbon").getMinutes())
                                }
                                </label>
                            ) : (""

                            )}
                            <label htmlFor="">
                                Nª Socio
                                <input type="text" name={"n_socio"} value={n_socio} onChange={e => onChange(e)} required />
                            </label>

                            {modalType === "livre" ? (
                                <label htmlFor="">
                                    Hora Avaliação
                                    <input type="time" name={"time_appoint"} value={time_appoint} onChange={e => onChange(e)} required />
                                </label>
                            ) : (
                                <label htmlFor="">
                                    Nome Cliente: {nome_client}
                                </label>
                            )}


                            {modalType === "livre" ? (<button>Guardar</button>) : (
                                //<>-</>
                                <button>Apagar Marcação</button>
                                 )}
                            {alert ? (<div className={"alert alert-danger active"}>{alert}</div>): ("")}
                        </form>
                    </div>

                </div>
            </div>
        </Fragment>
};
RotaAcupunturaAdministracao.propTypes = {
    auth: PropTypes.object.isRequired,
    getRotaAcupuntura: PropTypes.func.isRequired,
    currentDataPrev: PropTypes.func.isRequired,
    insertAppointment: PropTypes.func.isRequired,
    deleteAppointment: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    rotaacupuntura: state.rotaacupuntura
});
export default connect(mapStateToProps, { getRotaAcupuntura, currentDataPrev, insertAppointment, deleteAppointment })( RotaAcupunturaAdministracao );
