/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_SOCIOS,
    GET_SOCIOS_ERROR,
    GET_SOCIOS_NUTRICOA,
    GET_SOCIOS_ERROR_NUTRICOA
} from "./types";

export const getAllSocios = () => async dispatch => {

    try {
        const res = await axios.post("/api/socios");


        dispatch({
            type: GET_SOCIOS,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_SOCIOS_ERROR,
        })
    }
}
export const updateSocios = ({sociosData}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { sociosData };
    try {

        const res = await axios.post("/api/socios/update" ,body, config);


        dispatch({
            type: GET_SOCIOS,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_SOCIOS_ERROR,
        })
    }
}

export const getSociosByFiltroText = ({nome_socio_filtro, n_socio_filtro, telemovel_socio_filtro}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { nome_socio_filtro, n_socio_filtro, telemovel_socio_filtro };
    try {

        const res = await axios.post("/api/socios/filtro" ,body, config);


        dispatch({
            type: GET_SOCIOS,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_SOCIOS_ERROR,
        })
    }
}
export const getNutricaoDates = ({date, nutricao_log_id , n_socio}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { date, nutricao_log_id, n_socio };
    try {

        const res = await axios.post("/api/socios/nutricao" ,body, config);


        dispatch({
            type: GET_SOCIOS_NUTRICOA,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_SOCIOS_ERROR_NUTRICOA,
        })
    }
}
export const updateNutricaoDates = ({date, nutricao_log_id, n_socio}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { date, nutricao_log_id, n_socio };
    try {

        const res = await axios.post("/api/socios/nutricaoupdate" ,body, config);


        dispatch({
            type: GET_SOCIOS_NUTRICOA,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_SOCIOS_ERROR_NUTRICOA,
        })
    }
}
export const updateNutricaoPlanoDates = ({date, nutricao_log_id}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { date, nutricao_log_id };
    try {

        const res = await axios.post("/api/socios/nutricaoupdateplano" ,body, config);


        dispatch({
            type: GET_SOCIOS_NUTRICOA,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_SOCIOS_ERROR_NUTRICOA,
        })
    }
}


