/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_INSCRICOES,
    GET_INSCRICOES_ERROR,
    GET_CANCELAMENTOS,
    GET_CANCELAMENTOS_ERROR,
} from "./types";
export const getInscricao = ({  date, id_adesao, id_clube  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {  date, id_adesao, id_clube  };

    try {
        const res = await axios.post("/api/inscricoes", body, config);

        dispatch({
            type: GET_INSCRICOES,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_INSCRICOES_ERROR,
        })
    }
}
export const insertInscricao = ({ real, date, id_adesao, id_clube, objectivo_real  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { real, date, id_adesao , id_clube, objectivo_real };
    try {
        const res = await axios.post("/api/inscricoes/new", body, config);

        dispatch({
            type: GET_INSCRICOES,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_INSCRICOES_ERROR,
        })
    }
}


export const getCancelamento = ({  date, id_adesao, id_clube  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {  date, id_adesao, id_clube  };
    console.log(body)
    try {
        const res = await axios.post("/api/inscricoes/cancelamentos", body, config);

        dispatch({
            type: GET_CANCELAMENTOS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_CANCELAMENTOS_ERROR,
        })
    }
}
export const insertCancelamento = ({  date, atrittion, suspencao, fecho, atrittion_objectivo, suspencao_objectivo,fecho_objectivo, id_clube }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { date, atrittion, suspencao, fecho ,atrittion_objectivo, suspencao_objectivo,fecho_objectivo, id_clube  };
    try {
        const res = await axios.post("/api/inscricoes/updatecancelamento", body, config);

        dispatch({
            type: GET_CANCELAMENTOS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_CANCELAMENTOS_ERROR,
        })
    }
}