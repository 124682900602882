/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_LIMPEZA,
    GET_LIMPEZA_ERROR,
} from "./types";

export const getLimpeza = ({id_clube}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { id_clube };
    try {
        const res = await axios.post("/api/limpeza", body , config);


        dispatch({
            type: GET_LIMPEZA,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_LIMPEZA_ERROR,
        })
    }
}
export const updateLimpeza = ({manutencaoData, id_clube }) => async dispatch => {

    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { manutencaoData, id_clube };
    try {
        const res = await axios.post("/api/limpeza/update", body, config);


        dispatch({
            type: GET_LIMPEZA,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_LIMPEZA_ERROR,
        })
    }
}

