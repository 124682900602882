/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import '../../../styles/header.css';
import '../../../styles/dashboard.css';
import {logout} from "../../../actions/auth";

const Header = ({auth: {user}, logout}) => {
    const [headerData, setHeaderData] = useState({
        initState: ""
    });
    const {initState} = headerData;
    const onChange = e => setHeaderData({...headerData, [e.target.name]: e.target.value});
    function changeBackground(e) {
        e.target.classList.toggle('active');
    }

    return user === null ? <Fragment>
            <div><img src="./img/loading.gif" alt=""/></div>
        </Fragment> :
        <Fragment>
            <div className="page-header text-center">
                <div className="menu-container">
                    <div className="logo-bg">
                        <img src="./img/logo.png" alt="Logo-dashboard" />
                    </div>

                    <div className="menu-container-items">
                        <span className="in-menu"  >
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="home-alt" className="svg-inline--fa fa-home-alt fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M541 229.16l-232.85-190a32.16 32.16 0 0 0-40.38 0L35 229.16a8 8 0 0 0-1.16 11.24l10.1 12.41a8 8 0 0 0 11.2 1.19L96 220.62v243a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-128l64 .3V464a16 16 0 0 0 16 16l128-.33a16 16 0 0 0 16-16V220.62L520.86 254a8 8 0 0 0 11.25-1.16l10.1-12.41a8 8 0 0 0-1.21-11.27zm-93.11 218.59h.1l-96 .3V319.88a16.05 16.05 0 0 0-15.95-16l-96-.27a16 16 0 0 0-16.05 16v128.14H128V194.51L288 63.94l160 130.57z"></path></svg>
                            <Link to={"/dashboard"} className="btn btn-default">Dashboard</Link>
                         </span>
                        <span className="in-menu"  >
                           <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="user-chart" className="svg-inline--fa fa-user-chart fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M608 0H192c-17.67 0-32 14.33-32 32v96c-53.02 0-96 42.98-96 96s42.98 96 96 96 96-42.98 96-96c0-41.74-26.8-76.9-64-90.12V32h416v352H305.34c-.59-.94-1.03-1.96-1.65-2.88-17.25-25.62-46.67-39.11-76.9-39.11C199 342.02 192.02 352 160 352c-31.97 0-38.95-9.98-66.79-9.98-30.23 0-59.65 13.48-76.9 39.11C6.01 396.42 0 414.84 0 434.67V472c0 22.09 17.91 40 40 40h240c22.09 0 40-17.91 40-40v-37.33c0-6.41-.84-12.6-2.04-18.67H608c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32zM224 224c0 35.29-28.71 64-64 64s-64-28.71-64-64 28.71-64 64-64 64 28.71 64 64zm64 248c0 4.41-3.59 8-8 8H40c-4.41 0-8-3.59-8-8v-37.33c0-12.79 3.75-25.13 10.85-35.67 10.53-15.64 29.35-24.98 50.36-24.98 21.8 0 29.99 9.98 66.79 9.98 36.79 0 45.01-9.98 66.79-9.98 21 0 39.83 9.34 50.36 24.98 7.1 10.54 10.85 22.88 10.85 35.67V472zm50.62-319.31c-9.38-9.38-24.56-9.38-33.94 0l-25.49 25.49c4.56 11.72 7.3 24.17 8.21 37.04l34.25-34.25L384.69 244c4.69 4.69 10.81 7.02 16.97 7.02s12.28-2.33 16.97-7.02l58.97-58.97 33.24 33.24c3.96 3.96 8.82 5.73 13.6 5.73 9.99 0 19.57-7.76 19.57-19.47v-95.58c0-7.15-5.8-12.95-12.95-12.95h-95.58c-17.31 0-25.98 20.93-13.74 33.17l33.24 33.24-53.31 53.31-63.05-63.03zM512 128v46.18L465.82 128H512z"></path></svg>
                            Gestão Comercial
                            <ul>
                                <div>
                                    <li> <Link to={"/contactosangariados"} className="btn btn-default">Contactos Angariados</Link>  </li>
                                    <li> <Link to={"/contactosnaoconvertidos"} className="btn btn-default">Contactos Não Convertidos</Link>  </li>
                                    <li> <Link to={"/inscricoes"} className="btn btn-default">Inscrições</Link>                     </li>
                                    <li> <Link to={"/receitas"} className="btn btn-default">Receitas</Link>                         </li>
                                    <li> <Link to={"/chamadas"} className="btn btn-default">Chamadas</Link>                         </li>
                                    <li> <Link to={"/agenda"} className="btn btn-default">Agenda Comercial</Link>                   </li>

                                </div>
                            </ul>
                        </span>

                        <span className="in-menu serviceclient-icon">
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="user-headset" className="svg-inline--fa fa-user-headset fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M320 352h-4.7c-12.16 0-24 2.9-35.5 6.8a173.76 173.76 0 0 1-111.64 0c-11.48-3.9-23.29-6.78-35.42-6.78H128A128 128 0 0 0 0 480a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32 128 128 0 0 0-128-128zM32 480a96.1 96.1 0 0 1 96-96h4.74c6.92 0 14.92 1.62 25.16 5.09a205.75 205.75 0 0 0 132.16 0c10.31-3.49 18.33-5.11 25.24-5.11h4.7a96.1 96.1 0 0 1 96 96zm16-256a16 16 0 0 0 16-16v-16c0-88.22 71.78-160 160-160s160 71.78 160 160v16a80.09 80.09 0 0 1-80 80h-32a32 32 0 0 0-32-32h-32a32 32 0 0 0 0 64h96a112.14 112.14 0 0 0 112-112v-16C416 86.12 329.88 0 224 0S32 86.12 32 192v16a16 16 0 0 0 16 16zM224 96a95.57 95.57 0 0 1 71.23 159.76c0 .09.13.15.18.24H304a47.89 47.89 0 0 0 40.55-22.58C349 220.36 352 206.58 352 192a128 128 0 0 0-256 0c0 40.42 19.1 76 48.35 99.47-.06-1.17-.35-2.28-.35-3.47a63.25 63.25 0 0 1 8.93-32A95.58 95.58 0 0 1 224 96z"></path></svg>
                            Serviço ao Cliente
                            <ul>
                                <div>
                                    <li> <Link to={"/rota_pt"} className="btn btn-default">Rota PT</Link>                    </li>
                                    <li> <Link to={"/rota_nutricao"} className="btn btn-default">Rota Nutrição</Link>        </li>
				    <li> <Link to={"/rota_acupuntura"} className="btn btn-default">Rota Acupuntura</Link>        </li>

                                    <li> <Link to={"/socios"} className="btn btn-default">Sócios</Link>                      </li>
                                    <li> <Link to={"/limpeza"} className="btn btn-default">Limpeza</Link>                    </li>
                                    <li> <Link to={"/manutencao"} className="btn btn-default">Manutenção</Link>              </li>
                                    <li> <Link to={"/aulas"} className="btn btn-default">Aulas</Link>                        </li>
                                    <li> <Link to={"/personaltrainers"} className="btn btn-default">Personal Trainers</Link> </li>
                                    <li> <Link to={"/instrutorsala"} className="btn btn-default">Instrutores de Sala</Link> </li>

                                </div>
                            </ul>
                        </span>

                        <span className="in-menu">
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chart-network" className="svg-inline--fa fa-chart-network fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M513.6 202.8l-19.2-25.6-48 36 19.2 25.6 48-36zM576 192c13.3 0 25.6-4 35.8-10.9 6.8-4.6 12.7-10.5 17.3-17.3C636 153.6 640 141.3 640 128c0-13.3-4-25.6-10.9-35.8-2.3-3.4-4.9-6.6-7.8-9.5-2.9-2.9-6.1-5.5-9.5-7.8C601.6 68 589.3 64 576 64s-25.6 4-35.8 10.9c-6.8 4.6-12.7 10.5-17.3 17.3C516 102.4 512 114.7 512 128c0 35.3 28.7 64 64 64zm0-96c17.6 0 32 14.4 32 32s-14.4 32-32 32-32-14.4-32-32 14.4-32 32-32zM99.8 250.9C89.6 244 77.3 240 64 240s-25.6 4-35.8 10.9c-6.8 4.6-12.7 10.5-17.3 17.3C4 278.4 0 290.7 0 304c0 35.3 28.7 64 64 64s64-28.7 64-64c0-13.3-4-25.6-10.9-35.8-4.6-6.8-10.5-12.7-17.3-17.3zM64 336c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm88-16h48v-32h-48v32zm469.3 82.7c-2.9-2.9-6.1-5.5-9.5-7.8C601.6 388 589.3 384 576 384s-25.6 4-35.8 10.9c-3.3 2.2-6.3 4.7-9.1 7.5l-91.8-55.1c5.6-13.3 8.7-28 8.7-43.3 0-61.9-50.1-112-112-112-11.3 0-21.9 2.2-32.2 5.2l-39.3-84.1C278.8 101.4 288 83.9 288 64c0-13.3-4-25.6-10.9-35.8-4.6-6.8-10.5-12.7-17.3-17.3C249.6 4 237.3 0 224 0s-25.6 4-35.8 10.9c-6.8 4.6-12.7 10.5-17.3 17.3C164 38.4 160 50.7 160 64c0 35.3 28.7 64 64 64 4 0 7.9-.5 11.7-1.2l39 83.6c-30.5 20-50.7 54.4-50.7 93.6 0 61.9 50.1 112 112 112 35 0 65.8-16.4 86.4-41.5l92.4 55.4c-1.7 5.8-2.7 11.8-2.7 18.1 0 35.3 28.7 64 64 64 13.3 0 25.6-4 35.8-10.9 6.8-4.6 12.7-10.5 17.3-17.3C636 473.6 640 461.3 640 448c0-13.3-4-25.6-10.9-35.8-2.3-3.4-5-6.6-7.8-9.5zM224 96c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm112 288c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80zm240 96c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32z"></path></svg>
                            Gestão Clube
                            <ul>
                                <div>
                                     <li>   <Link to={"/reunioes"} className="btn btn-default">Reuniões Coordenação</Link>  </li>
                                     <li>   <Link to={"/pontoscriticos"} className="btn btn-default">Pontos Críticos</Link> </li>
                                     <li>   <Link to={"/utilizadores"} className="btn btn-default">Utilizadores</Link> </li>

                                    {/* <li>   <a href={"/colaboradores"}  className="btn btn-default">Relatórios Gestão</a>   </li>
                                     <li>   <a href={"/colaboradores"}  className="btn btn-default">Relatórios POS</a>      </li>
                                     <li>   <a href={"/colaboradores"}  className="btn btn-default">Relatórios Chamadas</a> </li>*/}
                                    <li>   <Link to={"/colaboradores"} className="btn btn-default">Colaboradores</Link>    </li>
                                     <li>   <Link to={"/planoformacao"} className="btn btn-default">Plano Formação</Link>   </li>
                                     <li>   <Link to={"/mapaferias"} className="btn btn-default">Mapa de Férias</Link>      </li>
                                     <li>   <Link to={"/projeccaocomercial"} className="btn btn-default">Projecção Comercial</Link>      </li>
                                     <li>   <Link to={"/bancodd"} className="btn btn-default">Banco DD</Link>      </li>
                                     <li> <Link to={"/cancelamentos"} className="btn btn-default">Cancelamentos e Suspensões</Link>  </li>


                                </div>
                            </ul>
                        </span>

                        <span className="in-menu">
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="sign-out" className="svg-inline--fa fa-sign-out fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M48 64h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-8.8 0-16 7.2-16 16v288c0 8.8 7.2 16 16 16h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48zm279 19.5l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l132 131.4H172c-6.6 0-12 5.4-12 12v10c0 6.6 5.4 12 12 12h279.9L320 404.4c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l164.5-164c4.7-4.7 4.7-12.3 0-17L344 83.5c-4.7-4.7-12.3-4.7-17 0z"></path></svg>
                            <a onClick={logout}>Logout</a>
                         </span>

                    </div>
                </div>
                <div className="icon-menu-mobile">
                    <div className="top-bar bar"></div>
                    <div className="middle-bar bar"></div>
                    <div className="bottom-bar bar"></div>
                </div>
            </div>
        </Fragment>
};

Header.propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(mapStateToProps, { logout })(Header);
