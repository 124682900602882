/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";

const setAuthToken = token => {
    if(token)
        axios.defaults.headers.common["x-auth-token"] = token;
    else
        delete axios.defaults.headers.common["x-auth-token"];
}


export default setAuthToken;