/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_UTILIZADORES,
    GET_UTILIZADORES_ERROR,
} from "./types";

export const getUtilizadores = ({nome_colaborador_filtro}) => async dispatch => {
     const config = {
         header: {
             "Content-Type": "application/json"
         }
     }
     const body = { nome_colaborador_filtro  };
    try {
        const res = await axios.post("/api/utilizadores", body, config);


        dispatch({
            type: GET_UTILIZADORES,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_UTILIZADORES_ERROR,
        })
    }
}
export const insertUtilizadores = ({ nome_ut, email_ut, tel_ut, pass_ut, id_level_ut, id, id_clube_ut }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { nome_ut, email_ut, tel_ut, pass_ut, id_level_ut, id, id_clube_ut };
    try {
        const res = await axios.post("/api/utilizadores/new", body, config);

        dispatch({
            type: GET_UTILIZADORES,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_UTILIZADORES_ERROR,
        })
    }
}

export const deleteUtilizadores= ({id_clube_ut, id }) => async dispatch => {
   
   const config = {
        header: {
            "Content-Type": "application/json"
        }
    }

    const body = {id_clube_ut, id};

    try {
        
        const res = await axios.post("/api/utilizadores/delete", body, config);

        dispatch({
            type: GET_UTILIZADORES,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_UTILIZADORES_ERROR,
        })
    }
}



