/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getCancelamento, insertCancelamento } from "./actions/inscricoes";

const CancelamentosDirectorClube = ({ auth: {user}, insertCancelamento, getCancelamento, inscricoes: { data } }) => {
    useEffect(() => {
        getCancelamento({ date: moment().format("YYYY-MM-DD"), id_adesao: 1  });
    },[]);
    useLayoutEffect(() => {
        setCancelamentosData(
            {
                ...cancelamentosData,
                atrittion: data && data.atrittion && data.atrittion.value,
                suspencao: data && data.suspencao && data.suspencao.value,
                fecho: data && data.fecho && data.fecho.value,
                atrittion_objectivo: data && data.objectivo ? data.objectivo.attrition ? data.objectivo.attrition : 0 : 0,
                suspencao_objectivo: data && data.objectivo ? data.objectivo.suspensao ? data.objectivo.suspensao : 0 : 0,
                fecho_objectivo: data && data.objectivo ? data.objectivo.fecho ? data.objectivo.fecho : 0 : 0,

            }
        );

    },[data]);
    const [cancelamentosData, setCancelamentosData] = useState({
        date:  moment().format("YYYY-MM-DD"),
        atrittion: 0,
        suspencao: 0,
        fecho: 0,
        atrittion_objectivo: 0,
        suspencao_objectivo: 0,
        fecho_objectivo: 0,
        clube_selected: 1,
    });

    const { clube_selected, date, atrittion, suspencao, fecho, atrittion_objectivo, suspencao_objectivo, fecho_objectivo} = cancelamentosData;
    const onChange = e => setCancelamentosData({...cancelamentosData, [e.target.name]: e.target.value});
    const onChangeCol = async e => {
        setCancelamentosData({...cancelamentosData, [e.target.name]: e.target.value});
        await getCancelamento({ date, id_adesao: 1  });

    };
    const onChangeDate = async e => {
        setCancelamentosData({...cancelamentosData, [e.target.name]: e.target.value});
        try{
             await getCancelamento({ date:e.target.value, id_adesao: 1  });
        } catch (e) {

        }
    };

    const onSubmit = async e => {
        e.preventDefault();

        try{
            await insertCancelamento({ date, atrittion, suspencao, fecho, atrittion_objectivo, suspencao_objectivo, fecho_objectivo });
        } catch (e) {

        }
    }
    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs">
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Gestão Comercial >
                        <Link to={"/cancelamentos"}>Cancelamentos & Suspensões</Link>
                    </div>

                    <form onSubmit={e => onSubmit(e) } className={"table-inputs"}>
                        <label htmlFor="">Filtro:
                            <input type="date" name={"date"} value={date} onChange={e => onChangeDate(e)}/>
                        </label>

{/*
                        <p className={"table-input-info"}>**Devem preencher somente com os valores do HC, não acrescentar valores de caixa</p>
*/}

                        <div className="table-grid-two-columns">

                            <label htmlFor="">
                                <div>Atrittion
                                    <span>Objectivo:   <input type="number" name={"atrittion_objectivo"} value={atrittion_objectivo} onChange={e => onChange(e)}/></span>
                                </div>

                                <input type="number" name={"atrittion"} value={atrittion} onChange={e => onChange(e)}/>
                            </label>

                            <label htmlFor="">
                                <div>
                                    Suspensão
                                    <span>Objectivo:   <input type="number" name={"suspencao_objectivo"} value={suspencao_objectivo} onChange={e => onChange(e)}/></span>
                                </div>
                                <input type="number" name={"suspencao"} value={suspencao} onChange={e => onChange(e)}/>
                            </label>

                            <label htmlFor="">
                                <div>
                                    Fecho
                                    <span>Objectivo:   <input type="number" name={"fecho_objectivo"} value={fecho_objectivo} onChange={e => onChange(e)}/></span>

                                </div>
                                <input type="number" name={"fecho"} value={fecho} onChange={e => onChange(e)}/>
                            </label>
                        </div>


                        <button>Guardar</button>

                    </form>
                </div>
            </div>
        </Fragment>
};
CancelamentosDirectorClube.propTypes = {
    insertCancelamento: PropTypes.func.isRequired,
    getCancelamento: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    inscricoes: state.inscricoes,
});
export default connect(mapStateToProps, { getCancelamento, insertCancelamento })( CancelamentosDirectorClube );
