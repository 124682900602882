/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_INSCRICOES, GET_INSCRICOES_ERROR,
    GET_RECEITAS,
    GET_RECEITAS_ERROR,
} from "./types";

export const getReceita = ({  date , id_clube }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {  date  , id_clube};
    console.log(body)
    try {
        const res = await axios.post("/api/receitas", body, config);

        dispatch({
            type: GET_RECEITAS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_RECEITAS_ERROR,
        })
    }
}


export const insertReceitas = ({ date, inscricoes, mensalidades, entradas_diarias, agua_vit, cartoes_socios, nutricao, seguros, rendas, pack_senhas, maq_vending, merch, id_clube,
                                   inscricoesCaixa, mensalidadesCaixa, entradas_diariasCaixa, agua_vitCaixa, cartoes_sociosCaixa, nutricaoCaixa, segurosCaixa, rendasCaixa, pack_senhasCaixa, maq_vendingCaixa, merchCaixa,
                                   inscricoes_objetivo, mensalidades_objetivo, entradas_diarias_objetivo, agua_vit_objetivo, cartoes_socios_objetivo, nutricao_objetivo, seguros_objetivo, rendas_objetivo, pack_senhas_objetivo, maq_vending_objetivo, merch_objetivo}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { date, inscricoes, mensalidades, entradas_diarias, agua_vit, cartoes_socios, nutricao, seguros, rendas, pack_senhas, maq_vending, merch, id_clube, inscricoesCaixa, mensalidadesCaixa, entradas_diariasCaixa, agua_vitCaixa, cartoes_sociosCaixa, nutricaoCaixa, segurosCaixa, rendasCaixa, pack_senhasCaixa, maq_vendingCaixa, merchCaixa ,
        inscricoes_objetivo, mensalidades_objetivo, entradas_diarias_objetivo, agua_vit_objetivo, cartoes_socios_objetivo, nutricao_objetivo, seguros_objetivo, rendas_objetivo, pack_senhas_objetivo, maq_vending_objetivo, merch_objetivo};
    try {
        const res = await axios.post("/api/receitas/new", body, config);

        dispatch({
            type: GET_RECEITAS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_RECEITAS_ERROR,
        })
    }
}

export const updateBloquear = ({ date, checked, id_clube }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { date, checked, id_clube };
    try {
        const res = await axios.post("/api/receitas/block", body, config);

        dispatch({
            type: GET_RECEITAS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_RECEITAS_ERROR,
        })
    }
}