/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getPlanoFormacao, updateOrInsertPlanoFormacao, deletePlanoFormacao } from "./actions/planoformacao";
import Pagination from "../gestaocomercial/Pagination";

const PlanoFormacaoAdministracao = ({ auth: {user}, getPlanoFormacao, updateOrInsertPlanoFormacao, deletePlanoFormacao, planoformacao: { data }}) => {
    useEffect(() => {
        getPlanoFormacao({id_clube: 1});
    },[]);

    useLayoutEffect(() => {
        setPlanoFormacaoData({...planoFormacaoData,
            modal: data ? !!data.err : false,
            err: data ? data.err ?  data.err : false : false,
            clube_selected: data ? data.clube_selected ?  data.clube_selected : 1 : 1,
        });

    },[data]);

    const [planoFormacaoData, setPlanoFormacaoData] = useState({
        modal: false,
        date: moment().format("YYYY-MM-DD"),
        formacao: "" ,
        participantes: "",
        local: "",
        idplano: "",
        err: false,
        clube_selected: 1
    });

    const { clube_selected, err, modal, date, formacao, local, participantes, idplano } = planoFormacaoData;
    const onChange = e => setPlanoFormacaoData({...planoFormacaoData, [e.target.name]: e.target.value});
    const onDeleteRow = async (e, id) => {
        e.preventDefault();
        if(e.target.classList.contains("row-delete"))
            try{
                await deletePlanoFormacao({ idplano: id, id_clube:clube_selected });
            }catch (e){
                console.log(e)
            }
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let resultados = data ? data.planoformacao ? data.planoformacao : [] : [];
    const currentPosts = resultados.slice(indexOfFirstPost,indexOfLastPost);
    const paginate = (e,pageNumber) => {
        e.preventDefault()
        setCurrentPage(pageNumber)
    }

    const onSubmit = async e => {
        e.preventDefault();
        console.log({
            date, formacao, local, participantes, idplano
        })

        try{
            await updateOrInsertPlanoFormacao({  date, formacao, local, participantes, idplano, id_clube:clube_selected });
        }catch (e){
            console.log(e)
        }

    }
    const onOpenModal = async e => {
        e.preventDefault();
        setPlanoFormacaoData({...planoFormacaoData,
            ["modal"]: modal ? false : true,
            ["date"]: moment().format("YYYY-MM-DD"),
            ["formacao"]: "",
            ["local"]: "",
            ["participantes"]:  "",
            ["idplano"]:  "",

        });

    }
    const onOpenModalValues = async (e, vdate, vformacao, vlocal, vparticipantes, vdata_entrada, vid, vid_clube) => {
        e.preventDefault();

        if(!e.target.classList.contains("row-delete"))
            setPlanoFormacaoData({...planoFormacaoData,
                ["modal"]: modal ? false : true,
                ["date"]: moment(vdate).format("YYYY-MM-DD"),
                ["formacao"]: vformacao,
                ["local"]: vlocal,
                ["participantes"]: vparticipantes,
                ["idplano"]: vid,
            });
    }
    const onChangeCol = async e => {
        setPlanoFormacaoData({...planoFormacaoData, [e.target.name]: e.target.value});
        await getPlanoFormacao({  id_clube:  e.target.value});
    };
    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Gestão Clube >
                        <Link to={"/pontoscriticos"}>Plano Formacao</Link>
                    </div>
                    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="">
                            Clube:
                            <select name="clube_selected"   value={clube_selected} onChange={ e => onChangeCol(e)} style={{textTransform: "capitalize"}}>
                                {user && user.clubes.map((e) => (
                                    <option value={e.id} >{e.localidade}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <button  onClick={e => onOpenModal(e)} className={"new-btn"}>Novo</button>
                    <table className="table-format-one">
                        <thead>
                        <tr>
                            <th>Data</th>
                            <th>Formação</th>
                            <th>Onde</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            {currentPosts && currentPosts.map(({date, formacao, local, participantes, data_entrada, id, id_clube}) => (
                                <tr key={id} className={"to-call"} onClick={e => onOpenModalValues(e, date,formacao, local, participantes, data_entrada, id, id_clube)}>
                                    <td>
                                        { date ? (
                                            (parseInt(new Date(date).getDate())  < 10 ? ("0" + new Date(date).getDate()) : new Date(date).getDate())
                                            + "/" +
                                            (parseInt(new Date(date).getMonth() + 1)  < 10 ? ("0" + (new Date(date).getMonth() +1)) : new Date(date).getMonth() +1)
                                            + "/" +
                                            (parseInt(new Date(date).getFullYear()))
                                        ) : ("") }
                                    </td>
                                    <td>{formacao}</td>
                                    <td>
                                        {local}
                                    </td>
                                    <td onClick={e => onDeleteRow(e, id)} className={"row-delete"}>
                                        X
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination postsPerPAge={postsPerPage} totalPosts={resultados.length} paginate={paginate} currentPage={currentPage}/>

                    <div className={modal ? "new-lead-modal new-colaborador  new-colaborador-reunioes active": "new-colaborador new-colaborador-reunioes new-lead-modal"}>
                         <span id="close-new-lead-modal" onClick={ e => onOpenModal(e)}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => onSubmit(e)}>
                            <label htmlFor="">
                                Data
                                <input type="date" name={"date"} value={date} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                Formação
                                <input type="text"  name={"formacao"} value={formacao} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                Local
                                <input type="text"  name={"local"} value={local} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                Participantes
                                <textarea  name={"participantes"} value={participantes} onChange={e => onChange(e)} required cols="10" rows="10"></textarea>
                            </label>
                            <button>GUARDAR</button>
                        </form>
                    </div>

                </div>
            </div>

        </Fragment>
};
PlanoFormacaoAdministracao.propTypes = {
    auth: PropTypes.object.isRequired,
    getPlanoFormacao: PropTypes.func.isRequired,
    updateOrInsertPlanoFormacao: PropTypes.func.isRequired,
    deletePlanoFormacao: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    planoformacao: state.planoformacao

});
export default connect(mapStateToProps, { getPlanoFormacao, updateOrInsertPlanoFormacao, deletePlanoFormacao  })( PlanoFormacaoAdministracao );
