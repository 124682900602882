/**
 * Created by danielsilva on 10/04/2020.
 */
export const SET_ALERT =        "SET_ALERT";
export const GET_CLIENT_LIST =     "GET_CLIENT_LIST";
export const ERROR_CLIENT_LIST = "ERROR_CLIENT_LIST";
export const AUTH_ERROR = "AUTH_ERROR";


export const REMOVE_ALERT =     "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL =    "REGISTER_FAIL";
export const USER_LOADED =      "USER_LOADED";
export const LOGIN_SUCCESS =    "LOGIN_SUCCESS";
export const LOGIN_FAIL =       "LOGIN_FAIL";
export const LOGOUT =           "LOGOUT";
export const GET_FEATURED_VIDEO =      "GET_FEATURED_VIDEO";
export const ERROR_FEATURED_VIDEO =    "ERROR_FEATURED_VIDEO";
export const GET_CLASS_VIDEO =      "GET_CLASS_VIDEO";
export const ERROR_CLASS_VIDEO =    "ERROR_CLASS_VIDEO";
export const RECOVERY_PASS_SUCCESS =    "RECOVERY_PASS_SUCCESS";
export const RECOVERY_PASS_FAIL =    "RECOVERY_PASS_FAIL";
export const RESET_PASS_SUCCESS =    "RESET_PASS_SUCCESS";
export const RESET_PASS_FAIL =    "RESET_PASS_FAIL";
export const GET_CLASS_VIDEO_TYPE =      "GET_CLASS_VIDEO_TYPE";
export const ERROR_CLASS_VIDEO_TYPE =    "ERROR_CLASS_VIDEO_TYPE";
export const GET_PT_VIDEO_TYPE =      "GET_PT_VIDEO_TYPE";
export const ERROR_PT_VIDEO_TYPE =    "ERROR_PT_VIDEO_TYPE";
export const GET_PT_SINGLE_VIDEO_TYPE =      "GET_PT_SINGLE_VIDEO_TYPE";
export const ERROR_PT_SINGLE_VIDEO_TYPE =    "ERROR_PT_SINGLE_VIDEO_TYPE";
export const GET_NUTRI_VIDEO_TYPE =      "GET_NUTRI_VIDEO_TYPE";
export const ERROR_NUTRI_VIDEO_TYPE =    "ERROR_NUTRI_VIDEO_TYPE";
export const GET_LIFESTYLE_VIDEO_TYPE =      "GET_LIFESTYLE_VIDEO_TYPE";
export const ERROR_LIFESTYLE_VIDEO_TYPE =    "ERROR_LIFESTYLE_VIDEO_TYPE";
export const TYPE_MARCACAO_SUCCESS =      "TYPE_MARCACAO_SUCCESS";
export const TYPE_MARCACAO_FAIL =    "TYPE_MARCACAO_FAIL";
export const TYPELIST_MARCACAO_SUCCESS =      "TYPELIST_MARCACAO_SUCCESS";
export const TYPELIST_MARCACAO_FAIL =    "TYPELIST_MARCACAO_FAIL";
export const POST_CLIENT_LIST =      "POST_CLIENT_LIST";
export const ERROR_POST_CLIENT_LIST =    "ERROR_POST_CLIENT_LIST";
export const GET_MARCACOES_LIST =      "GET_MARCACOES_LIST";
export const ERROR_MARCACOES_LIST =    "ERROR_MARCACOES_LIST";
export const GET_MARCACOES_LIST_DATA =      "GET_MARCACOES_LIST_DATA";
export const ERROR_MARCACOES_LIST_DATA =    "ERROR_MARCACOES_LIST_DATA";







