/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_PERSONAL_TRAINER,
    GET_PERSONAL_TRAINER_ERROR,
} from "./types";

export const getPersonalTrainerAvaliacoes = ( ) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {  };
    try {
        const res = await axios.post("/api/personaltrainer", body, config);

        dispatch({
            type: GET_PERSONAL_TRAINER,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PERSONAL_TRAINER_ERROR,
        })
    }
}


export const updatePersonalTRainer = ({  personalTrainerData }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { personalTrainerData };
    try {
        const res = await axios.post("/api/personaltrainer/update",body, config);


        dispatch({
            type: GET_PERSONAL_TRAINER,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PERSONAL_TRAINER_ERROR,
        })
    }
}

export const deletePersonalTRainer= ({ id }) => async dispatch => {
   
   const config = {
        header: {
            "Content-Type": "application/json"
        }
    }

    const body = {id};

    try {
        
        const res = await axios.post("/api/personaltrainer/delete", body, config);

        dispatch({
            type: GET_PERSONAL_TRAINER,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_PERSONAL_TRAINER_ERROR,
        })
    }
}