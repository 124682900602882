/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_PROJECCAO,
    GET_PROJECCAO_ERROR,
} from "./types";

export const getProjeccao = ({ id_clube }) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = { id_clube };
        const res = await axios.post("/api/projeccao", body ,config);


        dispatch({
            type: GET_PROJECCAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PROJECCAO_ERROR,
        })
    }
}
export const currentDataPrev = ({ prevDate, id_clube }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { prevDate, id_clube };
    try {
        const res = await axios.post("/api/projeccao", body, config);


        dispatch({
            type: GET_PROJECCAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PROJECCAO_ERROR,
        })
    }
}
export const insertObjectivo = ({ prevDate, consultores, id_clube }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { prevDate,  consultores, id_clube};
    try {
        const res = await axios.post("/api/projeccao/objectivos", body, config);


        dispatch({
            type: GET_PROJECCAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PROJECCAO_ERROR,
        })
    }
}
export const insertProjeccoes = ({ prevDate, segundaDate, tercaDate, quartaDate,
                                     quintaDate, sextaDate, sabadoDate, domingoDate,
                                     segunda_projeccao, segunda_actual, terca_projeccao, terca_actual, quarta_projeccao, quarta_actual, quinta_projeccao,
                                     quinta_actual, sexta_projeccao, sexta_actual, sabado_projeccao, sabado_actual, domingo_projeccao, domingo_actual, id_clube }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { prevDate,  segundaDate, tercaDate, quartaDate,
        quintaDate, sextaDate, sabadoDate, domingoDate,
        segunda_projeccao, segunda_actual, terca_projeccao, terca_actual, quarta_projeccao, quarta_actual, quinta_projeccao,
        quinta_actual, sexta_projeccao, sexta_actual, sabado_projeccao, sabado_actual, domingo_projeccao, domingo_actual, id_clube };

    try {
        const res = await axios.post("/api/projeccao/projeccao", body, config);


        dispatch({
            type: GET_PROJECCAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PROJECCAO_ERROR,
        })
    }
}

