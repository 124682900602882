/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getPersonalTrainerAvaliacoes, updatePersonalTRainer, deletePersonalTRainer } from "./actions/personaltrainer";
import Pagination from "../gestaocomercial/Pagination";

const PersonalTrainersAdministracao = ({ auth: {user}, getPersonalTrainerAvaliacoes, personaltrainer: { data }, updatePersonalTRainer, deletePersonalTRainer }) => {
    useEffect(() => {
        getPersonalTrainerAvaliacoes({ id_clube: 1});
    },[]);
    useLayoutEffect(() => {
        setPersonalTrainerData({...personalTrainerData,
            modal: data ? !!data.err : false,
            err: data ? data.err ?  data.err : false : false,
            clube_selected: data ? data.clube_selected ?  data.clube_selected : 1 : 1,
            loading:false,
        });
    },[data]);
    const [personalTrainerData, setPersonalTrainerData] = useState({
        modal: false,
        av_observador: "",
        instrutor_select: "",
        cliente_nome: "",
        date_cliente: "",
        av_extra_pt_cliente : "",
        av_app_cliente: "",
        av_ex_demos: "",
        av_corrige_ex_demos: "",
        av_corrige_ex: "",
        av_cria_prog: "",
        av_obs_cliente: "",
        av_pos_cliente: "",
        av_prep_local: "",
        av_cump_cliente: "",
        av_chega: "",
        error: "",
        id: "",
        clube_selected: 1
    });

    const { clube_selected,id, modal, av_observador, instrutor_select, cliente_nome, date_cliente, error, av_extra_pt_cliente, av_app_cliente, av_ex_demos, av_corrige_ex_demos, av_corrige_ex,
        av_cria_prog, av_obs_cliente, av_pos_cliente, av_prep_local, av_cump_cliente, av_chega } = personalTrainerData;
    const onChange = e => setPersonalTrainerData({...personalTrainerData, [e.target.name]: e.target.value});

    const onChangeDate = async e => {
        setPersonalTrainerData({...personalTrainerData, [e.target.name]: e.target.value});


    };

    const onSubmit = async e => {
        e.preventDefault();

        try {
            await updatePersonalTRainer({personalTrainerData, id_clube: clube_selected});
        } catch (e) {

        }
    }

    const onDelete = async e => {
        e.preventDefault();
        
        await deletePersonalTRainer({id_clube: clube_selected, id});
       
   }

    const onOpenModal = async e => {
        e.preventDefault();
        setPersonalTrainerData({...personalTrainerData, ["modal"]: modal ? false : true,
            ["av_observador"]: "",
            ["cliente_nome"]:  "",
            ["date_cliente"]: "",
            ["instrutor_select"]: "",

            ["av_chega"]:  "",
            ["av_cump_cliente"]:  "",
            ["av_prep_local"]: "",
            ["av_pos_cliente"]: "",
            ["av_obs_cliente"]:  "",
            ["av_cria_prog"]: "",
            ["av_corrige_ex"]: "",
            ["av_corrige_ex_demos"]:  "",
            ["av_ex_demos"]:  "",
            ["av_app_cliente"]:  "",
            ["av_extra_pt_cliente"]:  "",

            ["id"]: "",});

    }
    const onOpenModalValues  = async (e,id, instrutor, observador, cliente, date, avaliacao) => {
        e.preventDefault();
        let personalTrainerContent = JSON.parse(avaliacao);

        setPersonalTrainerData({...personalTrainerData,
            ["modal"]: modal ? false : true,
            ["av_observador"]: observador,
            ["cliente_nome"]: cliente,
            ["date_cliente"]: moment(date).format("YYYY-MM-DD HH:mm:ss"),
            ["instrutor_select"]: instrutor,

            ["av_chega"]: personalTrainerContent ? personalTrainerContent.av_chega  ? personalTrainerContent.av_chega : 0 : 0,
            ["av_cump_cliente"]: personalTrainerContent ? personalTrainerContent.av_cump_cliente  ? personalTrainerContent.av_cump_cliente : 0 : 0,
            ["av_prep_local"]:personalTrainerContent ? personalTrainerContent.av_prep_local ? personalTrainerContent.av_prep_local : 0 : 0,
            ["av_pos_cliente"]:personalTrainerContent ? personalTrainerContent.av_pos_cliente ? personalTrainerContent.av_pos_cliente : 0 : 0,
            ["av_obs_cliente"]: personalTrainerContent ? personalTrainerContent.av_obs_cliente  ? personalTrainerContent.av_obs_cliente : 0 : 0,
            ["av_cria_prog"]:personalTrainerContent ? personalTrainerContent.av_cria_prog  ? personalTrainerContent.av_cria_prog : 0 : 0,
            ["av_corrige_ex"]:personalTrainerContent ? personalTrainerContent.av_corrige_ex  ? personalTrainerContent.av_corrige_ex : 0 : 0,
            ["av_corrige_ex_demos"]: personalTrainerContent ? personalTrainerContent.av_corrige_ex_demos  ? personalTrainerContent.av_corrige_ex_demos : 0 : 0,
            ["av_ex_demos"]: personalTrainerContent ? personalTrainerContent.av_ex_demos  ? personalTrainerContent.av_ex_demos : 0 : 0,
            ["av_app_cliente"]: personalTrainerContent ? personalTrainerContent.av_app_cliente  ? personalTrainerContent.av_app_cliente : 0 : 0,
            ["av_extra_pt_cliente"]: personalTrainerContent ? personalTrainerContent.av_extra_pt_cliente ? personalTrainerContent.av_extra_pt_cliente : 0 : 0,

            ["id"]:id,
        });

    }

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let resultados = data ? data.personaltrainer ? data.personaltrainer : [] : [];
    const currentPosts = resultados.slice(indexOfFirstPost,indexOfLastPost);

    const paginate = (e,pageNumber) => {
        e.preventDefault()
        setCurrentPage(pageNumber)
    }
    const onChangeConsultor = async e => {
        setPersonalTrainerData({...personalTrainerData, [e.target.name]: e.target.value});
        await getPersonalTrainerAvaliacoes({ id_clube:  e.target.value});

    };
    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Serviço ao Cliente >
                        <Link to={"/personaltrainers"}>Personal Trainers</Link>
                    </div>
                    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="">
                            Clube:
                            <select name="clube_selected"   value={clube_selected} onChange={ e => onChangeConsultor(e)} style={{textTransform: "capitalize"}}>
                                {user && user.clubes.map((e) => (
                                    <option value={e.id} >{e.localidade}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <button  onClick={e => onOpenModal(e)} className={"new-btn"}>Novo</button>

                    <table className="table-format-one">
                        <thead>
                            <tr>
                                <th>Instrutor</th>
                                <th>Observador</th>
                                <th>Data</th>
                                <th>Cliente</th>
                            </tr>
                        </thead>
                        <tbody>

                            {currentPosts && currentPosts.map(({id, instrutor, observador, cliente, date, nome_pt, avaliacao }) => (
                                <tr className={"to-call"} onClick={e => onOpenModalValues(e, id, instrutor, observador, cliente, date, avaliacao )}>
                                    <td>{nome_pt}</td>
                                    <td>{observador}</td>
                                    <td>  { date ? (
                                        (parseInt(new Date(date).getDate())  < 10 ? ("0" + new Date(date).getDate()) : new Date(date).getDate())
                                        + "/" +
                                        (parseInt(new Date(date).getMonth() + 1)  < 10 ? ("0" + (new Date(date).getMonth() +1)) : new Date(date).getMonth() +1)
                                        + "/" +
                                        (parseInt(new Date(date).getFullYear())) + " " + (
                                        (parseInt(new Date(date).getHours())  < 10 ? ("0" + new Date(date).getHours()) : new Date(date).getHours())
                                        + ":" +
                                        (parseInt(new Date(date).getMinutes())  < 10 ? ("0" + new Date(date).getMinutes()) : new Date(date).getMinutes())
                                        )
                                    ): ("") }</td>
                                    <td>{cliente}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination postsPerPAge={postsPerPage} totalPosts={resultados.length} paginate={paginate} currentPage={currentPage}/>
                    <div className={modal ? "new-lead-modal new-personaltable active": "new-lead-modal new-personaltable"}>
                         <span id="close-new-lead-modal" onClick={ e => onOpenModal(e)}>
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => onSubmit(e)}>
                            <div className="top-form">
                                <label htmlFor="">
                                    Instrutor
                                    <select name={"instrutor_select"} value={instrutor_select} onChange={e => onChange(e)} required>
                                        {user && user.personaltrainers.map((e) => (
                                            <option value={e.id}>{e.nome}</option>
                                        ))}
                                    </select>
                                </label>
                                <label htmlFor="">
                                    Observador
                                    <input type="text" name={"av_observador"} value={av_observador} onChange={e => onChange(e)} required/>
                                </label>
                                {/* <label htmlFor="">
                                    Data e Hora
                                    <input type="datetime-local" name={"date_cliente"} value={date_cliente} onChange={e => onChange(e)}  />
                                </label>*/}
                                <label htmlFor="">
                                    Cliente
                                    <input type="text" name={"cliente_nome"} value={cliente_nome} onChange={e => onChange(e)} required/>
                                </label>
                            </div>
                            <label htmlFor=""><h3>Critério de observação</h3></label>
                            <label htmlFor="">
                                Pontualidade/chega 5' antes do treino começar
                                <span>
                                      Faz
                                    <input type="radio" name={"av_chega"} value={"1"} onChange={e => onChange(e)} checked={av_chega === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_chega"} value={"2"} onChange={e => onChange(e)} checked={av_chega === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_chega"} value={"3"} onChange={e => onChange(e)} checked={av_chega === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_chega"} value={"4"} onChange={e => onChange(e)} checked={av_chega === "4"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Usa Toalha corretamente nos alongamentos assistidos
                                <span>
                                      Faz
                                    <input type="radio" name={"av_cump_cliente"} value={"1"} onChange={e => onChange(e)} checked={av_cump_cliente === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_cump_cliente"} value={"2"} onChange={e => onChange(e)} checked={av_cump_cliente === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_cump_cliente"} value={"3"} onChange={e => onChange(e)} checked={av_cump_cliente === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_cump_cliente"} value={"4"} onChange={e => onChange(e)} checked={av_cump_cliente === "4"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Prepara local e equipamentos antecipadamente
                                <span>
                                      Faz
                                    <input type="radio" name={"av_prep_local"} value={"1"} onChange={e => onChange(e)} checked={av_prep_local === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_prep_local"} value={"2"} onChange={e => onChange(e)} checked={av_prep_local === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_prep_local"} value={"3"} onChange={e => onChange(e)} checked={av_prep_local === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_prep_local"} value={"4"} onChange={e => onChange(e)} checked={av_prep_local === "4"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Spotting corretamente
                                <span>
                                      Faz
                                    <input type="radio" name={"av_pos_cliente"} value={"1"} onChange={e => onChange(e)} checked={av_pos_cliente === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_pos_cliente"} value={"2"} onChange={e => onChange(e)} checked={av_pos_cliente === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_pos_cliente"} value={"3"} onChange={e => onChange(e)} checked={av_pos_cliente === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_pos_cliente"} value={"4"} onChange={e => onChange(e)} checked={av_pos_cliente === "4"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Fico no cliente
                                <span>
                                      Faz
                                    <input type="radio" name={"av_obs_cliente"} value={"1"} onChange={e => onChange(e)} checked={av_obs_cliente === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_obs_cliente"} value={"2"} onChange={e => onChange(e)} checked={av_obs_cliente === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_obs_cliente"} value={"3"} onChange={e => onChange(e)} checked={av_obs_cliente === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_obs_cliente"} value={"4"} onChange={e => onChange(e)} checked={av_obs_cliente === "4"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Adapta o treino ao cliente
                                <span>
                                      Faz
                                    <input type="radio" name={"av_cria_prog"} value={"1"} onChange={e => onChange(e)} checked={av_cria_prog === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_cria_prog"} value={"2"} onChange={e => onChange(e)} checked={av_cria_prog === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_cria_prog"} value={"3"} onChange={e => onChange(e)} checked={av_cria_prog === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_cria_prog"} value={"4"} onChange={e => onChange(e)} checked={av_cria_prog === "4"} required/>
                                </span>
                            </label>


                            <label htmlFor="">
                                Corrige exercício verbalmente
                                <span>
                                      Faz
                                    <input type="radio" name={"av_corrige_ex"} value={"1"} onChange={e => onChange(e)} checked={av_corrige_ex === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_corrige_ex"} value={"2"} onChange={e => onChange(e)} checked={av_corrige_ex === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_corrige_ex"} value={"3"} onChange={e => onChange(e)} checked={av_corrige_ex === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_corrige_ex"} value={"4"} onChange={e => onChange(e)} checked={av_corrige_ex === "4"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Corrige exercício com demonstração
                                <span>
                                      Faz
                                    <input type="radio" name={"av_corrige_ex_demos"} value={"1"} onChange={e => onChange(e)} checked={av_corrige_ex_demos === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_corrige_ex_demos"} value={"2"} onChange={e => onChange(e)} checked={av_corrige_ex_demos === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_corrige_ex_demos"} value={"3"} onChange={e => onChange(e)} checked={av_corrige_ex_demos === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_corrige_ex_demos"} value={"4"} onChange={e => onChange(e)} checked={av_corrige_ex_demos === "4"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Demonstra exercício corretamente
                                <span>
                                      Faz
                                    <input type="radio" name={"av_ex_demos"} value={"1"} onChange={e => onChange(e)} checked={av_ex_demos === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_ex_demos"} value={"2"} onChange={e => onChange(e)} checked={av_ex_demos === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_ex_demos"} value={"3"} onChange={e => onChange(e)} checked={av_ex_demos === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_ex_demos"} value={"4"} onChange={e => onChange(e)} checked={av_ex_demos === "4"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Usa APP com cliente com cliente (marcação do treino na app)
                                <span>
                                      Faz
                                    <input type="radio" name={"av_app_cliente"} value={"1"} onChange={e => onChange(e)} checked={av_app_cliente === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_app_cliente"} value={"2"} onChange={e => onChange(e)} checked={av_app_cliente === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_app_cliente"} value={"3"} onChange={e => onChange(e)} checked={av_app_cliente === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_app_cliente"} value={"4"} onChange={e => onChange(e)} checked={av_app_cliente === "4"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Passa treino extra PT
                                <span>
                                      Faz
                                    <input type="radio" name={"av_extra_pt_cliente"} value={"1"} onChange={e => onChange(e)} checked={av_extra_pt_cliente === "1"} required/>
                                </span>
                                <span>
                                    Não Faz
                                    <input type="radio" name={"av_extra_pt_cliente"} value={"2"} onChange={e => onChange(e)} checked={av_extra_pt_cliente === "2"} required/>
                                </span>
                                <span>
                                    Com Dificuldade
                                    <input type="radio" name={"av_extra_pt_cliente"} value={"3"} onChange={e => onChange(e)} checked={av_extra_pt_cliente === "3"} required/>
                                </span>
                                <span>
                                    Não se Aplica
                                    <input type="radio" name={"av_extra_pt_cliente"} value={"4"} onChange={e => onChange(e)} checked={av_extra_pt_cliente === "4"} required/>
                                </span>
                            </label>

                            <button>GUARDAR</button>
                            <button id="delete" onClick={e => onDelete(e)}>APAGAR</button>
                        </form>
                    </div>

                </div>
            </div>

        </Fragment>
};
PersonalTrainersAdministracao.propTypes = {
    auth: PropTypes.object.isRequired,
    getPersonalTrainerAvaliacoes: PropTypes.func.isRequired,
    updatePersonalTRainer: PropTypes.func.isRequired,
    deletePersonalTRainer: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    personaltrainer: state.personaltrainer
});
export default connect(mapStateToProps, { getPersonalTrainerAvaliacoes, updatePersonalTRainer, deletePersonalTRainer })( PersonalTrainersAdministracao );
