/**
 * Created by danielsilva on 10/04/2020.
 */

export const GET_SOCIOS =        "GET_SOCIOS";
export const GET_SOCIOS_ERROR =     "GET_SOCIOS_ERROR";

export const GET_ROTA =        "GET_ROTA";
export const GET_ROTA_ERROR =     "GET_ROTA_ERROR";
export const GET_MANUTENCAO =        "GET_MANUTENCAO";
export const GET_MANUTENCAO_ERROR =     "GET_MANUTENCAO_ERROR";
export const GET_PERSONAL_TRAINER =        "GET_PERSONAL_TRAINER";
export const GET_PERSONAL_TRAINER_ERROR =     "GET_PERSONAL_TRAINER_ERROR";
export const GET_LIMPEZA =        "GET_LIMPEZA";
export const GET_LIMPEZA_ERROR =     "GET_LIMPEZA_ERROR";
export const GET_AULAS =        "GET_AULAS";
export const GET_AULAS_ERROR =     "GET_AULAS_ERROR";
export const GET_INSTRUTOR_SALA =        "GET_INSTRUTOR_SALA";
export const GET_INSTRUTOR_SALA_ERROR=     "GET_INSTRUTOR_SALA_ERROR";
export const GET_NUTRICAO =        "GET_NUTRICAO";
export const GET_NUTRICAO_ERROR=     "GET_NUTRICAO_ERROR";
export const GET_SOCIOS_NUTRICOA =        "GET_SOCIOS_NUTRICOA";
export const GET_SOCIOS_ERROR_NUTRICOA=     "GET_SOCIOS_ERROR_NUTRICOA";
export const GET_ACUPUNTURA =        "GET_ACUPUNTURA";
export const GET_ACUPUNTURA_ERROR=     "GET_ACUPUNTURA_ERROR";
