/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_PLANO_FORMACAO,
    GET_PLANO_FORMACAO_ERROR,
} from "./types";

export const getPlanoFormacao = () => async dispatch => {

    try {
        const res = await axios.post("/api/planoformacao");


        dispatch({
            type: GET_PLANO_FORMACAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PLANO_FORMACAO_ERROR,
        })
    }
}
export const updateOrInsertPlanoFormacao = ({  date, formacao, local, participantes, idplano  }) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = { date, formacao, local, participantes, idplano  };

        const res = await axios.post("/api/planoformacao/update", body, config);


        dispatch({
            type: GET_PLANO_FORMACAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PLANO_FORMACAO_ERROR,
        })
    }
}
export const deletePlanoFormacao = ({ idplano  }) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = { idplano  };

        const res = await axios.post("/api/planoformacao/delete", body, config);


        dispatch({
            type: GET_PLANO_FORMACAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PLANO_FORMACAO_ERROR,
        })
    }
}

