/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_PLANO_FORMACAO,
    GET_PLANO_FORMACAO_ERROR,
} from "./types";

export const getPlanoFormacao = ({id_clube}) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = { id_clube };
        const res = await axios.post("/api/planoformacao", body, config);


        dispatch({
            type: GET_PLANO_FORMACAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PLANO_FORMACAO_ERROR,
        })
    }
}
export const updateOrInsertPlanoFormacao = ({  date, formacao, local, participantes, idplano, id_clube  }) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = { date, formacao, local, participantes, idplano, id_clube  };

        const res = await axios.post("/api/planoformacao/update", body, config);


        dispatch({
            type: GET_PLANO_FORMACAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PLANO_FORMACAO_ERROR,
        })
    }
}
export const deletePlanoFormacao = ({ idplano, id_clube  }) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = { idplano , id_clube };

        const res = await axios.post("/api/planoformacao/delete", body, config);


        dispatch({
            type: GET_PLANO_FORMACAO,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PLANO_FORMACAO_ERROR,
        })
    }
}

