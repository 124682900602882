/**
 * Created by danielsilva on 10/04/2020.
 */

export const GET_AGENDAS = "GET_AGENDAS";
export const GET_AGENDAS_ERROR = "GET_AGENDAS_ERROR";






