/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { Calendar, CalendarControls } from 'react-yearly-calendar';
import { getFerias,insertFerias }  from "./actions/ferias";

const MapaFeriasRecepcao = ({ auth: {user} ,insertFerias , getFerias, ferias: { data }}) => {
    useEffect(() => {
        setFeriasData(
            {
                ...feriasData,
              //  selected_colaborador: data ? data.holidays ? data.holidays.user_id : 0  : 0,
                holidays_restantes: data ? data.holidays ? data.holidays.holidays_year : 0  : 0,
                holidays: data ? data.holidays ? JSON.parse(data.holidays.holidays) : false : false,
                error: data ? data.error ? data.error : 0  : 0,
                loading: false
            }
        );

        console.log("TIK TAK")
    },[data])

    const [feriasData, setFeriasData] = useState({
        modal: false,
        selected_colaborador: 0,
        holidays_restantes: 0,
        holidays: false,
        datede: null,
        dateate: null,
        error: false,
        loading: false
    });

    const { modal, selected_colaborador, holidays_restantes, holidays, datede, dateate, error, loading } = feriasData;
    const onChange = e => setFeriasData({...feriasData, [e.target.name]: e.target.value});
    const onChangeCol = async e => {
        setFeriasData({...feriasData, [e.target.name]: e.target.value, ["loading"]: true});

       await getFerias({selected_colaborador: e.target.value}).then((err,res)=> {
            setFeriasData({...feriasData, ["loading"]: false});
        })

    };
    const onSubmit = async e => {
        e.preventDefault();
        setFeriasData({...feriasData, ["loading"]: true});

        try {
            await insertFerias({ selected_colaborador,  datede, dateate}).then((err,res)=> {
                setFeriasData({...feriasData, ["loading"]: false});
            })
        } catch (e){

        }


    }
    const onOpenModal = async e => {
        e.preventDefault();
        setFeriasData({...feriasData, ["modal"]: modal ? false : true});

    }

    const customCSSclasses = {
        holidays: holidays ? holidays.ferias : [],
        feriados: [
            '2020-01-01',
            '2020-04-10',
            '2020-04-12',
            '2020-04-25',
            '2020-05-01',
            '2020-06-10',
            '2020-06-11',
            '2020-08-15',
            '2020-10-05',
            '2020-11-01',
            '2020-12-01',
            '2020-12-08',
            '2020-12-25',
        ],
        spring: {
            start: '2020-01-01',
            end: '2020-01-31'
        },
        summer: {
            start: '2020-09-01',
            end: '2020-09-30'
        },
        autumn: {
            start: '2020-09-23',
            end: '2020-12-21'
        },
        weekend: 'Sat,Sun',
        winter: day => day.isBefore( moment([2020,2,21]) ) || day.isAfter( moment([2020,11,21]))
    }
    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Gestão Clube >
                        <Link to={"/mapaferias"}>Mapa de Ferias</Link>
                    </div>
                    {loading ? (<div className={"loading-spinner"}></div>) : ("")}
                    <div className="table-inputs-filtros">
                        <label htmlFor="">
                            <h2>Mapa de Ferias</h2>
                        </label>
                        <label htmlFor="">
                            Colaborador:
                            <select name="selected_colaborador"   id="" onChange={ e => onChangeCol(e)}>
                                <option value="0">Seleciona um Colaborador</option>
                                {user && user.colaboradores.map((e) => (
                                    <option value={e.id}>{e.nome}</option>
                                ))}
                            </select>
                        </label>
                        <button  onClick={e => onOpenModal(e)} className={"new-btn"} style={{maxWidth: "200px", margin: "20px 0 0 0"}}>Novo</button>


                    </div>
                    <div className="calendar-mapa">
                        <span><strong>Dias de Ferias Restantes:</strong> {holidays_restantes}</span>
                        <Calendar
                            year={2020}
                            customClasses={customCSSclasses}
                            firstDayOfWeek={1}
                            showDaysOfWeek={true}
                        />
                    </div>
                    <div className={modal ? "new-lead-modal new-ferias active": "new-ferias new-lead-modal"}>
                         <span id="close-new-lead-modal" onClick={ e => onOpenModal(e)}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => onSubmit(e)}>
                            <label htmlFor="" className={"label-full"}>
                                <select name="selected_colaborador"  id="" onChange={ e => onChangeCol(e)}>
                                    <option value="0">Seleciona um Colaborador</option>
                                    {user && user.colaboradores.map((e) => (
                                        <option value={e.id}>{e.nome}</option>
                                    ))}
                                </select>
                            </label>
                            <label htmlFor="">
                                Data De
                                <input type="date" name={"datede"} value={datede} onChange={ e => onChange(e)}/>
                            </label>
                            <label htmlFor="">
                                Data Ate
                                <input type="date" name={"dateate"} value={dateate} onChange={ e => onChange(e)}/>
                            </label>
                            <button>GUARDAR</button>
                            <div className={error ? "alert alert-danger active": "alert alert-danger"}>{error}</div>
                        </form>
                    </div>

                </div>
            </div>

        </Fragment>
};
MapaFeriasRecepcao.propTypes = {
    auth: PropTypes.object.isRequired,
    getFerias: PropTypes.func,
    insertFerias: PropTypes.func,
};
const mapStateToProps = state => ({
    auth: state.auth,
    ferias: state.ferias
});
export default connect(mapStateToProps, { getFerias, insertFerias })( MapaFeriasRecepcao );
