/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_CHAMADAS,
    GET_CHAMADAS_ERROR,
} from "./types";

export const getChamadas = ({  date, id_clube  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {  date , id_clube };

    try {
        const res = await axios.post("/api/chamadas", body, config);

        dispatch({
            type: GET_CHAMADAS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_CHAMADAS_ERROR,
        })
    }
}
export const getChamadasByConsultor = ({  date, selected_colaborador, id_clube  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {  date, selected_colaborador , id_clube };

    try {
        const res = await axios.post("/api/chamadas", body, config);

        dispatch({
            type: GET_CHAMADAS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_CHAMADAS_ERROR,
        })
    }
}
export const insertChamadasByConsultor = ({ date, pos_realizadas, pos_atendidas, pos_marcadas, leads_realizadas, leads_atendidas, leads_marcadas, mi_realizadas, mi_atendidas, mi_marcadas, prospect_realizadas, prospect_atendidas, prospect_marcadas, desistencia_realizadas, desistencia_atendidas, desistencia_marcadas, parcerias_realizadas, parcerias_atendidas, parcerias_marcadas, followup_realizadas, followup_atendidas, followup_marcadas, selected_colaborador , id_clube}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { date, pos_realizadas, pos_atendidas, pos_marcadas, leads_realizadas, leads_atendidas, leads_marcadas, mi_realizadas, mi_atendidas, mi_marcadas, prospect_realizadas, prospect_atendidas, prospect_marcadas,
        desistencia_realizadas, desistencia_atendidas, desistencia_marcadas, parcerias_realizadas, parcerias_atendidas, parcerias_marcadas, followup_realizadas, followup_atendidas, followup_marcadas, selected_colaborador, id_clube };
    try {
        const res = await axios.post("/api/chamadas/new", body, config);

        dispatch({
            type: GET_CHAMADAS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_CHAMADAS_ERROR,
        })
    }
}
export const insertChamadas = ({ date, inscricoes, mensalidades, entradas_diarias, agua_vit, cartoes_socios, nutricao, seguros, rendas, pack_senhas, maq_vending, merch, id_clube }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { date, inscricoes, mensalidades, entradas_diarias, agua_vit, cartoes_socios, nutricao, seguros, rendas, pack_senhas, maq_vending, merch , id_clube};
    try {
        const res = await axios.post("/api/receitas/new", body, config);

        dispatch({
            type: GET_CHAMADAS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_CHAMADAS_ERROR,
        })
    }
}