/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getPontosCriticos, updateOrInsertPontosCriticos, deletePontosCriticos } from "./actions/pontoscriticos";
import Pagination from "../gestaocomercial/Pagination";

const PontosCriticosAdministracao = ({ auth: {user}, getPontosCriticos, updateOrInsertPontosCriticos, deletePontosCriticos, pontoscriticos: { data }}) => {
    useEffect(() => {
        getPontosCriticos({id_clube: 1});
    },[]);
    useLayoutEffect(() => {
        setPontosCriticosData({...pontosCriticosData,
            modal: data ? !!data.err : false,
            err: data ? data.err ?  data.err : false : false,
            clube_selected: data ? data.clube_selected ?  data.clube_selected : 1 : 1,
        });

    },[data]);

    const [pontosCriticosData, setPontosCriticosData] = useState({
        modal: false,
        date: moment().format("YYYY-MM-DD"),
        topico: "" ,
        status: 1,
        descricao: "",
        solucao: "",
        idponto: "",
        err: false,
        clube_selected: 1
    });

    const { clube_selected, err, modal, date, topico, status, descricao, solucao, idponto } = pontosCriticosData;
    const onChange = e => setPontosCriticosData({...pontosCriticosData, [e.target.name]: e.target.value});
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let resultados = data ? data.pontoscriticos ? data.pontoscriticos : [] : [];
    const currentPosts = resultados.slice(indexOfFirstPost,indexOfLastPost);
    const paginate = (e,pageNumber) => {
        e.preventDefault()
        setCurrentPage(pageNumber)
    }


     const onSubmit = async e => {
        e.preventDefault();

        try{
            await updateOrInsertPontosCriticos({  date, topico, status, descricao, solucao, idponto, id_clube:clube_selected });
        }catch (e){

        }

    }
    const onDeleteRow = async (e, id) => {
        e.preventDefault();
        if(e.target.classList.contains("row-delete"))
            try{
                await deletePontosCriticos({ idponto: id, id_clube: clube_selected });
            }catch (e){
                console.log(e)
            }
    }

    const onOpenModal = async e => {
        e.preventDefault();
        setPontosCriticosData({...pontosCriticosData,
            ["modal"]: modal ? false : true,
            ["date"]: moment().format("YYYY-MM-DD"),
            ["topico"]: "",
            ["status"]: "",
            ["descricao"]:  "",
            ["solucao"]:  "",
            ["idponto"]:  "",
        });
    }
    const onOpenModalValues = async (e,vdate, vtopico, vstatus, vdescricao, vsolucao, vidponto) => {
        e.preventDefault();

        if(!e.target.classList.contains("row-delete"))
            setPontosCriticosData({...pontosCriticosData,
                ["modal"]: modal ? false : true,
                ["date"]: moment(vdate).format("YYYY-MM-DD"),
                ["topico"]: vtopico,
                ["status"]: vstatus,
                ["descricao"]: vdescricao,
                ["solucao"]: vsolucao,
                ["idponto"]: vidponto,


            });
    }
    const onChangeCol = async e => {
        setPontosCriticosData({...pontosCriticosData, [e.target.name]: e.target.value});
        await getPontosCriticos({  id_clube:  e.target.value});
    };

    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Gestão Clube >
                        <Link to={"/pontoscriticos"}>Pontos Criticos</Link>
                    </div>
                    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="">
                            Clube:
                            <select name="clube_selected"   value={clube_selected} onChange={ e => onChangeCol(e)} style={{textTransform: "capitalize"}}>
                                {user && user.clubes.map((e) => (
                                    <option value={e.id} >{e.localidade}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <button  onClick={e => onOpenModal(e)} className={"new-btn"}>Novo</button>
                        <table className="table-format-one">
                            <thead>
                            <tr>
                                <th>Data</th>
                                <th>Topico</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                                {currentPosts && currentPosts.map(({date, topico, status, desc_prob, sol_prob, id}) => (
                                    <tr className={parseInt(status) === 1 ? "yellow-call" :  parseInt(status) === 2 ? "red-call" : parseInt(status) === 3 ? "green-call" : "to-call"} onClick={e => onOpenModalValues(e, date, topico, status, desc_prob, sol_prob, id)}>
                                        <td>
                                            { date ? (
                                                (parseInt(new Date(date).getDate())  < 10 ? ("0" + new Date(date).getDate()) : new Date(date).getDate())
                                                + "/" +
                                                (parseInt(new Date(date).getMonth() + 1)  < 10 ? ("0" + (new Date(date).getMonth() +1)) : new Date(date).getMonth() +1)
                                                + "/" +
                                                (parseInt(new Date(date).getFullYear()))
                                            ): ("") }
                                        </td>
                                        <td>{topico}</td>
                                        <td>
                                            {((status) => {
                                                if(parseInt(status) === 1)
                                                    return "Pendente"
                                                else  if(parseInt(status) === 2)
                                                    return "Rejeitado"
                                                else
                                                    return "Aprovado"
                                             })(status)}
                                        </td>
                                        <td onClick={e => onDeleteRow(e, id)} className={"row-delete"}>
                                                X
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination postsPerPAge={postsPerPage} totalPosts={resultados.length} paginate={paginate} currentPage={currentPage}/>

                    <div className={modal ? "new-lead-modal new-colaborador  new-colaborador-reunioes active": "new-colaborador new-colaborador-reunioes new-lead-modal"}>
                         <span id="close-new-lead-modal" onClick={ e => onOpenModal(e)}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => onSubmit(e)}>
                            <label htmlFor="">
                                Data
                                <input type="date" name={"date"} value={date} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                Topico
                                <input type="text" name={"topico"} value={topico} onChange={e => onChange(e)} required/>
                            </label>
                            <label htmlFor="">
                                Status
                                <select name={"status"} value={status} onChange={e => onChange(e)} required>
                                    <option value="">Seleciona uma opção</option>
                                    <option value="1">Pendente</option>
                                    <option value="2">Rejeitado</option>
                                    <option value="3">Aprovado</option>
                                </select>
                            </label>
                            <label htmlFor="">
                                Descrição do Problema
                                <textarea name={"descricao"} value={descricao} onChange={e => onChange(e)} cols="10" rows="10" required></textarea>
                            </label>
                            <label htmlFor="">
                                Solução do Problema
                                <textarea name={"solucao"} value={solucao} onChange={e => onChange(e)} cols="10" rows="10" required></textarea>
                            </label>
                            <button className={"new-btn"}>GUARDAR</button>

                            <div className={err ? "alert alert-danger active": "alert alert-danger"}>{err}</div>

                        </form>
                    </div>

                </div>
            </div>

        </Fragment>
};
PontosCriticosAdministracao.propTypes = {
    auth: PropTypes.object.isRequired,
    getPontosCriticos: PropTypes.func.isRequired,
    updateOrInsertPontosCriticos: PropTypes.func.isRequired,
    deletePontosCriticos: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    pontoscriticos: state.pontoscriticos
});
export default connect(mapStateToProps, { getPontosCriticos, updateOrInsertPontosCriticos, deletePontosCriticos })( PontosCriticosAdministracao );
