/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/rotapt.css";
import moment from "moment";
import 'moment/locale/pt'  // without this line it didn't work

import { getRotaPT, currentDataPrev, insertDisponibilidade, deleteAppointment, updateMarcacao } from "./actions/rotapt";
moment.locale('pt-pt');
const RotaPTPersonalTrainer = ({ auth: {user}, rotapt: { data }, getRotaPT, currentDataPrev, insertDisponibilidade, deleteAppointment, updateMarcacao }) => {
    useLayoutEffect(() => {
        getRotaPT({id_clube: 1, id_pt: 0})
    },[]);

    useEffect(() => {
        setSRotaPTData(
            {
                ...rotaPTData,
                modal: data ? data.payload ? data.payload.error ? true : false : false: false,
                modalAv: data ? data.payload ? data.payload.error ? true : false : false: false,
                alert: data ? data.payload ? data.payload.error : 0  : 0,
                loading: false
	        
            }
        );
    },[data]);

    const [rotaPTData, setSRotaPTData] = useState({
        marcacaoID: "",
        currentData: new Date(),
        modal: false,
        modalAv: false,
        modalType: "",
        time_appoint: "",
        nome_pt: "",
        n_socio: "",
        date: "",
        date_fim: "",
        nome_client: "",
        marcacao_de: "",
        marcacao_ate: "",
        repetir_semans: "",
        telemovel: "",
        status_av: "",
        alert: false
    });

    const { status_av, telemovel, modalAv, repetir_semans, marcacao_de, marcacao_ate, marcacaoID, date, currentData, modal, modalType, time_appoint, nome_pt, n_socio, date_fim, nome_client, alert } = rotaPTData;


    let diaSemanaAgendaText = new Date(currentData)
    let diaSemanaAgendaTextDomingo = new Date(currentData),
        diaSemanaAgendaTextSegunda= new Date(currentData),
        diaSemanaAgendaTextTerca= new Date(currentData),
        diaSemanaAgendaTextQuarta= new Date(currentData),
        diaSemanaAgendaTextQuinta= new Date(currentData),
        diaSemanaAgendaTextSexta= new Date(currentData),
        diaSemanaAgendaTextSabado= new Date(currentData);

    if(diaSemanaAgendaText.getDay() === 0) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 6)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 5)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() - 4)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() - 3)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() - 2)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() - 1)
    }
    if(diaSemanaAgendaText.getDay() === 1) {
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() + 1)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() + 2)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() + 3)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() + 4)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 5)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 6)
    }
    if(diaSemanaAgendaText.getDay() === 2) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 1)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() + 1)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() + 2)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() + 3)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 4)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 5)
    }
    if(diaSemanaAgendaText.getDay() === 3) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 2)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 1)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() + 1)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() + 2)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 3)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 4)
    }
    if(diaSemanaAgendaText.getDay() === 4) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 3)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 2)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() - 1)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() + 1)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 2)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 3)
    }
    if(diaSemanaAgendaText.getDay() === 5) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 4)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 3)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() - 2)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() - 1)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 1)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 2)
    }
    if(diaSemanaAgendaText.getDay() === 6) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 5)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 4)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() - 3)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() - 2)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() - 1)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() +1 )
    }

    const onChange = e => setSRotaPTData({...rotaPTData, [e.target.name]: e.target.value});
    const onCheckboxChange = (e) => {
        setSRotaPTData({...rotaPTData, [e.target.name]: e.target.checked});
    }
    const onChangeDate = async e => {
        setSRotaPTData({...rotaPTData, [e.target.name]: e.target.value});
        console.log(e.target.value)

    };
    const onSubmit = async e => {
        e.preventDefault();

        try {
            await insertDisponibilidade({repetir_semans, marcacao_de, marcacao_ate, currentData});
            setSRotaPTData({...rotaPTData,
                ["modal"]: false,
                ["modalType"]: "",
                ["nome_pt"]: "",
                ["n_socio"]:  "",
                ["date"]:  "",
                ["date_fim"]: "",
                ["nome_client"]: "",
                ["marcacaoID"]: "",
            });
        } catch (e) {
            setSRotaPTData(
                {
                    ...rotaPTData,
                    alert: "Erro ao Inserir Disponibilidade Verifica se a hora de 'ate' não é menor que a hora de 'de'"
                }
            );
        }
    }
    const onDelete = async e => {
        e.preventDefault();

        try {
            await deleteAppointment({ marcacaoID, prevDate: currentData});
            setSRotaPTData({...rotaPTData,
                ["modal"]: false,
                ["modalType"]: "",
                ["nome_pt"]: "",
                ["n_socio"]:  "",
                ["marcacao_de"]:  "",
                ["marcacao_ate"]: "",
                ["nome_client"]: "",
                ["marcacaoID"]: "",
            });

        } catch (e) {
            setSRotaPTData(
                {
                    ...rotaPTData,
                    alert: "Erro ao apagar appointment"
                }
            );
        }
    }
    const onChangeCurrentDataPrev = async e => {
        e.preventDefault();
        setSRotaPTData(
            {
                ...rotaPTData,
                currentData: new Date(currentData.setDate(currentData.getDate() - 7))
            }
        );

        try {
            await currentDataPrev({ prevDate: currentData }).then((res) =>{
                console.log(res)
                // window.location.reload();
            });
        }catch(e){

        }
    };
    const onChangeCurrentDataNext = async e => {
        e.preventDefault();
        setSRotaPTData(
            {
                ...rotaPTData,
                currentData: new Date(currentData.setDate(currentData.getDate() + 7))
            }
        );

        try {
         await currentDataPrev({prevDate: currentData}).then((res) =>{
                console.log(res)
                // window.location.reload();
            });
        }catch(e){

        }
    };
    const onScroll = e => {
        let agendaId = document.getElementById("agenda-comercial-container");
        let agendaScrollId = document.getElementById("agenda-comercial-container-scroll");
        agendaScrollId.width = agendaId.width;
        agendaId.scrollLeft = agendaScrollId.scrollLeft;
    }
    const onMainScroll = e => {
        let agendaId = document.getElementById("agenda-comercial-container");
        let agendaScrollId = document.getElementById("agenda-comercial-container-scroll");
        agendaScrollId.width = agendaId.width;

        agendaScrollId.scrollLeft = agendaId.scrollLeft;
    }
    const onOpenModal = async (e, type, nome_pt, n_socio, date, date_fim, nome_client,marcacaoID) => {
        e.preventDefault();

        let reactDate = changeTimezone(new Date(date), "Europe/Lisbon")

        let reactDateFim =  changeTimezone(new Date(date_fim), "Europe/Lisbon")




        console.log({
            date,
            date_fim,
            reactDate,
            reactDateFim
        })
         setSRotaPTData({...rotaPTData,
            ["modal"]: true,
            ["modalType"]: type,
            ["nome_pt"]: nome_pt ? nome_pt : "",
            ["n_socio"]: n_socio ? n_socio: "",
            ["marcacao_de"]: reactDate  ? moment(reactDate).format("YYYY-MM-DDTHH:mm:ss")  : "",
            ["marcacao_ate"]: reactDateFim  ? moment(reactDateFim).format("YYYY-MM-DDTHH:mm:ss")  : "",
            ["nome_client"]: nome_client ? nome_client : "",
            ["marcacaoID"]: marcacaoID ? marcacaoID : "",

        });

    }
    const onCloseModal = async (e, type) => {
        e.preventDefault();
        setSRotaPTData({...rotaPTData, ["modal"]: modal ? false : true });

    }
    const onOpenModalAv = async (e, type, nome_pt, n_socio, date, date_fim, nome_client, marcacaoID, telemovel, status_av) => {
        e.preventDefault();

        setSRotaPTData({...rotaPTData,
            ["modalAv"]: true,
            ["modalType"]: type,
            ["status_av"]: status_av ? status_av : "",
            ["n_socio"]: n_socio ? n_socio: "",
            ["nome_client"]: nome_client ? nome_client : "",
            ["marcacaoID"]: marcacaoID ? marcacaoID : "",
            ["telemovel"]: telemovel ? telemovel : "",
        });

    }
    const onCloseModalAv = async (e, type) => {
        e.preventDefault();
        setSRotaPTData({...rotaPTData, ["modalAv"]: modalAv ? false : true });

    }
    const onSubmitAv = async e => {
        e.preventDefault();

        try {
            await updateMarcacao({marcacaoID, status_av, currentData});
            setSRotaPTData({...rotaPTData,
                ["modalAv"]: true,
                ["modalType"]: "",
                ["status_av"]: "",
                ["n_socio"]: "",
                ["nome_client"]: "",
                ["marcacaoID"]: "",
                ["telemovel"]: "",
            });
        } catch (e) {

        }
    }

    function changeTimezone(date, ianatz) {
        let invdate = new Date(date.toLocaleString('en-US', { timeZone: ianatz }));
        // let diff = date.getTime() - invdate.getTime();
        // return new Date(date.getTime() + diff);
	return invdate;
    }

    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Serviço ao Cliente >
                        <Link to={"/rota_pt"}>Rota PT</Link>
                    </div>

                    <button  onClick={e => onOpenModal(e)} className={"new-btn"}>Adicionar Disponibilidade</button>

		    
		    <div className="agenda-comercial-container-tot"  onScroll={ e => onMainScroll(e)} >
                       <div className="agenda-column">
                                <h4>Tot Seg</h4>
			        <h3> {data ? (
                                data.payload.segundaMarcacoesTot.length > 0 ? (
                                        data.payload.segundaMarcacoesTot.map((e) => (
                                            e.total
                                        ))
                                    ):
                                    (
                                         <>0</>
                                    )
                            ) : ("") }</h3>
                                
                       </div> 
                       <div className="agenda-column">
                                <h4>Tot Ter</h4>
				<h3> {data ? (
                                data.payload.tercaMarcacoesTot.length > 0 ? (
                                        data.payload.tercaMarcacoesTot.map((e) => (
                                            e.total
                                        ))
                                    ):
                                    (
                                         <>0</>
                                    )
                            ) : ("") }</h3>
		       </div>
		       <div className="agenda-column">
                                <h4>Tot Qua</h4>
				<h3> {data ? (
                                data.payload.quartaMarcacoesTot.length > 0 ? (
                                        data.payload.quartaMarcacoesTot.map((e) => (
                                            e.total
                                        ))
                                    ):
                                    (
                                         <>0</>
                                    )
                            ) : ("") }</h3>
                       </div> 
                       <div className="agenda-column">
                                <h4>Tot Qui</h4>
				<h3> {data ? (
                                data.payload.quintaMarcacoesTot.length > 0 ? (
                                        data.payload.quintaMarcacoesTot.map((e) => (
                                            e.total
                                        ))
                                    ):
                                    (
                                         <>0</>
                                    )
                            ) : ("") }</h3>
		       </div>
		       <div className="agenda-column">
                                <h4>Tot Sex</h4>
				<h3> {data ? (
                                data.payload.sextaMarcacoesTot.length > 0 ? (
                                        data.payload.sextaMarcacoesTot.map((e) => (
                                            e.total
                                        ))
                                    ):
                                    (
                                         <>0</>
                                    )
                            ) : ("") }</h3>
                       </div> 
                       <div className="agenda-column">
                                <h4>Tot Sab</h4>
				<h3> {data ? (
                                data.payload.sabadoMarcacoesTot.length > 0 ? (
                                        data.payload.sabadoMarcacoesTot.map((e) => (
                                            e.total
                                        ))
                                    ):
                                    (
                                         <>0</>
                                    )
                            ) : ("") }</h3>
		       </div>
		       <div className="agenda-column">
                                <h4>Tot Dom</h4>
				<h3> {data ? (
                                data.payload.domingoMarcacoesTot.length > 0 ? (
                                        data.payload.domingoMarcacoesTot.map((e) => (
                                            e.total
                                        ))
                                    ):
                                    (
                                        <>0</>
                                    )
                            ) : ("") }</h3>
		       </div>
		    </div>

                    <div className="agenda-comercial">
                        {/* <div className="agenda-title">
                            <button className="active">AS DISPONIBILIDADES DE HOJE</button>
                            <button>AS AVALIAÇÕES DE HOJE</button>
                        </div> */}
                        <div className="buttons-days">
                            <a href="" onClick={ e => onChangeCurrentDataPrev(e) }>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-left"
                                     className="svg-inline--fa fa-chevron-left fa-w-8" role="img"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path fill="currentColor"
                                          d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"></path>
                                </svg>
                                Semana Anterior
                            </a>
                            <a href="" onClick={ e => onChangeCurrentDataNext(e) }>
                                Semana Seguinte
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-right"
                                     className="svg-inline--fa fa-chevron-right fa-w-8" role="img"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path fill="currentColor"
                                          d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path>
                                </svg>

                            </a>
                        </div>
		        <div className="current-mes-week">
                            {((diaSemanaAgendaTextSegunda) => {
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 1)
                                    return "Janeiro";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 2)
                                    return "Fevereiro";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 3)
                                    return "Março";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 4)
                                    return "Abril";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 5)
                                    return "Maio";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 6)
                                    return "Junho";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 7)
                                    return "Julho";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 8)
                                    return "Agosto";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 9)
                                    return "Setembro";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 10)
                                    return "Outrubro";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 11)
                                    return "Novembro";
                                if( (diaSemanaAgendaTextSegunda.getMonth() +1) === 12)
                                    return "Dezembro";

                            })(diaSemanaAgendaTextSegunda)} | Semana de dia  {diaSemanaAgendaTextSegunda.getDate()} - {diaSemanaAgendaTextDomingo.getDate()}
                        </div>
                        <div id="agenda-comercial-container-scroll" className="agenda-comercial-container-scrol" onScroll={ e => onScroll(e)}>
                            <div className="agenda-comercial-container-div"></div>
                        </div>
                        <div id="agenda-comercial-container" className="agenda-comercial-container" onScroll={ e => onMainScroll(e)}>

                            <div className="agenda-column">
                                <h3>Segunda / {diaSemanaAgendaTextSegunda.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="segunda">
                                    {data && data.payload.segundaValues.map((element) => (
                                        <div className="agenda-item" onClick={e => onOpenModal(e, "livre", element.nome_pt,  element.n_socio, element.date, element.date_fim, null, element.id)}>
                                            <div className="name-type">
                                                <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                    (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                    + ":" +
                                                    (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                                }
                                                </span>
                                                <span className="type-title"><p>{element.nome_pt} </p></span>
                                            </div>
                                        </div>
                                    ))}

                                    {data && data.payload.segundaMarcacoesValues.map((element) => (
                                        <div className={parseInt(element.status_av) === 1 ?  "realizada-class agenda-marcacao " : parseInt(element.status_av) === 2 ?  "nao-ralizada-class agenda-marcacao " : parseInt(element.status_av) === 3 ?  "reagendada-class agenda-marcacao" : "agenda-marcacao"  } onClick={e => onOpenModalAv(e, "marcacao", element.nome_pt,  element.n_socio, element.date, element.date_fim, element.client_name, element.id,element.telemovel, element.status_av)}>
                                            <div className="name-type">
                                           <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                               + ":" +
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                           }
                                                </span>
                                                <span className="type-title"><p>{element.client_name} / {element.nome_pt}</p></span>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Terça / {diaSemanaAgendaTextTerca.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="terca">
                                    {data && data.payload.tercaValues.map((element) => (
                                        <div className="agenda-item" onClick={e => onOpenModal(e, "livre", element.nome_pt,  element.n_socio, element.date, element.date_fim, null, element.id)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.nome_pt} </p></span>
                                            </div>
                                        </div>
                                    ))}

                                    {data && data.payload.tercaMarcacoesValues.map((element) => (
                                        <div className={parseInt(element.status_av) === 1 ?  "realizada-class agenda-marcacao " : parseInt(element.status_av) === 2 ?  "nao-ralizada-class agenda-marcacao " : parseInt(element.status_av) === 3 ?  "reagendada-class agenda-marcacao" : "agenda-marcacao"  } onClick={e => onOpenModalAv(e, "marcacao", element.nome_pt,  element.n_socio, element.date, element.date_fim, element.client_name, element.id,element.telemovel, element.status_av)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.client_name} / {element.nome_pt}</p></span>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Quarta / {diaSemanaAgendaTextQuarta.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="quarta">
                                    {data && data.payload.quartaValues.map((element) => (
                                        <div className="agenda-item" onClick={e => onOpenModal(e, "livre", element.nome_pt,  element.n_socio, element.date, element.date_fim, null, element.id)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.nome_pt} </p></span>
                                            </div>
                                        </div>
                                    ))}

                                    {data && data.payload.quartaMarcacoesValues.map((element) => (
                                        <div className={parseInt(element.status_av) === 1 ?  "realizada-class agenda-marcacao " : parseInt(element.status_av) === 2 ?  "nao-ralizada-class agenda-marcacao " : parseInt(element.status_av) === 3 ?  "reagendada-class agenda-marcacao" : "agenda-marcacao"  } onClick={e => onOpenModalAv(e, "marcacao", element.nome_pt,  element.n_socio, element.date, element.date_fim, element.client_name, element.id,element.telemovel, element.status_av)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.client_name} / {element.nome_pt}</p></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Quinta / {diaSemanaAgendaTextQuinta.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="quinta">
                                    {data && data.payload.quintaValues.map((element) => (
                                        <div className="agenda-item" onClick={e => onOpenModal(e, "livre", element.nome_pt,  element.n_socio, element.date, element.date_fim, null, element.id)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.nome_pt} </p></span>
                                            </div>
                                        </div>
                                    ))}

                                    {data && data.payload.quintaMarcacoesValues.map((element) => (
                                        <div className={parseInt(element.status_av) === 1 ?  "realizada-class agenda-marcacao " : parseInt(element.status_av) === 2 ?  "nao-ralizada-class agenda-marcacao " : parseInt(element.status_av) === 3 ?  "reagendada-class agenda-marcacao" : "agenda-marcacao"  } onClick={e => onOpenModalAv(e, "marcacao", element.nome_pt,  element.n_socio, element.date, element.date_fim, element.client_name, element.id,element.telemovel, element.status_av)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.client_name} / {element.nome_pt}</p></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Sexta / {diaSemanaAgendaTextSexta.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="sexta">
                                    {data && data.payload.sextaValues.map((element) => (
                                        <div className="agenda-item" onClick={e => onOpenModal(e, "livre", element.nome_pt,  element.n_socio, element.date, element.date_fim, null, element.id)}>
                                            <div className="name-type">
                                           <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                               + ":" +
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                           }
                                                </span>
                                                <span className="type-title"><p>{element.nome_pt} </p></span>
                                            </div>
                                        </div>
                                    ))}

                                    {data && data.payload.sextaMarcacoesValues.map((element) => (
                                        <div className={parseInt(element.status_av) === 1 ?  "realizada-class agenda-marcacao " : parseInt(element.status_av) === 2 ?  "nao-ralizada-class agenda-marcacao " : parseInt(element.status_av) === 3 ?  "reagendada-class agenda-marcacao" : "agenda-marcacao"  } onClick={e => onOpenModalAv(e, "marcacao", element.nome_pt,  element.n_socio, element.date, element.date_fim, element.client_name, element.id,element.telemovel, element.status_av)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.client_name} / {element.nome_pt}</p></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Sabado / {diaSemanaAgendaTextSabado.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="sabado">
                                    {data && data.payload.sabadoValues.map((element) => (
                                        <div className="agenda-item" onClick={e => onOpenModal(e, "livre", element.nome_pt,  element.n_socio, element.date, element.date_fim, null, element.id)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.nome_pt}</p></span>
                                            </div>
                                        </div>
                                    ))}

                                    {data && data.payload.sabadoMarcacoesValues.map((element) => (
                                        <div className={parseInt(element.status_av) === 1 ?  "realizada-class agenda-marcacao " : parseInt(element.status_av) === 2 ?  "nao-ralizada-class agenda-marcacao " : parseInt(element.status_av) === 3 ?  "reagendada-class agenda-marcacao" : "agenda-marcacao"  } onClick={e => onOpenModalAv(e, "marcacao", element.nome_pt,  element.n_socio, element.date, element.date_fim, element.client_name, element.id,element.telemovel, element.status_av)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.client_name} / {element.nome_pt}</p></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="agenda-column">
                                <h3>Domingo / {diaSemanaAgendaTextDomingo.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="domingo">
                                    {data && data.payload.domingoValues.map((element) => (
                                        <div className="agenda-item" onClick={e => onOpenModal(e, "livre", element.nome_pt,  element.n_socio, element.date, element.date_fim, null, element.id)}>
                                            <div className="name-type">
                                           <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                               + ":" +
                                               (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                           }
                                                </span>
                                                <span className="type-title"><p>{element.nome_pt} </p></span>
                                            </div>
                                        </div>
                                    ))}

                                    {data && data.payload.domingoMarcacoesValues.map((element) => (
                                        <div className={parseInt(element.status_av) === 1 ?  "realizada-class agenda-marcacao " : parseInt(element.status_av) === 2 ?  "nao-ralizada-class agenda-marcacao " : parseInt(element.status_av) === 3 ?  "reagendada-class agenda-marcacao" : "agenda-marcacao"  } onClick={e => onOpenModalAv(e, "marcacao", element.nome_pt,  element.n_socio, element.date, element.date_fim, element.client_name, element.id,element.telemovel, element.status_av)}>
                                            <div className="name-type">
                                            <span className="name-title">
                                                    {
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getHours() )
                                                        + ":" +
                                                        (parseInt(changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date), "Europe/Lisbon").getMinutes() )
                                                    } - {
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getHours() )
                                                + ":" +
                                                (parseInt(changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )  < 10 ? ("0" + changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() ) : changeTimezone(new Date(element.date_fim), "Europe/Lisbon").getMinutes() )
                                            }
                                                </span>
                                                <span className="type-title"><p>{element.client_name} / {element.nome_pt}</p></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={modal ? "new-lead-modal new-appointment active " + modalType: "new-appointment new-lead-modal " + modalType}>
                         <span id="close-new-lead-modal" onClick={ e => onCloseModal(e)}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => {modalType === "livre" ? (
                            onDelete(e)
                        ) : (
                            onSubmit(e)
                        )} } noValidate>
                            {modalType === "livre" ? (
                                    <>
                                        <label htmlFor="">
                                            De:
                                            <input type="datetime-local" name="marcacao_de" value={marcacao_de} onChange={e => onChange(e)}/>
                                        </label>

                                        <label htmlFor="">
                                            Até:
                                            <input type="datetime-local" name="marcacao_ate" value={marcacao_ate} onChange={e => onChange(e)}/>
                                        </label>

                                    </>
                            ) : (
                                <>
                                    <label htmlFor="">
                                        De:
                                        <input type="datetime-local" name="marcacao_de" value={marcacao_de} onChange={e => onChange(e)}/>
                                    </label>

                                    <label htmlFor="">
                                        Até:
                                        <input type="datetime-local" name="marcacao_ate" value={marcacao_ate} onChange={e => onChange(e)}/>
                                    </label>

                                </>
                            )}
                            {modalType === "livre" ? ("") : (
                                <p>
                                    <label htmlFor="">
                                        Repetir esta Hora Todas as Semanas
                                        <input type="checkbox" name="repetir_semans" value={repetir_semans} onChange={e => onCheckboxChange(e)} checked={repetir_semans} style={{"height": "30px"}}/>
                                    </label>
                                </p>
                            )}
                            {modalType === "livre" ? (<button>Apagar</button>) : (<button>Guardar</button>)}
                            {alert ? (<div className={"alert alert-danger active"}>{alert}</div>): ("")}
                        </form>
                    </div>
                    <div className={modalAv ? "new-lead-modal new-appointment active " + modalType: "new-appointment new-lead-modal " + modalType}>
                         <span id="close-new-lead-modal" onClick={ e => onCloseModalAv(e)}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => onSubmitAv(e)} noValidate>
                            <label htmlFor="">Nome: {nome_client}</label>
                            <label htmlFor="">Contacto: {telemovel}</label>
                            <label htmlFor="">NªSocio: {n_socio}</label>
                            <label htmlFor="" style={{"flexDirection": "column"}}>
                                Status Avaliação
                                <span>Reagendada
                                    <input type="radio" name={"status_av"} value={"3"} onChange={e => onChange(e)} checked={status_av === "3"}  required style={{"height": "30px"}} />
                                </span>
                                <span>Não Realizada
                                    <input type="radio"  name={"status_av"} value={"2"} onChange={e => onChange(e)} checked={status_av === "2"}  required style={{"height": "30px"}} />
                                </span>
                                <span>Realizada
                                    <input type="radio"  name={"status_av"} value={"1"} onChange={e => onChange(e)} checked={status_av === "1"}  required style={{"height": "30px"}} />
                                </span>
                            </label>
                            <button>Guardar</button>
                            {alert ? (<div className={"alert alert-danger active"}>{alert}</div>): ("")}
                        </form>
                    </div>

                </div>
            </div>
        </Fragment>
};
RotaPTPersonalTrainer.propTypes = {
    auth: PropTypes.object.isRequired,
    getRotaPT: PropTypes.func.isRequired,
    currentDataPrev: PropTypes.func.isRequired,
    insertDisponibilidade: PropTypes.func.isRequired,
    deleteAppointment: PropTypes.func.isRequired,
    updateMarcacao: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    rotapt: state.rotapt
});
export default connect(mapStateToProps, { getRotaPT, currentDataPrev, insertDisponibilidade, deleteAppointment, updateMarcacao })( RotaPTPersonalTrainer );
