/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_RELATORIO_ROTAPT,
    GET_RELATORIO_ROTAPT_ERROR,
} from "./types";

export const getRelatorioRotaPT = ({ filtro_de, filtro_ate, select_type, instrutor_select, id_clube }) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = { filtro_de, filtro_ate, select_type, instrutor_select, id_clube };
        const res = await axios.post("/api/relatoriorotapt",body , config);


        dispatch({
            type: GET_RELATORIO_ROTAPT,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_RELATORIO_ROTAPT_ERROR,
        })
    }
}

