/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getAllSocios, updateSocios, getSociosByFiltroText } from "./actions/socios";
import Pagination from "../gestaocomercial/Pagination";
const SociosDirectorClube = ({ auth: {user}, socios: { data }, getAllSocios, updateSocios, getSociosByFiltroText}) => {
    useEffect(() => {
        getAllSocios({id_clube: 1})
    },[]);
    useLayoutEffect(() => {
        setSociosData({...sociosData,
            modal: data ? !!data.err : false,
            err: data ? data.err ?  data.err : false : false,
            loading:false,
        });

    },[data]);
    const [sociosData, setSociosData] = useState({
        clube_selected: 1,
        date:  moment().format("YYYY-MM-DD"),
        modal: false,
        nome: "",
        n_socio: "",
        email: "",
        telemovel: "",
        aniversario: "",
        id: "",
        id_clube: "",
        nsocio_hidden: "519803",

        date__avaliacao: "",
        _avaliacao_agendado: "",
        _avaliacao_concluido: true,
        _primeiro_treino_date: "",
        _primeiro_treino_agendado: "",
        _primeiro_treino_concluido: "",
        _aula_grupo_agendado: "",
        _aula_grupo_concluido: "",
        _aula_grupo_date: "",
        _acupuntura_agendado: "",
        _acupuntura_concluido: "",
        _acupuntura_date: "",
        oferta_agua: false,

        _primeira_reavaliacao_agendado: "",
        _primeira_reavaliacao_concluido: "",
        _primeira_reavaliacao_date: "",
        _segundo_treino_date: "",
        _segundo_treino_agendado: "",
        _segundo_treino_concluido: "",
        _segunda_aula_grupo_agendado: "",
        _segunda_aula_grupo_concluido: "",
        _segunda_aula_grupo_date: "",
        _meeting_cs_agendado: "",
        _meeting_cs_concluido: "",
        _meeting_cs_date: "",
        oferta_consulta_nutricao_alimentar: false,



        _segunda_reavaliacao_agendado: "",
        _segunda_reavaliacao_concluido: "",
        _segunda_reavaliacao_date: "",
        _treino_flash_facil_agendado: "",
        _treino_flash_facil_concluido: "",
        _treino_flash_facil_date: "",
        _speed_classes_agendado: "",
        _speed_classes_concluido: "",
        _speed_classes_date: "",
        _dc_agendado: "",
        _dc_concluido: "",
        _dc_date: "",
        oferta_desconto_voucher:"",
        observacoes: "",




        _treino_experimental_agendado: "on",
        _treino_experimental_concluido: "on",
        _treino_experimental_date: "2019-07-08",
        err: false,
        loading:true,
        nome_socio_filtro: "",
        n_socio_filtro: "",
        telemovel_socio_filtro: ""

    });
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);

    const { clube_selected,nome_socio_filtro,n_socio_filtro, telemovel_socio_filtro, err,loading, date, modal, nome, n_socio, email, telemovel, aniversario, id,
        date__avaliacao, _avaliacao_agendado, _avaliacao_concluido,
        _primeiro_treino_date, _primeiro_treino_agendado, _primeiro_treino_concluido,
        _aula_grupo_agendado, _aula_grupo_concluido, _aula_grupo_date,
        _acupuntura_agendado,  _acupuntura_concluido, _acupuntura_date,
        oferta_agua,

        _primeira_reavaliacao_agendado, _primeira_reavaliacao_concluido, _primeira_reavaliacao_date,
        _segundo_treino_date, _segundo_treino_agendado, _segundo_treino_concluido,
        _segunda_aula_grupo_agendado, _segunda_aula_grupo_concluido, _segunda_aula_grupo_date,
        _meeting_cs_agendado, _meeting_cs_concluido, _meeting_cs_date,
        oferta_consulta_nutricao_alimentar,


        _segunda_reavaliacao_agendado, _segunda_reavaliacao_concluido, _segunda_reavaliacao_date,
        _treino_flash_facil_agendado, _treino_flash_facil_concluido, _treino_flash_facil_date,
        _speed_classes_agendado, _speed_classes_concluido, _speed_classes_date,
        _dc_agendado, _dc_concluido, _dc_date,
        oferta_desconto_voucher, observacoes

    } = sociosData;
    const onChange = e => setSociosData({...sociosData, [e.target.name]: e.target.value});
    const onCheckboxChange = (e) => {
        setSociosData({...sociosData, [e.target.name]: e.target.checked});

    }


    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let resultados = data ? data.socios ? data.socios : [] : [];
    const currentPosts = resultados.slice(indexOfFirstPost,indexOfLastPost);
    const paginate = (e,pageNumber) => {
        e.preventDefault()
        setCurrentPage(pageNumber)
    }
    const onChangeDate = async e => {
        setSociosData({...sociosData, [e.target.name]: e.target.value});
        console.log(e.target.value)

    };
    const onOpenModal = async e => {
        e.preventDefault();
        setSociosData({...sociosData,
            ["modal"]: modal ? false : true,
            ["nome"]: "",
            ["n_socio"]: "",
            ["email"]: "",
            ["telemovel"]: "",
            ["aniversario"]: "",
            ["id"]: "",
            ["date__avaliacao"]: "",
            ["_avaliacao_agendado"]: "",
            ["_avaliacao_concluido"]:  "",
            ["_primeiro_treino_date"]: "",
            ["_primeiro_treino_agendado"]: "",
            ["_primeiro_treino_concluido"]: "",
            ["_aula_grupo_agendado"]: "",
            ["_aula_grupo_concluido"]: "",
            ["_aula_grupo_date"]: "",
            ["_acupuntura_agendado"]: "",
            ["_acupuntura_concluido"]: "",
            ["_acupuntura_date"]: "",
            ["oferta_agua"]: "",


            ["_primeira_reavaliacao_agendado"]: "",
            ["_primeira_reavaliacao_concluido"]: "",
            ["_primeira_reavaliacao_date"]: "",
            ["_segundo_treino_date"]: "",
            ["_segundo_treino_agendado"]:  "",
            ["_segundo_treino_concluido"]:  "",
            ["_segunda_aula_grupo_agendado"]:  "",
            ["_segunda_aula_grupo_concluido"]: "",
            ["_segunda_aula_grupo_date"]: "",
            ["_meeting_cs_agendado"]: "",
            ["_meeting_cs_concluido"]: "",
            ["_meeting_cs_date"]: "",
            ["oferta_consulta_nutricao_alimentar"]: "",


            ["_segunda_reavaliacao_agendado"]: "",
            ["_segunda_reavaliacao_concluido"]: "",
            ["_segunda_reavaliacao_date"]: "",
            ["_treino_flash_facil_agendado"]: "",
            ["_treino_flash_facil_concluido"]: "",
            ["_treino_flash_facil_date"]: "",
            ["_speed_classes_agendado"]:"",
            ["_speed_classes_concluido"]: "",
            ["_speed_classes_date"]: "",
            ["_dc_agendado"]: "",
            ["_dc_concluido"]: "",
            ["_dc_date"]: "",
            ["oferta_desconto_voucher"]: "",
            ["observacoes"]: "",
        });

    }
    const  onOpenModalValue = async (e, id, n_socio, nome, telemovel, email, aniversario, data, observacoes) => {
        e.preventDefault();
        let viagemSocioContent = JSON.parse(data);


        setSociosData({...sociosData,
            ["modal"]: modal ? false : true,
            ["nome"]: nome ? nome : "",
            ["n_socio"]: n_socio && n_socio !== "null" ? n_socio : "",
            ["email"]: email !== "null" ? email : "",
            ["telemovel"]: telemovel && telemovel !== "null" ? telemovel : "",
            ["aniversario"]: aniversario ? moment(aniversario).format("YYYY-MM-DD")  : "",
            ["id"]:id,
            ["date__avaliacao"]: viagemSocioContent ? viagemSocioContent.date__avaliacao ? moment(viagemSocioContent.date__avaliacao).format("YYYY-MM-DD")  : ""  : "",
            ["_avaliacao_agendado"]:  viagemSocioContent ? viagemSocioContent._avaliacao_agendado ? true : false : "",
            ["_avaliacao_concluido"]:   viagemSocioContent ? viagemSocioContent._avaliacao_concluido ? true : false : "",
            ["_primeiro_treino_date"]:  viagemSocioContent ? viagemSocioContent._primeiro_treino_date ? moment(viagemSocioContent._primeiro_treino_date).format("YYYY-MM-DD")  : false : "",
            ["_primeiro_treino_agendado"]:  viagemSocioContent ?  viagemSocioContent._primeiro_treino_agendado ? true : false : "",
            ["_primeiro_treino_concluido"]:  viagemSocioContent ?  viagemSocioContent._primeiro_treino_concluido ? true : false : "",
            ["_aula_grupo_agendado"]:  viagemSocioContent ?  viagemSocioContent._aula_grupo_agendado ? true : false: "",
            ["_aula_grupo_concluido"]:  viagemSocioContent ?  viagemSocioContent._aula_grupo_concluido ? true : false : "",
            ["_aula_grupo_date"]:  viagemSocioContent ?  viagemSocioContent._aula_grupo_date ? moment(viagemSocioContent._aula_grupo_date).format("YYYY-MM-DD")  : false : "",
            ["_acupuntura_agendado"]:  viagemSocioContent ?  viagemSocioContent._acupuntura_agendado ? true : false : "",
            ["_acupuntura_concluido"]:  viagemSocioContent ?  viagemSocioContent._acupuntura_concluido ? true : false : "",
            ["_acupuntura_date"]:  viagemSocioContent ?  viagemSocioContent._acupuntura_date ? moment(viagemSocioContent._acupuntura_date).format("YYYY-MM-DD")  : false : "",
            ["oferta_agua"]:  viagemSocioContent ?  viagemSocioContent.oferta_agua ? true : false : "",


            ["_primeira_reavaliacao_agendado"]: viagemSocioContent ? viagemSocioContent._primeira_reavaliacao_agendado ? true : false : "",
            ["_primeira_reavaliacao_concluido"]: viagemSocioContent ? viagemSocioContent._primeira_reavaliacao_concluido ? true : false : "",
            ["_primeira_reavaliacao_date"]:viagemSocioContent ?  viagemSocioContent._primeira_reavaliacao_date ? moment(viagemSocioContent._primeira_reavaliacao_date).format("YYYY-MM-DD")  : false : "",
            ["_segundo_treino_date"]: viagemSocioContent ? viagemSocioContent._segundo_treino_date ? moment(viagemSocioContent._segundo_treino_date).format("YYYY-MM-DD")  : false : "",
            ["_segundo_treino_agendado"]:  viagemSocioContent ? viagemSocioContent._segundo_treino_agendado ? true : false : "",
            ["_segundo_treino_concluido"]: viagemSocioContent ? viagemSocioContent._segundo_treino_concluido ? true : false : "",
            ["_segunda_aula_grupo_agendado"]: viagemSocioContent ? viagemSocioContent._segunda_aula_grupo_agendado ? true : false : "",
            ["_segunda_aula_grupo_concluido"]: viagemSocioContent ? viagemSocioContent._segunda_aula_grupo_concluido ? true : false : "",
            ["_segunda_aula_grupo_date"]: viagemSocioContent ? viagemSocioContent._segunda_aula_grupo_date ? moment(viagemSocioContent._segunda_aula_grupo_date).format("YYYY-MM-DD")  : false : "",
            ["_meeting_cs_agendado"]: viagemSocioContent ?  viagemSocioContent._meeting_cs_agendado ? true : false : "",
            ["_meeting_cs_concluido"]: viagemSocioContent ?  viagemSocioContent._meeting_cs_concluido ? true : false : "",
            ["_meeting_cs_date"]: viagemSocioContent ? viagemSocioContent._meeting_cs_date ? moment(viagemSocioContent._meeting_cs_date).format("YYYY-MM-DD")  : false : "",
            ["oferta_consulta_nutricao_alimentar"]: viagemSocioContent ? viagemSocioContent.oferta_consulta_nutricao_alimentar ? true : false : "",


            ["_segunda_reavaliacao_agendado"]: viagemSocioContent ?  viagemSocioContent._segunda_reavaliacao_agendado ? true : false: "",
            ["_segunda_reavaliacao_concluido"]: viagemSocioContent ?  viagemSocioContent._segunda_reavaliacao_concluido ? true : false: "",
            ["_segunda_reavaliacao_date"]: viagemSocioContent ?  viagemSocioContent._segunda_reavaliacao_date ? moment(viagemSocioContent._segunda_reavaliacao_date).format("YYYY-MM-DD")  : false: "",
            ["_treino_flash_facil_agendado"]: viagemSocioContent ?  viagemSocioContent._treino_flash_facil_agendado ? true : false: "",
            ["_treino_flash_facil_concluido"]: viagemSocioContent ?  viagemSocioContent._treino_flash_facil_concluido ? true : false: "",
            ["_treino_flash_facil_date"]:  viagemSocioContent ? viagemSocioContent._treino_flash_facil_date ? moment(viagemSocioContent._treino_flash_facil_date).format("YYYY-MM-DD")  : false: "",
            ["_speed_classes_agendado"]:  viagemSocioContent ?  viagemSocioContent._speed_classes_agendado ? true : false: "",
            ["_speed_classes_concluido"]: viagemSocioContent ?  viagemSocioContent._speed_classes_concluido ? true : false: "",
            ["_speed_classes_date"]:  viagemSocioContent ? viagemSocioContent._speed_classes_date ? moment(viagemSocioContent._speed_classes_date).format("YYYY-MM-DD")  : false: "",
            ["_dc_agendado"]: viagemSocioContent ?  viagemSocioContent._dc_agendado ? true : false: "",
            ["_dc_concluido"]:  viagemSocioContent ? viagemSocioContent._dc_concluido ? true : false: "",
            ["_dc_date"]: viagemSocioContent ?  viagemSocioContent._dc_date ? moment(viagemSocioContent._dc_date).format("YYYY-MM-DD")  : false: "",
            ["oferta_desconto_voucher"]:  viagemSocioContent ?  viagemSocioContent.oferta_desconto_voucher ? true : false: "",
            ["observacoes"]: observacoes ? observacoes : "",

        });

    }
    const onSubmit = async e => {
        e.preventDefault();
        setSociosData({...sociosData,
            loading:true,
        });


        try {
            await updateSocios({sociosData});
        } catch(e){

        }
    }
    const onChangeText = async (e) => {
        setCurrentPage(1)
        let nomeData = nome_socio_filtro,
            NumeroData = n_socio_filtro,
            SocioDate = telemovel_socio_filtro;


        if(nome_socio_filtro != e.target.value && "nome_socio_filtro" === e.target.name) {
            nomeData = e.target.value;
            NumeroData = "";
            SocioDate = "";
            setSociosData({...sociosData,
                [e.target.name]: e.target.value,
                ["loading"]: true,
                ["n_socio_filtro"]: "",
                ["telemovel_socio_filtro"]: "",
            });
        }

        if(n_socio_filtro != e.target.value && "n_socio_filtro" === e.target.name) {
            NumeroData = e.target.value;
            nomeData = "";
            SocioDate = "";
            setSociosData({...sociosData,
                [e.target.name]: e.target.value,
                ["loading"]: true,
                ["nome_socio_filtro"]: "",
                ["telemovel_socio_filtro"]: ""
            });
        }
        if(telemovel_socio_filtro != e.target.value && "telemovel_socio_filtro" === e.target.name) {
            SocioDate = e.target.value;
            nomeData = "";
            NumeroData = "";
            setSociosData({...sociosData,
                [e.target.name]: e.target.value,
                ["loading"]: true,
                ["nome_socio_filtro"]: "",
                ["n_socio_filtro"]: ""
            });
        }


        try {
            await getSociosByFiltroText({ nome_socio_filtro: nomeData, n_socio_filtro: NumeroData  , telemovel_socio_filtro: SocioDate, id_clube: clube_selected })
        } catch (e){

        }

    };
    const onChangeConsultor = async e => {
        setSociosData({...sociosData, [e.target.name]: e.target.value});
        await getAllSocios({ id_clube:  e.target.value});

    };
    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Serviço ao Cliente >
                        <Link to={"/socios"}>Socios</Link>
                    </div>
                    {loading ? (<div className={"loading-spinner"}></div>) : ("")}
                    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="">
                            Clube:
                            <select name="clube_selected"   value={clube_selected} onChange={ e => onChangeConsultor(e)} style={{textTransform: "capitalize"}}>
                                {user && user.clubes.map((e) => (
                                    <option value={e.id} >{e.localidade}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="">
                            Nª Socio:
                            <input type="number"  name="n_socio_filtro"  value={n_socio_filtro} id="" onChange={ e => onChangeText(e)}  />
                        </label>
                        <label htmlFor="">
                            Nome:
                            <input type="text"   name="nome_socio_filtro"  value={nome_socio_filtro} id="" onChange={ e => onChangeText(e)} />
                        </label>
                        <label htmlFor="">
                            Telemovel:
                            <input type="number"  name="telemovel_socio_filtro"  value={telemovel_socio_filtro} id="" onChange={ e => onChangeText(e)} />
                        </label>
                    </div>

                   {/* <button  onClick={e => onOpenModal(e)} className={"new-btn"}>Novo</button>*/}

                    <table className="table-format-one last-td-fixed socios-fixed-table">
                        <thead>
                            <tr>
                                <th>Nº Sócio</th>
                                <th className="hide">Nome</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                        {currentPosts.map(({id, n_socio, nome, telemovel, email, aniversario, data, observacoes}) => (
                            <tr className={"to-call"} onClick={ e => onOpenModalValue(e, id, n_socio, nome, telemovel, email, aniversario, data, observacoes)}>
                                <td>{n_socio}</td>
                                <td>{nome}</td>
                                <td>
                                </td>
                            </tr>
                        ))}

                        </tbody>
                    </table>
                    <Pagination postsPerPAge={postsPerPage} totalPosts={resultados.length} paginate={paginate} currentPage={currentPage}/>

                    <div className={modal ? "new-lead-modal new-personaltable new-socio  active": "new-personaltable new-socio new-lead-modal"}>
                         <span id="close-new-lead-modal" onClick={ e => onOpenModal(e)}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => onSubmit(e)}>

                            {loading ? (<div className={"loading-spinner"}></div>) : ("")}
                            <div className="top-form">
                                <label htmlFor="">
                                    Nome
                                    <input type="text" name={"nome"} value={nome} onChange={e => onChange(e)} />
                                </label>
                                <label htmlFor="">
                                    NªSocio
                                    <input type="number" name={"n_socio"} value={n_socio} onChange={e => onChange(e)}/>
                                </label>
                                <label htmlFor="">
                                    Email
                                    <input type="email" name={"email"} value={email} onChange={e => onChange(e)}/>
                                </label>
                                <label htmlFor="">
                                    Telemovel
                                    <input type="number" name={"telemovel"} value={telemovel} onChange={e => onChange(e)}/>
                                </label>
                                <label htmlFor="">
                                    Data Nascimento
                                    <input type="date" name={"aniversario"} value={aniversario} onChange={e => onChange(e)}/>
                                </label>
                            </div>
                            <label htmlFor=""><h3>Viagem do Socio</h3></label>
                            <div className={"socio-list-viagem"}>
                                <label htmlFor="">
                                    1ª Avaliação
                                    <span>
                                        Agendado
                                        <input type="checkbox" name={"_avaliacao_agendado"} value={_avaliacao_agendado} onChange={e => onCheckboxChange(e)} checked={_avaliacao_agendado}/>
                                    </span>
                                    <span>
                                        Data
                                        <input type="date" name={"date__avaliacao"} value={date__avaliacao} onChange={e => onChange(e)} checked={date__avaliacao}/>
                                    </span>
                                    <span>
                                        Concluido
                                        <input type="checkbox" name={"_avaliacao_concluido"} value={_avaliacao_concluido} onChange={e => onCheckboxChange(e)} checked={_avaliacao_concluido}/>


                                    </span>
                                </label>
                                <label htmlFor="">
                                    1ª Treino Assistido
                                    <span>
                                        Agendado
                                        <input type="checkbox" name={"_primeiro_treino_agendado"} value={_primeiro_treino_agendado} onChange={e => onCheckboxChange(e)} checked={_primeiro_treino_agendado}/>
                                    </span>
                                    <span>
                                        Data
                                        <input type="date" name={"_primeiro_treino_date"} value={_primeiro_treino_date} onChange={e => onChange(e)} checked={_primeiro_treino_date}/>
                                    </span>
                                    <span>
                                        Concluido
                                        <input type="checkbox" name={"_primeiro_treino_concluido"} value={_primeiro_treino_concluido} onChange={e => onCheckboxChange(e)} checked={_primeiro_treino_concluido}/>
                                    </span>
                                </label>
                                <label htmlFor="">
                                    1ª Aula de Grupo
                                    <span>
                                        Agendado
                                        <input type="checkbox" name={"_aula_grupo_agendado"} value={_aula_grupo_agendado} onChange={e => onCheckboxChange(e)} checked={_aula_grupo_agendado} />
                                    </span>
                                    <span>
                                        Data
                                        <input type="date" name={"_aula_grupo_date"} value={_aula_grupo_date} onChange={e => onChange(e)} checked={_aula_grupo_date} />
                                    </span>
                                    <span>
                                        Concluido
                                        <input type="checkbox" name={"_aula_grupo_concluido"} value={_aula_grupo_concluido} onChange={e => onCheckboxChange(e)} checked={_aula_grupo_concluido} />
                                    </span>
                                </label>
                                <label htmlFor="">
                                    Acunpuntura
                                    <span>
                                        Agendado
                                        <input type="checkbox" name={"_acupuntura_agendado"} value={_acupuntura_agendado} onChange={e => onCheckboxChange(e)} checked={_acupuntura_agendado} />
                                    </span>
                                    <span>
                                        Data
                                        <input type="date"  name={"_acupuntura_date"} value={_acupuntura_date} onChange={e => onChange(e)} checked={_acupuntura_date} />
                                    </span>
                                    <span>
                                        Concluido
                                        <input type="checkbox"  name={"_acupuntura_concluido"} value={_acupuntura_concluido} onChange={e => onCheckboxChange(e)} checked={_acupuntura_concluido} />
                                    </span>
                                </label>
                                <label htmlFor="" className={"label-oferta"}>
                                    <h3>Um Mês de Agua Vitaminada</h3>
                                    <span><input type="checkbox"  name={"oferta_agua"} value={oferta_agua} onChange={e => onCheckboxChange(e)} checked={oferta_agua}/></span>
                                    <span></span>
                                    <span></span>
                                </label>

                                <label htmlFor="">

                                    1ª Reavaliação
                                    <span>
                                        Agendado
                                        <input type="checkbox" name={"_primeira_reavaliacao_agendado"} value={_primeira_reavaliacao_agendado} onChange={e => onCheckboxChange(e)} checked={_primeira_reavaliacao_agendado} />
                                    </span>
                                    <span>
                                        Data
                                        <input type="date" name={"_primeira_reavaliacao_date"} value={_primeira_reavaliacao_date} onChange={e => onChange(e)} checked={_primeira_reavaliacao_date} />
                                    </span>
                                    <span>
                                        Concluido
                                        <input type="checkbox"  name={"_primeira_reavaliacao_concluido"} value={_primeira_reavaliacao_concluido} onChange={e => onCheckboxChange(e)} checked={_primeira_reavaliacao_concluido}/>
                                    </span>
                                </label>
                                <label htmlFor="">
                                    2ª Treino Assistido
                                    <span>
                                        Agendado
                                        <input type="checkbox" name={"_segundo_treino_agendado"} value={_segundo_treino_agendado} onChange={e => onCheckboxChange(e)} checked={_segundo_treino_agendado}  />
                                    </span>
                                    <span>
                                        Data
                                        <input type="date" name={"_segundo_treino_date"} value={_segundo_treino_date} onChange={e => onChange(e)} checked={_segundo_treino_date}  />
                                    </span>
                                    <span>
                                        Concluido
                                        <input type="checkbox" name={"_segundo_treino_concluido"} value={_segundo_treino_concluido} onChange={e => onCheckboxChange(e)} checked={_segundo_treino_concluido}  />
                                    </span>
                                </label>
                                <label htmlFor="">
                                    2ª Aula de Grupo
                                    <span>
                                        Agendado
                                        <input type="checkbox" name={"_segunda_aula_grupo_agendado"} value={_segunda_aula_grupo_agendado} onChange={e => onCheckboxChange(e)} checked={_segunda_aula_grupo_agendado} />
                                    </span>
                                    <span>
                                        Data
                                        <input type="date"  name={"_segunda_aula_grupo_date"} value={_segunda_aula_grupo_date} onChange={e => onChange(e)} checked={_segunda_aula_grupo_date}  />
                                    </span>
                                    <span>
                                        Concluido
                                        <input type="checkbox"  name={"_segunda_aula_grupo_concluido"} value={_segunda_aula_grupo_concluido} onChange={e => onCheckboxChange(e)} checked={_segunda_aula_grupo_concluido} />
                                    </span>
                                </label>
                                <label htmlFor="">
                                    Meeting CS
                                    <span>
                                        Agendado
                                        <input type="checkbox" name={"_meeting_cs_agendado"} value={_meeting_cs_agendado} onChange={e => onCheckboxChange(e)} checked={_meeting_cs_agendado}/>
                                    </span>
                                    <span>
                                        Data
                                        <input type="date"  name={"_meeting_cs_date"} value={_meeting_cs_date} onChange={e => onChange(e)} checked={_meeting_cs_date} />
                                    </span>
                                    <span>
                                        Concluido
                                        <input type="checkbox"  name={"_meeting_cs_concluido"} value={_meeting_cs_concluido} onChange={e => onCheckboxChange(e)} checked={_meeting_cs_concluido} />
                                    </span>
                                </label>
                                <label htmlFor="" className={"label-oferta"}>
                                    <h3>Consulta de Nutrição + Plano alimentar</h3>
                                    <span><input type="checkbox"  name={"oferta_consulta_nutricao_alimentar"} value={oferta_consulta_nutricao_alimentar} onChange={e => onCheckboxChange(e)} checked={oferta_consulta_nutricao_alimentar}  /></span>
                                    <span></span>
                                    <span></span>
                                </label>

                                <label htmlFor="">
                                    2ª Reavaliação
                                    <span>
                                        Agendado
                                        <input type="checkbox"  name={"_segunda_reavaliacao_agendado"} value={_segunda_reavaliacao_agendado} onChange={e => onCheckboxChange(e)} checked={_segunda_reavaliacao_agendado} />
                                    </span>
                                    <span>
                                        Data
                                        <input type="date"  name={"_segunda_reavaliacao_date"} value={_segunda_reavaliacao_date} onChange={e => onChange(e)} checked={_segunda_reavaliacao_date} />
                                    </span>
                                    <span>
                                        Concluido
                                        <input type="checkbox"  name={"_segunda_reavaliacao_concluido"} value={_segunda_reavaliacao_concluido} onChange={e => onCheckboxChange(e)} checked={_segunda_reavaliacao_concluido} />
                                    </span>
                                </label>
                                <label htmlFor="">
                                    Treino Flash
                                    <span>
                                        Agendado
                                        <input type="checkbox"  name={"_treino_flash_facil_agendado"} value={_treino_flash_facil_agendado} onChange={e => onCheckboxChange(e)} checked={_treino_flash_facil_agendado} />
                                    </span>
                                    <span>
                                        Data
                                        <input type="date"  name={"_treino_flash_facil_date"} value={_treino_flash_facil_date} onChange={e => onChange(e)} checked={_treino_flash_facil_date} />
                                    </span>
                                    <span>
                                        Concluido
                                        <input type="checkbox"  name={"_treino_flash_facil_concluido"} value={_treino_flash_facil_concluido} onChange={e => onCheckboxChange(e)} checked={_treino_flash_facil_concluido} />
                                    </span>
                                </label>
                                <label htmlFor="">
                                    Speed Class
                                    <span>
                                        Agendado
                                        <input type="checkbox"   name={"_speed_classes_agendado"} value={_speed_classes_agendado} onChange={e => onCheckboxChange(e)} checked={_speed_classes_agendado}  />
                                    </span>
                                    <span>
                                        Data
                                        <input type="date" name={"_speed_classes_date"} value={_speed_classes_date} onChange={e => onChange(e)} checked={_speed_classes_date}  />
                                    </span>
                                    <span>
                                        Concluido
                                        <input type="checkbox"  name={"_speed_classes_concluido"} value={_speed_classes_concluido} onChange={e => onCheckboxChange(e)} checked={_speed_classes_concluido}  />
                                    </span>
                                </label>
                                <label htmlFor="">
                                    Meenting CS
                                    <span>
                                        Agendado
                                        <input type="checkbox"  name={"_dc_agendado"} value={_dc_agendado} onChange={e => onCheckboxChange(e)} checked={_dc_agendado}  />
                                    </span>
                                    <span>
                                        Data
                                        <input type="date" name={"_dc_date"} value={_dc_date} onChange={e => onChange(e)} checked={_dc_date}  />
                                    </span>
                                    <span>
                                        Concluido
                                        <input type="checkbox" name={"_dc_concluido"} value={_dc_concluido} onChange={e => onCheckboxChange(e)} checked={_dc_concluido}  />
                                    </span>
                                </label>
                                <label htmlFor="" className={"label-oferta"}>
                                    <h3> Oferta 50% De Desconto + Voucher</h3>
                                    <span><input type="checkbox" name={"oferta_desconto_voucher"} value={oferta_desconto_voucher} onChange={e => onCheckboxChange(e)} checked={oferta_desconto_voucher}  /></span>
                                    <span></span>
                                    <span></span>
                                </label>

                            </div>
                            <label htmlFor="" className={"fix-label-column"}>
                                Observações
                                <textarea name={"observacoes"} value={observacoes} onChange={e => onChange(e)}   cols="10" rows="5"></textarea>
                            </label>
                            <button>GUARDAR</button>
                            {err ? (<div className={"alert alert-danger active"}>{err}</div>): ("")}
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
};
SociosDirectorClube.propTypes = {
    auth: PropTypes.object.isRequired,
    getAllSocios: PropTypes.func.isRequired,
    updateSocios: PropTypes.func.isRequired,
    getSociosByFiltroText: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    socios: state.socios
});
export default connect(mapStateToProps, { getAllSocios, updateSocios, getSociosByFiltroText })( SociosDirectorClube );
