/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_AGENDAS,
    GET_AGENDAS_ERROR,
} from "./types";

export const getDashboard = ({currentDate}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { currentDate };

    try {
        const res = await axios.post("/api/dashboard", body, config);

        dispatch({
            type: GET_AGENDAS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_AGENDAS_ERROR,
        })
    }
}
export const getAgenda = ({ date, selected_colaborador }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { date, selected_colaborador };

    try {
        const res = await axios.post("/api/agenda", body, config);

        dispatch({
            type: GET_AGENDAS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_AGENDAS_ERROR,
        })
    }
}
export const insertAgenda = ({ date, id, nome_client, contact, date_marcacao, tipo_marcacao, consultor_id, consultor_recebeu, observacoes, n_socio, email, data_nascimento, tarifario, status_marcacao, status_app,countryCode }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { date, id, nome_client, contact, date_marcacao, tipo_marcacao, consultor_id, consultor_recebeu, observacoes, n_socio, email, data_nascimento, tarifario, status_marcacao, status_app,countryCode  };
    try {
        const res = await axios.post("/api/agenda/new", body, config);

        dispatch({
            type: GET_AGENDAS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_AGENDAS_ERROR,
        })
    }
}
export const updateAgenda = ({ date, id, nome_client, contact, date_marcacao, tipo_marcacao, consultor_id, consultor_recebeu, observacoes, n_socio, email, data_nascimento, tarifario, status_marcacao, status_app,countryCode }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { date, id, nome_client, contact, date_marcacao, tipo_marcacao, consultor_id, consultor_recebeu, observacoes, n_socio, email, data_nascimento, tarifario, status_marcacao, status_app, countryCode  };
    try {
        const res = await axios.post("/api/agenda/update", body, config);

        dispatch({
            type: GET_AGENDAS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_AGENDAS_ERROR,
        })
    }
}
