/**
 * Created by danielsilva on 11/04/2020.
 */
import {
    GET_SOCIOS,
    GET_SOCIOS_ERROR,
    GET_SOCIOS_NUTRICOA,
    GET_SOCIOS_ERROR_NUTRICOA
} from "../actions/types";

const initialState = {
    loading: true,
    data: null,
    error: {}
}

export default  function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_SOCIOS:
        case  GET_SOCIOS_NUTRICOA:

            return {
                ...state,
                data: payload,
                loading: false
            }
        case GET_SOCIOS_ERROR:
        case  GET_SOCIOS_ERROR_NUTRICOA:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}