/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getRelatorioRotaPT } from "./actions/relatoriorotapt";
import Pagination from "../gestaocomercial/Pagination";
import NumberFormat from "react-number-format";

const RelatorioRotaPTDiretorClube = ({ auth: {user}, getRelatorioRotaPT, relatoriorotapt: { data }}) => {
    useEffect(() => {
        getRelatorioRotaPT({ filtro_de: null, filtro_ate: null, select_type: null, instrutor_select: null, id_clube: null});
    },[]);

    useLayoutEffect(() => {
        setRelatorioRotaData({...relatorioRotaData,
            modal: data ? !!data.err : false,
            err: data ? data.err ?  data.err : false : false,
            total_marcacoes: data ? data.total_marcacoes ?  data.total_marcacoes : 0 : 0,

        });
    },[data]);

    let filtrodeDate = new Date();
    filtrodeDate.setDate(25)
    filtrodeDate.setMonth(filtrodeDate.getMonth() -1)
    const [relatorioRotaData, setRelatorioRotaData] = useState({
        modal: false,
        date: moment().format("YYYY-MM-DD"),
        err: false,
        filtro_de: moment(filtrodeDate).format("YYYY-MM-DD"),
        filtro_ate: moment().format("YYYY-MM-DD"),
        select_type: "0",
        instrutor_select: "0",
        total_marcacoes: 0,
        total_realizadas: 0,
    });

    const { err, modal, date, instrutor_select, filtro_de, filtro_ate, select_type, total_marcacoes, total_realizadas } = relatorioRotaData;
    const onChange = e => setRelatorioRotaData({...relatorioRotaData, [e.target.name]: e.target.value});
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let resultados = data ? data.relatoriorotapt ? data.relatoriorotapt : [] : [];
    const currentPosts = resultados.slice(indexOfFirstPost,indexOfLastPost);
    const paginate = (e,pageNumber) => {
        e.preventDefault()
        setCurrentPage(pageNumber)
    }


    const onSearch = async (e) => {
        e.preventDefault();

        try {
            await getRelatorioRotaPT({ filtro_de, filtro_ate, select_type, instrutor_select, id_clube: null});
        } catch(e){

        }

    }

    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Gestão Clube >
                        <Link to={"/relatorios_rotapt"}>Relatorio RotaPT</Link>
                    </div>
                    <form onSubmit={e => onSearch(e)}>
                      <div className="table-inputs-filtros table-inputs-filtros-contactos">

                            <label>
                                De:
                                <input type="date" name="filtro_de" value={filtro_de}  onChange={e => onChange(e)} />
                            </label>

                            <label >
                                Até:
                                <input type="date" name="filtro_ate"  value={filtro_ate}  onChange={e => onChange(e)}  />
                            </label>


                            <label>Tipo
                                <select name="select_type" value={select_type}  onChange={e => onChange(e)} >
                                    <option value="0" selected="selected">Todas</option>
                                    <option value="1">Realizadas</option>
                                    <option value="2">Não Realizadas</option>
                                    <option value="3">Reagendadas</option>
                                </select>
                            </label>


                            <label htmlFor="">
                                Personal Trainer
                                <select name={"instrutor_select"} value={instrutor_select} onChange={e => onChange(e)} required>
                                    <option value="0">Todos</option>
                                    {user && user.personaltrainers.map((e) => (
                                        <option value={e.id}>{e.nome}</option>
                                    ))}
                                </select>
                            </label>

                            <button className={"new-btn"}>Procurar</button>
                    </div>
                    </form>

                    <div className="alerts-container" >
                        <div className=""  style={{fontStyle: "italic"}} > Total:  <strong style={{"color":" #7d00ff",
                            fontStyle: "normal"}}>  { total_marcacoes} </strong> </div>
                    </div>
                    <table className="table-format-one">
                        <thead>
                        <tr>
                            <th>Nome</th>
                            <th>N Socio</th>
                            <th>PT</th>
                            <th>Data</th>
                        </tr>
                        </thead>
                        <tbody>
                            {currentPosts && currentPosts.map(({date, client_name, client_contact, personal_trainer_name, n_socio, id}) => (
                                <tr className={"to-call"}>

                                    <td>{client_name}</td>
                                    <td>{n_socio}</td>
                                    <td>{personal_trainer_name}</td>
                                    <td>
                                        { date ? (
                                            (parseInt(new Date(date).getDate())  < 10 ? ("0" + new Date(date).getDate()) : new Date(date).getDate())
                                            + "/" +
                                            (parseInt(new Date(date).getMonth() + 1)  < 10 ? ("0" + (new Date(date).getMonth() +1)) : new Date(date).getMonth() +1)
                                            + "/" +
                                            (parseInt(new Date(date).getFullYear())) + " " +

                                            (parseInt(new Date(date).getHours())  < 10 ? ("0" + new Date(date).getHours()) : new Date(date).getHours())
                                            + ":" +
                                            (parseInt(new Date(date).getMinutes())  < 10 ? ("0" + new Date(date).getMinutes()) : new Date(date).getMinutes())

                                        ): ("") }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination postsPerPAge={postsPerPage} totalPosts={resultados.length} paginate={paginate} currentPage={currentPage}/>




                </div>
            </div>

        </Fragment>
};
RelatorioRotaPTDiretorClube.propTypes = {
    auth: PropTypes.object.isRequired,
    getRelatorioRotaPT: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    relatoriorotapt: state.relatoriorotapt
});
export default connect(mapStateToProps, { getRelatorioRotaPT })( RelatorioRotaPTDiretorClube );
