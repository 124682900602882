/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_ROTA,
    GET_ROTA_ERROR,
} from "./types";

export const getRotaPT = ({prevDate, id_clube, id_pt }) => async dispatch => {
    
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {prevDate, id_clube, id_pt};
    try {
        const res = await axios.post("/api/rotapt", body, config);

        dispatch({
            type: GET_ROTA,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ROTA_ERROR,
        })
    }
}
export const currentDataPrev = ({prevDate, id_clube, id_pt }) => async dispatch => {
    
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {prevDate, id_clube, id_pt};
    try {
        const res = await axios.post("/api/rotapt", body, config);

        dispatch({
            type: GET_ROTA,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ROTA_ERROR,
        })
    }
}

export const insertAppointment = ({ marcacaoID, n_socio, time_appoint }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { marcacaoID, n_socio, time_appoint };
    try {
        const res = await axios.post("/api/rotapt/insert", body, config);

        dispatch({
            type: GET_ROTA,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ROTA_ERROR,
        })
    }
}
export const deleteAppointment = ({ marcacaoID, prevDate, id_clube, id_pt}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { marcacaoID, prevDate, id_clube, id_pt };

    try {
        const res = await axios.post("/api/rotapt/delete", body, config);

        dispatch({
            type: GET_ROTA,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ROTA_ERROR,
        })
    }
}
export const deleteAppointmentPT = ({ marcacaoID, prevDate, id_clube, id_pt }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { marcacaoID, prevDate, id_clube, id_pt };

    try {
        const res = await axios.post("/api/rotapt/deletePT", body, config);

        dispatch({
            type: GET_ROTA,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ROTA_ERROR,
        })
    }
}
export const insertDisponibilidade = ({ repetir_semans, marcacao_de, marcacao_ate, currentData }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { repetir_semans, marcacao_de, marcacao_ate, currentData };
    try {
        const res = await axios.post("/api/rotapt/disponibilidade", body, config);

        dispatch({
            type: GET_ROTA,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ROTA_ERROR,
        })
    }
}

export const updateMarcacao = ({ marcacaoID, status_av, currentData }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { marcacaoID, status_av, currentData };
    try {
        const res = await axios.post("/api/rotapt/updateStatus", body, config);

        dispatch({
            type: GET_ROTA,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_ROTA_ERROR,
        })
    }
}
