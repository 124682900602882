/**
 * Created by danielsilva on 10/04/2020.
 */
import { combineReducers } from "redux";
import auth from "./auth";
import agenda from "../components/recepcao/layout/reducers/agenda";
import contactosnaoconvertidos from "../components/administracao/gestaocomercial/reducers/contactosnaoconvertidos";
import bancodd from "../components/administracao/gestaoclube/reducers/bancodd";
import utilizadores from "../components/diretorclube/gestaoclube/reducers/utilizadores";
import contactosangariados from "../components/recepcao/gestaocomercial/reducers/contactosangariados";
import inscricoes from "../components/recepcao/gestaocomercial/reducers/inscricoes";
import receitas from "../components/recepcao/gestaocomercial/reducers/receitas";
import socios from "../components/recepcao/servicocliente/reducers/socios";
import rotapt from "../components/recepcao/servicocliente/reducers/rotapt";
import colaboradores from "../components/diretorclube/gestaoclube/reducers/colaboradores";
import chamadas from "../components/recepcao/gestaocomercial/reducers/chamadas";
import pontoscriticos from "../components/administracao/gestaoclube/reducers/pontoscriticos";
import planoformacao from "../components/administracao/gestaoclube/reducers/planoformacao";
import reunioescordenacao from "../components/administracao/gestaoclube/reducers/reunioescordenacao";
import manutencao from "../components/recepcao/servicocliente/reducers/manutencao";
import personaltrainer from "../components/recepcao/servicocliente/reducers/personaltrainer";
import limpeza from "../components/recepcao/servicocliente/reducers/limpeza";
import aulas from "../components/recepcao/servicocliente/reducers/aulas";
import instrutorsala from "../components/recepcao/servicocliente/reducers/instrutorsala";
import ferias from "../components/diretorclube/gestaoclube/reducers/ferias";
import projeccao from "../components/administracao/gestaoclube/reducers/projeccao";
import rotanutricao from "../components/recepcao/servicocliente/reducers/rotanutricao";
import relatoriorotapt from "../components/diretorclube/gestaoclube/reducers/relatoriorotapt";
import rotaacupuntura from "../components/recepcao/servicocliente/reducers/rotaacupuntura";

export default combineReducers({
    auth,
    contactosangariados,
    inscricoes,
    receitas,
    socios,
    ferias,
    rotapt,
    agenda,
    colaboradores,
    projeccao,
    chamadas,
    pontoscriticos,
    planoformacao,
    reunioescordenacao,
    manutencao,
    personaltrainer,
    limpeza,
    aulas,
    instrutorsala,
    contactosnaoconvertidos,
    bancodd,
    rotanutricao,
    utilizadores,
    relatoriorotapt,
    rotaacupuntura
});