/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getProjeccao, currentDataPrev, insertObjectivo, insertProjeccoes } from "./actions/projeccao";

const ProjeccaoComercialAdministrativa = ({ auth: {user}, getProjeccao, currentDataPrev, insertObjectivo, insertProjeccoes, projeccao: { data }}) => {
    useEffect(() => {
        setProjecaoData(
            {
                ...projecaoData,
                consultores: (user && user.colaboradores.filter((element) => {
                    if(element.id_clube === parseInt(clube_selected))
                        return element
                }) )
            }
        );

        getProjeccao({id_clube: 1});
    },[user])
    useLayoutEffect(() => {
        setProjecaoData(
            {
                ...projecaoData,
                segunda_projeccao: data ? data.segundaValues.length ? data.segundaValues[0].projeccao : 0  : 0,
                segunda_actual: data ? data.segundaValues.length ? data.segundaValues[0].actual : 0  : 0,
                terca_projeccao: data ? data.tercaValues.length ? data.tercaValues[0].projeccao : 0  : 0,
                terca_actual: data ? data.tercaValues.length ? data.tercaValues[0].actual : 0  : 0,
                quarta_projeccao:data ? data.quartaValues.length ? data.quartaValues[0].projeccao : 0  : 0,
                quarta_actual: data ? data.quartaValues.length ? data.quartaValues[0].actual : 0  : 0,
                quinta_projeccao: data ? data.quintaValues.length ? data.quintaValues[0].projeccao : 0  : 0,
                quinta_actual: data ? data.quintaValues.length ? data.quintaValues[0].actual : 0  : 0,
                sexta_projeccao: data ? data.sextaValues.length ? data.sextaValues[0].projeccao : 0  : 0,
                sexta_actual: data ? data.sextaValues.length ? data.sextaValues[0].actual : 0  : 0,
                sabado_projeccao: data ? data.sabadoValues.length ? data.sabadoValues[0].projeccao : 0  : 0,
                sabado_actual:data ? data.sabadoValues.length ? data.sabadoValues[0].actual : 0  : 0,
                domingo_projeccao: data ? data.domingoValues.length ? data.domingoValues[0].projeccao : 0  : 0,
                domingo_actual: data ? data.domingoValues.length ? data.domingoValues[0].actual : 0  : 0,
                clube_selected: data ? data.clube_selected ?  data.clube_selected : 1 : 1,
                consultores: (data && data.colaboradores.filter((element) => {
                    if(element.id_clube === parseInt(clube_selected))
                        return element
                }) )
            }
        );
    },[data])
    const [projecaoData, setProjecaoData] = useState({
        date:  moment().format("YYYY-MM-DD"),
        consultores: [],
        currentData: new Date(),
        segunda_projeccao: 0,
        segunda_actual: 0,
        terca_projeccao: 0,
        terca_actual: 0,
        quarta_projeccao: 0,
        quarta_actual: 0,
        quinta_projeccao: 0,
        quinta_actual: 0,
        sexta_projeccao: 0,
        sexta_actual: 0,
        sabado_projeccao: 0,
        sabado_actual: 0,
        domingo_projeccao: 0,
        domingo_actual: 0,
        clube_selected: 1,
        consultoresArray: []
    });


    const { consultoresArray, clube_selected, date, inscricoes, consultores, currentData, segunda_projeccao, segunda_actual, terca_projeccao, terca_actual,
        quarta_projeccao, quarta_actual, quinta_projeccao, quinta_actual, sexta_projeccao, sexta_actual, sabado_projeccao, sabado_actual, domingo_projeccao, domingo_actual } = projecaoData;
    const onChange = e => setProjecaoData({...projecaoData, [e.target.name]: e.target.value});
    const onChangeUpdate = index => e => {
        let newArr = [...consultores];
        newArr[index] = {
            ...consultores[index],
            [e.target.name]:e.target.value
        };
        setProjecaoData({...projecaoData, consultores: newArr});
        console.log(newArr)
        console.log(consultores)
    };
    let diaSemanaAgendaText = new Date(currentData)
    let diaSemanaAgendaTextDomingo = new Date(currentData),
        diaSemanaAgendaTextSegunda= new Date(currentData),
        diaSemanaAgendaTextTerca= new Date(currentData),
        diaSemanaAgendaTextQuarta= new Date(currentData),
        diaSemanaAgendaTextQuinta= new Date(currentData),
        diaSemanaAgendaTextSexta= new Date(currentData),
        diaSemanaAgendaTextSabado= new Date(currentData);

    if(diaSemanaAgendaText.getDay() === 0) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 6)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 5)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() - 4)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() - 3)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() - 2)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() - 1)
    }
    if(diaSemanaAgendaText.getDay() === 1) {
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() + 1)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() + 2)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() + 3)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() + 4)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 5)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 6)
    }
    if(diaSemanaAgendaText.getDay() === 2) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 1)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() + 1)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() + 2)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() + 3)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 4)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 5)
    }
    if(diaSemanaAgendaText.getDay() === 3) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 2)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 1)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() + 1)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() + 2)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 3)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 4)
    }
    if(diaSemanaAgendaText.getDay() === 4) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 3)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 2)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() - 1)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() + 1)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 2)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 3)
    }
    if(diaSemanaAgendaText.getDay() === 5) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 4)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 3)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() - 2)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() - 1)
        diaSemanaAgendaTextSabado.setDate(diaSemanaAgendaTextSabado.getDate() + 1)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() + 2)
    }
    if(diaSemanaAgendaText.getDay() === 6) {
        diaSemanaAgendaTextSegunda.setDate(diaSemanaAgendaTextSegunda.getDate() - 5)
        diaSemanaAgendaTextTerca.setDate(diaSemanaAgendaTextTerca.getDate() - 4)
        diaSemanaAgendaTextQuarta.setDate(diaSemanaAgendaTextQuarta.getDate() - 3)
        diaSemanaAgendaTextQuinta.setDate(diaSemanaAgendaTextQuinta.getDate() - 2)
        diaSemanaAgendaTextSexta.setDate(diaSemanaAgendaTextSexta.getDate() - 1)
        diaSemanaAgendaTextDomingo.setDate(diaSemanaAgendaTextDomingo.getDate() +1 )
    }

    const onChangeCurrentDataPrev = async e => {
        e.preventDefault();
        setProjecaoData(
            {
                ...projecaoData,
                currentData: new Date(currentData.setDate(currentData.getDate() - 7))
            }
        );

        try {
            await currentDataPrev({ prevDate: currentData })
        }catch(e){

        }
    };
    const onChangeCurrentDataNext = async e => {
        e.preventDefault();
            setProjecaoData(
            {
                ...projecaoData,
                currentData: new Date(currentData.setDate(currentData.getDate() + 7))
            }
        );

        try {
            await currentDataPrev({ prevDate: currentData })
        }catch(e){

        }
    };


    const onSubmit = async e => {
        e.preventDefault();

        try {
            await insertObjectivo({prevDate: currentData, consultores, id_clube: clube_selected});
        }catch(e){

        }
    }
    const onSubmitProjeccao = async e => {
        e.preventDefault();


        try {
            await insertProjeccoes({ prevDate: currentData,  segundaDate: diaSemanaAgendaTextSegunda, tercaDate: diaSemanaAgendaTextTerca, quartaDate:diaSemanaAgendaTextQuarta,
                quintaDate: diaSemanaAgendaTextQuinta, sextaDate:diaSemanaAgendaTextSexta, sabadoDate: diaSemanaAgendaTextSabado, domingoDate:diaSemanaAgendaTextDomingo,
                segunda_projeccao, segunda_actual, terca_projeccao, terca_actual, quarta_projeccao, quarta_actual, quinta_projeccao,
                quinta_actual, sexta_projeccao, sexta_actual, sabado_projeccao, sabado_actual, domingo_projeccao, domingo_actual, id_clube: clube_selected });
        }catch(e){
            console.log(e);
        }
    }
    const onChangeCol = async (e) => {
        setProjecaoData({...projecaoData, [e.target.name]: e.target.value});
        await getProjeccao({  id_clube:  e.target.value});
    };
    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs">
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Gestão Comercial >
                        <Link to={"/projeccaocomercial"}>Projecção Comercial</Link>
                    </div>
                    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="">
                            Clube:
                            <select name="clube_selected"  value={clube_selected} onChange={ e => onChangeCol(e)} style={{textTransform: "capitalize"}}>
                                {user && user.clubes.map((e) => (
                                    <option value={e.id} >{e.localidade}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <form className={"table-inputs"} onSubmit={ e => onSubmit(e) }>
                        <div className="table-grid-five-columns">
                            <label htmlFor="">
                                <div>
                                    Nome
                                </div>
                                <div>
                                    Objectivo
                                </div>
                                <div>
                                    Actual
                                </div>
                                <div>
                                    Variação
                                </div>
                                <div>
                                    Variação %
                                </div>
                            </label>
                            {consultores.map((e, index) => (

                                <label htmlFor="" key={e.name}>
                                    <div>
                                        {e.nome}
                                    </div>
                                    <div>
                                        <span className={"hide-desktop"}>Objectivo</span>
                                        <input type="number" name={"objectivo"} value={e.objectivo} onChange={onChangeUpdate(index)} />
                                    </div>
                                    <div>
                                        <span className={"hide-desktop"}>Actual</span>
                                        <input type="number" name={"atual"}     value={e.atual} onChange={onChangeUpdate(index)} />
                                    </div>
                                    <div>
                                        <span className={"hide-desktop"}>Variação</span>
                                        <input type="number" className={(e.atual > 0 ? e.objectivo > e.atual ? "red" : "green" : "")} value={(e.atual - e.objectivo )}  />
                                    </div>
                                    <div>
                                        <span className={"hide-desktop"}>Variação %</span>
                                        <input type="text" className={(e.atual > 0 ? e.objectivo > e.atual ? "red" : "green" : "")} value={ e.objectivo > 0? ((e.atual/e.objectivo) * 100).toFixed(2) + '%' : ""} />
                                    </div>
                                </label>
                            ))}
                        </div>
                        <button>Guardar</button>

                       {/* <div className="table-grid-five-columns-table">
                            <label htmlFor="">
                                <div>

                                </div>
                                <div>
                                    Seg
                                </div>
                                <div>
                                    Ter
                                </div>
                                <div>
                                    Qua
                                </div>
                                <div>
                                    Qui
                                </div>
                                <div>
                                    Sex
                                </div>
                                <div>
                                    Sab
                                </div>
                                <div>
                                    Dom
                                </div>
                                <div>
                                    Total
                                </div>
                            </label>

                            <label htmlFor="">
                                <div>
                                    <span>Projecção Semanal 1</span>
                                    <span>Actual</span>
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                            </label>
                            <label htmlFor="">
                                <div>
                                    <span>Projecção Semanal 2</span>
                                    <span>Actual</span>
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                            </label>
                            <label htmlFor="">
                                <div>
                                    <span>Projecção Semanal 3</span>
                                    <span>Actual</span>
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                            </label>
                            <label htmlFor="">
                                <div>
                                    <span>Projecção Semanal 4</span>
                                    <span>Actual</span>
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                            </label>
                            <label htmlFor="">
                                <div>
                                    <span>Projecção Semanal 5</span>
                                    <span>Actual</span>
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                            </label>
                            <label htmlFor="">
                                <div>
                                    <span>Projecção Semanal 6</span>
                                    <span>Actual</span>
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                                <div>
                                    <input type="text" />
                                    <input type="text" />
                                </div>
                            </label>
                        </div>*/}

                    </form>

                    <form className="projecao-comercial" onSubmit={e => onSubmitProjeccao(e)}>
                        <div className="buttons-days">
                            <a href="" onClick={ e => onChangeCurrentDataPrev(e) }>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-left"
                                     className="svg-inline--fa fa-chevron-left fa-w-8" role="img"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path fill="currentColor"
                                          d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"></path>
                                </svg>
                                Semana Anterior
                            </a>
                            <a href="" onClick={ e => onChangeCurrentDataNext(e) }>
                                Semana Seguinte
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-right"
                                     className="svg-inline--fa fa-chevron-right fa-w-8" role="img"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path fill="currentColor"
                                          d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path>
                                </svg>

                            </a>
                        </div>

                        <div id="agenda-projecao-container" className="agenda-comercial-container">
                            <div className="agenda-column">
                                <h3></h3>
                                <div className="agenda-item-container" data-zone="">
                                    <span>Projecção</span>
                                    <span>Actual</span>
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Seg / {diaSemanaAgendaTextSegunda.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="segunda">
                                    <span><input type="text" name={"segunda_projeccao"} value={segunda_projeccao} onChange={e => onChange(e)} /></span>
                                    <span><input type="text" name={"segunda_actual"} value={segunda_actual} onChange={e => onChange(e)} /></span>
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Ter / {diaSemanaAgendaTextTerca.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="terca">
                                    <span><input type="text" name={"terca_projeccao"} value={terca_projeccao} onChange={e => onChange(e)} /></span>
                                    <span><input type="text" name={"terca_actual"} value={terca_actual} onChange={e => onChange(e)} /></span>
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Qua / {diaSemanaAgendaTextQuarta.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="quarta">
                                    <span><input type="text" name={"quarta_projeccao"} value={quarta_projeccao} onChange={e => onChange(e)} /></span>
                                    <span><input type="text" name={"quarta_actual"} value={quarta_actual} onChange={e => onChange(e)} /></span>
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Qui / {diaSemanaAgendaTextQuinta.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="quinta">
                                    <span><input type="text" name={"quinta_projeccao"} value={quinta_projeccao} onChange={e => onChange(e)} /></span>
                                    <span><input type="text" name={"quinta_actual"} value={quinta_actual} onChange={e => onChange(e)} /></span>
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Sex / {diaSemanaAgendaTextSexta.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="sexta">
                                    <span><input type="text" name={"sexta_projeccao"} value={sexta_projeccao} onChange={e => onChange(e)} /></span>
                                    <span><input type="text" name={"sexta_actual"} value={sexta_actual} onChange={e => onChange(e)} /></span>
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Sab / {diaSemanaAgendaTextSabado.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="sabado">
                                    <span><input type="text" name={"sabado_projeccao"} value={sabado_projeccao} onChange={e => onChange(e)} /></span>
                                    <span><input type="text" name={"sabado_actual"} value={sabado_actual} onChange={e => onChange(e)} /></span>
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Dom / {diaSemanaAgendaTextDomingo.getDate()}</h3>
                                <div className="agenda-item-container" data-zone="domingo">
                                    <span><input type="text" name={"domingo_projeccao"} value={domingo_projeccao} onChange={e => onChange(e)} /></span>
                                    <span><input type="text" name={"domingo_actual"} value={domingo_actual} onChange={e => onChange(e)} /></span>
                                </div>
                            </div>
                            <div className="agenda-column">
                                <h3>Total</h3>
                                <div className="agenda-item-container" data-zone="domingo">
                                    {data ? (
                                        data.totalValues.length > 0 ? (
                                                data.totalValues.map((e) => (
                                                    <>
                                                        <span><input type="text" value={e.projeccao} disabled/></span>
                                                        <span><input type="text" value={e.actual}  disabled/></span>
                                                    </>
                                                ))
                                            ):
                                            (
                                                <>
                                                    <span><input type="text" value="0" /></span>
                                                    <span><input type="text" value="0" /></span>
                                                </>
                                            )
                                    ) : ("") }
                                </div>
                            </div>
                        </div>

                        <div className="total-projeccoes">
                            <div><strong>Total Projecções Mês:</strong> {data ? (
                                data.totalMesValues.length > 0 ? (
                                        data.totalMesValues.map((e) => (
                                            <input type="text" value={e.projeccao} disabled/>
                                        ))
                                    ):
                                    (
                                        <>
                                           0
                                        </>
                                    )
                            ) : ("") }</div>
                            <div><strong>Total Actual Mês:</strong> {data ? (
                                data.totalMesValues.length > 0 ? (
                                        data.totalMesValues.map((e) => (
                                            <input type="text" value={e.actual} disabled/>
                                        ))
                                    ):
                                    (
                                        <>
                                            0
                                        </>
                                    )
                            ) : ("") }</div>
                        </div>
                        <div className="table-inputs-bottom">
                            <button>Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
};
ProjeccaoComercialAdministrativa.propTypes = {
    auth: PropTypes.object.isRequired,
    getProjeccao: PropTypes.func.isRequired,
    currentDataPrev: PropTypes.func.isRequired,
    insertObjectivo: PropTypes.func.isRequired,
    insertProjeccoes: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    projeccao: state.projeccao
});
export default connect(mapStateToProps, { getProjeccao, currentDataPrev, insertObjectivo, insertProjeccoes })( ProjeccaoComercialAdministrativa );
