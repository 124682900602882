/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_PERSONAL_TRAINER,
    GET_PERSONAL_TRAINER_ERROR,
} from "./types";

export const getPersonalTrainerAvaliacoes = () => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }

    try {
        const res = await axios.post("/api/personaltrainer");

        dispatch({
            type: GET_PERSONAL_TRAINER,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PERSONAL_TRAINER_ERROR,
        })
    }
}


export const updatePersonalTRainer = ({  personalTrainerData, id_clube }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { personalTrainerData, id_clube };
    try {
        const res = await axios.post("/api/personaltrainer/update",body, config);


        dispatch({
            type: GET_PERSONAL_TRAINER,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PERSONAL_TRAINER_ERROR,
        })
    }
}
