/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getLimpeza, updateLimpeza } from "./actions/limpeza";
import Pagination from "../gestaocomercial/Pagination";

const LimpezaDiretorClube = ({ auth: {user}, getLimpeza, limpeza: { data }, updateLimpeza}) => {
    useEffect(() => {
        getLimpeza({id_clube: 1 });
    },[]);
    useLayoutEffect(() => {
        setManutencaoData({...manutencaoData,
            modal: data ? !!data.err : false,
            err: data ? data.err ?  data.err : false : false,
            loading:false,
            clube_selected: data ? data.clube_selected ?  data.clube_selected : 1 : 1,
        });
    },[data]);
    const [manutencaoData, setManutencaoData] = useState({
        modal: false,
        err: false,
        ambiente_gymnasium: "",
        info_gymnasium: "",
        ar_gymnasium: "",
        maquinas_gymnasium: "",
        organizacao_vendas_gymnasium: "",
        organizacao_gymnasium: "",
        interacao_gymnasium: "",
        apresentacao_staff_gymnasium: "",
        relacao_gymnasium: "",
        gymkids_gymnasium: "",
        aulas_gymnasium: "",
        ballimpeza_gymnasium : "",
        limpeza_gymnasium: "",
        cheia_gymnasium: "",
        som_gymnasium: "",
        luz_gymnasium: "",
        experiencia_gymnasium: "",
        id: "",
        clube_selected: 1
    });

    const { clube_selected, id, modal, err, ambiente_gymnasium,info_gymnasium, ar_gymnasium, maquinas_gymnasium, organizacao_vendas_gymnasium, organizacao_gymnasium, interacao_gymnasium,
        apresentacao_staff_gymnasium, relacao_gymnasium, gymkids_gymnasium, aulas_gymnasium, ballimpeza_gymnasium, limpeza_gymnasium, cheia_gymnasium, som_gymnasium, luz_gymnasium, experiencia_gymnasium } = manutencaoData;
    const onChange = e => setManutencaoData({...manutencaoData, [e.target.name]: e.target.value});
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let resultados = data ? data.limpeza ? data.limpeza : [] : [];
    const currentPosts = resultados.slice(indexOfFirstPost,indexOfLastPost);

    const paginate = (e,pageNumber) => {
        e.preventDefault()
        setCurrentPage(pageNumber)
    }
    const onChangeDate = async e => {
        setManutencaoData({...manutencaoData, [e.target.name]: e.target.value});


    };
    const onSubmit = async e => {
        e.preventDefault();

        try {
            await updateLimpeza({manutencaoData, id_clube: clube_selected })
        } catch (e) {

        }
    }
    const onOpenModal = async e => {
        e.preventDefault();
        setManutencaoData({...manutencaoData,
            ["modal"]: modal ? false : true,
            ["ambiente_gymnasium"]: "",
            ["info_gymnasium"]: "",
            ["ar_gymnasium"]: "",
            ["maquinas_gymnasium"]: "",
            ["organizacao_vendas_gymnasium"]: "",
            ["organizacao_gymnasium"]: "",
            ["interacao_gymnasium"]: "",
            ["apresentacao_staff_gymnasium"]: "",
            ["relacao_gymnasium"]: "",
            ["gymkids_gymnasium"]: "",
            ["aulas_gymnasium"]: "",
            ["ballimpeza_gymnasium"] : "",
            ["limpeza_gymnasium"]: "",
            ["cheia_gymnasium"]: "",
            ["som_gymnasium"]: "",
            ["luz_gymnasium"]: "",
            ["experiencia_gymnasium"]: "",
            ["id"]:"",
        });

    }
    const onCheckboxChange = (e) => {
        setManutencaoData({...manutencaoData, [e.target.name]: e.target.checked});
    }
    const  onOpenModalValues  = async (e,data, id) => {
        e.preventDefault();
        let LimpezaContent = JSON.parse(data);
        setManutencaoData({...manutencaoData,
            ["modal"]: modal ? false : true,
            ["ambiente_gymnasium"]: LimpezaContent ? LimpezaContent.ambiente_gymnasium === "yes" ? "yes" : "no" : "",
            ["info_gymnasium"]: LimpezaContent ? LimpezaContent.info_gymnasium === "yes" ? "yes" : "no" : "",
            ["ar_gymnasium"]: LimpezaContent ? LimpezaContent.ar_gymnasium === "yes" ? "yes" : "no" : "",
            ["maquinas_gymnasium"]: LimpezaContent ? LimpezaContent.maquinas_gymnasium === "yes" ? "yes" : "no" : "",
            ["organizacao_vendas_gymnasium"]:LimpezaContent ? LimpezaContent.organizacao_vendas_gymnasium === "yes" ? "yes" : "no" : "",
            ["organizacao_gymnasium"]:LimpezaContent ? LimpezaContent.organizacao_gymnasium === "yes" ? "yes" : "no" : "",
            ["interacao_gymnasium"]: LimpezaContent ? LimpezaContent.interacao_gymnasium === "yes" ? "yes" : "no" : "",
            ["apresentacao_staff_gymnasium"]:LimpezaContent ? LimpezaContent.apresentacao_staff_gymnasium === "yes" ? "yes" : "no" : "",
            ["relacao_gymnasium"]:LimpezaContent ? LimpezaContent.relacao_gymnasium === "yes" ? "yes" : "no" : "",
            ["gymkids_gymnasium"]: LimpezaContent ? LimpezaContent.gymkids_gymnasium === "yes" ? "yes" : "no" : "",
            ["aulas_gymnasium"]: LimpezaContent ? LimpezaContent.aulas_gymnasium === "yes" ? "yes" : "no" : "",
            ["ballimpeza_gymnasium"] : LimpezaContent ? LimpezaContent.ballimpeza_gymnasium === "yes" ? "yes" : "no" : "",
            ["limpeza_gymnasium"]: LimpezaContent ? LimpezaContent.limpeza_gymnasium === "yes" ? "yes" : "no" : "",
            ["cheia_gymnasium"]: LimpezaContent ? LimpezaContent.cheia_gymnasium === "yes" ? "yes" : "no" : "",
            ["som_gymnasium"]: LimpezaContent ? LimpezaContent.som_gymnasium === "yes" ? "yes" : "no" : "",
            ["luz_gymnasium"]: LimpezaContent ? LimpezaContent.luz_gymnasium === "yes" ? "yes" : "no" : "",
            ["experiencia_gymnasium"]: LimpezaContent ? LimpezaContent.experiencia_gymnasium === "yes" ? "yes" : "no" : "",
            ["id"]:id,
        });

    }

    const onChangeConsultor = async e => {
        setManutencaoData({...manutencaoData, [e.target.name]: e.target.value});
        await getLimpeza({id_clube: e.target.value });
    };

    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs" >
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Serviço ao Cliente >
                        <Link to={"/limpeza"}>Limpeza</Link>
                    </div>
                   {/* <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="">
                            Clube:
                            <select name="clube_selected"   value={clube_selected} onChange={ e => onChangeConsultor(e)} style={{textTransform: "capitalize"}}>
                                {user && user.clubes.map((e) => (
                                    <option value={e.id} >{e.localidade}</option>
                                ))}
                            </select>
                        </label>
                    </div>*/}

                    <button  onClick={e => onOpenModal(e)} className={"new-btn"}>Novo</button>
                    {data ? data.err ? (
                        <div className={data.err ? "alert alert-danger active": "alert alert-danger"}>{data.err}</div>
                    ) : ("") : ("")}

                    <table className="table-format-one">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Data</th>
                            </tr>
                        </thead>

                        <tbody>

                            {currentPosts && currentPosts.map(({ id, data, date }) => (
                                <tr className={"to-call"} onClick={e => onOpenModalValues(e,data,id)}>
                                    <td>Inspeção ao Clube</td>
                                    <td>
                                        { date ? (
                                            (parseInt(new Date(date).getDate())  < 10 ? ("0" + new Date(date).getDate()) : new Date(date).getDate())
                                            + "/" +
                                            (parseInt(new Date(date).getMonth() + 1)  < 10 ? ("0" + (new Date(date).getMonth() +1)) : new Date(date).getMonth() +1)
                                            + "/" +
                                            (parseInt(new Date(date).getFullYear())) + " " +

                                            (parseInt(new Date(date).getHours())  < 10 ? ("0" + new Date(date).getHours()) : new Date(date).getHours())
                                            + ":" +
                                            (parseInt(new Date(date).getMinutes())  < 10 ? ("0" + new Date(date).getMinutes()) : new Date(date).getMinutes())

                                        ): ("") }
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination postsPerPAge={postsPerPage} totalPosts={resultados.length} paginate={paginate} currentPage={currentPage}/>

                    <div className={modal ? "new-lead-modal new-manutencao  new-limpeza active": "new-manutencao new-lead-modal new-limpeza"}>
                         <span id="close-new-lead-modal" onClick={ e => onOpenModal(e)}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => onSubmit(e)}>
                            <label htmlFor="">
                                Ambiente do Gymnasium assim que se entra
                                <span>Sim
                                    <input type="radio" name={"ambiente_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={ambiente_gymnasium === "yes"} required/>
                                </span>
                                <span>Não
                                    <input type="radio" name={"ambiente_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={ambiente_gymnasium === "no"} required/>
                                </span>

                            </label>
                            <label htmlFor="">
                                Experiência durante o tour
                                <span>Sim
                                    <input type="radio" name={"experiencia_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={experiencia_gymnasium === "yes"} required/>
                                </span>
                                <span>Não
                                    <input type="radio" name={"experiencia_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={experiencia_gymnasium === "no"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Luz
                                <span>Sim
                                    <input type="radio" name={"luz_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={luz_gymnasium === "yes"} required />
                                </span>
                                <span>Não
                                    <input type="radio" name={"luz_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={luz_gymnasium === "no"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Som/ Música apropriada
                                <span>Sim
                                    <input type="radio" name={"som_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={som_gymnasium === "yes"}  required/>
                                </span>
                                <span>Não
                                    <input type="radio"  name={"som_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={som_gymnasium === "no"}  required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Cheia
                                <span>Sim
                                    <input type="radio" name={"cheia_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={cheia_gymnasium === "yes"}  required/>
                                </span>
                                <span>Não
                                    <input type="radio" name={"cheia_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={cheia_gymnasium === "no"}  required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Limpeza
                                <span>Sim
                                    <input type="radio" name={"limpeza_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={limpeza_gymnasium === "yes"}  required/>
                                </span>
                                <span>Não
                                    <input type="radio" name={"limpeza_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={limpeza_gymnasium === "no"}  required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Balneários - Limpeza
                                <span>Sim
                                    <input type="radio" name={"ballimpeza_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={ballimpeza_gymnasium === "yes"}  required/>
                                </span>
                                <span>Não
                                    <input type="radio" name={"ballimpeza_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={ballimpeza_gymnasium === "no"}  required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Salas de aulas
                                <span>Sim
                                    <input type="radio" name={"aulas_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={aulas_gymnasium === "yes"}  required/>
                                </span>
                                <span>Não
                                    <input type="radio" name={"aulas_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={aulas_gymnasium === "no"}  required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Gymkids: Materiais em bom estado/limpeza
                                <span>Sim
                                    <input type="radio"  name={"gymkids_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={gymkids_gymnasium === "yes"}  required/>
                                </span>
                                <span>Não
                                    <input type="radio" name={"gymkids_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={gymkids_gymnasium === "no"}  required/>
                                </span>
                            </label>

                            <label htmlFor="">
                                Relação próxima do Staff com os sócios
                                <span>Sim
                                    <input type="radio" name={"relacao_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={relacao_gymnasium === "yes"} required/>
                                </span>
                                <span>Não
                                    <input type="radio" name={"relacao_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={relacao_gymnasium === "no"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Apresentação do Staff (uniforme/higiene)
                                <span>Sim
                                    <input type="radio" name={"apresentacao_staff_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={apresentacao_staff_gymnasium === "yes"} required />
                                </span>
                                <span>Não
                                    <input type="radio" name={"apresentacao_staff_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={apresentacao_staff_gymnasium === "no"}  required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Interação na sala de máquinas com os sócios
                                <span>Sim
                                    <input type="radio"  name={"interacao_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={interacao_gymnasium === "yes"}  required/>
                                </span>
                                <span>Não
                                    <input type="radio" name={"interacao_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={interacao_gymnasium === "no"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Organização das Áreas de Staff
                                <span>Sim
                                    <input type="radio" name={"organizacao_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={organizacao_gymnasium === "yes"}  required/>
                                </span>
                                <span>Não
                                    <input type="radio" name={"organizacao_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={organizacao_gymnasium === "no"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Organização Office de Vendas/ Recepção
                                <span>Sim
                                    <input type="radio" name={"organizacao_vendas_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={organizacao_vendas_gymnasium === "yes"} required/>
                                </span>
                                <span>Não
                                    <input type="radio" name={"organizacao_vendas_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={organizacao_vendas_gymnasium === "no"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Máquinas avariadas
                                <span>Sim
                                    <input type="radio" name={"maquinas_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={maquinas_gymnasium === "yes"} required/>
                                </span>
                                <span>Não
                                    <input type="radio" name={"maquinas_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={maquinas_gymnasium === "no"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Ar condicionado/qualidade do ar
                                <span>Sim
                                    <input type="radio" name={"ar_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={ar_gymnasium === "yes"} required/>
                                </span>
                                <span>Não
                                    <input type="radio" name={"ar_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={ar_gymnasium === "no"} required/>
                                </span>
                            </label>
                            <label htmlFor="">
                                Informação aos sócios está no local próprio
                                <span>Sim
                                    <input type="radio" name={"info_gymnasium"} value={"yes"} onChange={e => onChange(e)} checked={info_gymnasium === "yes"} required/>
                                </span>
                                <span>Não
                                    <input type="radio" name={"info_gymnasium"} value={"no"} onChange={e => onChange(e)} checked={info_gymnasium === "no"} required/>
                                </span>
                            </label>
                            <button>GUARDAR</button>
                        </form>
                    </div>

                </div>
            </div>

        </Fragment>
};
LimpezaDiretorClube.propTypes = {
    auth: PropTypes.object.isRequired,
    getLimpeza:  PropTypes.func.isRequired,
    updateLimpeza:  PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    limpeza: state.limpeza
});
export default connect(mapStateToProps, { getLimpeza, updateLimpeza })( LimpezaDiretorClube );
