/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect,useLayoutEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import { getInscricao, insertInscricao } from "./actions/inscricoes";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


const InscricoesAdministracao = ({ auth: {user}, insertInscricao, getInscricao, inscricoes: {data}}) => {
    useEffect(() => {
        getInscricao({ date: moment().format("YYYY-MM-DD"), id_adesao: 1, id_clube: 1 });
    },[]);
    useLayoutEffect(() => {
        setInscricoesData(
            {
                ...inscricoesData,
                totalMes: data && data.totalmes,
                real: data && data.inscricoes && data.inscricoes.value,
                clube_selected:  data && data.clube_selected,
                objectivo_real: data && data.objectivos ? data.objectivos : 0,
            }
        );

    },[data]);
    const [inscricoesData, setInscricoesData] = useState({
        real: 0,
        date: moment().format("YYYY-MM-DD"),
        totalMes: 0,
        clube_selected: 1,
        objectivo_real: 0
    });

    const { real, date , totalMes, clube_selected, objectivo_real} = inscricoesData;
    const onChange = e => setInscricoesData({...inscricoesData, [e.target.name]: e.target.value});

    const onChangeDate = async e => {
        setInscricoesData({...inscricoesData, [e.target.name]: e.target.value});

        await getInscricao({ date: e.target.value, id_adesao: 1 , id_clube: clube_selected});
    };
    const onChangeCol = async e => {
        setInscricoesData({...inscricoesData, [e.target.name]: e.target.value});
        await getInscricao({ date, id_adesao: 1 , id_clube:  e.target.value});

    };
    const onSubmit = async e => {
        e.preventDefault();
        await insertInscricao({ real, date, id_adesao: 1 , id_clube: clube_selected, objectivo_real});
    }
    return user === null ? <Fragment> <div><img src="./img/loading.gif" alt=""/></div> </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container" >
                <div className="container" >
                    <div className="breadcrumbs">
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Gestão Comercial >
                        <Link to={"/inscricoes"}>Inscrições</Link>
                    </div>
                    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="">
                            Clube:
                            <select name="clube_selected"   value={clube_selected} onChange={ e => onChangeCol(e)} style={{textTransform: "capitalize"}}>
                                {user && user.clubes.map((e) => (
                                    <option value={e.id} >{e.localidade}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <form onSubmit={e => onSubmit(e) } className={"table-inputs"}>
                        <h2>Objetivo Inscrições: {objectivo_real} / Real Inscrições Mês: {totalMes}</h2>

                        <label htmlFor="">Filtro:
                            <input type="date" name={"date"} value={date} onChange={e => onChangeDate(e)}/>
                        </label>
                        <label htmlFor="">Objectivo Mês:
                            <input type="number" name={"objectivo_real"} value={objectivo_real} onChange={e => onChange(e)}/>
                        </label>
                        <label htmlFor="">Real Dia:
                            <input type="number" name={"real"} value={real} onChange={e => onChange(e)}/>
                        </label>

                        <button>Guardar</button>
                    </form>

                </div>
            </div>
        </Fragment>
};
InscricoesAdministracao.propTypes = {
    insertInscricao: PropTypes.func.isRequired,
    getInscricao: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    inscricoes: state.inscricoes,
});
export default connect(mapStateToProps, {  insertInscricao , getInscricao })(InscricoesAdministracao);
