/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_PONTOS_CRITICOS,
    GET_PONTOS_CRITICOS_ERROR,
} from "./types";

export const getPontosCriticos = ({id_clube}) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = {  id_clube };
        const res = await axios.post("/api/pontoscriticos" , body, config);


        dispatch({
            type: GET_PONTOS_CRITICOS,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PONTOS_CRITICOS_ERROR,
        })
    }
}
export const updateOrInsertPontosCriticos = ({  date, topico, status, descricao, solucao, idponto, id_clube  }) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = { date, topico, status, descricao, solucao, idponto , id_clube };

        const res = await axios.post("/api/pontoscriticos/update", body, config);


        dispatch({
            type: GET_PONTOS_CRITICOS,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PONTOS_CRITICOS_ERROR,
        })
    }
}
export const deletePontosCriticos = ({ idponto , id_clube }) => async dispatch => {

    try {
        const config = {
            header: {
                "Content-Type": "application/json"
            }
        }
        const body = { idponto ,id_clube  };

        const res = await axios.post("/api/pontoscriticos/delete", body, config);


        dispatch({
            type: GET_PONTOS_CRITICOS,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_PONTOS_CRITICOS_ERROR,
        })
    }
}

