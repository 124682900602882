/**
 * Created by danielsilva on 10/04/2020.
 */
import React, { Fragment, useState, useEffect, useLayoutEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import "../../../styles/tables.css";
import "../../../styles/leads.css";
import moment from "moment";
import { getBancoDD, updateBancoDD, newBancoDD } from "./actions/bancodd";
import NumberFormat from 'react-number-format';

const BancoDDAdministracao = ({ auth: { user }, getBancoDD, updateBancoDD, newBancoDD, bancodd: { data } }) => {
    useEffect(() => {
        getBancoDD({ id_clube: 1, banco_dd: null })
    }, []);

    useLayoutEffect(() => {
        setBancoDDData({
            ...bancoDDData,

            bancoList: data ? data.all_list ? data.all_list : "" : "",
            banco_selected: data ? data.banco_selected ? data.banco_selected : data.all_list[0].id : "",
            clube_selected: data ? data.clube_selected ? data.clube_selected : 1 : 1,

            first_envio_qtd_first: data ? data.dataJson ? data.dataJson.first_envio_qtd_first : 0 : 0,
            first_envio_vlr_first: data ? data.dataJson ? data.dataJson.first_envio_vlr_first : 0 : 0,
            first_retorno_qtd_first: data ? data.dataJson ? data.dataJson.first_retorno_qtd_first : 0 : 0,
            first_retorno_vlr_first: data ? data.dataJson ? data.dataJson.first_retorno_vlr_first : 0 : 0,
            first_envio_qtd_rcur: data ? data.dataJson ? data.dataJson.first_envio_qtd_rcur : 0 : 0,
            first_envio_vlr_rcur: data ? data.dataJson ? data.dataJson.first_envio_vlr_rcur : 0 : 0,
            first_retorno_qtd_rcur: data ? data.dataJson ? data.dataJson.first_retorno_qtd_rcur : 0 : 0,
            first_retorno_vlr_rcur: data ? data.dataJson ? data.dataJson.first_retorno_vlr_rcur : 0 : 0,

            first_agua_vlr_first: data ? data.dataJson ? data.dataJson.first_agua_vlr_first : 0 : 0,
            first_seguros_vlr_first: data ? data.dataJson ? data.dataJson.first_seguros_vlr_first : 0 : 0,
            first_mensalidades_vlr_first: data ? data.dataJson ? data.dataJson.first_mensalidades_vlr_first : 0 : 0,
            first_agua_vlr_rcur: data ? data.dataJson ? data.dataJson.first_agua_vlr_rcur : 0 : 0,
            first_seguros_vlr_rcur: data ? data.dataJson ? data.dataJson.first_seguros_vlr_rcur : 0 : 0,
            first_mensalidades_vlr_rcur: data ? data.dataJson ? data.dataJson.first_mensalidades_vlr_rcur : 0 : 0,


            total_qtd_envio: data ? data.total_qtd_envio ? data.total_qtd_envio : 0 : 0,
            total_vlr_retorno: data ? data.total_vlr_retorno ? data.total_vlr_retorno : 0 : 0,
            total_qtd_retorno: data ? data.total_qtd_retorno ? data.total_qtd_retorno : 0 : 0,
            total_vlr_retorno_first: data ? data.total_vlr_retorno_first ? data.total_vlr_retorno_first : 0 : 0,

            total_vlr_agua_first: data ? data.total_vlr_agua_first ? data.total_vlr_agua_first : 0 : 0,
            total_vlr_seguros_first: data ? data.total_vlr_seguros_first ? data.total_vlr_seguros_first : 0 : 0,
            total_vlr_mensalidades_first: data ? data.total_vlr_mensalidades_first ? data.total_vlr_mensalidades_first : 0 : 0,
            total_vlr_asm_first: data ? data.total_vlr_asm_first ? data.total_vlr_asm_first : 0 : 0,


            second_envio_qtd_first: data ? data.dataJson ? data.dataJson.second_envio_qtd_first : 0 : 0,
            second_envio_vlr_first: data ? data.dataJson ? data.dataJson.second_envio_vlr_first : 0 : 0,
            second_retorno_qtd_first: data ? data.dataJson ? data.dataJson.second_retorno_qtd_first : 0 : 0,
            second_retorno_vlr_first: data ? data.dataJson ? data.dataJson.second_retorno_vlr_first : 0 : 0,
            second_envio_qtd_rcur: data ? data.dataJson ? data.dataJson.second_envio_qtd_rcur : 0 : 0,
            second_envio_vlr_rcur: data ? data.dataJson ? data.dataJson.second_envio_vlr_rcur : 0 : 0,
            second_retorno_qtd_rcur: data ? data.dataJson ? data.dataJson.second_retorno_qtd_rcur : 0 : 0,
            second_retorno_vlr_rcur: data ? data.dataJson ? data.dataJson.second_retorno_vlr_rcur : 0 : 0,

            second_agua_vlr_first: data ? data.dataJson ? data.dataJson.second_agua_vlr_first : 0 : 0,
            second_seguros_vlr_first: data ? data.dataJson ? data.dataJson.second_seguros_vlr_first : 0 : 0,
            second_mensalidades_vlr_first: data ? data.dataJson ? data.dataJson.second_mensalidades_vlr_first : 0 : 0,
            second_agua_vlr_rcur: data ? data.dataJson ? data.dataJson.second_agua_vlr_rcur : 0 : 0,
            second_seguros_vlr_rcur: data ? data.dataJson ? data.dataJson.second_seguros_vlr_rcur : 0 : 0,
            second_mensalidades_vlr_rcur: data ? data.dataJson ? data.dataJson.second_mensalidades_vlr_rcur : 0 : 0,

            total_qtd_envio_second: data ? data.total_qtd_envio_second ? data.total_qtd_envio_second : 0 : 0,
            total_qtd_retorno_second: data ? data.total_qtd_retorno_second ? data.total_qtd_retorno_second : 0 : 0,
            total_vlr_retornosecond: data ? data.total_vlr_retornosecond ? data.total_vlr_retornosecond : 0 : 0,
            total_vlr_retorno_second: data ? data.total_vlr_retorno_second ? data.total_vlr_retorno_second : 0 : 0,

            total_vlr_agua_second: data ? data.total_vlr_agua_second ? data.total_vlr_agua_second : 0 : 0,
            total_vlr_seguros_second: data ? data.total_vlr_seguros_second ? data.total_vlr_seguros_second : 0 : 0,
            total_vlr_mensalidades_second: data ? data.total_vlr_mensalidades_second ? data.total_vlr_mensalidades_second : 0 : 0,
            total_vlr_asm_second: data ? data.total_vlr_asm_second ? data.total_vlr_asm_second : 0 : 0,


            third_envio_qtd_first: data ? data.dataJson ? data.dataJson.third_envio_qtd_first : 0 : 0,
            third_envio_vlr_first: data ? data.dataJson ? data.dataJson.third_envio_vlr_first : 0 : 0,
            third_retorno_qtd_first: data ? data.dataJson ? data.dataJson.third_retorno_qtd_first : 0 : 0,
            third_retorno_vlr_first: data ? data.dataJson ? data.dataJson.third_retorno_vlr_first : 0 : 0,
            third_envio_qtd_rcur: data ? data.dataJson ? data.dataJson.third_envio_qtd_rcur : 0 : 0,
            third_envio_vlr_rcur: data ? data.dataJson ? data.dataJson.third_envio_vlr_rcur : 0 : 0,
            third_retorno_qtd_rcur: data ? data.dataJson ? data.dataJson.third_retorno_qtd_rcur : 0 : 0,
            third_retorno_vlr_rcur: data ? data.dataJson ? data.dataJson.third_retorno_vlr_rcur : 0 : 0,

            third_agua_vlr_first: data ? data.dataJson ? data.dataJson.third_agua_vlr_first : 0 : 0,
            third_seguros_vlr_first: data ? data.dataJson ? data.dataJson.third_seguros_vlr_first : 0 : 0,
            third_mensalidades_vlr_first: data ? data.dataJson ? data.dataJson.third_mensalidades_vlr_first : 0 : 0,
            third_agua_vlr_rcur: data ? data.dataJson ? data.dataJson.third_agua_vlr_rcur : 0 : 0,
            third_seguros_vlr_rcur: data ? data.dataJson ? data.dataJson.third_seguros_vlr_rcur : 0 : 0,
            third_mensalidades_vlr_rcur: data ? data.dataJson ? data.dataJson.third_mensalidades_vlr_rcur : 0 : 0,


            total_qtd_envio_third: data ? data.total_qtd_envio_third ? data.total_qtd_envio_third : 0 : 0,
            total_qtd_retorno_third: data ? data.total_qtd_retorno_third ? data.total_qtd_retorno_third : 0 : 0,
            total_vlr_retornothird: data ? data.total_vlr_retornothird ? data.total_vlr_retornothird : 0 : 0,
            total_vlr_retorno_third: data ? data.total_vlr_retorno_third ? data.total_vlr_retorno_third : 0 : 0,

            total_vlr_agua_third: data ? data.total_vlr_agua_third ? data.total_vlr_agua_third : 0 : 0,
            total_vlr_seguros_third: data ? data.total_vlr_seguros_third ? data.total_vlr_seguros_third : 0 : 0,
            total_vlr_mensalidades_third: data ? data.total_vlr_mensalidades_third ? data.total_vlr_mensalidades_third : 0 : 0,
            total_vlr_asm_third: data ? data.total_vlr_asm_third ? data.total_vlr_asm_third : 0 : 0,


            total_vlr_asm: data ? data.total_vlr_asm ? data.total_vlr_asm : 0 : 0,
            total_recebido_mes: data ? data.total_recebido_mes ? data.total_recebido_mes : 0 : 0,
            recebido_perc: data ? data.recebido_perc ? data.recebido_perc : 0 : 0,
            total_retencao_perc: data ? data.total_retencao_perc ? data.total_retencao_perc : 0 : 0,
            total_socios_pages: data ? data.total_socios_pages ? data.total_socios_pages : 0 : 0,

            total_socios_pagos_geral: data ? data.total_socios_pagos_geral ? data.total_socios_pagos_geral : 0 : 0,
            total_recebido_mes_geral: data ? data.total_recebido_mes_geral ? data.total_recebido_mes_geral : 0 : 0,


            total_enviados_geral_first: data ? data.total_enviados_geral_first ? data.total_enviados_geral_first : 0 : 0,
            total_enviados_geral_second: data ? data.total_enviados_geral_second ? data.total_enviados_geral_second : 0 : 0,
            total_enviados_geral_third: data ? data.total_enviados_geral_third ? data.total_enviados_geral_third : 0 : 0,

            total_retorno_geral_first: data ? data.total_retorno_geral_first ? data.total_retorno_geral_first : 0 : 0,
            total_retorno_geral_second: data ? data.total_retorno_geral_second ? data.total_retorno_geral_second : 0 : 0,
            total_retorno_geral_third: data ? data.total_retorno_geral_third ? data.total_retorno_geral_third : 0 : 0,

            refused_adc: data ? data.refused_adc ? data.refused_adc : 0 : 0,
            refused_aut: data ? data.refused_aut ? data.refused_aut : 0 : 0,
            refused_nores: data ? data.refused_nores ? data.refused_nores : 0 : 0,
            refused_closed: data ? data.refused_closed ? data.refused_closed : 0 : 0,
            refused_nofunds: data ? data.refused_nofunds ? data.refused_nofunds : 0 : 0,
            refused_contest: data ? data.refused_contest ? data.refused_contest : 0 : 0,
            refused_refused: data ? data.refused_refused ? data.refused_refused : 0 : 0,
            refused_serv: data ? data.refused_serv ? data.refused_serv : 0 : 0,


            two_refused_adc: data ? data.two_refused_adc ? data.two_refused_adc : 0 : 0,
            two_refused_aut: data ? data.two_refused_aut ? data.two_refused_aut : 0 : 0,
            two_refused_nores: data ? data.two_refused_nores ? data.two_refused_nores : 0 : 0,
            two_refused_closed: data ? data.two_refused_closed ? data.two_refused_closed : 0 : 0,
            two_refused_nofunds: data ? data.two_refused_nofunds ? data.two_refused_nofunds : 0 : 0,
            two_refused_contest: data ? data.two_refused_contest ? data.two_refused_contest : 0 : 0,
            two_refused_refused: data ? data.two_refused_refused ? data.two_refused_refused : 0 : 0,
            two_refused_serv: data ? data.two_refused_serv ? data.two_refused_serv : 0 : 0,


            third_refused_adc: data ? data.third_refused_adc ? data.third_refused_adc : 0 : 0,
            third_refused_aut: data ? data.third_refused_aut ? data.third_refused_aut : 0 : 0,
            third_refused_nores: data ? data.third_refused_nores ? data.third_refused_nores : 0 : 0,
            third_refused_closed: data ? data.third_refused_closed ? data.third_refused_closed : 0 : 0,
            third_refused_nofunds: data ? data.third_refused_nofunds ? data.third_refused_nofunds : 0 : 0,
            third_refused_contest: data ? data.third_refused_contest ? data.third_refused_contest : 0 : 0,
            third_refused_refused: data ? data.third_refused_refused ? data.third_refused_refused : 0 : 0,
            third_refused_serv: data ? data.third_refused_serv ? data.third_refused_serv : 0 : 0,
        });

    }, [data]);

    const [bancoDDData, setBancoDDData] = useState({
        modal: false,
        date: moment().format("YYYY-MM-DD"),
        banco_selected: "",
        bancoList: [],
        err: false,
        first_envio_qtd_first: 0,
        first_envio_vlr_first: 0,
        first_retorno_qtd_first: 0,
        first_retorno_vlr_first: 0,
        first_envio_qtd_rcur: 0,
        first_envio_vlr_rcur: 0,
        first_retorno_qtd_rcur: 0,
        first_retorno_vlr_rcur: 0,
        clube_selected: 1,
        total_qtd_envio: 0,
        total_vlr_retorno: 0,
        total_qtd_retorno: 0,
        total_vlr_retorno_first: 0,
        first_agua_vlr_first: 0,
        first_seguros_vlr_first: 0,
        first_mensalidades_vlr_first: 0,
        first_agua_vlr_rcur: 0,
        first_seguros_vlr_rcur: 0,
        first_mensalidades_vlr_rcur: 0,
        total_vlr_agua_first: 0,
        total_vlr_seguros_first: 0,
        total_vlr_mensalidades_first: 0,
        total_vlr_asm_first: 0,


        second_envio_qtd_first: 0,
        second_envio_vlr_first: 0,
        second_retorno_qtd_first: 0,
        second_retorno_vlr_first: 0,
        second_envio_qtd_rcur: 0,
        second_envio_vlr_rcur: 0,
        second_retorno_qtd_rcur: 0,
        second_retorno_vlr_rcur: 0,
        total_qtd_envio_second: 0,
        total_qtd_retorno_second: 0,
        total_vlr_retornosecond: 0,
        total_vlr_retorno_second: 0,
        second_agua_vlr_first: 0,
        second_seguros_vlr_first: 0,
        second_mensalidades_vlr_first: 0,
        second_agua_vlr_rcur: 0,
        second_seguros_vlr_rcur: 0,
        second_mensalidades_vlr_rcur: 0,
        total_vlr_agua_second: 0,
        total_vlr_seguros_second: 0,
        total_vlr_mensalidades_second: 0,
        total_vlr_asm_second: 0,


        third_envio_qtd_first: 0,
        third_envio_vlr_first: 0,
        third_retorno_qtd_first: 0,
        third_retorno_vlr_first: 0,
        third_envio_qtd_rcur: 0,
        third_envio_vlr_rcur: 0,
        third_retorno_qtd_rcur: 0,
        third_retorno_vlr_rcur: 0,
        total_qtd_envio_third: 0,
        total_qtd_retorno_third: 0,
        total_vlr_retornothird: 0,
        total_vlr_retorno_third: 0,
        third_agua_vlr_first: 0,
        third_seguros_vlr_first: 0,
        third_mensalidades_vlr_first: 0,
        third_agua_vlr_rcur: 0,
        third_seguros_vlr_rcur: 0,
        third_mensalidades_vlr_rcur: 0,
        total_vlr_agua_third: 0,
        total_vlr_seguros_thrid: 0,
        total_vlr_mensalidades_third: 0,
        total_vlr_asm_third: 0,

        total_vlr_asm: 0,
        total_recebido_mes: 0,
        recebido_perc: 0,
        total_retencao_perc: 0,
        total_socios_pages: 0,
        total_socios_pagos_geral: 0,
        total_recebido_mes_geral: 0,

        total_enviados_geral_first: 0,
        total_enviados_geral_second: 0,
        total_enviados_geral_third: 0,

        total_retorno_geral_first: 0,
        total_retorno_geral_second: 0,
        total_retorno_geral_third: 0,
        nome_dd: "",
        date_dd: "",
        refused_adc: 0,
        refused_aut: 0,
        refused_nores: 0,
        refused_closed: 0,
        refused_nofunds: 0,
        refused_contest: 0,
        refused_refused: 0,
        refused_serv: 0,

        two_refused_adc: 0,
        two_refused_aut: 0,
        two_refused_nores: 0,
        two_refused_closed: 0,
        two_refused_nofunds: 0,
        two_refused_contest: 0,
        two_refused_refused: 0,
        two_refused_serv: 0,


        third_refused_adc: 0,
        third_refused_aut: 0,
        third_refused_nores: 0,
        third_refused_closed: 0,
        third_refused_nofunds: 0,
        third_refused_contest: 0,
        third_refused_refused: 0,
        third_refused_serv: 0,
    });

    const {
        two_refused_adc, two_refused_aut, two_refused_nores, two_refused_closed, two_refused_nofunds, two_refused_contest, two_refused_refused, two_refused_serv,
        third_refused_adc, third_refused_aut, third_refused_nores, third_refused_closed, third_refused_nofunds, third_refused_contest, third_refused_refused, third_refused_serv,
        refused_adc, refused_aut, refused_nores, refused_closed, refused_nofunds, refused_contest, refused_refused, refused_serv,
        nome_dd, date_dd, total_enviados_geral_first, total_enviados_geral_second, total_enviados_geral_third, total_retorno_geral_first, total_retorno_geral_second, total_retorno_geral_third,
        total_socios_pagos_geral, total_recebido_mes_geral,
        total_retencao_perc, recebido_perc, total_recebido_mes, total_socios_pages,
        second_envio_qtd_first, second_envio_vlr_first, second_retorno_qtd_first, second_retorno_vlr_first,
        second_envio_qtd_rcur, second_envio_vlr_rcur, second_retorno_qtd_rcur, second_retorno_vlr_rcur,

        third_envio_qtd_first, third_envio_vlr_first, third_retorno_qtd_first, third_retorno_vlr_first,
        third_envio_qtd_rcur, third_envio_vlr_rcur, third_retorno_qtd_rcur, third_retorno_vlr_rcur,

        total_qtd_envio_second, total_qtd_retorno_second, total_vlr_retornosecond, total_vlr_retorno_second,
        total_qtd_envio_third, total_qtd_retorno_third, total_vlr_retornothird, total_vlr_retorno_third,
        total_qtd_envio, total_vlr_retorno, total_qtd_retorno, total_vlr_retorno_first, clube_selected, err, modal, date, banco_selected, bancoList,
        first_envio_qtd_first, first_envio_vlr_first, first_retorno_qtd_first, first_retorno_vlr_first,
        first_envio_qtd_rcur, first_envio_vlr_rcur, first_retorno_qtd_rcur, first_retorno_vlr_rcur,
        first_agua_vlr_first, first_seguros_vlr_first, first_mensalidades_vlr_first, first_agua_vlr_rcur, first_seguros_vlr_rcur, first_mensalidades_vlr_rcur,
        second_agua_vlr_first, second_seguros_vlr_first, second_mensalidades_vlr_first, second_agua_vlr_rcur, second_seguros_vlr_rcur, second_mensalidades_vlr_rcur,
        third_agua_vlr_first, third_seguros_vlr_first, third_mensalidades_vlr_first, third_agua_vlr_rcur, third_seguros_vlr_rcur, third_mensalidades_vlr_rcur,
        total_vlr_agua_first, total_vlr_seguros_first, total_vlr_mensalidades_first, total_vlr_asm_first,
        total_vlr_agua_second, total_vlr_seguros_second, total_vlr_mensalidades_second, total_vlr_asm_second,
        total_vlr_agua_third, total_vlr_seguros_third, total_vlr_mensalidades_third, total_vlr_asm_third, total_vlr_asm } = bancoDDData;
    const onChange = e => setBancoDDData({ ...bancoDDData, [e.target.name]: e.target.value });


    const onSubmit = async e => {
        e.preventDefault();

        try {
            await updateBancoDD({ id_clube: clube_selected, banco_dd: banco_selected, data: bancoDDData })
        } catch (e) {

        }

    }
    const onCreateNewDD = async e => {
        e.preventDefault();

        try {
            await newBancoDD({ nome_dd, date_dd })
        } catch (e) {

        }

    }
    const onCloseModal = async e => {
        e.preventDefault();
        setBancoDDData({
            ...bancoDDData,
            ["modal"]: modal ? false : true,
            ["nome_dd"]: "",
            ["date_dd"]: "",
        });
    }
    const onOpenModal = async e => {
        e.preventDefault();
        setBancoDDData({
            ...bancoDDData,
            ["modal"]: modal ? false : true,
            ["date"]: moment().format("YYYY-MM-DD"),

        });
    }
    const onChangeCol = async e => {
        setBancoDDData({ ...bancoDDData, [e.target.name]: e.target.value });
        await getBancoDD({ id_clube: e.target.value, banco_dd: banco_selected })
    };
    const onChangeColMes = async e => {
        setBancoDDData({ ...bancoDDData, [e.target.name]: e.target.value });
        await getBancoDD({ id_clube: clube_selected, banco_dd: e.target.value })
    };
    const onChangeNumber = e => setBancoDDData({ ...bancoDDData, [e.target.name]: e.target.value.replace("€", "") });

    return user === null ? <Fragment>
        <div><img src="./img/loading.gif" alt="" /></div>
    </Fragment> :
        <Fragment>
            <Header />
            <div id="main-container">
                <div className="container">
                    <div className="breadcrumbs">
                        <Link to={"/dashboard"}>Dashboard</Link>
                        > Gestão Clube >
                        <Link to={"/bancoDD"}>Banco DD</Link>
                    </div>
                    <div className="table-inputs-filtros table-inputs-filtros-contactos">
                        <label htmlFor="">
                            Clube:
                            <select name="clube_selected" value={clube_selected} onChange={e => onChangeCol(e)}
                                style={{ textTransform: "capitalize" }}>
                                {user && user.clubes.map((e) => (
                                    <option value={e.id}>{e.localidade}</option>
                                ))}
                            </select>
                        </label>

                        <label htmlFor="">
                            Mês:
                            <select name="banco_selected" value={banco_selected} onChange={e => onChangeColMes(e)}
                                style={{ textTransform: "capitalize" }}>
                                {bancoList && bancoList.map((e) => (
                                    <option value={e.id}>{e.nome_dd}</option>
                                ))}
                            </select>
                        </label>
                    </div>

                    <button onClick={e => onOpenModal(e)} className={"new-btn"}>Novo</button>

                    <form onSubmit={e => onSubmit(e)}>

                        <table className="ui celled table dd_ana"
                            style={{ "maxWidth": "1085px", "border-collapse": "collapse", "minWidth": "960px" }}>
                            <thead>
                                <tr>
                                    <th style={{ "width": "120px" }}></th>
                                    <th style={{ "width": "120px" }}></th>
                                    <th></th>
                                    <th>FIRST</th>
                                    <th>RCUR</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>

                            <tbody style={{ "borderRight": "1px solid", "borderLeft": "1px solid" }}>
                                <tr>
                                    <td style={{ "borderRight": "1px solid  #552cc6" }}>1ª Fase<br />dia 25</td>
                                    <td style={{ "borderRight": "1px solid  #552cc6", "height": "100px" }}>
                                        <table style={{ "height": "130px" }}>
                                            <tbody>
                                                <tr>
                                                    <td>1º Envio</td>
                                                </tr>
                                                <tr>
                                                    <td>1ª Retorno</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Qtd</td>
                                                </tr>
                                                <tr>
                                                    <td>Valor</td>
                                                </tr>
                                                <tr>
                                                    <td>Qtd</td>
                                                </tr>
                                                <tr>
                                                    <td>Valor</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>

                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={first_envio_qtd_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} name={"first_envio_qtd_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={first_envio_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"first_envio_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={first_retorno_qtd_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} name={"first_retorno_qtd_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={first_retorno_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"first_retorno_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={first_envio_qtd_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} name={"first_envio_qtd_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={first_envio_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"first_envio_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={first_retorno_qtd_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} name={"first_retorno_qtd_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={first_retorno_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"first_retorno_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={(total_qtd_envio)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} displayType={"text"} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={total_vlr_retorno} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={total_qtd_retorno} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} displayType={"text"} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={total_vlr_retorno_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ "borderRight": "none", "width": "200px" }}>Água Vitaminada</td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>
                                    <td style={{ "borderLeft": "none", "borderRight": "1px solid  #552cc6" }}></td>

                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={first_agua_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"first_agua_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={first_agua_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"first_agua_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={total_vlr_agua_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ "borderRight": "none", "width": "200px" }}>Seguros</td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>
                                    <td style={{ "borderLeft": "none", "borderRight": "1px solid  #552cc6" }}></td>

                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={first_seguros_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"first_seguros_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={first_seguros_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"first_seguros_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={(total_vlr_seguros_first)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ "borderRight": "none", "width": "200px" }}>Mensalidades</td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>
                                    <td style={{ "borderLeft": "none", "borderRight": "1px solid  #552cc6" }}></td>

                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={first_mensalidades_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"first_mensalidades_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={first_mensalidades_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"first_mensalidades_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={(total_vlr_mensalidades_first)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ "borderRight": "none", "width": "200px" }}>Total</td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>

                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}>

                                    </td>
                                    <td style={{ "borderLeft": "none", "borderRight": "1px solid  #552cc6" }}>

                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={(total_vlr_asm_first)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ "borderRight": "1px solid  #552cc6" }}>2ª Fase<br />dia 3/4</td>
                                    <td style={{ "borderRight": "1px solid  #552cc6", "height": "100px" }}>
                                        <table style={{ "height": "130px" }}>
                                            <tbody>
                                                <tr>
                                                    <td>2º Envio</td>
                                                </tr>
                                                <tr>
                                                    <td>2ª Retorno</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Qtd</td>
                                                </tr>
                                                <tr>
                                                    <td>Valor</td>
                                                </tr>
                                                <tr>
                                                    <td>Qtd</td>
                                                </tr>
                                                <tr>
                                                    <td>Valor</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>

                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={second_envio_qtd_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} name={"second_envio_qtd_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={second_envio_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"second_envio_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={second_retorno_qtd_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} name={"second_retorno_qtd_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={second_retorno_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"second_retorno_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={second_envio_qtd_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} name={"second_envio_qtd_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={second_envio_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"second_envio_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={second_retorno_qtd_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} name={"second_retorno_qtd_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={second_retorno_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"second_retorno_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={(total_qtd_envio_second)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} displayType={"text"} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={total_vlr_retornosecond} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={total_qtd_retorno_second} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} displayType={"text"} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={total_vlr_retorno_second} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ "borderRight": "none", "width": "200px" }}>Água Vitaminada</td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>
                                    <td style={{ "borderLeft": "none", "borderRight": "1px solid  #552cc6" }}></td>

                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={second_agua_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"second_agua_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={second_agua_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"second_agua_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={(total_vlr_agua_second)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ "borderRight": "none", "width": "200px" }}>Seguros</td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>
                                    <td style={{ "borderLeft": "none", "borderRight": "1px solid  #552cc6" }}></td>

                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={second_seguros_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"second_seguros_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={second_seguros_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"second_seguros_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={(total_vlr_seguros_second)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ "borderRight": "none", "width": "200px" }}>Mensalidades</td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>
                                    <td style={{ "borderLeft": "none", "borderRight": "1px solid  #552cc6" }}></td>

                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={second_mensalidades_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"second_mensalidades_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={second_mensalidades_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"second_mensalidades_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={(total_vlr_mensalidades_second)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ "borderRight": "none", "width": "200px" }}>Total</td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>

                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}>

                                    </td>
                                    <td style={{ "borderLeft": "none", "borderRight": "1px solid  #552cc6" }}>

                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={(total_vlr_asm_second)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ "borderRight": "1px solid  #552cc6" }}>3ª Fase<br />dia 13/14</td>
                                    <td style={{ "borderRight": "1px solid  #552cc6", "height": "100px" }}>
                                        <table style={{ "height": "130px" }}>
                                            <tbody>
                                                <tr>
                                                    <td>3º Envio</td>
                                                </tr>
                                                <tr>
                                                    <td>3ª Retorno</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Qtd</td>
                                                </tr>
                                                <tr>
                                                    <td>Valor</td>
                                                </tr>
                                                <tr>
                                                    <td>Qtd</td>
                                                </tr>
                                                <tr>
                                                    <td>Valor</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>

                                    <td className="data_col">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={third_envio_qtd_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} name={"third_envio_qtd_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={third_envio_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"third_envio_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={third_retorno_qtd_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} name={"third_retorno_qtd_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={third_retorno_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"third_retorno_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={third_envio_qtd_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} name={"third_envio_qtd_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={third_envio_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"third_envio_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={third_retorno_qtd_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} name={"third_retorno_qtd_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={third_retorno_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"third_retorno_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={(total_qtd_envio_third)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} displayType={"text"} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={total_vlr_retornothird} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={total_qtd_retorno_third} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} displayType={"text"} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <NumberFormat value={total_vlr_retorno_third} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ "borderRight": "none", "width": "200px" }}>Água Vitaminada</td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>
                                    <td style={{ "borderLeft": "none", "borderRight": "1px solid  #552cc6" }}></td>

                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={third_agua_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"third_agua_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={third_agua_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"third_agua_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={(total_vlr_agua_third)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ "borderRight": "none", "width": "200px" }}>Seguros</td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>
                                    <td style={{ "borderLeft": "none", "borderRight": "1px solid  #552cc6" }}></td>

                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={third_seguros_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"third_seguros_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={third_seguros_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"third_seguros_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={(total_vlr_seguros_third)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ "borderRight": "none", "width": "200px" }}>Mensalidades</td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>
                                    <td style={{ "borderLeft": "none", "borderRight": "1px solid  #552cc6" }}></td>

                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={third_mensalidades_vlr_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"third_mensalidades_vlr_first"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={third_mensalidades_vlr_rcur} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} name={"third_mensalidades_vlr_rcur"} onChange={e => onChangeNumber(e)} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={(total_vlr_mensalidades_third)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ "borderRight": "none", "width": "200px" }}>Total</td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>
                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}></td>

                                    <td style={{ "borderLeft": "none", "borderRight": "none" }}>

                                    </td>
                                    <td style={{ "borderLeft": "none", "borderRight": "1px solid  #552cc6" }}>

                                    </td>
                                    <td className="data_col" style={{ "borderRight": "1px solid  #552cc6" }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <NumberFormat value={(total_vlr_asm_third)} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={'€'} displayType={"text"} />
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Total Água Vitaminada + Seguros + Mensalidades
                                    </td>
                                    <td>
                                        <NumberFormat value={total_vlr_asm} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={"€"} displayType={"text"} />
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Total Recebidos Mês
                                    </td>
                                    <td>
                                        <NumberFormat value={total_recebido_mes} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={"€"} displayType={"text"} />
                                    </td>
                                </tr>


                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        % Recebida
                                    </td>
                                    <td>

                                        {recebido_perc} %
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Retenção %
                                    </td>
                                    <td>
                                        {total_retencao_perc} %
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Total de Sócios Pagos a DD
                                    </td>
                                    <td>
                                        {total_socios_pages}
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "white", "color": "black"
                                    }}>
                                        1ª Retorno
                                    </td>
                                    <td>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Data Limite ADC
                                    </td>
                                    <td>
                                        <input type="text" name={"refused_adc"} value={refused_adc} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Autorização Inativa
                                    </td>
                                    <td>
                                        <input type="text" name={"refused_aut"} value={refused_aut} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Sem Respota
                                    </td>
                                    <td>
                                        <input type="text" name={"refused_nores"} value={refused_nores} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Conta Encerrada
                                    </td>
                                    <td>
                                        <input type="text" name={"refused_closed"} value={refused_closed} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>  <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Insuficiência de Fundos
                                    </td>
                                    <td>
                                        <input type="text" name={"refused_nofunds"} value={refused_nofunds} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Operação Contestada
                                    </td>
                                    <td>
                                        <input type="text" name={"refused_contest"} value={refused_contest} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Recusado pelo Devedor
                                    </td>
                                    <td>
                                        <input type="text" name={"refused_refused"} value={refused_refused} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Serviço Especifico
                                    </td>
                                    <td>
                                        <input type="text" name={"refused_serv"} value={refused_serv} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>





                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "white", "color": "black"
                                    }}>
                                        2ª Retorno
                                    </td>
                                    <td>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Data Limite ADC
                                    </td>
                                    <td>
                                        <input type="text" name={"two_refused_adc"} value={two_refused_adc} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Autorização Inativa
                                    </td>
                                    <td>
                                        <input type="text" name={"two_refused_aut"} value={two_refused_aut} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Sem Respota
                                    </td>
                                    <td>
                                        <input type="text" name={"two_refused_nores"} value={two_refused_nores} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Conta Encerrada
                                    </td>
                                    <td>
                                        <input type="text" name={"two_refused_closed"} value={two_refused_closed} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>  <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Insuficiência de Fundos
                                    </td>
                                    <td>
                                        <input type="text" name={"two_refused_nofunds"} value={two_refused_nofunds} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Operação Contestada
                                    </td>
                                    <td>
                                        <input type="text" name={"two_refused_contest"} value={two_refused_contest} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Recusado pelo Devedor
                                    </td>
                                    <td>
                                        <input type="text" name={"two_refused_refused"} value={two_refused_refused} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Serviço Especifico
                                    </td>
                                    <td>
                                        <input type="text" name={"two_refused_serv"} value={two_refused_serv} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>




                                {/*  3 ª RETORNO*/}
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "white", "color": "black"
                                    }}>
                                        3ª Retorno
                                    </td>
                                    <td>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Data Limite ADC
                                    </td>
                                    <td>
                                        <input type="text" name={"third_refused_adc"} value={third_refused_adc} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Autorização Inativa
                                    </td>
                                    <td>
                                        <input type="text" name={"third_refused_aut"} value={third_refused_aut} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Sem Respota
                                    </td>
                                    <td>
                                        <input type="text" name={"third_refused_nores"} value={third_refused_nores} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Conta Encerrada
                                    </td>
                                    <td>
                                        <input type="text" name={"third_refused_closed"} value={third_refused_closed} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>  <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Insuficiência de Fundos
                                    </td>
                                    <td>
                                        <input type="text" name={"third_refused_nofunds"} value={third_refused_nofunds} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Operação Contestada
                                    </td>
                                    <td>
                                        <input type="text" name={"third_refused_contest"} value={third_refused_contest} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Recusado pelo Devedor
                                    </td>
                                    <td>
                                        <input type="text" name={"third_refused_refused"} value={third_refused_refused} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{
                                        "textAlign": "left", "paddingLeft": " 20px",
                                        "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                    }}>
                                        Serviço Especifico
                                    </td>
                                    <td>
                                        <input type="text" name={"third_refused_serv"} value={third_refused_serv} onChange={e => onChange(e)} style={{ "textAlign": "center", "border": "0" }} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button className={"new-btn"}>Guardar</button>
                    </form>

                    <table style={{
                        "marginTop": "40px", "marginBottom": "20px", "minWidth": "340px"
                    }}>
                        <thead>
                            <tr>
                                <th colSpan="2" style={{
                                    "textAlign": "left", "paddingLeft": " 20px",
                                    "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                }}> Total Enviados
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{
                                    "textAlign": "center",
                                    "background": "white", "color": "black"
                                }}>1ª Envio</td>
                                <td> <NumberFormat value={total_enviados_geral_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={"€"} displayType={"text"} /> </td>

                            </tr>
                            <tr>
                                <td style={{
                                    "textAlign": "center",
                                    "background": "white", "color": "black"
                                }}>2ª Envio</td>
                                <td> <NumberFormat value={total_enviados_geral_second} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={"€"} displayType={"text"} /> </td>

                            </tr>
                            <tr>
                                <td style={{
                                    "textAlign": "center",
                                    "background": "white", "color": "black"
                                }}>3ª Envio</td>
                                <td> <NumberFormat value={total_enviados_geral_third} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={"€"} displayType={"text"} /> </td>

                            </tr>
                        </tbody>
                    </table>
                    <table style={{
                        "marginBottom": "20px", "minWidth": "340px"
                    }}>
                        <thead>
                            <tr>
                                <th colSpan="2" style={{
                                    "textAlign": "left", "paddingLeft": " 20px",
                                    "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                }}> Total Recebidos
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{
                                    "textAlign": "center",
                                    "background": "white", "color": "black"
                                }}>1ª Retorno</td>
                                <td> <NumberFormat value={total_retorno_geral_first} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={"€"} displayType={"text"} /> </td>

                            </tr>
                            <tr>
                                <td style={{
                                    "textAlign": "center",
                                    "background": "white", "color": "black"
                                }}>2ª Retorno</td>
                                <td> <NumberFormat value={total_retorno_geral_second} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={"€"} displayType={"text"} /> </td>

                            </tr>
                            <tr>
                                <td style={{
                                    "textAlign": "center",
                                    "background": "white", "color": "black"
                                }}>3ª Retorno</td>
                                <td> <NumberFormat value={total_retorno_geral_third} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={"€"} displayType={"text"} /> </td>

                            </tr>
                        </tbody>
                    </table>
                    <table style={{
                        "marginBottom": "20px", "minWidth": "340px"
                    }}>
                        <tbody>
                            <tr>
                                <td style={{
                                    "textAlign": "left", "paddingLeft": " 20px",
                                    "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                }}>DD Recebidos Gymnasium
                                </td>
                                <td> <NumberFormat value={total_recebido_mes_geral} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} suffix={"€"} displayType={"text"} /> </td>
                            </tr>
                            <tr>
                                <td style={{
                                    "textAlign": "left", "paddingLeft": " 20px",
                                    "background": "linear-gradient(45deg, #552cc6 0%, #be7bef 100%)", "color": "white"
                                }}> Total Sócios Pagos a DD </td>
                                <td> <NumberFormat value={total_socios_pagos_geral} fixedDecimalScale={true} thousandSeparator={"."} decimalSeparator={","} displayType={"text"} /> </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className={modal ? "new-lead-modal active" : "new-lead-modal"}>
                        <span id="close-new-lead-modal" onClick={e => onCloseModal(e)}>
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus"
                                className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512"><path fill="currentColor"
                                    d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                        </span>
                        <form onSubmit={e => onCreateNewDD(e)}>
                            <div className="ojectivos-receitas-container objetivos-v2-container">
                                <label htmlFor="">
                                    Nome:
                                    <input type="text" name="nome_dd" value={nome_dd} placeholder="Nome DD Mês" onChange={e => onChange(e)} />
                                </label>
                                <label htmlFor="">
                                    Mês:
                                    <input type="month" name="date_dd" value={date_dd} placeholder="DD Mês" onChange={e => onChange(e)} />
                                </label>
                                <button>GUARDAR</button>
                            </div>
                        </form>
                    </div>


                </div>
            </div>

        </Fragment>
};
BancoDDAdministracao.propTypes = {
    auth: PropTypes.object.isRequired,
    getBancoDD: PropTypes.func.isRequired,
    updateBancoDD: PropTypes.func.isRequired,
    newBancoDD: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    bancodd: state.bancodd
});
export default connect(mapStateToProps, { getBancoDD, updateBancoDD, newBancoDD })(BancoDDAdministracao);
