/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_INSTRUTOR_SALA,
    GET_INSTRUTOR_SALA_ERROR,
} from "./types";

export const getInstrutorSalaAvaliacoes = ({id_clube}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { id_clube };
    try {
        const res = await axios.post("/api/instrutorsala", body , config);

        dispatch({
            type: GET_INSTRUTOR_SALA,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_INSTRUTOR_SALA_ERROR,
        })
    }
}


export const updateInstrutorSala = ({  instrutorSalaData, id_clube }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { instrutorSalaData, id_clube };
    try {
        const res = await axios.post("/api/instrutorsala/update", body, config);


        dispatch({
            type: GET_INSTRUTOR_SALA,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_INSTRUTOR_SALA_ERROR,
        })
    }
}



export const deleteInstrutorSala = ({  id_clube, id }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { id_clube, id };
    try {
        const res = await axios.post("/api/instrutorsala/delete", body, config);


        dispatch({
            type: GET_INSTRUTOR_SALA,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_INSTRUTOR_SALA_ERROR,
        })
    }
}