/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import DashboardAdmin from "../../administracao/layout/Dashboard";
import ContactosNaoConvertidosRecepcao from "../../recepcao/gestaocomercial/ContactosNaoConvertidos";
import ContactosNaoConvertidosAdministracao from "../../administracao/gestaocomercial/ContactosNaoConvertidos";
import ContactosNaoConvertidosDirectorClube from "../../diretorclube/gestaocomercial/ContactosNaoConvertidos";

const ContactosNaoConvertidos = ({auth: {user}}) => {
    const [leadsData, setLeadsData] = useState({
        initState: ""
    });
    const { initState } = leadsData;
    const onChange = e => setLeadsData({...leadsData, [e.target.name]: e.target.value});

    return user === null ? <Fragment>
            <div><img src="./img/loading.gif" alt=""/></div>
        </Fragment> :
        <Fragment>
            {(function() {
                switch (user.id_level) {
                    case 0:
                        return <ContactosNaoConvertidosAdministracao />;
                    case 1:
                        return <ContactosNaoConvertidosDirectorClube />;
                    case 2:
                        return <ContactosNaoConvertidosRecepcao />;
                    default:
                        return null;
                }
            })()}
        </Fragment>

};

ContactosNaoConvertidos.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(mapStateToProps, {})( ContactosNaoConvertidos );
