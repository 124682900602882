/**
 * Created by danielsilva on 11/04/2020.
 */
import axios from "axios";
import {
    GET_COLABORADORES,
    GET_COLABORADORES_ERROR,
} from "./types";

 export const getColaboradores = ({nome_colaborador_filtro}) => async dispatch => {
     const config = {
         header: {
             "Content-Type": "application/json"
         }
     }
     
     const body = { nome_colaborador_filtro };
    try {
        
         const res = await axios.post("/api/colaboradores", body, config);
      

        dispatch({
            type: GET_COLABORADORES,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_COLABORADORES_ERROR,
        })
    }
}


export const insertColaboradores = ({ nome_co, tel_co, funcao_co, morada_co, iban_co, email_gym_co, email_pessoal_co, tipo_contrato_co, inicio_contrato_co, fim_contrato_co, id_clube_co, efetivo_co, id }) => async dispatch => {
    
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
   
    const body = { nome_co, tel_co, funcao_co, morada_co ,iban_co, email_gym_co, email_pessoal_co, tipo_contrato_co, inicio_contrato_co, fim_contrato_co, id_clube_co, efetivo_co, id  };
    try {
        
        const res = await axios.post("/api/colaboradores/new", body, config);

        dispatch({
            type: GET_COLABORADORES,
            payload: res.data
        })

    } catch (err) {
        dispatch({
            type: GET_COLABORADORES_ERROR,
        })
    }
}

export const deleteColaboradores= ({id_clube_co, id }) => async dispatch => {
   
   const config = {
        header: {
            "Content-Type": "application/json"
        }
    }

    const body = {id_clube_co, id };

    try {
        
        const res = await axios.post("/api/colaboradores/delete", body, config);

        dispatch({
            type: GET_COLABORADORES,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_COLABORADORES_ERROR,
        })
    }
}

