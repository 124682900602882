/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import '../../../styles/header.css';
import '../../../styles/dashboard.css';
import { logout } from "../../../actions/auth";

const Header = ({auth: {user}, logout}) => {
    const [headerData, setHeaderData] = useState({
        initState: "",
        menu: false,
    });
    const { initState, menu } = headerData;
    const onChange = e => setHeaderData({...headerData, [e.target.name]: e.target.value});
    function changeBackground(e) {
        e.target.classList.toggle('active');
    }
    const onOpenMenu = async e => {
        setHeaderData({...headerData, ["menu"]: menu ? false : true});
    }
    return user === null ? <Fragment>
            <div><img src="./img/loading.gif" alt=""/></div>
        </Fragment> :
        <Fragment>
            <div className={menu ? "mobile-menu-container-items active"  : "mobile-menu-container-items"}>
                 <span id="close-new-lead-modal" onClick={ e => onOpenMenu(e)}>
                    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                </span>
                <Link to={"/dashboard"} className="btn btn-default">Dashboard</Link>
                <Link to={"/rota_pt"} className="btn btn-default">Rota PT</Link>
                <Link to={"/socios"} className="btn btn-default">Sócios</Link>
                <a onClick={logout}>Logout</a>
            </div>

            <div className="page-header text-center">
                <div className="menu-container">
                    <div className="logo-bg">
                        <img src="./img/logo.png" alt="Logo-dashboard" />
                    </div>

                    <div className="menu-container-items">
                        <span className="in-menu"  >
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="home-alt" className="svg-inline--fa fa-home-alt fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M541 229.16l-232.85-190a32.16 32.16 0 0 0-40.38 0L35 229.16a8 8 0 0 0-1.16 11.24l10.1 12.41a8 8 0 0 0 11.2 1.19L96 220.62v243a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-128l64 .3V464a16 16 0 0 0 16 16l128-.33a16 16 0 0 0 16-16V220.62L520.86 254a8 8 0 0 0 11.25-1.16l10.1-12.41a8 8 0 0 0-1.21-11.27zm-93.11 218.59h.1l-96 .3V319.88a16.05 16.05 0 0 0-15.95-16l-96-.27a16 16 0 0 0-16.05 16v128.14H128V194.51L288 63.94l160 130.57z"></path></svg>
                            <Link to={"/dashboard"} className="btn btn-default">Dashboard</Link>
                         </span>

                        <span className="in-menu serviceclient-icon">
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="user-headset" className="svg-inline--fa fa-user-headset fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M320 352h-4.7c-12.16 0-24 2.9-35.5 6.8a173.76 173.76 0 0 1-111.64 0c-11.48-3.9-23.29-6.78-35.42-6.78H128A128 128 0 0 0 0 480a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32 128 128 0 0 0-128-128zM32 480a96.1 96.1 0 0 1 96-96h4.74c6.92 0 14.92 1.62 25.16 5.09a205.75 205.75 0 0 0 132.16 0c10.31-3.49 18.33-5.11 25.24-5.11h4.7a96.1 96.1 0 0 1 96 96zm16-256a16 16 0 0 0 16-16v-16c0-88.22 71.78-160 160-160s160 71.78 160 160v16a80.09 80.09 0 0 1-80 80h-32a32 32 0 0 0-32-32h-32a32 32 0 0 0 0 64h96a112.14 112.14 0 0 0 112-112v-16C416 86.12 329.88 0 224 0S32 86.12 32 192v16a16 16 0 0 0 16 16zM224 96a95.57 95.57 0 0 1 71.23 159.76c0 .09.13.15.18.24H304a47.89 47.89 0 0 0 40.55-22.58C349 220.36 352 206.58 352 192a128 128 0 0 0-256 0c0 40.42 19.1 76 48.35 99.47-.06-1.17-.35-2.28-.35-3.47a63.25 63.25 0 0 1 8.93-32A95.58 95.58 0 0 1 224 96z"></path></svg>
                            Serviço ao Cliente
                            <ul>
                                <div>
                                    <li> <Link to={"/rota_pt"} className="btn btn-default">Rota PT</Link>                    </li>
                                    <li> <Link to={"/socios"} className="btn btn-default">Sócios</Link>                      </li>
                                </div>
                            </ul>
                        </span>

                        <span className="in-menu">
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="sign-out" className="svg-inline--fa fa-sign-out fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M48 64h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-8.8 0-16 7.2-16 16v288c0 8.8 7.2 16 16 16h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48zm279 19.5l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l132 131.4H172c-6.6 0-12 5.4-12 12v10c0 6.6 5.4 12 12 12h279.9L320 404.4c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l164.5-164c4.7-4.7 4.7-12.3 0-17L344 83.5c-4.7-4.7-12.3-4.7-17 0z"></path></svg>
                            <a onClick={logout}>Logout</a>
                         </span>

                    </div>
                </div>
                <div className="icon-menu-mobile" onClick={e => onOpenMenu(e)}>
                    <div className="top-bar bar"></div>
                    <div className="middle-bar bar"></div>
                    <div className="bottom-bar bar"></div>
                </div>
            </div>
        </Fragment>
};

Header.propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(mapStateToProps, { logout })(Header);
