import React from "react";

const Pagination = ({ postsPerPAge, totalPosts, paginate, currentPage}) => {
    const pageNumber = [];

    for(let i =1; i <= Math.ceil(totalPosts / postsPerPAge); i++) {
        pageNumber.push(i);
    }

    return (
        <nav>
            <ul className="pagination">
                {pageNumber.map(number => (
                    <li key={number} className={currentPage === number ? "page-item active": "page-item"}>
                        <a className={"page-link"} onClick={(e) => paginate(e,number)}>{number}</a>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default Pagination;