/**
 * Created by danielsilva on 10/04/2020.
 */
import React,{Fragment, useState, useEffect,useContext } from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {logout} from "../../actions/auth";
import '../../styles/dashboard.css';
// ADMIN ROUTES FOLDER
import DashboardAdmin from "../administracao/layout/Dashboard";
// DIRETOR ROUTES FOLDER
import DashboardDiretorClube from "../diretorclube/layout/Dashboard";
// RECEPCAO ROUTES FOLDER
//import DashboardRecepcao from "../diretorclube/layout/Dashboard";
import DashboardRecepcao from "../recepcao/gestaocomercial/Agenda";

import RelatorioRotaPT from "../ptsenior/gestaoclube/RelatorioRotaPT";

import RotaPTPersonalTrainer from "../personaltrainer/servicocliente/RotaPT";
import ManutencaoManutencao from "../manutencao/servicocliente/Manutencao";

import RotaNutricaoNutricao from "../nutricao/servicocliente/RotaNutricao";
import RotaAcupunturaAcupuntor from "../acupuntura/servicocliente/RotaAcupuntura";

const Dashboard = ({auth: { user } }) => {
    return user === null ? <Fragment><div> <img src="/img/loading.gif" alt=""/></div></Fragment> : <Fragment>
        {(function() {
            switch (user.id_level) {
                case 0:
                    return <DashboardAdmin />;
                case 1:
                    return <DashboardDiretorClube />;
                case 2:
                    return <DashboardRecepcao />;
                case 4:
                    return <RotaPTPersonalTrainer />;
                case 5:
                    return <RotaNutricaoNutricao />;
                case 6:
                    return <ManutencaoManutencao />;
                case 7:
                    return <RotaAcupunturaAcupuntor />;
                case 8:
                    return <RelatorioRotaPT />

                default:
                    return null;
            }
        })()}
    </Fragment>
};

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(mapStateToProps, {})( Dashboard );
