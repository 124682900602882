/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import '../../../styles/header.css';
import '../../../styles/dashboard.css';
import { logout } from "../../../actions/auth";

const Header = ({auth: {user}, logout}) => {
    const [headerData, setHeaderData] = useState({
        initState: "",
        menu: false,
    });
    const { initState, menu } = headerData;
    const onChange = e => setHeaderData({...headerData, [e.target.name]: e.target.value});
    function changeBackground(e) {
        e.target.classList.toggle('active');
    }
    const onOpenMenu = async e => {
        setHeaderData({...headerData, ["menu"]: menu ? false : true});
    }
    return user === null ? <Fragment>
            <div><img src="./img/loading.gif" alt=""/></div>
        </Fragment> :
        <Fragment>
            <div className={menu ? "mobile-menu-container-items active"  : "mobile-menu-container-items"}>
                 <span id="close-new-lead-modal" onClick={ e => onOpenMenu(e)}>
                    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                </span>
                <Link to={"/dashboard"} className="btn btn-default">Dashboard</Link>
                <a onClick={logout}>Logout</a>
            </div>

            <div className="page-header text-center">
                <div className="menu-container">
                    <div className="logo-bg">
                        <img src="./img/logo.png" alt="Logo-dashboard" />
                    </div>

                    <div className="menu-container-items">
                        <span className="in-menu"  >
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="home-alt" className="svg-inline--fa fa-home-alt fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M541 229.16l-232.85-190a32.16 32.16 0 0 0-40.38 0L35 229.16a8 8 0 0 0-1.16 11.24l10.1 12.41a8 8 0 0 0 11.2 1.19L96 220.62v243a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-128l64 .3V464a16 16 0 0 0 16 16l128-.33a16 16 0 0 0 16-16V220.62L520.86 254a8 8 0 0 0 11.25-1.16l10.1-12.41a8 8 0 0 0-1.21-11.27zm-93.11 218.59h.1l-96 .3V319.88a16.05 16.05 0 0 0-15.95-16l-96-.27a16 16 0 0 0-16.05 16v128.14H128V194.51L288 63.94l160 130.57z"></path></svg>
                            <Link to={"/dashboard"} className="btn btn-default">Dashboard</Link>
                         </span>
                        <span className="in-menu">
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="sign-out" className="svg-inline--fa fa-sign-out fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M48 64h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-8.8 0-16 7.2-16 16v288c0 8.8 7.2 16 16 16h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48zm279 19.5l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l132 131.4H172c-6.6 0-12 5.4-12 12v10c0 6.6 5.4 12 12 12h279.9L320 404.4c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l164.5-164c4.7-4.7 4.7-12.3 0-17L344 83.5c-4.7-4.7-12.3-4.7-17 0z"></path></svg>
                            <a onClick={logout}>Logout</a>
                         </span>

                    </div>
                </div>
                <div className="icon-menu-mobile" onClick={e => onOpenMenu(e)}>
                    <div className="top-bar bar"></div>
                    <div className="middle-bar bar"></div>
                    <div className="bottom-bar bar"></div>
                </div>
            </div>
        </Fragment>
};

Header.propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});
export default connect(mapStateToProps, { logout })(Header);
